import * as events from "../Events";
const initialState = {
  scheduletime: [],
  ivrnumbers: [],
};
export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.ADD_CALL_SCHEDULE:
      return {
        ...state,
        scheduletime: Array.isArray(action.payload)
          ? action.payload
          : [action.payload, ...state.scheduletime],
      };
    case events.EDIT_CALL_SCHEDULE:
      return {
        ...state,
        scheduletime: state.scheduletime.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case events.DELETE_CALL_SCHEDULE:
      return {
        ...state,
        scheduletime: state.scheduletime.filter(
          (item) => item.id !== action.payload
        ),
      };
    case events.ADD_IVR_NUMBERS:
      return {
        ...state,
        ivrnumbers: Array.isArray(action.payload)
          ? action.payload
          : [action.payload, ...state.ivrnumbers],
      };
    case events.EDIT_IVR_NUMBERS:
      return {
        ...state,
        ivrnumbers: state.ivrnumbers.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case events.DELETE_IVR_NUMBERS:
      return {
        ...state,
        ivrnumbers: state.ivrnumbers.filter(
          (item) => item.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
