import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllInvitedMembers = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/invited-members`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const invites = resData.data;
        dispatch({ type: events.GET_ALL_TEAM_INVITES, data: invites });
        return invites ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const inviteTeamMembers = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/invite`, {
        method: "Post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newTeamMember = resData.data;
        dispatch({ type: events.ADD_TEAMMEMBER, data: newTeamMember });
        return newTeamMember ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const updateInvitedMember = (from, id, data) => {
  const url =
    from === "invited"
      ? `${events.API_URL}/invite-permissions/${id}`
      : `${events.API_URL}/team-permissions/${id}`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const updatedMember = resData.data;
        dispatch({
          type: events.UPDATE_TEAMINVITES_PERMISSIONS,
          data: updatedMember,
        });
        return updatedMember ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
// DELETE_TEAM_INVITE
export const deleteInviteMember = (from, id) => {
  const url =
    from === "invited"
      ? `${events.API_URL}/invited-members/${id}`
      : `${events.API_URL}/team-members/${id}`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedMember = resData.data;
        dispatch({
          type: events.DELETE_TEAM_INVITE,
          data: deletedMember,
        });
        return deletedMember ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getAllTeamMembers = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/team-members`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const teammembers = resData.data;
        dispatch({ type: events.GET_TEAM_MEMBERS, data: teammembers });
        return teammembers ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
