import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchLeftArrow = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Prev_arrow_inactive_icon" transform="translate(-926 -530)">
      <Rect
        id="Rectangle_414"
        data-name="Rectangle 414"
        width={aw(24)}
        height={ah(24)}
        transform="translate(926 530)"
        fill="none"
      />
      <Path
        id="Path_10291"
        data-name="Path 10291"
        d="M16.914-8.066a.667.667,0,0,0,.42-.234A.673.673,0,0,0,17.5-8.75a.662.662,0,0,0-.2-.488.662.662,0,0,0-.488-.2H4.824L9.16-13.809l.078-.078a.8.8,0,0,0,.127-.469A.655.655,0,0,0,9.16-14.8a.651.651,0,0,0-.479-.2.651.651,0,0,0-.479.2L2.715-9.258a.147.147,0,0,1-.049.059.147.147,0,0,0-.049.059.67.67,0,0,0-.117.449.66.66,0,0,0,.2.43L8.2-2.7l.078.059A.618.618,0,0,0,8.73-2.5a.66.66,0,0,0,.43-.2.718.718,0,0,0,.2-.5.718.718,0,0,0-.2-.5L4.824-8.066Z"
        transform="translate(928 551)"
        fill={props.disabled ? "#c1c1c1" : "#212121"}
      />
    </G>
  </Svg>
);
export default LeadsWatchLeftArrow;
