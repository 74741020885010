import { React, useState, useEffect } from "react";

import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Hidden,
  useBreakpointValue,
  Flex,
  Menu,
  Box,
  ScrollView,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import { aw, ah } from "../../utils";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchCheckboxMenu from "../../components/LeadsWatchCheckboxMenu";

import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";
import {
  getVerticals,
  getCampaigns,
  getBuyers,
  getPublishers,
  download,
  getLeads,
} from "../../store/actions/reports";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import moment from "moment/moment";

const IVR = ({ isOpen }) => {
  const [downloading, setDownloading] = useState(false);
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const allVerticals = useSelector((state) => state.reports.verticals);
  const allCampaigns = useSelector((state) => state.reports.campaigns);
  const allBuyers = useSelector((state) => state.reports.buyers);
  const allPublishers = useSelector((state) => state.reports.publishers);
  const profile = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();

  const leads = useSelector((state) => state.reports.leads);
  const [currentPage, setCurrentPage] = useState(
    page && page.reportLeads ? parseInt(page.reportLeads) : 1
  );

  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.leadsReports ? parseInt(pagecount.leadsReports) : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({});
  const [filters1, setFilters1] = useState({});
  const [sort, setSort] = useState({});

  const [selectedValues, setSelectedValues] = useState({});

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const { control, reset, getValues } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.leadsReports
          ? pagecount.leadsReports.toString()
          : "10",
      campaign_id: "",
      publisher_id: "",
      buyer_id: "",
    },
  });
  const [fields, setFields] = useState([
    "date",
    "publisher",
    "buyer",
    "campaign",
  ]);
  const handleChangeSelect = (value) => {
    setSelectCount(value);
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, leadsReports: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, reportLeads: 1 }));
  };
  useEffect(() => {
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    setEndDate(new Date());
    setFilters({
      start_date: moment(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    });
  }, []);
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
    }
  }, [filters.start_date, filters.end_date]);
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);
      let date1 = moment(date);
      let date2 = moment(filters.end_date);
      let difference = date2.diff(date1, "days");

      if (difference > 30 || difference < 0) {
        setEndDate(moment(date).add(30, "days").toDate());
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
          end_date: moment(date).add(30, "days").format("YYYY-MM-DD"),
        });
      } else {
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
        });
      }
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };

  const fetchAllLeads = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        getLeads(currentPage, selectCount, { ...filters1, type: "ivr" })
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setIsLoading(false);
  };
  const fetchAllVerticals = async () => {
    try {
      await dispatch(getVerticals(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllBuyers = async () => {
    try {
      await dispatch(getBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllPublishers = async () => {
    try {
      await dispatch(getPublishers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      await dispatch(getCampaigns(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  // useEffect(() => {
  //   fetchAllVerticals();
  //   getData();
  //   fetchAllBuyers();
  //   fetchAllPublishers();
  // }, []);
  useEffect(() => {
    if (filters1.start_date && filters1.end_date) {
      if (profile && profile.role === "user") {
        if (profile.permission_ids.lead.includes("view")) {
          fetchAllLeads();
        }
      } else {
        fetchAllLeads();
      }
    }
  }, [currentPage, selectCount, sort, filters1]);

  const handleFilters = () => {
    setFilters1(filters);
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, reportLeads: 1 }));
    setOpenFilter(false);
  };
  const requiredData = leads
    ? leads.list.map((val) => {
        return {
          _id: val._id,
          buyer: val.buyer_name,
          publisher: val.publisher_name,
          vertical: val.vertical_name,
          campaign: val.campaign_name,
          response_id: val.response_id,
          system_response: val.system_response,
          buyer_response: val.buyer_response,
          created: val.created,
          cost: val.cost,
          price: val.price,
          profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,

          email: val.details
            ? val.details.find((item) => item.name === "Email")?.value
            : null,
          phone: val.details
            ? val.details.find((item) => item.name === "Phone")?.value
            : null,
        };
      })
    : [];
  const loadOptions = async (inputValue, callback, list, defaultOptions) => {
    try {
      const data =
        list === "vertical"
          ? await dispatch(getVerticals(1, 10, undefined, inputValue))
          : list === "campaign"
          ? await dispatch(
              getCampaigns(1, 10, undefined, undefined, inputValue)
            )
          : list === "buyer"
          ? await dispatch(getBuyers(1, 10, undefined, inputValue))
          : await dispatch(
              getPublishers(1, 10, undefined, undefined, inputValue)
            );

      callback(
        list === "campaign"
          ? data &&
              data.list.map((val) => ({
                value: val.unique_id,
                label: `${val.name}(${val.unique_id})`,
              }))
          : list === "publisher" || list === "buyer"
          ? data &&
            data.list.map((val) => ({
              value: val._id,
              label: `${val.firstname} ${val.lastname}`,
            }))
          : data &&
            data.list.map((val) => ({
              value: val._id,
              label: val.name,
            }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const defaultOptions = allVerticals
    ? allVerticals.list.map((val) => ({ value: val._id, label: val.name }))
    : [];
  const defaultOptionsForCam = allCampaigns
    ? allCampaigns.list.map((val) => ({
        value: val.unique_id,
        label: `${val.name}( ${val.unique_id})`,
      }))
    : [];
  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForPub = allPublishers
    ? allPublishers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForRes = [
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Trash",
      label: "Trash",
    },
    {
      value: "Duplicate",
      label: "Duplicate",
    },
    {
      value: "Failure",
      label: "Failure",
    },
  ];

  const handleOptionChange = (key, selectedOption) => {
    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  const handleDownload = async (type) => {
    setDownloading(true);
    try {
      await dispatch(
        download(
          currentPage,
          selectCount,
          { filters: { ...filters1, type: "ivr" } },
          "leads",
          type
        )
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setDownloading(false);
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <Hidden only={["sm", "base"]}>
            <VStack
              height={ah(35)}
              mt={ah(10)}
              // width={[aw(350), aw(350), aw(1248)]}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
              zIndex={1}
            >
              <HStack justifyContent={"space-between"}>
                <HStack height={ah(32)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Show Entries :{" "}
                  </Text>
                  <Center>
                    <LeadsWatchSelect
                      control={control}
                      name={"select"}
                      list={["10", "15", "20", "25"]}
                      // value={selectCount}
                      handleChange={(value) =>
                        handleChangeSelect(parseInt(value))
                      }
                      width={75}
                      height={ah(32)}
                    />
                  </Center>
                </HStack>

                <HStack space={aw(12)} width={aw(733)}>
                  <LeadsWatchMenu
                    width={aw(120)}
                    mr={aw(5)}
                    title={
                      // <Pressable>
                      <Flex
                        direction="row"
                        borderColor="#D1D5DB"
                        bg="#FAFBFD"
                        borderWidth={aw(1)}
                        width={aw(115)}
                        height={ah(32)}
                        borderRadius={aw(5)}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        {downloading ? null : <LeadsWatchDownloadIcon />}
                        <Text
                          fontFamily="RobotoRegular"
                          color="black"
                          fontSize={aw(14)}
                        >
                          {downloading ? "Downloading..." : "Download"}
                        </Text>
                      </Flex>
                      // </Pressable>
                    }
                  >
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => handleDownload("csv")}
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color="#212121"
                      >
                        CSV
                      </Text>
                    </Menu.Item>
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => handleDownload("xlsx")}
                    >
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color="#212121"
                      >
                        XLSX
                      </Text>
                    </Menu.Item>
                  </LeadsWatchMenu>
                  <HStack>
                    <LeadsWatchCalendar
                      startDate={startDate}
                      handleFromDate={handleFromDate}
                      handleEndDate={handleEndDate}
                      width={aw(141)}
                      height={ah(30)}
                      endDate={endDate}
                    />
                  </HStack>
                  <HStack>
                    <LeadsWatchCheckboxMenu
                      sheight={ah(250)}
                      arr={[
                        "date",
                        // "email",
                        // "phone",
                        "buyer",
                        // "system_response",
                        "publisher",
                        // "buyer_response",
                        "campaign",
                        // "vertical",
                        "cost",
                        "price",
                        "profit",
                        "id",
                      ]}
                      title="Select Fields"
                      height={ah(32)}
                      width={aw(160)}
                      value={fields}
                      handleChange={(value) => {
                        if (value.includes("id")) {
                          setFields(
                            value.map((val) => {
                              if (val === "id") {
                                return "_id";
                              } else {
                                return val;
                              }
                            })
                          );
                        } else {
                          setFields(value);
                        }
                      }}
                    />
                    <Flex
                      position="absolute"
                      right="-2"
                      top="-8"
                      borderColor="#C1C1C1"
                      bg="#2230F8"
                      borderRadius="50%"
                      size={aw(17)}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text
                        color="#FFFFFF"
                        fontFamily="RobotoMedium"
                        fontSize={aw(11)}
                      >
                        {fields.length}
                      </Text>
                    </Flex>
                  </HStack>
                  <LeadsWatchButton
                    bg="#FFFFFF"
                    onPress={() => setOpenFilter(!openFilter)}
                    leftIcon={<LeadsWatchFilterIcon />}
                    label={"Filter"}
                    style={{
                      width: aw(115),
                      height: ah(32),
                      borderColor: "#D1D5DB",
                    }}
                    fontWeight="400"
                  />
                  {openFilter && (
                    <Box
                      px={aw(10)}
                      position="absolute"
                      zIndex={1}
                      top={ah(35)}
                      right={aw(98)}
                      width={aw(570)}
                      minH={ah(180)}
                      // maxH={ah(500)}
                      py={ah(5)}
                      // my={ah(10)}
                      bgColor="#FFFFFF"
                      borderColor="#E1E1E1"
                      style={{
                        shadowColor: "#00000033",
                        shadowRadius: aw(20),
                      }}
                      borderWidth={aw(1)}
                      borderRadius={aw(6)}
                    >
                      <ScrollView maxH={ah(400)} px={aw(10)} py={ah(15)}>
                        <Flex direction="row" wrap="wrap" gap={ah(20)}>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("vertical") &&
                                getValues("vertical").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptions}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "vertical")
                              }
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "vertical_id",
                                  selectedOption
                                )
                              }
                              onFocus={() => {
                                if (allVerticals) {
                                  if (allVerticals.list.length < 10) {
                                    fetchAllVerticals();
                                  }
                                } else {
                                  fetchAllVerticals();
                                }
                              }}
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllVerticals();
                                  // return inputValue;
                                }
                              }}
                              placeholder="Verticals"
                              onChange={(selectedOption) =>
                                handleOptionChange(
                                  "vertical_id",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="vertical"
                            />
                          </Flex>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("campaign") &&
                                getValues("campaign").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "campaign_id",
                                  selectedOption
                                )
                              }
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "campaign")
                              }
                              onFocus={() => {
                                if (allCampaigns) {
                                  if (allCampaigns.list.length < 10) {
                                    getData();
                                  }
                                } else {
                                  getData();
                                }
                              }}
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  getData();
                                  // return inputValue;
                                }
                              }}
                              defaultOptions={defaultOptionsForCam}
                              control={control}
                              placeholder="Campaigns"
                              name="campaign"
                            />
                          </Flex>
                          {profile && profile.role !== "publisher" && (
                            <Flex gap={ah(3)} maxW={"96%"}>
                              <LeadsWatchAsyncSelect
                                isMulti={true}
                                screen={
                                  getValues("buyer") &&
                                  getValues("buyer").length > 0
                                    ? "edit"
                                    : "create"
                                }
                                minWidth={aw(250)}
                                type="async"
                                defaultOptions={defaultOptionsForBuyers}
                                loadOptions={(inputValues, callback) =>
                                  loadOptions(inputValues, callback, "buyer")
                                }
                                onInputChange={(inputValues) => {
                                  if (inputValues === "") {
                                    fetchAllBuyers();

                                    // return inputValue;
                                  }
                                }}
                                onFocus={() => {
                                  if (allBuyers) {
                                    if (allBuyers.list.length < 10) {
                                      fetchAllBuyers();
                                    }
                                  } else {
                                    fetchAllBuyers();
                                  }
                                }}
                                handleChange={(selectedOption) =>
                                  handleOptionChange("buyer_id", selectedOption)
                                }
                                control={control}
                                placeholder="Buyers"
                                name="buyer"
                              />
                            </Flex>
                          )}
                          {profile && profile.role !== "publisher" && (
                            <Flex gap={ah(3)} maxW={"96%"}>
                              <LeadsWatchAsyncSelect
                                isMulti={true}
                                screen={
                                  getValues("publisher") &&
                                  getValues("publisher").length > 0
                                    ? "edit"
                                    : "create"
                                }
                                minWidth={aw(250)}
                                type="async"
                                defaultOptions={defaultOptionsForPub}
                                loadOptions={(inputValues, callback) =>
                                  loadOptions(
                                    inputValues,
                                    callback,
                                    "publisher"
                                  )
                                }
                                onInputChange={(inputValues) => {
                                  if (inputValues === "") {
                                    fetchAllPublishers();
                                    // return inputValue;
                                  }
                                }}
                                onFocus={() => {
                                  if (allPublishers) {
                                    if (allPublishers.list.length < 10) {
                                      fetchAllPublishers();
                                    }
                                  } else {
                                    fetchAllPublishers();
                                  }
                                }}
                                placeholder="Publishers"
                                handleChange={(selectedOption) =>
                                  handleOptionChange(
                                    "publisher_id",
                                    selectedOption
                                  )
                                }
                                control={control}
                                name="publisher"
                              />
                            </Flex>
                          )}
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("system_respons") &&
                                getValues("system_response").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              defaultOptions={defaultOptionsForRes}
                              placeholder="System Response"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "system_response",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="system_response"
                            />
                          </Flex>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("buyer_response") &&
                                getValues("buyer_response").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              defaultOptions={defaultOptionsForRes}
                              placeholder="Buyer Response"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "buyer_response",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="buyer_response"
                            />
                          </Flex>
                          <Flex gap={ah(3)} maxW={"96%"}></Flex>
                        </Flex>
                        <Flex
                          width={aw(520)}
                          justifyContent={"flex-end"}
                          direction="row"
                          gap={aw(10)}
                          zIndex={-1}
                          mt={ah(10)}
                        >
                          <LeadsWatchButton
                            label="Cancel"
                            onPress={() => {
                              setOpenFilter(false);
                              reset({
                                vertical: "",
                                campaign: "",
                                publisher: "",
                                buyer: "",
                                buyer_response: "",
                                system_response: "",
                                report_filters: "",
                              });

                              if (Object.keys(filters).length > 2) {
                                setSelectedValues({});
                                setFilters({
                                  start_date: filters.start_date,
                                  end_date: filters.end_date,
                                });
                              }
                              if (Object.keys(filters1).length > 2) {
                                setFilters1({
                                  start_date: filters1.start_date,
                                  end_date: filters1.end_date,
                                });
                              }
                            }}
                            style={{ height: ah(32) }}
                          />
                          <LeadsWatchButton
                            onPress={handleFilters}
                            label="Apply"
                            style={{
                              height: ah(32),
                            }}
                            bg="#2230F8"
                            color="#FFFFFF"
                            borderColor="#05BBC9"
                          />
                        </Flex>
                      </ScrollView>
                    </Box>
                  )}
                </HStack>
              </HStack>
            </VStack>
          </Hidden>
          <VStack>
            <VStack mt={3}>
              <LeadsWatchTable
                isOpen={isOpen}
                sort={sort}
                heading={
                  profile && profile.role === "publisher"
                    ? [...fields.filter((val) => val !== "buyer")]
                    : fields
                }
                screen="reports"
                //   handleSort={handleSort}

                // data={allLeads && allLeads.list && allLeads.list}
                data={requiredData}
                // filter={openFilter}
                isLoading={isLoading}
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={leads && leads.total_count && leads.total_count}
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, reportLeads: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};
export default IVR;
