import * as events from "../Events";
const token = localStorage.getItem("auth");

export const login = (email, password, team, role) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          team,
          role,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;

        dispatch({ type: events.SET_USER, data: user });
        localStorage.setItem("auth", user.token);
        localStorage.setItem("userId", user._id);
        localStorage.setItem("email", user.email);
        localStorage.setItem("firstName", user.firstname);
        localStorage.setItem("lastName", user.lastname);
        localStorage.setItem("phone", user.phone);
        localStorage.setItem("company", user.company);
        localStorage.setItem("time_zone", user.time_zone);
        localStorage.setItem("verify_email", user.verify_email);
        localStorage.setItem("image", user.image);
        localStorage.setItem("logo", user.logo);
        localStorage.setItem("voice_id", user.voice_id);

        window.location.reload(false);
        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const register = (
  firstname,
  lastname,
  email,
  inviteCode,
  company,
  phone,
  id,
  token
) => {
  console.log(id);
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/register1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstname: firstname,
          lastname: lastname,
          email: email,
          invitation_code: inviteCode,
          company: company,
          phone: phone,
          id: id,
          token: token,
        }),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;
        dispatch({ type: events.REGISTER, data: user });
        localStorage.setItem("auth", user.token);
        localStorage.setItem("userId", user._id);
        localStorage.setItem("email", user.email);
        localStorage.setItem("firstName", user.firstname);
        localStorage.setItem("lastName", user.lastname);
        localStorage.setItem("phone", user.phone);
        localStorage.setItem("company", user.company);
        localStorage.setItem("time_zone", user.time_zone);
        localStorage.setItem("verify_email", user.verify_email);
        localStorage.setItem("image", user.image);
        localStorage.setItem("logo", user.logo);
        localStorage.setItem("voice_id", user.voice_id);

        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const forgotPassword = (email, team, role) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/forgot`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          team: team,
          role: role,
        }),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;
        dispatch({ type: events.FORGOT_PASSWORD, data: user });
        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const resetPassword = (
  email,
  password,
  confirmpassword,
  otp,
  team,
  role
) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
          confirmpassword: confirmpassword,
          otp: otp,
          team: team,
          role: role,
        }),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;
        dispatch({ type: events.RESET_PASSWORD, data: user });
        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const updateProfile = (data) => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/upload-img`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const updatedProfile = resData.data;

        dispatch({ type: events.UPDATE_PROFILE, data: updatedProfile });
        return updatedProfile ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getProfile = () => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const profile = resData.data;

        dispatch({ type: events.GET_PROFILE, data: profile });

        return profile ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const generateNewPassword = (data) => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/change/password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const profile = resData.data;

        dispatch({ type: events.GET_PROFILE, data: profile });
        return profile ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const editProfile = (data) => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/profile`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const profile = resData.data;

        dispatch({ type: events.EDIT_PROFILE, data: profile });
        return profile ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const updateLogo = (data) => {
  const token = localStorage.getItem("auth");
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/upload-logo`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const updatedProfile = resData.data;

        dispatch({ type: events.UPDATE_LOGO, data: updatedProfile });
        return updatedProfile ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const removeProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: events.REMOVE_PROFILE });
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const emailSend = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;
        dispatch({ type: events.EMAIL_SEND, data: user });

        return user ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
// export const sendOtp = (data, name) => {
//   return async (dispatch) => {
//     try {
//       const response = await fetch(`${events.API_URL}/${name}/otp`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });
//       if (!response.ok) {
//         const resError = await response.json();

//         if (resError && resError.error) {
//           throw new Error(resError.error);
//         }
//       } else {
//         const resData = await response.json();

//         const otp = resData.data;
//         dispatch({ type: events.GET_OTP, data: otp });
//         return otp ? true : false;
//       }
//     } catch (err) {
//       throw new Error(err.message);
//     }
//   };
// };
// export const verifyOtp = (otp, name) => {
//   return async (dispatch) => {
//     try {
//       const response = await fetch(`${events.API_URL}/${name}/verify`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           otp: otp,
//         }),
//       });
//       if (!response.ok) {
//         const resError = await response.json();

//         if (resError && resError.error) {
//           throw new Error(resError.error);
//         }
//       } else {
//         const resData = await response.json();

//         const msg = resData.data;
//         dispatch({ type: events.VERIFY_OTP, data: msg });
//         return msg ? true : false;
//       }
//     } catch (err) {
//       throw new Error(err.message);
//     }
//   };
// };
export const sendOtp = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/sendloginotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const otp = resData.data;
        dispatch({ type: events.GET_OTP, data: otp });
        return otp ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const verifyOtp = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/verifyloginotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const msg = resData.data;
        dispatch({ type: events.VERIFY_OTP, data: msg });
        return msg ? msg : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const registerNew = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/register1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const msg = resData.data;
        dispatch({ type: events.VERIFY_OTP, data: msg });
        return msg ? msg : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const selectUser = (data, newUser) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/selectuser?new=${newUser}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        const resError = await response.json();
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;

        dispatch({ type: events.SELECT_USER, data: user });
        return user ? user : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

//deleteaccount
export const deleteAccount = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/delete/account`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        const resError = await response.json();
        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const user = resData.data;

        dispatch({ type: events.DELETE_ACCOUNT, data: user });
        return user ? user : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
