import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw,ah } from "../../utils";
const LeadsWatchRightDirection = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Group_946" data-name="Group 946" transform="translate(-895 -434)">
      <Rect
        id="Rectangle_812"
        data-name="Rectangle 812"
        width={aw(24)}
        height={ah(24)}
        transform="translate(895 434)"
        fill="none"
      />
      <Path
        id="Path_10310"
        data-name="Path 10310"
        d="M.98-8l-4.13-4.13,1.18-1.17L3.33-8l-5.3,5.3L-3.15-3.88Z"
        transform="translate(907 454)"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default LeadsWatchRightDirection;
