import { Modal, HStack } from "native-base";
import React from "react";
import { aw, ah } from "../utils";

const LeadsWatchModal = (props) => {
  return (
    <Modal
      initialFocusRef={props.initialFocusRef}
      finalFocusRef={props.finalFocusRef}
      // trapFocus={false}
      focusable={false}
      // style={{
      //   // left: ah(298),
      //   width: aw(770),
      //   background: "#FFFFFF 0% 0% no-repeat padding-box",

      //   borderRadius: "14px 14px 0px 0px",
      //   opacity: 1,
      //   borderColor:"#D7D7D7"

      // }}

      placement={props.placement || "center"}
      animationPreset={props.animationPreset || "fade"}
      isOpen={props.showModal}
      onClose={props.onClose}
      transparent={false}
      style={{
        boxShadow: "0px 7px 10px #0000000D",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        opacity: 4,
        // borderRadius: aw(14),
      }}
    >
      <Modal.Content
        maxHeight={props.height}
        maxWidth={props.width}
        style={{ ...props.style }}
        borderRadius={aw(14)}
      >
        {/* <Modal.Content> */}
        {props.heading && (
          <Modal.Header
            style={{
              backgroundColor: "#FFFFFF",
              borderWidth: 0,
              borderColor: "#FFFFFF",

              // padding: aw(2),
              // borderColor: "#D7D7D7",
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 0,
            }}
          >
            {/* {props.required ? null : (
              <Modal.CloseButton
                alignSelf={"center"}
                color="#0D2238"
                _icon={{
                  size: aw(10),
                }}
                _hover={{
                  bg: "#D7D7D7",
                }}
              />
            )} */}

            <HStack
              style={{
                boxShadow: "0px 7px 10px #0000000D",
                borderRadius: "16px 16px 0px 0px",
                backgroundColor: "#FFFFFF",
                height: ah(55),
                width: props.width,
                borderWidth: 0,

                paddingLeft: aw(20),
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props.required ? null : (
                <Modal.CloseButton
                  alignSelf={"center"}
                  color="#0D2238"
                  _icon={{
                    size: aw(10),
                  }}
                  _hover={{
                    bg: "#D7D7D7",
                  }}
                />
              )}
              {props.heading}
              {/* <LeadsWatchActiveIcon /> */}
            </HStack>
          </Modal.Header>
        )}
        <Modal.Body backgroundColor={"#FFFFFF"}>{props.children}</Modal.Body>
      </Modal.Content>
    </Modal>
  );
};

export default LeadsWatchModal;
