import {
  Text,
  VStack,
  HStack,
  ScrollView,
  Box,
  FlatList,
  Pressable,
} from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import LeadsWatchCard from "../../components/LeadsWatchCard";
import { aw, ah } from "../../utils";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchInput from "../../components/LeadsWatchInput";

const PayPublisher = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    value,
    getValues,
  } = useForm({
    // mode: "onBlur",
    mode: "all",
  });
  return (
    <>
      <Box marginTop={ah(30)}>
        <LeadsWatchCard
          width={aw(1235)}
          height={ah(126)}
          alignItems={"flex-start"}
        >
          <VStack pl={aw(20)} space={ah(15)}>
            <HStack
              justifyContent={"space-between"}
              alignItems={"baseline"}
              width={aw(1200)}
            >
              <Text
                style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                color="#121233"
                mt={ah(15)}
              >
                Get From Buyer
              </Text>
            </HStack>
            <HStack mb={ah(20)} space={aw(20)}>
              <LeadsWatchSelect
                control={control}
                name={"payment"}
                placeholder={"Enter Amount"}
                list={["active", "inactive"]}
                width={aw(527)}
                height={ah(40)}
              />
              <LeadsWatchSelect
                control={control}
                name={"payment"}
                placeholder={"Enter Amount"}
                list={["active", "inactive"]}
                width={aw(527)}
                height={ah(40)}
              />
              <LeadsWatchButton
                label={"Pay"}
                style={{
                  width: aw(100),
                  height: ah(40),
                  borderRadius: "6px",
                }}
                color="#FFFFFF"
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                bg={"#2230F8"}
                fontWeight="400"
              />
            </HStack>
          </VStack>
        </LeadsWatchCard>
      </Box>
    </>
  );
};

export default PayPublisher;
