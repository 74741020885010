import { Text, VStack, HStack, Divider, Pressable } from "native-base";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { aw, ah } from "../../utils";

const Reports = ({ isOpen }) => {
  const [arr, setArr] = useState([]);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.auth.profile);
  const teamMembers = useSelector((state) => state.team.teamMembers);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const arr1 = [];
    if (profile && profile.role === "user") {
      Object.keys(profile.permission_ids).map((val) => {
        if (
          Array.isArray(profile.permission_ids[val]) &&
          profile.permission_ids[val].length > 0 &&
          val !== "call"
        ) {
          val !== "vertical" || val !== "lead"
            ? arr1.push({ name: `${val}s`, value: `${val}s` })
            : val === "vertical"
            ? arr1.push(
                { name: `${val}s`, value: `${val}s` },
                { name: "Vertical Fields", value: "verticalFields" }
              )
            : arr1.push(
                { name: `${val}s`, value: `${val}s` },
                { name: "IVR", value: "ivr" },
                { name: "PPC", value: "ppc" }
              );
        }
      });
      const customOrder = [
        "leads",
        "campaigns",
        "publishers",
        "buyers",
        "verticals",
        "verticalFields",
        "calls",
      ];

      const orderedList = customOrder
        .map((val) => arr1.find((order) => order.value === val))
        .filter(Boolean);

      setArr(orderedList);
    } else if (profile && profile.role === "publisher") {
      setArr([
        { name: "Leads", value: "leads" },
        { name: "Campaigns", value: "campaigns" },
      ]);
    } else {
      setArr([
        { name: "Leads", value: "leads" },
        { name: "IVR", value: "ivr" },
        { name: "PPC", value: "ppc" },
        { name: "Campaigns", value: "campaigns" },
        { name: "Publishers", value: "publishers" },
        { name: "Buyers", value: "buyers" },
        { name: "Buyer Routes", value: "buyerRoutes" },
        { name: "Verticals", value: "verticals" },
        { name: "Vertical Fields", value: "verticalFields" },
      ]);
    }
  }, [profile]);

  return (
    <HStack height={ah(698)}>
      <VStack
        top={ah(70)}
        // left={[aw(0), aw(3), aw(80)]}
        // width={aw(1298)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(160)] : [aw(0), aw(3), aw(80)]}
        position={"fixed"}
      >
        <VStack top={ah(8)} marginLeft={aw(20)}>
          <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            space={aw(8)}
            alignItems={"flex-end"}
            width={aw(1248)}
          >
            <HStack
              alignItems={"baseline"}
              // width={aw(194)}
              space={aw(5)}
              justifyContent="space-between"
            >
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily={"RobotoMedium"}
              >
                Your Reports
              </Text>
            </HStack>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            All your Reports will be shown here
          </Text>

          <Divider top={ah(10)} width={aw(1248)} bg="#E6E7E9" />
          <HStack mt={ah(20)} space={aw(56)} height={ah(30)}>
            {arr.map((val, index) => (
              <Pressable
                isDisabled={
                  profile && profile.role === "user"
                    ? profile.permission_ids["lead"].includes("view")
                      ? false
                      : true
                    : false
                }
                onPress={() => navigate(`/reports/${val.value}`)}
                key={val.name}
                borderBottomWidth={
                  location.pathname.includes(val.value) && aw(3)
                }
                borderColor={"#0000FF"}
                height={ah(41)}
              >
                <Text
                  lineHeight={ah(34)}
                  fontSize={aw(14)}
                  fontFamily={"RobotoRegular"}
                  color={
                    location.pathname.includes(val.value)
                      ? "#0000FF"
                      : "#919191"
                  }
                  fontWeight={400}
                  textTransform={"capitalize"}
                >
                  {val.name}
                </Text>
              </Pressable>
            ))}
          </HStack>
          <Divider
            top={ah(10)}
            // width={aw(1248)}
            bg="#E6E7E9"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          />
          <VStack mt={ah(10)}>
            <Outlet />
          </VStack>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default Reports;
