import * as events from "../Events";

export const addcallSchedule = (data) => {
  console.log(data);
  return {
    type: events.ADD_CALL_SCHEDULE,
    payload: data,
  };
};
export const editcallSchedule = (data) => {
  return {
    type: events.EDIT_CALL_SCHEDULE,
    payload: data,
  };
};
export const deletecallSchedule = (data) => {
  return {
    type: events.DELETE_CALL_SCHEDULE,
    payload: data,
  };
};
//for ivr numbers
export const addivrNum = (data) => {
  console.log(data, "ivrNum");
  return {
    type: events.ADD_IVR_NUMBERS,
    payload: data,
  };
};
export const editivrNum = (data) => {
  return {
    type: events.EDIT_IVR_NUMBERS,
    payload: data,
  };
};
export const deleteivrNum = (data) => {
  return {
    type: events.DELETE_IVR_NUMBERS,
    payload: data,
  };
};
