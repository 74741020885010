import { React, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
  Box,
  Flex,
  ScrollView,
} from "native-base";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getAllBuyers } from "../../store/actions/buyers";
import { getAllCampaigns } from "../../store/actions/campaigns";
import { getAllPublishers } from "../../store/actions/publishers";
import { aw, ah } from "../../utils";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import {
  getAllLeads,
  getSingleLead,
  handleDownload,
  handleSingleDownload,
} from "../../store/actions/leads";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchCheckboxMenu from "../../components/LeadsWatchCheckboxMenu";

// const WS_URL = "ws://192.168.1.56:8080/todo";
const Leads = ({
  setDisplayLeads,
  displayLeads,
  newLeads,
  setNewLeads,
  isOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [downloading, setDownloading] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const allBuyers = useSelector((state) => state.buyers.allBuyers);
  const allPublishers = useSelector((state) => state.publishers.allPublishers);
  const allCampaigns = useSelector((state) => state.campaigns.allCampaigns);
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));

  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const profile = useSelector((state) => state.auth.profile);
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const dispatch = useDispatch();

  const [checked, setChecked] = useState([]);
  const [leadId, setLeadId] = useState([]);
  const allLeads = useSelector((state) => state.leads.allLeads);
  const singleLead = useSelector((state) => state.leads.singleLead);

  const [currentPage, setCurrentPage] = useState(
    page && page.leads ? parseInt(page.leads) : 1
  );
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.leads ? parseInt(pagecount.leads) : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [filters1, setFilters1] = useState({});
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState(getValues1("search"));
  const [clearSearch, setClearSearch] = useState(false);
  const [fields, setFields] = useState(
    type === "ivr" || type === "ppc"
      ? ["date", "publisher", "buyer", "campaign"]
      : [
          "date",
          "email",
          "phone",
          "system_response",
          "publisher",
          "buyer",
          "buyer_response",
          "campaign",
        ]
  );

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });

  const { control, getValues, reset } = useForm({
    mode: "all",

    defaultValues: {
      select: pagecount && pagecount.leads ? pagecount.leads.toString() : "10",
      campaign_id: "",
      publisher_id: "",
      buyer_id: "",
    },
  });

  const handleChangeSelect = (value) => {
    setSelectCount(value);
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, leads: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
  };
  useEffect(() => {
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    setEndDate(new Date());
    setFilters({
      start_date: moment(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    });
  }, []);
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);
      let date1 = moment(date);
      let date2 = moment(filters.end_date);
      let difference = date2.diff(date1, "days");

      if (difference > 30 || difference < 0) {
        setEndDate(moment(date).add(30, "days").toDate());
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
          end_date: moment(date).add(30, "days").format("YYYY-MM-DD"),
        });
      } else {
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
        });
      }
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };

  const fetchAllLeads = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllLeads(
          currentPage,
          selectCount,
          sort,
          { ...filters1, type: type },
          search
        )
      );
    } catch (err) {
      console.log(err.message);

      toast.error(err.message.toLowerCase());
    }
    setNewLeads(0);
    setIsLoading(false);
  };
  const handleSort = (order) => {
    setSort(order);
  };
  useEffect(() => {
    setFields(
      type === "ivr" || type === "ppc"
        ? [
            "date",
            "publisher",
            "buyer",
            "campaign",
            "system_response",
            "buyer_response",
          ]
        : [
            "date",
            "email",
            "phone",
            "system_response",
            "publisher",
            "buyer",
            "buyer_response",
            "campaign",
          ]
    );
  }, [type]);
  useEffect(() => {
    if (filters1.start_date && filters1.end_date) {
      if (profile && profile.role === "user") {
        if (
          profile.permission_ids["publisher"] &&
          profile.permission_ids.lead.includes("view")
        ) {
          fetchAllLeads();
        } else {
          navigate("/settings/profile");
        }
      } else {
        fetchAllLeads();
      }
    }
  }, [currentPage, selectCount, sort, filters1, type, search]);
  useEffect(() => {
    const fetchsingleCamapign = async () => {
      if (id) {
        // setFilters({ ...filters, campaign_id: [id] });
        setFilters1({ ...filters1, campaign_id: [id] });
        try {
          const data = await dispatch(
            getAllCampaigns(1, 5, undefined, undefined, id)
          );

          reset({
            campaign: [
              {
                label: data.list[0].name + "(" + data.list[0].unique_id + ")",
                value: data.list[0].unique_id,
              },
            ],
          });
        } catch (err) {
          console.log(err);
        }
      }
    };
    fetchsingleCamapign();
  }, []);
  const handleView = async (id, rId) => {
    try {
      await dispatch(getSingleLead(id, rId, type));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsViewModalOpen(true);
  };
  const handleFilters = () => {
    setFilters1({ ...filters1, ...filters });
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
    setOpenFilter(false);
  };

  const onDownlaod = async () => {
    setDownloading(true);
    if (checked.length) {
      const data =
        type === "lead"
          ? {
              report_filters: filters1.report_filters,
              response_ids: checked,
            }
          : {
              leadcall_ids: checked,
              report_filters: filters1.report_filters,
            };
      try {
        await dispatch(handleDownload({ ...data, type: type }));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          handleDownload({
            filters: { ...filters1, type: type },
            report_filters: filters1.report_filters,
          })
        );
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setDownloading(false);
  };
  const singleLeadDownload = async (id, rId) => {
    try {
      await dispatch(handleSingleDownload(id, rId, type));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };

  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
      data.search !== "" && setClearSearch(true);
    }
  };

  useEffect(() => {
    setDisplayLeads(
      allLeads
        ? allLeads.list.map((val) => {
            return {
              _id: val._id,
              response_id: val.response_id,
              buyer: val.buyer_name,
              publisher: val.publisher_name,
              campaign: val.campaign_name,
              created: val.created,
              buyer_response: val.buyer_response,
              system_response: val.system_response,
              cost: val.cost,
              price: val.price,
              profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,

              email: val.details
                ? val.details.find((item) => item.name === "Email")?.value
                : null,
              phone: val.details
                ? val.details.find((item) => item.name === "Phone")?.value
                : null,
            };
          })
        : []
    );
  }, [allLeads && allLeads.list]);
  const onChangeHeaderCheckbox = () => {
    if (displayLeads.every((val) => checked.includes(val.response_id))) {
      const allResponseIds = displayLeads.map((val) => val.response_id);
      const allIds = displayLeads.map((val) => val._id);
      setChecked(checked.filter((val) => !allResponseIds.includes(val)));
      setLeadId(leadId.filter((val) => !allIds.includes(val)));
    } else {
      setChecked([...checked, ...displayLeads.map((val) => val.response_id)]);
      setLeadId([...leadId, ...displayLeads.map((val) => val._id)]);
    }
  };
  const handleChangeCheckBox = (id, rId) => {
    if (checked.includes(rId)) {
      setChecked((old) => old.filter((row) => row !== rId));
      setLeadId((old) => old.filter((row) => row !== id));
    } else {
      setChecked((old) => [...old, rId]);
      setLeadId((old) => [...old, id]);
    }
  };
  const fetchAllBuyers = async () => {
    try {
      await dispatch(getAllBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllPublishers = async () => {
    try {
      await dispatch(getAllPublishers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      await dispatch(getAllCampaigns(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  // useEffect(() => {
  //   // getData();
  //   fetchAllBuyers();
  //   fetchAllPublishers();
  // }, []);
  const handleOptionChange = (key, selectedOption) => {
    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  const loadOptions = async (inputValue, callback, list, defaultOptions) => {
    try {
      const data =
        list === "campaign"
          ? await dispatch(
              getAllCampaigns(1, 10, undefined, undefined, inputValue)
            )
          : list === "buyer"
          ? await dispatch(
              getAllBuyers(1, 10, undefined, undefined, inputValue)
            )
          : await dispatch(
              getAllPublishers(1, 10, undefined, undefined, inputValue)
            );

      callback(
        list === "campaign"
          ? data &&
              data.list.map((val) => ({
                value: val.unique_id,
                label: `${val.name}(${val.unique_id})`,
              }))
          : list === "publisher" || list === "buyer"
          ? data &&
            data.list.map((val) => ({
              value: val._id,
              label: `${val.firstname} ${val.lastname}`,
            }))
          : data &&
            data.list.map((val) => ({
              value: val._id,
              label: val.name,
            }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const defaultOptionsForCam = allCampaigns
    ? allCampaigns.list.map((val) => ({
        value: val.unique_id,
        label: `${val.name}( ${val.unique_id})`,
      }))
    : [];
  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForPub = allPublishers
    ? allPublishers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForRes = [
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Trash",
      label: "Trash",
    },
    {
      value: "Duplicate",
      label: "Duplicate",
    },
    {
      value: "Failure",
      label: "Failure",
    },
  ];
  const defaultOptionsForReportFil = [
    {
      value: "Leads_Watch_LeadId",
      label: "Leads Watch Lead Id",
    },
    {
      value: "Leads_Created_Date",
      label: "Leads Created Date",
    },
    {
      value: "Time",
      label: "Time",
    },
    {
      value: "Status_Change_Date",
      label: "Status Change Date",
    },
    {
      value: "Status_Change_Time",
      label: "Status Change Time",
    },
    {
      value: "System_Response",
      label: "System Response",
    },
    {
      value: "Publisher_ID",
      label: "Publisher ID",
    },
    {
      value: "Publisher_Name",
      label: "Publisher Name",
    },
    {
      value: "Buyer_ID",
      label: "Buyer ID",
    },
    {
      value: "Buyer_Name",
      label: "Buyer Name",
    },
    {
      value: "Campaign_ID",
      label: "Campaign ID",
    },
    {
      value: "Campaign_Name",
      label: "Campaign Name",
    },
    {
      value: "Buyer_Response",
      label: "Buyer Response",
    },

    {
      value: "Publisher_Payout",
      label: "Publisher Payout",
    },
    {
      value: "Buyer_Payout",
      label: "Buyer Payout",
    },
  ];
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
    }
  }, [filters.start_date, filters.end_date]);
  const requiredData = allLeads
    ? allLeads.list.map((val) => {
        return {
          _id: val._id,
          buyer: val.buyer_name,
          publisher: val.publisher_name,
          vertical: val.vertical_name,
          campaign: val.campaign_name,
          response_id: val.response_id,
          system_response: val.system_response,
          buyer_response: val.buyer_response,
          created: val.created,
          cost: val.cost,
          price: val.price,
          profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,

          email: val.details
            ? val.details.find((item) => item.name === "Email")?.value
            : null,
          phone: val.details
            ? val.details.find((item) => item.name === "Phone")?.value
            : null,
        };
      })
    : [];

  return (
    <HStack height={ah(698)}>
      <VStack
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          marginTop={ah(7)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // width={[aw(350), aw(350), aw(1248)]}
        >
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <HStack lineHeight={ah(34)} space={aw(8)} alignItems={"baseline"}>
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
              >
                Leads
              </Text>
              <Text
                fontSize={aw(18)}
                color={"#919191"}
                fontFamily="RobotoMedium"
              >
                {newLeads !== 0
                  ? allLeads &&
                    allLeads.total_count &&
                    allLeads.total_count + newLeads
                  : allLeads && allLeads.total_count && allLeads.total_count}
              </Text>
            </HStack>
            <Hidden only={["sm", "base"]}>
              <HStack>
                <LeadsWatchSearch
                  placeholder={[
                    'Search by "Date"',
                    'Search by "Buyer"',
                    'Search by "System Response"',
                    'Search by "Publisher"',
                    'Search by "Buyer Response"',
                    'Search by "Campaign"',
                  ]}
                  control={control1}
                  name="search"
                  onFocus={() => setClearSearch(false)}
                  inputRightElement={
                    clearSearch ? (
                      <Pressable
                        w={aw(20)}
                        onPress={() => {
                          reset1();
                          setSearch(getValues1("search"));
                          setClearSearch(false);
                          setCurrentPage(1);
                          localStorage.setItem(
                            "page",
                            JSON.stringify({ ...page, leads: 1 })
                          );
                        }}
                      >
                        <LeadsWatchCrossIcon />
                      </Pressable>
                    ) : (
                      <Pressable
                        w={aw(30)}
                        onPress={handleSubmit1(handleSearch)}
                        // pr={aw(10)}
                      >
                        <LeadsWatchSearchIcon />
                      </Pressable>
                    )
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit1(
                        handleSearch({ search: getValues1("search") })
                      );
                    }
                  }}
                />
              </HStack>
            </Hidden>
          </HStack>
          <HStack space={aw(250)}>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Leads list will be shown here
            </Text>
            <HStack space={aw(40)}>
              {["Lead", "IVR", "PPC"].map((val) => (
                <Pressable
                  borderBottomWidth={
                    (location.pathname.includes(`leads/${val.toLowerCase()}`) ||
                      type === val.toLowerCase()) &&
                    aw(3)
                  }
                  borderColor={"#0000FF"}
                  onPress={() => {
                    navigate(`/leads/${val.toLowerCase()}`);
                    setCurrentPage(1);
                    localStorage.setItem(
                      "page",
                      JSON.stringify({ ...page, leads: 1 })
                    );
                  }}
                  height={ah(42)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    // color={type === val.toLowerCase() ? "#0000FF" : "#919191"}
                    color={
                      location.pathname.includes(
                        `leads/${val.toLowerCase()}`
                      ) || type === val.toLowerCase()
                        ? "#0000FF"
                        : "#919191"
                    }
                    fontWeight={400}
                  >
                    {val}
                  </Text>
                </Pressable>
              ))}
            </HStack>
          </HStack>
          <Divider
            // mt={ah(9)}
            bg="#E6E7E9"
            height={ah(1)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            // width={[aw(350), aw(350), aw(1248)]}
          />
        </VStack>

        <VStack
          height={ah(35)}
          mt={ah(10)}
          zIndex={1}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // width={[aw(350), aw(350), aw(1248)]}
        >
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  // value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={75}
                  height={ah(32)}
                />
              </Center>
            </HStack>
            <Hidden only={["sm", "base"]}>
              <HStack space={aw(12)} zIndex={1} width={aw(734)}>
                <LeadsWatchButton
                  isDisabled={
                    checked.length === 0 &&
                    (!filters1.report_filters ||
                      (filters1.report_filters &&
                        filters1.report_filters.length === 0))
                  }
                  bg="#FFFFFF"
                  leftIcon={downloading ? null : <LeadsWatchDownloadIcon />}
                  onPress={onDownlaod}
                  label={downloading ? "Downloading..." : "Download"}
                  style={{
                    width: aw(115),
                    height: ah(32),
                    borderColor: "#D1D5DB",
                  }}
                  // fontWeight="400"
                  color="#212121"
                />
                <HStack>
                  <LeadsWatchCalendar
                    maxDate={true}
                    startDate={startDate}
                    handleFromDate={handleFromDate}
                    handleEndDate={handleEndDate}
                    width={aw(141)}
                    height={ah(30)}
                    endDate={endDate}
                  />
                </HStack>
                <HStack>
                  <LeadsWatchCheckboxMenu
                    sheight={ah(250)}
                    arr={[
                      "date",
                      "email",
                      "phone",
                      "buyer",
                      "system_response",
                      "publisher",
                      "buyer_response",
                      "campaign",
                      "vertical",
                      "cost",
                      "price",
                      "profit",
                      "id",
                    ]}
                    title="Select Fields"
                    height={ah(32)}
                    width={aw(160)}
                    value={fields}
                    handleChange={(value) => {
                      if (value.includes("id")) {
                        setFields(
                          value.map((val) => {
                            if (val === "id") {
                              return "_id";
                            } else {
                              return val;
                            }
                          })
                        );
                      } else {
                        setFields(value);
                      }
                    }}
                  />
                  <Flex
                    position="absolute"
                    right="-2"
                    top="-8"
                    borderColor="#C1C1C1"
                    bg="#2230F8"
                    borderRadius="50%"
                    size={aw(17)}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      color="#FFFFFF"
                      fontFamily="RobotoMedium"
                      fontSize={aw(11)}
                    >
                      {fields.length}
                    </Text>
                  </Flex>
                </HStack>
                <LeadsWatchButton
                  bg="#FFFFFF"
                  onPress={() => setOpenFilter(!openFilter)}
                  leftIcon={<LeadsWatchFilterIcon />}
                  label={"Filter"}
                  style={{
                    width: aw(115),
                    height: ah(32),
                    borderColor: "#D1D5DB",
                  }}
                  fontWeight="400"
                />
                {openFilter && (
                  <Box
                    px={aw(10)}
                    position="absolute"
                    zIndex={1}
                    top={ah(35)}
                    right={aw(98)}
                    width={aw(570)}
                    minH={ah(180)}
                    // maxH={ah(500)}
                    py={ah(5)}
                    // my={ah(10)}
                    bgColor="#FFFFFF"
                    borderColor="#E1E1E1"
                    style={{
                      shadowColor: "#00000033",
                      shadowRadius: aw(20),
                    }}
                    borderWidth={aw(1)}
                    borderRadius={aw(6)}
                  >
                    <ScrollView maxH={ah(400)} px={aw(10)} py={ah(15)}>
                      <Flex direction="row" wrap="wrap" gap={ah(20)}>
                        <Flex gap={ah(3)} maxW={"96%"}>
                          <LeadsWatchAsyncSelect
                            isDisabled={id ? true : false}
                            isMulti={true}
                            screen={
                              getValues("campaign") &&
                              getValues("campaign").length > 0
                                ? "edit"
                                : "create"
                            }
                            onFocus={() => {
                              if (allCampaigns) {
                                if (allCampaigns.list.length < 10) {
                                  getData();
                                }
                              } else {
                                getData();
                              }
                            }}
                            minWidth={aw(250)}
                            type="async"
                            handleChange={(selectedOption) =>
                              handleOptionChange("campaign_id", selectedOption)
                            }
                            loadOptions={(inputValues, callback) =>
                              loadOptions(inputValues, callback, "campaign")
                            }
                            onInputChange={(inputValues) => {
                              if (inputValues === "") {
                                getData();
                                // return inputValue;
                              }
                            }}
                            defaultOptions={defaultOptionsForCam}
                            control={control}
                            placeholder="Campaigns"
                            name="campaign"
                          />
                        </Flex>
                        {profile && profile.role !== "publisher" && (
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("buyer") &&
                                getValues("buyer").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptionsForBuyers}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "buyer")
                              }
                              onFocus={() => {
                                if (allBuyers) {
                                  if (allBuyers.list.length < 10) {
                                    fetchAllBuyers();
                                  }
                                } else {
                                  fetchAllBuyers();
                                }
                              }}
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllBuyers();

                                  // return inputValue;
                                }
                              }}
                              handleChange={(selectedOption) =>
                                handleOptionChange("buyer_id", selectedOption)
                              }
                              control={control}
                              placeholder="Buyers"
                              name="buyer"
                            />
                          </Flex>
                        )}
                        {profile && profile.role !== "publisher" && (
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("publisher") &&
                                getValues("publisher").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptionsForPub}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "publisher")
                              }
                              onFocus={() => {
                                if (allPublishers) {
                                  if (allPublishers.list.length < 10) {
                                    fetchAllPublishers();
                                  }
                                } else {
                                  fetchAllPublishers();
                                }
                              }}
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllPublishers();
                                  // return inputValue;
                                }
                              }}
                              placeholder="Publishers"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "publisher_id",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="publisher"
                            />
                          </Flex>
                        )}
                        <Flex gap={ah(3)} maxW={"96%"}>
                          <LeadsWatchAsyncSelect
                            isMulti={true}
                            screen={
                              getValues("system_response") &&
                              getValues("system_response").length > 0
                                ? "edit"
                                : "create"
                            }
                            minWidth={aw(250)}
                            defaultOptions={defaultOptionsForRes}
                            placeholder="System Response"
                            handleChange={(selectedOption) =>
                              handleOptionChange(
                                "system_response",
                                selectedOption
                              )
                            }
                            control={control}
                            name="system_response"
                          />
                        </Flex>
                        <Flex gap={ah(3)} maxW={"96%"}>
                          <LeadsWatchAsyncSelect
                            isMulti={true}
                            screen={
                              getValues("buyer_response") &&
                              getValues("buyer_response").length > 0
                                ? "edit"
                                : "create"
                            }
                            minWidth={aw(250)}
                            defaultOptions={defaultOptionsForRes}
                            placeholder="Buyer Response"
                            handleChange={(selectedOption) =>
                              handleOptionChange(
                                "buyer_response",
                                selectedOption
                              )
                            }
                            control={control}
                            name="buyer_response"
                          />
                        </Flex>
                        <Flex gap={ah(3)} maxW={"96%"}>
                          <LeadsWatchAsyncSelect
                            isMulti={true}
                            screen={
                              getValues("report_filters") &&
                              getValues("report_filters").length > 0
                                ? "edit"
                                : "create"
                            }
                            minWidth={aw(250)}
                            defaultOptions={defaultOptionsForReportFil}
                            placeholder="Report Filters"
                            handleChange={(selectedOption) =>
                              handleOptionChange(
                                "report_filters",
                                selectedOption
                              )
                            }
                            control={control}
                            name="report_filters"
                          />
                        </Flex>
                      </Flex>
                      <Flex
                        width={aw(520)}
                        justifyContent={"flex-end"}
                        direction="row"
                        gap={aw(10)}
                        zIndex={-1}
                        mt={ah(10)}
                      >
                        <LeadsWatchButton
                          label="Cancel"
                          onPress={() => {
                            setOpenFilter(false);
                            if (id) {
                              // console.log("entered", filters1.campaign_id);
                              console.log(getValues("campaign"), "entered");
                              const data = getValues("campaign");

                              reset({
                                campaign: data,
                              });
                              if (Object.keys(filters).length > 3) {
                                setSelectedValues({});
                                setFilters({
                                  start_date: filters.start_date,
                                  end_date: filters.end_date,
                                  campaign: filters.campaign,
                                });
                              }
                              if (Object.keys(filters1).length > 3) {
                                setFilters1({
                                  start_date: filters1.start_date,
                                  end_date: filters1.end_date,
                                  campaign: filters1.campaign,
                                });
                              }
                            } else {
                              reset({
                                campaign: "",
                                publisher: "",
                                buyer: "",
                                buyer_response: "",
                                system_response: "",
                                report_filters: "",
                              });

                              if (Object.keys(filters).length > 2) {
                                setSelectedValues({});
                                setFilters({
                                  start_date: filters.start_date,
                                  end_date: filters.end_date,
                                });
                              }
                              if (Object.keys(filters1).length > 2) {
                                setFilters1({
                                  start_date: filters1.start_date,
                                  end_date: filters1.end_date,
                                });
                              }
                            }
                          }}
                          style={{ height: ah(32) }}
                        />
                        <LeadsWatchButton
                          onPress={handleFilters}
                          label="Apply"
                          style={{
                            height: ah(32),
                          }}
                          bg="#2230F8"
                          color="#FFFFFF"
                          borderColor="#05BBC9"
                        />
                      </Flex>
                    </ScrollView>
                  </Box>
                )}
              </HStack>
            </Hidden>
          </HStack>
        </VStack>

        <VStack>
          <VStack mt={ah(15)}>
            <LeadsWatchTable
              type={type}
              isOpen={isOpen}
              onChangeHeaderCheckbox={onChangeHeaderCheckbox}
              handleChangeCheckBox={handleChangeCheckBox}
              selectedRows={checked}
              sort={sort}
              heading={
                profile && profile.role === "publisher"
                  ? [...fields.filter((val) => val !== "buyer"), "Actions"]
                  : [...fields, "Actions"]
              }
              handleDownload={singleLeadDownload}
              screen="lead"
              handleView={handleView}
              handleSort={handleSort}
              sortIcon={true}
              // data={allLeads && allLeads.list && allLeads.list}
              data={
                currentPage === 1
                  ? displayLeads && displayLeads.slice(0, selectCount)
                  : requiredData
              }
              isLoading={isLoading}
            />
          </VStack>
          <VStack
            bottom={aw(5)}
            position="fixed"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width="100%"
              totalCount={
                newLeads !== 0
                  ? allLeads &&
                    allLeads.total_count &&
                    allLeads.total_count + newLeads
                  : allLeads && allLeads.total_count && allLeads.total_count
                // displayLeads && displayLeads.length
              }
              currentPage={currentPage}
              pageSize={selectCount}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage);
                localStorage.setItem(
                  "page",
                  JSON.stringify({ ...page, leads: currentPage })
                );
              }}
            />
          </VStack>
        </VStack>
      </VStack>
      <LeadsWatchModal
        height={ah(600)}
        width={aw(770)}
        showModal={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Lead Details
            </Text>
            <Text
              color={"#212121"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            ></Text>
          </HStack>
        }
      >
        <HStack alignItems={"center"} mb={ah(10)}>
          <Text
            fontFamily="RobotoMedium"
            color={"#000000"}
            fontSize={aw(18)}
            width="61.5%"
          >
            Main Details
          </Text>
          {singleLead &&
            singleLead.transfered_call_details &&
            type !== "lead" && (
              <Text
                fontSize={aw(18)}
                fontFamily="RobotoMedium"
                color="#212121"
                width="30%"
              >
                Call Transfer Details
              </Text>
            )}
        </HStack>
        <HStack space={aw(155)}>
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center" width={aw(300)}>
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                : {singleLead && singleLead.publisher_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                System Status
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead &&
                  singleLead.system_response &&
                  singleLead.system_response}
              </Text>
            </HStack>
            {type === "lead" && (
              <HStack
                space={aw(14)}
                // alignItems="center"
                color="#919191"
                width={aw(300)}
              >
                <Text
                  width={aw(91)}
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#919191"
                >
                  Response
                </Text>
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  color="#212121"
                >
                  :{" "}
                  {singleLead &&
                    singleLead.response &&
                    JSON.stringify(singleLead.response)}
                </Text>
              </HStack>
            )}
          </VStack>
          {type === "lead" ? (
            <VStack space={ah(15)}>
              {singleLead &&
                singleLead.details &&
                singleLead.details.map((val) => (
                  <HStack space={aw(14)} key={val["name"]} width={aw(280)}>
                    <Text
                      color="#919191"
                      fontSize={aw(14)}
                      width={aw(91)}
                      fontFamily="RobotoRegular"
                    >
                      {val["name"]}
                    </Text>
                    <Text
                      // width={aw(210)}
                      fontSize={aw(14)}
                      fontFamily="RobotoMedium"
                      color="#212121"
                    >
                      : {val["value"]}
                    </Text>
                  </HStack>
                ))}
            </VStack>
          ) : (
            <VStack space={ah(15)}>
              {singleLead && singleLead.transfered_call_details && (
                <>
                  <HStack space={aw(14)} width={aw(280)}>
                    <Text
                      color="#919191"
                      fontSize={aw(14)}
                      width={aw(91)}
                      fontFamily="RobotoRegular"
                    >
                      Transfered To
                    </Text>
                    <Text
                      // width={aw(210)}
                      fontSize={aw(14)}
                      fontFamily="RobotoMedium"
                      color="#212121"
                    >
                      : {singleLead.transfered_call_details.to}
                    </Text>
                  </HStack>
                  <HStack space={aw(14)} width={aw(280)}>
                    <Text
                      color="#919191"
                      fontSize={aw(14)}
                      width={aw(91)}
                      fontFamily="RobotoRegular"
                    >
                      Call Duration
                    </Text>
                    <Text
                      // width={aw(210)}
                      fontSize={aw(14)}
                      fontFamily="RobotoMedium"
                      color="#212121"
                    >
                      : {`${singleLead.transfered_call_details.callduration}s`}
                    </Text>
                  </HStack>
                  <HStack space={aw(14)} width={aw(280)}>
                    <Text
                      color="#919191"
                      fontSize={aw(14)}
                      width={aw(91)}
                      fontFamily="RobotoRegular"
                    >
                      Call Status
                    </Text>
                    <Text
                      // width={aw(210)}
                      fontSize={aw(14)}
                      fontFamily="RobotoMedium"
                      color="#212121"
                    >
                      : {singleLead.transfered_call_details.callstatus}
                    </Text>
                  </HStack>
                </>
              )}
            </VStack>
          )}
        </HStack>
        <Divider
          my={ah(24)}
          bg="#E6E7E9"
          height={ah(1)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        />
        <Text
          fontFamily="RobotoMedium"
          color="#212121"
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Other details
        </Text>
        <HStack space={aw(155)}>
          <VStack space={ah(15)}>
            <HStack
              space={aw(14)}
              alignItems="center"
              fontFamily="RobotoRegular"
            >
              <Text color="#919191" fontSize={aw(14)} width={aw(91)}>
                Campaign Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                :{" "}
                {singleLead && singleLead.campaign_id && singleLead.campaign_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead &&
                  singleLead.publisher_id &&
                  singleLead.publisher_id}
              </Text>
            </HStack>
            {type === "lead" && (
              <HStack space={aw(14)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  width={aw(91)}
                  fontFamily="RobotoRegular"
                  color="#919191"
                >
                  Vertical Id
                </Text>
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  color="#212121"
                >
                  :{" "}
                  {singleLead &&
                    singleLead.vertical_id &&
                    singleLead.vertical_id}
                </Text>
              </HStack>
            )}
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singleLead && singleLead.buyer_id && singleLead.buyer_id}
              </Text>
            </HStack>
            {type === "lead" ? (
              <HStack space={aw(14)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  width={aw(91)}
                  fontFamily="RobotoRegular"
                  color="#919191"
                >
                  Response Id
                </Text>
                <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                  :{" "}
                  {singleLead &&
                    singleLead.response_id &&
                    singleLead.response_id}
                </Text>
              </HStack>
            ) : (
              <>
                <HStack space={aw(14)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    width={aw(91)}
                    fontFamily="RobotoRegular"
                    color="#919191"
                  >
                    Bill Duration
                  </Text>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    color="black"
                  >
                    :{" "}
                    {singleLead &&
                      singleLead.billduration &&
                      `${singleLead.billduration}s`}
                  </Text>
                </HStack>
                <HStack space={aw(14)} alignItems="center">
                  <Text
                    fontSize={aw(14)}
                    width={aw(91)}
                    fontFamily="RobotoRegular"
                    color="#919191"
                  >
                    Total Amount
                  </Text>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    color="black"
                  >
                    :{" "}
                    {singleLead &&
                      singleLead.totalamount &&
                      singleLead.totalamount}
                  </Text>
                </HStack>
              </>
            )}
          </VStack>
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Campaign Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                : {singleLead && singleLead.campaign_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Name
              </Text>

              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                : {singleLead && singleLead.publisher_name}
              </Text>
            </HStack>
            {type === "lead" && (
              <HStack space={aw(14)} alignItems="center">
                <Text
                  width={aw(118)}
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#919191"
                >
                  Vertical Name
                </Text>
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  color="#212121"
                  width={aw(118)}
                >
                  :{" "}
                  {singleLead &&
                    singleLead.vertical &&
                    singleLead.vertical[0] &&
                    singleLead.vertical[0].vertical_name}
                </Text>
              </HStack>
            )}
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                : {singleLead && singleLead.buyer_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer response
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.buyer_response &&
                  singleLead.buyer_response}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Divider
          my={ah(24)}
          bg="#D7D7D7"
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        />
        <Text
          fontFamily="RobotoMedium"
          color="#212121"
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Price details
        </Text>
        <HStack space={aw(213)}>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Cost
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              : {singleLead && singleLead.cost && singleLead.cost}
            </Text>
          </HStack>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Price
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              : {singleLead && singleLead.price && singleLead.price}
            </Text>
          </HStack>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Profit
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              :{" "}
              {singleLead &&
                singleLead.price &&
                singleLead.cost &&
                singleLead.price - singleLead.cost}
            </Text>
          </HStack>
        </HStack>
      </LeadsWatchModal>
    </HStack>
  );
};
export default Leads;
