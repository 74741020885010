import * as events from "../Events";
const initialState = {
  topCampagns: null,
  topPublishers: null,
  topBuyers: null,
  totalLeads: null,
  amountPerformance: null,
  leadPerformance: null,
  leadsMeter: null,
  creditsmeter: null,
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_TOP_CAMPAIGNS:
      return {
        ...state,
        topCampagns: action.data,
      };
    case events.GET_TOP_PUBLISHERS:
      return {
        ...state,
        topPublishers: action.data,
      };
    case events.GET_TOP_BUYERS:
      return {
        ...state,
        topBuyers: action.data,
      };
    case events.GET_TOTAL_LEADS:
      return {
        ...state,
        totalLeads: action.data,
      };
    case events.GET_AMOUNT_PERFORMANCE:
      return {
        ...state,
        amountPerformance: action.data,
      };
    case events.GET_LEAD_PERFORMANCE:
      return {
        ...state,
        leadPerformance: action.data,
      };
    case events.GET_LEADS_METER:
      return {
        ...state,
        leadsMeter: action.data,
      };
    case events.CREDITS_ANALYSIS:
      return {
        ...state,
        creditsmeter: action.data,
      };
    default:
      return state;
  }
};
