import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchCallsIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-1041 -23)">
        <Path
          data-name="Rectangle 378"
          transform="translate(1041 23)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10321"
          d="M-5.762-21.362h.024a2.079 2.079 0 01.769.244 3.548 3.548 0 01.7.513 16.248 16.248 0 011.611 1.941 6.431 6.431 0 011.1 1.843 1.88 1.88 0 010 .952 4.671 4.671 0 01-.488 1.025l-.1.22a1.937 1.937 0 00-.208.439.514.514 0 00.012.293 9.755 9.755 0 002.1 3.2A9.5 9.5 0 002.93-8.618a.529.529 0 00.354.024 2.469 2.469 0 00.549-.269l.267-.146a3.311 3.311 0 01.9-.378 2.142 2.142 0 01.879.012 6.431 6.431 0 011.843 1.1 16.248 16.248 0 011.946 1.61 3.387 3.387 0 01.513.708 2.189 2.189 0 01.244.781 3.7 3.7 0 01-.965 2.32 6.693 6.693 0 01-1.77 1.684 3.354 3.354 0 01-2.026.659 5.541 5.541 0 01-2.393-.61A16.963 16.963 0 01-.391-3.32 35.139 35.139 0 01-4.26-6.677a36.493 36.493 0 01-3.357-3.845 16.963 16.963 0 01-2.2-3.662l-.1-.244a5.889 5.889 0 01-.5-2.295 3.137 3.137 0 01.6-1.855 7.574 7.574 0 011.797-1.869 3.511 3.511 0 012.258-.915zm-.073 1.538h-.1a3.114 3.114 0 00-1.392.793 5.976 5.976 0 00-1.221 1.306l-.073.122a1.875 1.875 0 00-.269 1.184 4.934 4.934 0 00.464 1.624l.122.269a16.836 16.836 0 002.1 3.369 37.755 37.755 0 003.174 3.54 36.793 36.793 0 003.564 3.1 16.269 16.269 0 003.345 2l.22.1a3.927 3.927 0 001.55.354 2.169 2.169 0 001.211-.378l.049-.049a3.2 3.2 0 00.5-.4 7.791 7.791 0 00.623-.647 6.162 6.162 0 00.647-.891 1.172 1.172 0 00.208-.647.5.5 0 00-.073-.232 1.266 1.266 0 00-.22-.281 17.209 17.209 0 00-1.648-1.358 10.008 10.008 0 00-1.355-.94.829.829 0 00-.366.037 2.921 2.921 0 00-.562.281l-.269.146a3.426 3.426 0 01-1.013.366A1.935 1.935 0 012.368-7.2a12.853 12.853 0 01-2-1.025 10.738 10.738 0 01-1.7-1.379 10.235 10.235 0 01-1.378-1.724 11.33 11.33 0 01-1.05-2.026 1.832 1.832 0 01-.11-1.062 4.2 4.2 0 01.476-1.111l.122-.22a1.822 1.822 0 00.208-.452.893.893 0 00.012-.33 10.008 10.008 0 00-.94-1.355 17.209 17.209 0 00-1.355-1.648 1.266 1.266 0 00-.281-.22.5.5 0 00-.232-.073z"
          transform="translate(1053 46)"
          fill={
            props.disabled
              ? "#C1C1C1"
              : props.hover === "true"
              ? "#2230F8"
              : props.color || "#212121"
          }
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchCallsIcon;
