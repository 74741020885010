import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchDownloadIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Download_default_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Path_10294"
        data-name="Path 10294"
        d="M11.25-15.012a6.424,6.424,0,0,1,2.637.563,7.091,7.091,0,0,1,2.145,1.406,7.511,7.511,0,0,1,1.441,2.18A6.535,6.535,0,0,1,18-8.262a6.894,6.894,0,0,1-.6,2.848,6.559,6.559,0,0,1-1.723,2.25,6.715,6.715,0,0,1-2.461,1.371l.387-.352a2.188,2.188,0,0,0,.492-.773,2.452,2.452,0,0,0,.141-.844q0-.141,0-.176A5.256,5.256,0,0,0,15.891-5.8a5.164,5.164,0,0,0,.6-2.461,5.126,5.126,0,0,0-.422-2.039,4.952,4.952,0,0,0-1.09-1.652,5.32,5.32,0,0,0-1.687-1.125A5.126,5.126,0,0,0,11.25-13.5a4.97,4.97,0,0,0-1.863.352A5.08,5.08,0,0,0,7.8-12.2a5.168,5.168,0,0,0-1.16,1.406A5.4,5.4,0,0,0,6.047-9H4.5a2.877,2.877,0,0,0-2.109.879,2.877,2.877,0,0,0-.879,2.109,3.126,3.126,0,0,0,.246,1.266,2.715,2.715,0,0,0,.7.949,3,3,0,0,0,.984.6A2.821,2.821,0,0,0,4.5-2.988h.879a3.036,3.036,0,0,0,.527.844l.668.633H4.5a4.368,4.368,0,0,1-2.25-.6A4.38,4.38,0,0,1,.6-3.727,4.558,4.558,0,0,1,0-6.012a4.492,4.492,0,0,1,.6-2.25A4.65,4.65,0,0,1,2.25-9.914a4.368,4.368,0,0,1,2.25-.6h.387a6.566,6.566,0,0,1,1.406-2.32,6.282,6.282,0,0,1,2.215-1.582A6.492,6.492,0,0,1,11.25-15.012ZM9.738-9a.715.715,0,0,1,.527.211.739.739,0,0,1,.246.527V-3.3l.949-.984a.715.715,0,0,1,.527-.211.84.84,0,0,1,.563.211.715.715,0,0,1,.211.527.84.84,0,0,1-.211.563L10.3-.949a.84.84,0,0,1-.562.211.715.715,0,0,1-.527-.211L6.961-3.2a.84.84,0,0,1-.211-.562.8.8,0,0,1,.211-.527A.8.8,0,0,1,7.488-4.5a.84.84,0,0,1,.563.211L9-3.3V-8.262a.8.8,0,0,1,.211-.527A.8.8,0,0,1,9.738-9Z"
        transform="translate(29 56)"
        fill="#212121"
      />
    </G>
  </Svg>
);
export default LeadsWatchDownloadIcon;
