import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllPlans = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/plans`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPlans = resData.data;
        dispatch({ type: events.GET_ALL_PLANS, data: allPlans });
        return allPlans ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getAllCards = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pm/cards`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allcards = resData.data;
        dispatch({ type: events.GET_ALL_CARDS, data: allcards });
        return allcards ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addCard = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pm/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newCard = resData.data;
        dispatch({ type: events.ADD_CARD, data: newCard });
        return newCard ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteCard = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pm/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedCard = resData.data;
        dispatch({ type: events.DELETE_CARD, data: deletedCard });
        return deletedCard ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getAllInvoices = (history, currentpage) => {
  const uri =
    history === "sub"
      ? `${events.API_URL}/invoices?page=${currentpage}&limit=10`
      : `${events.API_URL}/credits?page=${currentpage}&limit=5`;
  return async (dispatch) => {
    try {
      const response = await fetch(uri, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allInvoices = resData.data;
        dispatch({ type: events.GET_INVOICES, data: allInvoices });
        return allInvoices ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getPlanDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/plans/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const planDetails = resData.data;
        dispatch({ type: events.GET_PLAN_DETAILS, data: planDetails });
        return planDetails ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addSubscription = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/subscription/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const subscriptionDetails = resData.data;

        dispatch({ type: events.ADD_SUBSCRIPTION, data: subscriptionDetails });
        return subscriptionDetails ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const cancelSubscription = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/subscription/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        // const resData = await response.json();

        dispatch({ type: events.CANCEL_SUBSCRIPTION });
        return true;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const defaultCard = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/pm/default`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const defaultCard = resData.data;
        dispatch({ type: events.Default_CARD, data: defaultCard });
        return defaultCard ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addAutoReload = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reload-credits`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const autoReload = resData.data;

        dispatch({ type: events.AUTO_RELOAD_CREDITS, data: autoReload });
        return autoReload ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const removeInvoices=()=>{
  return {
    type:events.REMOVE_INVOICES
  }
}