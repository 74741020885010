import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchBuyersIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Buyer_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        width={aw(36)}
        height={ah(36)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <Path
        id="Path_10288"
        data-name="Path 10288"
        d="M-2.905-12.5h-.122a.5.5,0,0,0-.464.244.942.942,0,0,0-.146.488.711.711,0,0,0,.208.525.711.711,0,0,0,.525.208h.1a.5.5,0,0,0,.464-.256.97.97,0,0,0,.146-.476.711.711,0,0,0-.208-.525A.682.682,0,0,0-2.905-12.5ZM9.79-6.763l-.732-6.25A5.133,5.133,0,0,0,7.6-15.8a3.459,3.459,0,0,0-2.4-.977H5.1a5.207,5.207,0,0,0-.562-1.807,5.019,5.019,0,0,0-1.123-1.465A5.1,5.1,0,0,0,1.855-21,4.776,4.776,0,0,0,0-21.362a5.4,5.4,0,0,0-1.99.378A4.586,4.586,0,0,0-3.638-19.9a5.2,5.2,0,0,0-1.013,1.416A4.739,4.739,0,0,0-5.1-16.772a3.546,3.546,0,0,0-2.478.977A4.768,4.768,0,0,0-8.96-13.013l-.83,6.128a7.911,7.911,0,0,0,.024,2.856,4.275,4.275,0,0,0,.977,1.978A4.142,4.142,0,0,0-6.934-.9a8.256,8.256,0,0,0,2.661.391H4.175A7.364,7.364,0,0,0,6.738-.928a4.729,4.729,0,0,0,1.88-1.2,4.53,4.53,0,0,0,1.05-1.953A6.89,6.89,0,0,0,9.79-6.763ZM-2.612-18.75a5.48,5.48,0,0,1,1.2-.757A3.339,3.339,0,0,1,0-19.8a3.517,3.517,0,0,1,2.356.891A3.253,3.253,0,0,1,3.54-16.772H-3.638a6.69,6.69,0,0,1,.391-1.062A3.176,3.176,0,0,1-2.612-18.75ZM4.175-1.978H-4.272A4.638,4.638,0,0,1-7.7-3.027q-1.013-1.05-.623-3.638l.83-6.055a3.418,3.418,0,0,1,.879-1.953A2.12,2.12,0,0,1-5.1-15.308H5.2a2.06,2.06,0,0,1,1.44.623,3.655,3.655,0,0,1,.952,1.868l.732,6.055a4.248,4.248,0,0,1-.793,3.6A4.392,4.392,0,0,1,4.175-1.978ZM3.027-12.5H2.905a.508.508,0,0,0-.452.244.89.89,0,0,0-.159.488.711.711,0,0,0,.208.525.711.711,0,0,0,.525.208h.1a.523.523,0,0,0,.476-.256.917.917,0,0,0,.159-.476.711.711,0,0,0-.208-.525A.711.711,0,0,0,3.027-12.5Z"
        transform="translate(1053 46)"
        fill={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </G>
  </Svg>
);
export default LeadsWatchBuyersIcon;
