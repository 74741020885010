import { React, useState, useEffect, useRef } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Stack,
  Avatar,
  Pressable,
  Hidden,
  useBreakpointValue,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import LeadsWatchProfileIcon from "../../components/svg/LeadsWatchProfileIcon";
// import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import { aw, ah } from "../../utils";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import {
  getAllBuyers,
  createBuyer,
  editBuyer,
  removeSingleBuyerRoute,
  deleteBuyer,
} from "../../store/actions/buyers";

const Groups = ({ isOpen }) => {
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [refresh, setRefresh] = useState(false);
  const [phoneNo, setPhoneNo] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const hiddenFileInput = useRef(null);
  const allBuyers = useSelector((state) => state.buyers.allBuyers);
  const [currentPage, setCurrentPage] = useState(
    page && page.buyers ? parseInt(page.buyers) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.buyers ? parseInt(pagecount.buyers) : 10
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const [edit, setEdit] = useState("");
  const [sort, setSort] = useState({});
  const [clearSearch, setClearSearch] = useState(false);
  const dispatch = useDispatch();
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,

    getValues,
  } = useForm({
    defaultValues: {
      select:
        pagecount && pagecount.buyers ? pagecount.buyers.toString() : "10",
    },
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,

    getValues: getValues1,
    reset: reset1,
  } = useForm({
    mode: "all",
    // defaultValues: { select: "10" },
  });
  const [search, setSearch] = useState(getValues1("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, buyers: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, buyers: 1 }));
  };

  const fetchBuyers = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllBuyers(currentPage, selectCount, sort, undefined, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["buyer"] &&
        profile.permission_ids["buyer"].includes("view")
      ) {
        fetchBuyers();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchBuyers();
    }
  }, [currentPage, selectCount, sort, search]);

  const handleChange = (e) => {
    setImage(e.target.files[0]);
    setImage1(URL.createObjectURL(e.target.files[0]));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const addBuyer = async (data) => {
    // phoneNo ? alert(phoneNo.format) : alert("no phone");

    let j = 0;
    const number = data.phone;
    let format = phoneNo && phoneNo.format.toString();
    for (let i = 0; i <= format.length - 1; i++) {
      if (format[i] === ".") {
        format = format.replace(".", number[j]);
        j++;
      }
    }

    let formData = new FormData();
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("company", data.company);
    formData.append("email", data.email);
    formData.append("phone", format);
    formData.append("image_file", image);
    formData.append("status", data.status);
    setIsSave(true);
    if (edit) {
      try {
        await dispatch(editBuyer(edit.id, formData));
        // setCurrentPage(1);
        await dispatch(getAllBuyers(currentPage, selectCount));
        setEdit("");
        setImage(null);
        setImage1(null);
        setIsModalOpen(false);

        toast.success("Buyer Edited Succesfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(createBuyer(formData));
        setCurrentPage(1);
        localStorage.setItem("page", JSON.stringify({ ...page, buyers: 1 }));
        await dispatch(getAllBuyers(currentPage, selectCount));
        setIsModalOpen(false);
        toast.success("Buyer Added Succesfully");
        setPhoneNo("in");
        reset({
          firstname: "",
          lastname: "",
          company: "",
          email: "",
          phone: "91",
          status: "",
        });
        setRefresh(!refresh);
        // clearErrors();
        setImage1(null);
        setImage(null);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setIsSave(false);
  };
  const handleEdit = (item) => {
    setEdit({
      firstname: item.firstname,
      lastname: item.lastname,
      company: item.company,
      email: item.email,
      phone: item.phone && item.phone.replace(/[^0-9]/g, ""),
      id: item._id,
      status: item.status,
    });
    setImage1(item.image && item.image !== "" ? item.image : null);
    setIsModalOpen(true);
  };
  useEffect(() => {
    // alert("useEffect run");
    if (edit) {
      reset(edit);
    } else {
      // clearErrors();
      setPhoneNo("in");
      reset({
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "91",
        status: "",
      });
      // setRefresh(!refresh);
    }
  }, [edit]);
  const handleSort = (order) => {
    setSort(order);
  };
  useEffect(() => {
    dispatch(removeSingleBuyerRoute());
  }, []);
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);

      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, buyers: 1 }));
    }
  };
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteBuyer(id));
        setDeleteId(null);
        setConfirmModal(false);
        await dispatch(
          getAllBuyers(currentPage, selectCount, sort, undefined, search)
        );
        toast.success("Buyer Deleted Succesfully");
      } catch (err) {
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Buyers
                </Text>
                <Text
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {allBuyers && allBuyers.list ? allBuyers.total_count : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base", "md"]}>
                <HStack space={aw(10)}>
                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Buyer"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(36)}
                    control={control1}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset1();
                            setSearch(getValues1("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, buyers: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit1(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit1(
                          handleSearch({ search: getValues1("search") })
                        );
                      }
                    }}
                  />
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["buyer"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => setIsModalOpen(true)}
                    label="Add New"
                    style={{
                      width: aw(114),
                      height: ah(36),
                      // borderRadius: aw(6),
                    }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    // fontSize={aw(15)}
                    variant={"solid"}
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Buyers list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(10)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>

              <LeadsWatchButton
                onPress={() => navigate("groups")}
                bg="#2230F8"
                borderColor="#2230F8"
                // fontSize={aw(15)}
                variant={"solid"}
                color="#FFFFFF"
                label="Manage Groups"
                style={{ height: ah(32) }}
              />
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>
              <LeadsWatchTable
                isOpen={isOpen}
                sort={sort}
                handleSort={handleSort}
                heading={[
                  "buyer_name",
                  "company",
                  "email",
                  "phone",
                  "Leads Accepted / total",
                  "status",
                  "Actions",
                ]}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                sortIcon={true}
                data={allBuyers && allBuyers.list ? allBuyers.list : []}
                screen={"buyer"}
                isLoading={isLoading}
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  allBuyers && allBuyers.list ? allBuyers.total_count : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, buyers: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(370)}
        height={ah(650)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEdit("");
          setImage1(null);
          setImage(null);
          setPhoneNo("in");
          reset({
            firstname: "",
            lastname: "",
            company: "",
            email: "",
            phone: "91",
            status: "",
          });
          setRefresh(!refresh);
          // clearErrors();
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            {edit ? "Edit Buyer" : "Add Buyer"}
          </Text>
        }
      >
        <VStack alignItems={"center"} space={ah(16)}>
          {image1 ? (
            <Avatar
              height={aw(72)}
              width={aw(72)}
              source={{
                uri: image1,
              }}
            />
          ) : (
            <LeadsWatchProfileIcon height={aw(72)} width={aw(72)} />
          )}
          <VStack alignItems={"center"}>
            <HStack space={1}>
              <VStack>
                <Text
                  // onPress={pickImage}
                  color="#212121"
                  fontFamily="RobotoMedium"
                  fontSize={aw(14)}
                  onPress={handleClick}
                >
                  Click to upload
                </Text>
                <input
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                  type="file"
                  onClick={(e) => (e.target.value = null)}
                  name="picture"
                  accept="image/png, image/jpg,image/jpeg"
                ></input>
                <Divider bg="#D7D7D7" thickness="2" />
              </VStack>
              {/* <Text
                color="#212121"
                fontFamily="RobotoRegular"
                fontSize={aw(14)}
              >
                or drag and drop
              </Text> */}
            </HStack>

            <Text color="#919191" fontSize={aw(12)} fontFamily="RobotoRegular">
              Maximum file size 50 MB
            </Text>
          </VStack>
        </VStack>
        <Stack mt={aw(30)} space={aw(15)} alignItems="center">
          <HStack space={ah(20)}>
            <LeadsWatchFilterInput
              refresh={refresh}
              screen={
                getValues("firstname") && getValues("firstname").length > 0
                  ? "edit"
                  : "create"
              }
              // hasValue={
              //   getValues("firstname") && getValues("firstname").length > 0
              //     ? false
              //     : true
              // }
              // style={{ width: aw(155), heigth: ah(40) }}
              width={aw(155)}
              height={ah(40)}
              name="firstname"
              placeholder="First Name"
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.firstname ? true : false}
              error={errors.firstname ? errors.firstname.message : ""}
            />
            <LeadsWatchFilterInput
              refresh={refresh}
              screen={
                getValues("lastname") && getValues("lastname").length > 0
                  ? "edit"
                  : "create"
              }
              // hasValue={
              //   getValues("lastname") && getValues("lastname").length > 0
              //     ? false
              //     : true
              // }
              // style={{ width: aw(155), heigth: ah(40) }}
              width={aw(155)}
              height={ah(40)}
              name="lastname"
              placeholder="Last Name"
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.lastname ? true : false}
              error={errors.lastname ? errors.lastname.message : ""}
            />
          </HStack>
          {/* <PhoneInput
            country={"us"}
            inputStyle={{
              width: aw(330),
              heigth: ah(40),
            }}
            value={phone}
            onChange={(phone) => setPhone(phone)}
            dropdownStyle={{
              height: ah(150),
              width: aw(330),
            }}
          /> */}
          <HStack zIndex={1}>
            <Controller
              control={control}
              name={"phone"}
              style={{
                width: aw(330),
                heigth: ah(40),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  color="#212121"
                  className={"phoneInput"}
                  country={"in"}
                  isValid={(inputNumber, country, countries) => {
                    setPhoneNo(country);
                    return !errors.phone;
                  }}
                  placeholder="Enter PhoneNumber"
                  value={value}
                  containerStyle={{
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                  }}
                  inputStyle={{
                    borderWidth: errors.phone ? 2 : 1,
                    borderRadius: aw(5),
                    width: aw(330),
                    height: ah(10),
                    fontFamily: "RobotoRegular",
                    backgroundColor: "#fff",
                    // borderWidth: 0,
                    fontSize: aw(15),
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                    // borderColor: !errors.phone && "#D1D5DB",
                  }}
                  onChange={(value, data) => {
                    onChange(value);
                    // onChange(value);
                  }}
                  onBlur={() => {
                    onBlur();
                  }}
                  dropdownStyle={{
                    height: ah(150),
                    width: aw(330),
                  }}
                />
              )}
              // rules={{
              //   required: {
              //     value: true,
              //     // validate: (value) =>
              //     //   phoneNo &&
              //     //   phoneNo.format.match(/\./g).length === value.length,
              //     message: "Enter a valid password",
              //     validate: (value) =>
              //       value.length === phoneNo.format.match(/\./g).length,
              //   },
              // }}
              rules={{
                required: {
                  value: true,
                },
                validate: (value) => {
                  return (
                    value && value.length === phoneNo.format.match(/\./g).length
                  );
                },
              }}
              invalid={errors.phone ? true : false}
              // invalid={true}
              error={errors.phone ? errors.phone.message : ""}
            />
          </HStack>
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("company") && getValues("company").length > 0
                ? "edit"
                : "create"
            }
            // hasValue={
            //   getValues("company") && getValues("company").length > 0
            //     ? false
            //     : true
            // }
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            height={ah(40)}
            name="company"
            placeholder="Company"
            control={control}
          />
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("email") && getValues("email").length > 0
                ? "edit"
                : "create"
            }
            // hasValue={
            //   getValues("email") && getValues("email").length > 0 ? false : true
            // }
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            height={ah(40)}
            name="email"
            placeholder="Email"
            control={control}
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />
          {edit ? (
            <HStack>
              <LeadsWatchSelect
                control={control}
                name="status"
                width={aw(330)}
                placeholder="status"
                list={["Active", "InActive"]}
                // value={status}
                // handleChange={(value) => setStatus(value)}
                height={ah(40)}
              />
            </HStack>
          ) : null}

          <HStack space={ah(20)}>
            <LeadsWatchButton
              label="Cancel"
              style={{ width: aw(155), height: ah(40) }}
              borderColor="#D1D5DB"
              onPress={() => {
                setIsModalOpen(false);
                setPhoneNo("in");
                reset({
                  firstname: "",
                  lastname: "",
                  company: "",
                  email: "",
                  phone: "91",
                  status: "",
                });
                setRefresh(!refresh);
                // clearErrors();
                setEdit("");
                setImage1(null);
                setImage(null);
              }}
            />
            <LeadsWatchButton
              isLoading={isSave}
              onPress={handleSubmit(addBuyer)}
              label={edit ? "Edit Buyer" : "Add Buyer"}
              // label={"Add Buyer"}
              style={{ width: aw(155), height: ah(40) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </HStack>
        </Stack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Buyer.
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => {
                setConfirmModal(false);
              }}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default Groups;

// https://api.leadswatch.com/assets/img649a9e730cd167c9c5ec13c3.jpg

// https://api.leadswatch.com/assets/img649a9e730cd167c9c5ec13c3.jpg
