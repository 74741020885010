import React, { useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  FlatList,
  Pressable,
  Tooltip,
} from "native-base";
import { set, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
//components
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";
//svg
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
//utils
import { ah, aw } from "../../utils";

import LeadsWatchTickIcon from "../../components/svg/LeadsWatchTickIcon";
import LeadsWatchCrossFillIcon from "../../components/svg/LeadsWatchCrossFillIcon";
import {
  addcallSchedule,
  editcallSchedule,
  deletecallSchedule,
  addivrNum,
  editivrNum,
  deleteivrNum,
} from "../../store/actions/common";
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";

const IVRCallConfiguration = ({
  control,
  errors,
  getValues,
  isOpen,
  name,
  type,
}) => {
  const callSchedule = useSelector((state) => state.common.scheduletime);
  const ivrnumbers = useSelector((state) => state.common.ivrnumbers);
  const dispatch = useDispatch();
  //   const [openScheduleModal, setOpenScheduleModal] = useState(false);
  //   const [fromDate, setFromDate] = useState("");
  //     const [endDate, setEndDate] = useState("");

  // const [callSchedule, setCallSchedule] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editivrId, setEdiivrId] = useState(null);
  const [addnew, setAddnew] = useState(false);
  const [addnewivr, setAddnewivr] = useState(false);
  const {
    control: control1,
    getValues: getValues1,
    formState: { errors: errors1 },
    reset: reset1,
    handleSubmit: handleSubmit1,
  } = useForm({ mode: "all" });
  const {
    control: control2,
    getValues: getValues2,
    formState: { errors: errors2 },
    reset: reset2,
    handleSubmit: handleSubmit2,
  } = useForm({ mode: "all" });
  const defaultValuesFortime = [
    { label: "00:00", value: "00:00" },
    { label: "01:00", value: "01:00" },
    { label: "02:00", value: "02:00" },
    { label: "03:00", value: "03:00" },
    { label: "04:00", value: "04:00" },
    { label: "05:00", value: "05:00" },
    { label: "06:00", value: "06:00" },
    { label: "07:00", value: "07:00" },
    { label: "08:00", value: "08:00" },
    { label: "09:00", value: "09:00" },
    { label: "10:00", value: "10:00" },
    { label: "11:00", value: "11:00" },
    { label: "12:00", value: "12:00" },
    { label: "13:00", value: "13:00" },
    { label: "14:00", value: "14:00" },
    { label: "15:00", value: "15:00" },
    { label: "16:00", value: "16:00" },
    { label: "17:00", value: "17:00" },
    { label: "18:00", value: "18:00" },
    { label: "19:00", value: "19:00" },
    { label: "20:00", value: "20:00" },
    { label: "21:00", value: "21:00" },
    { label: "22:00", value: "22:00" },
    {
      label: "23:00",
      value: "23:00",
    },
  ];
  const defaultDigitValues = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "0", value: "0" },
    { label: "*", value: "*" },
    { label: "#", value: "#" },
  ];
  const handleAddSchedule = async (data) => {
    if (!editId) {
      const newData = {
        day: data.day && data.day.value,
        start_time: data.start_time && data.start_time.value,
        end_time: data.end_time && data.end_time.value,
        id: Date.now().toString(),
      };

      dispatch(addcallSchedule(newData));
      reset1({ start_date: "", end_date: "", day: "" });
      setAddnew(false);
    } else {
      const newData = {
        day: data.day && data.day.value,
        start_time: data.start_time && data.start_time.value,
        end_time: data.end_time && data.end_time.value,
        id: editId,
      };
      dispatch(editcallSchedule(newData));
      reset1({ start_time: "", end_time: "", day: "" });
      setEditId(null);
    }
  };
  const handleCancel = () => {
    reset1({ start_time: "", end_time: "", day: "" });
    setAddnew(false);
    setEditId(null);
  };
  const handleCancelivr = () => {
    reset2({ numbers: "", message: "", digit: "" });
    setAddnewivr(false);
    setEdiivrId(null);
  };
  const handleEdit = (data) => {
    setAddnew(false);
    setEditId(data.id);
    reset1({
      day: {
        label: data.day.charAt(0).toUpperCase() + data.day.slice(1),
        value: data.day,
      },
      start_time: { label: data.start_date, value: data.start_date },
      end_time: { label: data.end_date, value: data.end_date },
    });
  };
  const handleDelete = (id) => {
    dispatch(deletecallSchedule(id));
  };
  //ivr numbers
  const handleAddivrnumbers = async (data) => {
    if (!editivrId) {
      const newData = {
        digit: data.digit && data.digit.value,
        message: data.message,
        numbers: data.numbers.split(","),
        id: Date.now().toString(),
      };

      dispatch(addivrNum(newData));
      reset2({ numbers: "", message: "", digit: "" });
      setAddnewivr(false);
    } else {
      const newData = {
        digit: data.digit && data.digit.value,
        message: data.message,
        numbers: data.numbers.split(","),
        id: editivrId,
      };
      dispatch(editivrNum(newData));
      reset2({ numbers: "", message: "", digit: "" });
      setEdiivrId(null);
    }
  };
  const handleEditivr = (data) => {
    setAddnewivr(false);
    setEdiivrId(data.id);
    reset2({
      digit: {
        label: data.digit,
        value: data.digit,
      },
      message: data.message,
      numbers: data.numbers.join(","),
    });
  };
  const handleDeleteivr = (id) => {
    dispatch(deleteivrNum(id));
  };
  return (
    <>
      <Box marginTop={ah(30)}>
        <LeadsWatchCard
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          height={ah(750)}
          alignItems={"flex-start"}
        >
          <VStack py={5} justifyContent={"center"} px={aw(15)} width={"100%"}>
            <HStack width={"100%"} height={ah(40)}>
              <Text
                style={{
                  fontFamily: "RobotoMedium",
                  fontSize: aw(18),
                  color: "#121233",
                }}
              >
                Call Configuration
              </Text>
            </HStack>
            <Flex gap={ah(25)}>
              <Flex
                direction="row"
                justifyContent={"space-between"}
                width="100%"
                // wrap="wrap"
                gap={ah(25)}
              >
                <Flex width="49%">
                  <LeadsWatchAsyncSelect
                    screen={name}
                    hasValue={getValues("delivery_type") ? false : true}
                    minWidth={"100%"}
                    defaultOptions={[
                      { label: "Hourly", value: "hourly" },
                      { label: "Daily", value: "daily" },
                      { label: "Weekly", value: "weekly" },
                      { label: "Montly", value: "monthly" },
                      { label: "Yearly", value: "yearly" },
                    ]}
                    control={control}
                    placeholder="Calls Limit Type"
                    name="delivery_type"
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.delivery_type ? true : false}
                    error={
                      errors.delivery_type ? errors.delivery_type.message : ""
                    }
                  />
                </Flex>
                <Flex width="49%">
                  <LeadsWatchFilterInput
                    onlyNumers={true}
                    screen={name}
                    hasValue={
                      getValues("no_of_calls") &&
                      getValues("no_of_calls").length > 0
                        ? false
                        : true
                    }
                    name={"no_of_calls"}
                    placeholder={"Calls Limit"}
                    control={control}
                    // style={{ width: aw(587), height: ah(40) }}
                    width="100%"
                    height={ah(40)}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.no_of_calls ? true : false}
                    error={errors.no_of_calls ? errors.no_of_calls.message : ""}
                  />
                </Flex>
              </Flex>
              <Flex
                direction="row"
                justifyContent={"space-between"}
                width="100%"
                // wrap="wrap"
                gap={ah(25)}
              >
                <Flex width="49%">
                  <LeadsWatchFilterInput
                    onlyNumers={true}
                    screen={name}
                    hasValue={
                      getValues("succses_time") &&
                      getValues("success_time").length > 0
                        ? false
                        : true
                    }
                    name={"success_time"}
                    placeholder={"Success Time (s)"}
                    control={control}
                    // style={{ width: aw(587), height: ah(40) }}
                    width={"100%"}
                    height={ah(40)}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.succses_time ? true : false}
                    error={
                      errors.succses_time ? errors.succses_time.message : ""
                    }
                    rightIcon={
                      <Tooltip
                        label="Enter time in seconds after which call will be considered as successfull Lead"
                        width={aw(170)}
                      >
                        <HStack pr={aw(5)}>
                          <LeadsWatchInfoIcon />
                        </HStack>
                      </Tooltip>
                    }
                  />
                </Flex>
                <Flex width="49%">
                  <LeadsWatchFilterInput
                    onlyNumers={true}
                    screen={name}
                    hasValue={
                      getValues("price") && getValues("price").length > 0
                        ? false
                        : true
                    }
                    name={"price"}
                    placeholder={"Amount"}
                    control={control}
                    // style={{ width: aw(587), height: ah(40) }}
                    width={"100%"}
                    height={ah(40)}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.price ? true : false}
                    error={errors.price ? errors.price.message : ""}
                  />
                </Flex>
              </Flex>
              {type === "ppc" && (
                <Flex>
                  <Flex width="49%">
                    <LeadsWatchFilterInput
                      screen={name}
                      hasValue={getValues("transfer_number") ? false : true}
                      control={control}
                      placeholder="Enter Transfer Number"
                      name="transfer_number"
                      rules={{
                        required: {
                          value: true,
                        },
                      }}
                      invalid={errors.transfer_number ? true : false}
                      error={
                        errors.transfer_number
                          ? errors.transfer_number.message
                          : ""
                      }
                      rightIcon={
                        <Tooltip
                          label="Enter Number To which lead have to be transformed
                        ex: 919912345678"
                          width={aw(170)}
                        >
                          <HStack pr={aw(5)}>
                            <LeadsWatchInfoIcon />
                          </HStack>
                        </Tooltip>
                      }
                    />
                  </Flex>
                </Flex>
              )}
              <Flex width="100%" gap={ah(10)}>
                <Flex
                  width="100%"
                  justifyContent={"space-between"}
                  direction="row"
                >
                  <Text
                    style={{
                      fontFamily: "RobotoMedium",
                      fontSize: aw(18),
                      color: "#121233",
                    }}
                  >
                    Call Schedule
                  </Text>
                  <LeadsWatchButton
                    onPress={() => {
                      setAddnew(true);
                      setEditId(null);
                      reset1({ day: "", start_time: "", end_time: "" });
                    }}
                    label="Add"
                    leftIcon={<LeadsWatchAddIcon />}
                    style={{
                      width: aw(90),
                      height: ah(36),
                      borderRadius: "6px",
                    }}
                    color="#FFFFFF"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#2230F8"}
                    fontWeight="400"
                    borderColor="#FFFFFF"
                  />
                </Flex>
                <Flex>
                  <HStack
                    alignItems={"center"}
                    style={{
                      height: ah(60),
                      background: "#F9FAFB 0% 0% no-repeat padding-box",
                      border: "1px solid #D7D7D7",
                      borderTopLeftRadius: aw(10),
                      borderTopRightRadius: aw(10),
                      borderBottom: "0px",
                    }}
                  >
                    {["Day", "Start Time", "End Time", "Actions"].map((val) => {
                      return (
                        <HStack
                          alignItems={"center"}
                          width={aw(290)}
                          justifyContent="flex-start"
                          key={val}
                        >
                          {val === "Start Time" || val === "End Time" ? (
                            <HStack width={aw(290)}>
                              <Text
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                ml={aw(20)}
                                color="#121233"
                              >
                                {val}
                              </Text>
                              <Tooltip label="Time Zone : UTC">
                                <HStack>
                                  <LeadsWatchInfoIcon />
                                </HStack>
                              </Tooltip>
                            </HStack>
                          ) : (
                            <Text
                              width={aw(290)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              ml={aw(20)}
                              color="#121233"
                            >
                              {val}
                            </Text>
                          )}
                        </HStack>
                      );
                    })}
                  </HStack>
                  {addnew && (
                    <HStack
                      alignItems={"center"}
                      style={{
                        height: ah(60),
                        // background: "#F9FAFB 0% 0% no-repeat padding-box",
                        border: "1px solid #D7D7D7",

                        borderBottomWidth: "0px",
                      }}
                    >
                      <HStack
                        alignItems={"center"}
                        width={aw(290)}
                        ml={aw(19)}
                        justifyContent="flex-start"
                      >
                        <LeadsWatchAsyncSelect
                          required={true}
                          height={40}
                          minWidth={aw(200)}
                          // minWidth={aw(583)}
                          defaultOptions={[
                            { value: "Monday", label: "Monday" },
                            { value: "Tuesday", label: "Tuesday" },
                            { value: "Wednesday", label: "Wednesday" },
                            { value: "Thursday", label: "Thursday" },
                            { value: "Friday", label: "Friday" },
                            { value: "Saturday", label: "Saturday" },
                            { value: "Sunday", label: "Sunday" },
                          ]}
                          control={control1}
                          name="day"
                          placeholder="Select Day"
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors1.day ? true : false}
                          error={errors1.day ? errors1.day.message : ""}
                        />
                      </HStack>
                      <HStack
                        alignItems={"center"}
                        width={aw(290)}
                        justifyContent="flex-start"
                      >
                        <LeadsWatchAsyncSelect
                          required={true}
                          height={40}
                          minWidth={aw(200)}
                          // minWidth={aw(583)}
                          defaultOptions={defaultValuesFortime}
                          control={control1}
                          name="start_time"
                          placeholder="Select Start Time"
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors1.start_time ? true : false}
                          error={
                            errors1.start_time ? errors1.start_time.message : ""
                          }
                        />
                      </HStack>
                      <HStack
                        alignItems={"center"}
                        width={aw(290)}
                        justifyContent="flex-start"
                      >
                        <LeadsWatchAsyncSelect
                          required={true}
                          height={40}
                          minWidth={aw(200)}
                          // minWidth={aw(583)}
                          defaultOptions={defaultValuesFortime}
                          control={control1}
                          name="end_time"
                          placeholder="Select End Time"
                          rules={{
                            required: {
                              value: true,
                            },
                          }}
                          invalid={errors1.end_time ? true : false}
                          error={
                            errors1.end_time ? errors1.end_time.message : ""
                          }
                        />
                      </HStack>
                      <HStack
                        alignItems={"center"}
                        width={aw(290)}
                        justifyContent="flex-start"
                      >
                        <Pressable onPress={handleSubmit1(handleAddSchedule)}>
                          <LeadsWatchTickIcon color="#32CD32" />
                        </Pressable>
                        <Pressable onPress={handleCancel}>
                          <LeadsWatchCrossFillIcon />
                        </Pressable>
                      </HStack>
                    </HStack>
                  )}

                  <FlatList
                    borderColor="#D7D7D7"
                    borderBottomRightRadius={aw(10)}
                    borderBottomLeftRadius={aw(10)}
                    borderWidth={1}
                    maxHeight={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(290)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={callSchedule}
                    keyExtractor={(item, index) => item.id}
                    renderItem={({ item, index }) => (
                      <VStack key={item.id}>
                        {editId !== item.id ? (
                          <HStack
                            height={ah(60)}
                            alignItems={"center"}
                            bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                            style={{
                              borderBottomWidth:
                                index === callSchedule.length - 1
                                  ? "0px"
                                  : "1px",
                              // borderBottomWidth: "1px",
                              borderColor: "#D7D7D7",
                            }}
                          >
                            <Text
                              color="#121233"
                              width={aw(290)}
                              ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                            >
                              {item.day}
                            </Text>
                            <Text
                              color="#121233"
                              width={aw(290)}
                              // ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                            >
                              {item.start_time}
                            </Text>
                            <Text
                              color="#121233"
                              width={aw(290)}
                              // ml={aw(20)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                            >
                              {item.end_time}
                            </Text>
                            <HStack width={aw(290)}>
                              <Pressable onPress={() => handleEdit(item)}>
                                <LeadsWatchEditIcon />
                              </Pressable>
                              <Pressable onPress={() => handleDelete(item.id)}>
                                <LeadsWatchDeleteIcon />
                              </Pressable>
                            </HStack>
                          </HStack>
                        ) : (
                          <HStack
                            alignItems={"center"}
                            style={{
                              height: ah(60),
                              // background: "#F9FAFB 0% 0% no-repeat padding-box",
                              // border: "1px solid #D7D7D7",

                              borderColor: "#D7D7D7",

                              borderBottomWidth:
                                index === callSchedule.length - 1
                                  ? "0px"
                                  : "1px",
                            }}
                          >
                            <HStack
                              alignItems={"center"}
                              width={aw(290)}
                              ml={aw(19)}
                              justifyContent="flex-start"
                            >
                              <LeadsWatchAsyncSelect
                                required={true}
                                height={40}
                                minWidth={aw(200)}
                                // minWidth={aw(583)}
                                defaultOptions={[
                                  { value: "Monday", label: "Monday" },
                                  { value: "Tuesday", label: "Tuesday" },
                                  { value: "Wednesday", label: "Wednesday" },
                                  { value: "Thursday", label: "Thursday" },
                                  { value: "Friday", label: "Friday" },
                                  { value: "Saturday", label: "Saturday" },
                                  { value: "Sunday", label: "Sunday" },
                                ]}
                                control={control1}
                                name="day"
                                placeholder="Select Day"
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={errors1.day ? true : false}
                                error={errors1.day ? errors1.day.message : ""}
                              />
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              width={aw(290)}
                              justifyContent="flex-start"
                            >
                              <LeadsWatchAsyncSelect
                                required={true}
                                height={40}
                                minWidth={aw(200)}
                                // minWidth={aw(583)}
                                defaultOptions={defaultValuesFortime}
                                control={control1}
                                name="start_time"
                                placeholder="Select Start Time"
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={errors1.start_time ? true : false}
                                error={
                                  errors1.start_time
                                    ? errors1.start_time.message
                                    : ""
                                }
                              />
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              width={aw(290)}
                              justifyContent="flex-start"
                            >
                              <LeadsWatchAsyncSelect
                                required={true}
                                height={40}
                                minWidth={aw(200)}
                                // minWidth={aw(583)}
                                defaultOptions={defaultValuesFortime}
                                control={control1}
                                name="end_time"
                                placeholder="Select End Time"
                                rules={{
                                  required: {
                                    value: true,
                                  },
                                }}
                                invalid={errors1.end_time ? true : false}
                                error={
                                  errors1.end_time
                                    ? errors1.end_time.message
                                    : ""
                                }
                              />
                            </HStack>
                            <HStack
                              alignItems={"center"}
                              width={aw(290)}
                              justifyContent="flex-start"
                            >
                              <Pressable
                                onPress={handleSubmit1(handleAddSchedule)}
                              >
                                <LeadsWatchTickIcon color="#32CD32" />
                              </Pressable>
                              <Pressable onPress={handleCancel}>
                                <LeadsWatchCrossFillIcon />
                              </Pressable>
                            </HStack>
                          </HStack>
                        )}
                      </VStack>
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
          </VStack>
        </LeadsWatchCard>
      </Box>
      {type === "ivr" && (
        <Box marginTop={ah(30)}>
          <LeadsWatchCard
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            height={ah(1000)}
            alignItems={"flex-start"}
          >
            <VStack
              py={5}
              justifyContent={"center"}
              px={aw(15)}
              width={"100%"}
              space={ah(10)}
            >
              <HStack
                width={"100%"}
                height={ah(40)}
                justifyContent={"space-between"}
                // alignItems={"center"}
              >
                <Text
                  style={{
                    fontFamily: "RobotoMedium",
                    fontSize: aw(18),
                    color: "#121233",
                  }}
                >
                  IVR Configuration
                </Text>
                <LeadsWatchButton
                  onPress={() => {
                    setAddnewivr(true);
                    setEdiivrId(null);
                    reset2({ numbers: "", message: "", digit: "" });
                  }}
                  label="Add"
                  leftIcon={<LeadsWatchAddIcon />}
                  style={{
                    width: aw(90),
                    height: ah(36),
                    borderRadius: "6px",
                  }}
                  color="#FFFFFF"
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  bg={"#2230F8"}
                  fontWeight="400"
                  borderColor="#FFFFFF"
                />
              </HStack>

              <Flex gap={ah(25)}>
                <Flex width="100%" gap={ah(10)}>
                  <Flex>
                    <HStack
                      alignItems={"center"}
                      style={{
                        height: ah(60),
                        background: "#F9FAFB 0% 0% no-repeat padding-box",
                        border: "1px solid #D7D7D7",
                        borderTopLeftRadius: aw(10),
                        borderTopRightRadius: aw(10),
                        borderBottom: "0px",
                      }}
                    >
                      {["Digit", "Message", "Numbers", "Actions"].map(
                        (val, index) => {
                          return (
                            <HStack
                              alignItems={"center"}
                              width={
                                val === "Digit" || val === "Actions"
                                  ? aw(170)
                                  : aw(460)
                              }
                              justifyContent="flex-start"
                              key={val}
                            >
                              <Text
                                // width={aw(350)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                                ml={index === 0 ? aw(20) : aw(0)}
                                color="#121233"
                              >
                                {val}
                              </Text>

                              {(val === "Message" || val === "Numbers") && (
                                <Tooltip
                                  label={
                                    val === "Message"
                                      ? "Messsage to be announced on press of keys"
                                      : "Numbers to be redirect on press of keys Ex:919912345694"
                                  }
                                  width={aw(170)}
                                >
                                  <HStack>
                                    <LeadsWatchInfoIcon />
                                  </HStack>
                                </Tooltip>
                              )}
                            </HStack>
                          );
                        }
                      )}
                    </HStack>
                    {addnewivr && (
                      <HStack
                        // alignItems={"center"}
                        style={{
                          height: ah(140),
                          // background: "#F9FAFB 0% 0% no-repeat padding-box",
                          border: "1px solid #D7D7D7",

                          borderBottomWidth: "0px",
                        }}
                      >
                        <HStack
                          alignItems={"center"}
                          width={aw(170)}
                          // ml={aw(15)}
                          justifyContent="flex-start"
                        >
                          <LeadsWatchAsyncSelect
                            ml={aw(20)}
                            required={true}
                            height={40}
                            minWidth={aw(100)}
                            // minWidth={aw(583)}
                            defaultOptions={defaultDigitValues.filter(
                              (val) =>
                                !ivrnumbers.find(
                                  (item) => item.digit === val.value
                                )
                            )}
                            control={control2}
                            name="digit"
                            placeholder="Select Digit"
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors2.digit ? true : false}
                            error={errors2.digit ? errors2.digit.message : ""}
                          />
                        </HStack>
                        <HStack
                          alignItems={"center"}
                          width={aw(460)}
                          justifyContent="flex-start"
                          // ml={aw(20)}
                        >
                          <LeadsWatchTextArea
                            // width={aw(200)}
                            required={true}
                            height={ah(100)}
                            style={{ width: aw(400) }}
                            control={control2}
                            name="message"
                            placeholder='Type your announcement message
                          Ex:"Contact sales team"'
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors2.message ? true : false}
                            error={
                              errors2.message ? errors2.message.message : ""
                            }
                          />
                        </HStack>
                        <HStack
                          alignItems={"center"}
                          width={aw(460)}
                          justifyContent="flex-start"
                        >
                          <LeadsWatchTextArea
                            required={true}
                            // width={aw(200)}
                            height={ah(100)}
                            style={{ width: aw(400) }}
                            control={control2}
                            name="numbers"
                            placeholder="Enter Numbers seprated by comma"
                            rules={{
                              required: {
                                value: true,
                              },
                            }}
                            invalid={errors2.numbers ? true : false}
                            error={
                              errors2.numbers ? errors2.numbers.message : ""
                            }
                          />
                        </HStack>
                        <HStack
                          alignItems={"center"}
                          width={aw(150)}
                          justifyContent="flex-start"
                        >
                          <Pressable
                            onPress={handleSubmit2(handleAddivrnumbers)}
                          >
                            <LeadsWatchTickIcon color="#32CD32" />
                          </Pressable>
                          <Pressable onPress={handleCancelivr}>
                            <LeadsWatchCrossFillIcon />
                          </Pressable>
                        </HStack>
                      </HStack>
                    )}
                    <FlatList
                      borderColor="#D7D7D7"
                      borderBottomRightRadius={aw(10)}
                      borderBottomLeftRadius={aw(10)}
                      borderWidth={1}
                      maxHeight={ah(300)}
                      ListEmptyComponent={
                        <HStack
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(290)}
                        >
                          <Text
                            color="black"
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            No data found
                          </Text>
                        </HStack>
                      }
                      data={ivrnumbers}
                      keyExtractor={(item, index) => index.toString()}
                      renderItem={({ item, index }) => (
                        <VStack key={item.id} width="100%">
                          {editivrId !== item.id ? (
                            <HStack
                              width="100%"
                              height={ah(60)}
                              alignItems={"center"}
                              bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                              style={{
                                borderBottomWidth:
                                  index === ivrnumbers.length - 1
                                    ? "0px"
                                    : "1px",
                                // borderBottomWidth: "1px",
                                borderColor: "#D7D7D7",
                              }}
                            >
                              <HStack
                                alignItems={"center"}
                                width={aw(170)}
                                justifyContent="flex-start"
                              >
                                <Text
                                  color="#121233"
                                  width={aw(150)}
                                  ml={aw(20)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoRegular"}
                                >
                                  {item.digit}
                                </Text>
                              </HStack>
                              <HStack
                                alignItems={"center"}
                                width={aw(460)}
                                justifyContent="flex-start"
                              >
                                <Text
                                  color="#121233"
                                  width={aw(450)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoRegular"}
                                >
                                  {item.message}
                                </Text>
                              </HStack>
                              <HStack
                                alignItems={"center"}
                                width={aw(460)}
                                justifyContent="flex-start"
                              >
                                <Text
                                  color="#121233"
                                  width={aw(450)}
                                  // ml={aw(20)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoRegular"}
                                >
                                  {item.numbers &&
                                    item.numbers.map((val, index) =>
                                      index !== item.numbers.length - 1
                                        ? val + ","
                                        : val
                                    )}
                                </Text>
                              </HStack>
                              <HStack width={aw(150)}>
                                <Pressable onPress={() => handleEditivr(item)}>
                                  <LeadsWatchEditIcon />
                                </Pressable>
                                <Pressable
                                  onPress={() => handleDeleteivr(item.id)}
                                >
                                  <LeadsWatchDeleteIcon />
                                </Pressable>
                              </HStack>
                            </HStack>
                          ) : (
                            <HStack
                              // alignItems={"center"}
                              style={{
                                height: ah(140),
                                // background: "#F9FAFB 0% 0% no-repeat padding-box",
                                // border: "1px solid #D7D7D7",
                                borderColor: "#D7D7D7",

                                borderBottomWidth:
                                  index === ivrnumbers.length - 1
                                    ? "0px"
                                    : "1px",
                              }}
                            >
                              <HStack
                                alignItems={"center"}
                                width={aw(170)}
                                // ml={aw(15)}
                                justifyContent="flex-start"
                              >
                                <LeadsWatchAsyncSelect
                                  ml={aw(20)}
                                  required={true}
                                  height={40}
                                  minWidth={aw(100)}
                                  // minWidth={aw(583)}
                                  defaultOptions={defaultDigitValues}
                                  control={control2}
                                  name="digit"
                                  placeholder="Select Digit"
                                  rules={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  invalid={errors2.digit ? true : false}
                                  error={
                                    errors2.digit ? errors2.digit.message : ""
                                  }
                                />
                              </HStack>
                              <HStack
                                alignItems={"center"}
                                width={aw(460)}
                                justifyContent="flex-start"
                                // ml={aw(20)}
                              >
                                <LeadsWatchTextArea
                                  // width={aw(200)}
                                  required={true}
                                  height={ah(100)}
                                  style={{ width: aw(400) }}
                                  control={control2}
                                  name="message"
                                  placeholder='Type your announcement message
                          Ex:"Contact sales team"'
                                  rules={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  invalid={errors2.message ? true : false}
                                  error={
                                    errors2.message
                                      ? errors2.message.message
                                      : ""
                                  }
                                />
                              </HStack>
                              <HStack
                                alignItems={"center"}
                                width={aw(460)}
                                justifyContent="flex-start"
                              >
                                <LeadsWatchTextArea
                                  required={true}
                                  // width={aw(200)}
                                  height={ah(100)}
                                  style={{ width: aw(400) }}
                                  control={control2}
                                  name="numbers"
                                  placeholder="Enter Numbers seprated by comma"
                                  rules={{
                                    required: {
                                      value: true,
                                    },
                                  }}
                                  invalid={errors2.numbers ? true : false}
                                  error={
                                    errors2.numbers
                                      ? errors2.numbers.message
                                      : ""
                                  }
                                />
                              </HStack>
                              <HStack
                                alignItems={"center"}
                                width={aw(150)}
                                justifyContent="flex-start"
                              >
                                <Pressable
                                  onPress={handleSubmit2(handleAddivrnumbers)}
                                >
                                  <LeadsWatchTickIcon color="#32CD32" />
                                </Pressable>
                                <Pressable onPress={handleCancelivr}>
                                  <LeadsWatchCrossFillIcon />
                                </Pressable>
                              </HStack>
                            </HStack>
                          )}
                        </VStack>
                      )}
                    />
                  </Flex>
                </Flex>
              </Flex>

              <Flex marginTop={ah(10)} gap={ah(15)}>
                <Text
                  style={{
                    fontFamily: "RobotoMedium",
                    fontSize: aw(18),
                    color: "#121233",
                  }}
                >
                  Default Keys configuration
                </Text>

                <Flex direction="row" justifyContent={"space-between"}>
                  <Flex width="31.5%">
                    <LeadsWatchTextArea
                      screen={"edit"}
                      control={control}
                      name="welcome_message"
                      placeholder="Type welcome message"
                      height={ah(100)}
                    />
                  </Flex>
                  <Flex width="31.5%">
                    <LeadsWatchTextArea
                      screen={"edit"}
                      control={control}
                      name="wrong_input_message"
                      placeholder="Type wrong Input Message"
                      height={ah(100)}
                    />
                  </Flex>
                  <Flex width="31.5%">
                    <LeadsWatchTextArea
                      screen={"edit"}
                      control={control}
                      name="no_input_message"
                      placeholder="Type No Input message"
                      height={ah(100)}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </VStack>
          </LeadsWatchCard>
        </Box>
      )}
    </>
  );
};

export default React.memo(IVRCallConfiguration);
