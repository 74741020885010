import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchMuteOffIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={aw(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-282 -612)">
        <Path
          data-name="Rectangle 391"
          transform="translate(282 612)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10347"
          d="M.107-18.8h-.214a3.781 3.781 0 00-2.814 1.192 3.965 3.965 0 00-1.16 2.868v4.189a3.965 3.965 0 001.16 2.868A3.781 3.781 0 00-.107-6.488h.214a3.781 3.781 0 002.815-1.193 3.965 3.965 0 001.16-2.868v-4.189a3.965 3.965 0 00-1.16-2.868A3.781 3.781 0 00.107-18.8zm-.215 1.4h.215a2.494 2.494 0 011.858.784 2.6 2.6 0 01.763 1.88v4.189a2.627 2.627 0 01-.763 1.9 2.494 2.494 0 01-1.858.784h-.214a2.494 2.494 0 01-1.858-.784 2.627 2.627 0 01-.763-1.9v-4.189a2.6 2.6 0 01.763-1.88A2.494 2.494 0 01-.107-17.4zm6.768 6.164a.626.626 0 01.473.2.679.679 0 01.193.483 7.393 7.393 0 01-.516 2.771A7.628 7.628 0 015.4-5.468a7.158 7.158 0 01-2.113 1.622 6.793 6.793 0 01-2.621.773v1.934a.679.679 0 01-.193.483A.626.626 0 010-.451a.658.658 0 01-.451-.172.625.625 0 01-.215-.43v-2.02a6.793 6.793 0 01-2.621-.773A7.158 7.158 0 01-5.4-5.468a7.628 7.628 0 01-1.407-2.31 7.393 7.393 0 01-.516-2.771.679.679 0 01.193-.483.626.626 0 01.473-.2.626.626 0 01.473.2.679.679 0 01.193.483 6.057 6.057 0 00.473 2.385A6.146 6.146 0 00-4.232-6.22 6.3 6.3 0 00-2.32-4.9 5.7 5.7 0 000-4.426 5.7 5.7 0 002.32-4.9a6.3 6.3 0 001.912-1.32 6.146 6.146 0 001.289-1.944 6.057 6.057 0 00.473-2.385.679.679 0 01.193-.483.626.626 0 01.473-.204z"
          transform="translate(294 634)"
          fill="#212121"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchMuteOffIcon;
