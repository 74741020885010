import * as events from "../Events";
const initialState = {
  allInvites: null,
  newTeamMember: null,
  updatedMember: null,
  teamMembers: null,
};
export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_TEAM_INVITES:
      return {
        ...state,
        allInvites: action.data,
      };
    case events.ADD_TEAMMEMBER:
      return {
        ...state,
        newTeamMember: action.data,
      };
    case events.UPDATE_TEAMINVITES_PERMISSIONS:
      return {
        ...state,
        updatedMember: action.data,
      };
    case events.GET_TEAM_MEMBERS:
      return {
        ...state,
        teamMembers: action.data,
      };
    case events.DELETE_TEAM_INVITE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
