import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchNavIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Nav-icon" transform="translate(-268 -407)">
      <Rect
        id="Rectangle_287"
        data-name="Rectangle 287"
        width={aw(24)}
        height={ah(24)}
        transform="translate(268 407)"
        fill="none"
      />
      <Path
        id="Fill_918"
        data-name="Fill 918"
        d="M27.465,17.432H9.037a1.455,1.455,0,1,1,0-2.906H27.465a1.455,1.455,0,1,1,0,2.906Zm-6.144-7.265H9.037A1.5,1.5,0,0,1,7.5,8.715,1.5,1.5,0,0,1,9.037,7.263H21.321a1.5,1.5,0,0,1,1.537,1.452A1.5,1.5,0,0,1,21.321,10.167Zm6.144-7.261H9.037A1.5,1.5,0,0,1,7.5,1.454,1.5,1.5,0,0,1,9.037,0H27.465A1.5,1.5,0,0,1,29,1.454,1.5,1.5,0,0,1,27.465,2.906Z"
        transform="translate(262 410.283)"
        fill="#0d2238"
      />
    </G>
  </Svg>
);
export default LeadsWatchNavIcon;
