import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllCalls = (page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/calls?limit=10&page=1`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allCalls = resData.data;
        dispatch({ type: events.GET_ALL_CALLS, data: allCalls });
        return allCalls ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getActiveCalls = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/calls/live`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const activeCalls = resData.data;
        dispatch({ type: events.GET_ACTIVE_CALLS, data: activeCalls });
        return activeCalls ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getAvailbleNum = (data, page) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/numbers/search?limit=10&page=${page}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allNumbers = resData.data;
        dispatch({ type: events.SEARCH_NUMBERS, data: allNumbers });
        return allNumbers ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const buyNumber = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/numbers/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const number = resData.data;
        dispatch({ type: events.BUY_NUMBER, data: number });
        return number ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const fetchAllNumbers = (data, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/numbers?limit=${limit}&page=${page}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allNumber = resData.data;
        dispatch({ type: events.GET_ALL_NUMBERS, data: allNumber });
        return allNumber ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getUnAllocatedNumbers = (page, limit, data, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/numbers/unallocated?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({search: search}),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const unAllocatedNum = resData.data;
        dispatch({ type: events.GET_UNALLOCATED_NUM, data: unAllocatedNum });
        return unAllocatedNum ? unAllocatedNum : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const removeSearchedNumbers = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: events.REMOVE_SEARCHED_NUMBERS });
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteNumber = (data, page) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/numbers/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedNumber = resData.data;
        dispatch({ type: events.DELETE_NUMBERS, data: deletedNumber });
        return deletedNumber ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getPhones = (page, limit, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/leads/contacts?limit=${limit}&page=${page}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ filters: data }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allNumbers = resData.data;
        dispatch({ type: events.GET_PHONE_NUM, data: allNumbers });
        return allNumbers ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getCallLogs = (page, limit, filters) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/calls?limit=${limit}&page=${page}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ filters }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allCallLogs = resData.data;
        dispatch({ type: events.CALL_LOGS, data: allCallLogs });
        return allCallLogs ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const fromNumber = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/select/number`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const fromNumber = resData.data;
        dispatch({ type: events.FROM_NUMBER, data: fromNumber });
        return fromNumber ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const prioritySetting = (number) => {
  console.log(token);
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/leads/priority`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ calluuid: number, number: "19045277752" }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const data = resData.data;
        dispatch({ type: events.PRIORITY, data: data });
        return data ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const sendResponseId = (calluuid, responseId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/leads/called`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ calluuid: calluuid, response_id: responseId }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const data = resData.data;
        dispatch({ type: events.SEND_RESPONSE, data: data });
        return data ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
