import * as events from "../Events";
const token = localStorage.getItem("auth");
const user = localStorage.getItem("userId");

export const getAllLeads = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/leads?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sort: sort,
            filters: filters,
            search: search,
          }),
        }
      );
      // const xhr = new XMLHttpRequest();
      // xhr.open(
      //   "POST",
      //   `${events.API_URL}/leads?page=${page}&limit=${limit}`,
      //   true
      // );

      // // Track progress
      // xhr.onprogress = (event) => {
      //   if (event.lengthComputable) {
      //     const percentComplete = (event.loaded / event.total) * 100;
      //     // setLoadingProgress(Math.round(percentComplete));
      //     console.log("percentComplete", percentComplete);
      //   }
      // };

      // xhr.onreadystatechange = () => {
      //   if (xhr.readyState === 4 && xhr.status === 200) {
      //     // Request is complete, handle your data here
      //     const responseData = JSON.parse(xhr.responseText);
      //     console.log(responseData);
      //   }
      // };

      // xhr.send();

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allLeads = resData.data;
        dispatch({ type: events.GET_ALL_LEADS, data: allLeads });
        return allLeads ? allLeads : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getSingleLead = (id, rId, type) => {
  const url =
    type === "lead"
      ? `${events.API_URL}/leads/${id}/${rId}`
      : `${events.API_URL}/leads/${id}`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const singleLead = resData.data;
        dispatch({ type: events.GET_SINGLE_LEAD, data: singleLead });
        return singleLead ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const handleDownload = (data) => {
  const url =
    (data.response_ids && data.response_ids.length) ||
    (data.leadcall_ids && data.leadcall_ids.length)
      ? `${events.API_URL}/leads/export-multiple-leads`
      : `${events.API_URL}/leads/export-leads`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        // let resData = [];
        const resData = await response.blob();

        const exportedLeads = window.URL.createObjectURL(
          new Blob([resData], {
            type: "text/csv",
          })
        );
        let a = document.createElement("a");
        a.href = exportedLeads;
        a.download = "leads.csv";
        a.click();

        dispatch({ type: events.EXPORT_LEADS });
        return exportedLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const handleSingleDownload = (lid, lr_id, type) => {
  const url =
    type === "lead"
      ? `${events.API_URL}/leads/${lid}/export-lead/${lr_id}`
      : `${events.API_URL}/leads/${lid}/export-lead`;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        // let resData = [];
        const resData = await response.blob();

        const exportedLeads = window.URL.createObjectURL(
          new Blob([resData], { type: "text/csv" })
        );
        let a = document.createElement("a");
        a.href = exportedLeads;
        a.download = "leads.csv";
        a.click();

        dispatch({ type: events.SINGLE_EXPORT });
        return exportedLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const sendBuyers = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/leads/resend-leads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const leadsSend = resData.data;
        dispatch({ type: events.SEND_BUYERS, data: leadsSend });
        return leadsSend ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getFormFields = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/leads/form-fields`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const formFields = resData.data;
        dispatch({ type: events.GET_FORM_FIELDS, data: formFields });
        return formFields ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const createLead = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        "https://pushtest.leadswatch.com/v1/leads/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ...data, user_id: user }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = "lead created successfully";

        const newLead = resData;
        dispatch({ type: events.CREATE_LEADS, data: newLead });
        return newLead ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const createMultipleLeads = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/leads/import-leads`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = "lead created successfully";

        const newLeads = resData;
        dispatch({ type: events.CREATE_MULTIPLE_LEADS, data: newLeads });
        return newLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getVerticalCampaigns = (id, page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${
          events.API_URL
        }/verticals/campaigns/${id}?page=${page}&limit=${limit}&search=${
          search ? search : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const verticalCampaigns = resData.data;
        dispatch({
          type: events.GET_CAMPAIGNS_BY_VERTICALS,
          data: verticalCampaigns,
        });
        return verticalCampaigns ? verticalCampaigns : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getCampaignPub = (id, page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${
          events.API_URL
        }/campaigns/publishers/${id}?page=${page}&limit=${limit}&search=${
          search ? search : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const campaignPub = resData.data;
        dispatch({
          type: events.GET_PUB_BY_CAMPAIGNS,
          data: campaignPub,
        });
        return campaignPub ? campaignPub : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
