import { React, useState, useEffect } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../utils";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import {
  getAllVerticals,
  addVertical,
  deleteVertical,
} from "../../store/actions/verticals";

import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";

const Verticals = ({ isOpen }) => {
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [sort, setSort] = useState({});
  const [refresh, setRefresh] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const [currentPage, setCurrentPage] = useState(
    page && page.verticals ? parseInt(page.verticals) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.verticals ? parseInt(pagecount.verticals) : 10
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const dispatch = useDispatch();
  const allVerticals = useSelector((state) => state.verticals.allVerticals);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.verticals
          ? pagecount.verticals.toString()
          : "10",
      verticalName: "",
      description: "",
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    // formState: { errors: errors1 },
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues1("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, verticals: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, verticals: 1 }));
  };
  const fetchVerticals = async () => {
    setIsLoading(true);
    try {
      await dispatch(getAllVerticals(currentPage, selectCount, sort, search));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["vertical"] &&
        profile.permission_ids["vertical"].includes("view")
      ) {
        fetchVerticals();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchVerticals();
    }
  }, [currentPage, selectCount, sort, search]);

  const handleCreateVertical = async (data) => {
    setSaving(true);
    try {
      await dispatch(addVertical(data.verticalName, data.description));
      setCurrentPage(1);
      localStorage.setItem("page", JSON.stringify({ ...page, verticals: 1 }));
      await dispatch(getAllVerticals(currentPage, selectCount));
      reset({
        select: "10",
        verticalName: "",
        description: "",
      });
      setRefresh(!refresh);
      // trigger();

      setIsModalOpen(false);
      toast.success("Vertical Added Successsfully");
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setSaving(false);
  };
  const handleSort = (order) => {
    setSort(order);
  };
  const handleDeleteVertical = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteVertical(id));
        setDeleteId(null);
        setConfirmModal(false);
        fetchVerticals();

        toast.success("Vertical Deleted Successsfully");
      } catch (err) {
        setConfirmModal(false);
        setDeleteId(null);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, verticals: 1 }));
    }
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Verticals
                </Text>
                <Text
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {allVerticals && allVerticals.total_count
                    ? allVerticals.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(10)}>
                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Description"',
                    ]}
                    height={ah(36)}
                    control={control1}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset1();
                            setSearch(getValues1("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, verticals: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit1(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit1(
                          handleSearch({ search: getValues1("search") })
                        );
                      }
                    }}
                  />
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["vertical"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    label="Add New"
                    style={{
                      width: aw(114),
                      height: ah(36),
                      // borderRadius: aw(6),
                    }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    // fontSize={aw(15)}
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                  />
                </HStack>
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Verticals list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} marginTop={ah(10)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              {/* 
              <HStack space={aw(12)}>
                <LeadsWatchFilterInput
                  hasVscreenalue={
                    getValues("BuyersId") && getValues("BuyersId").length > 0
                      ? "edit"
                      : "create"
                  }
                  autoFocus={false}
                  // style={{ width: aw(190), height: ah(36) }}
                  width={aw(190)}
                  height={ah(36)}
                  name="BuyersId"
                  placeholder="Buyer ID(s)"
                  control={control}
                />
              </HStack> */}
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>
              <LeadsWatchTable
                isOpen={isOpen}
                sort={sort}
                handleDelete={handleDeleteVertical}
                // handleDelete={handleDeleteVertical}
                handleSort={handleSort}
                keys={["name", "desc", "Actions"]}
                heading={["name", "desc", "Actions"]}
                sortIcon={true}
                data={
                  allVerticals && allVerticals.list ? allVerticals.list : []
                }
                // data={allVerticals}
                isLoading={isLoading}
                screen="vertical"
                currentPage={currentPage}
                selectCount={selectCount}
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  allVerticals && allVerticals.total_count
                    ? allVerticals.total_count
                    : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, verticals: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(370)}
        height={ah(380)}
        showModal={isModalOpen}
        onClose={() => {
          reset({
            select: "10",
            verticalName: "",
            description: "",
          });
          setRefresh(!refresh);
          setIsModalOpen(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Add Vertical
          </Text>
        }
      >
        <VStack alignItems="center">
          <LeadsWatchFilterInput
            screen={
              getValues("verticalName") && getValues("verticalName").length > 0
                ? "edit"
                : "create"
            }
            mt={ah(29)}
            refresh={refresh}
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            height={ah(40)}
            name="verticalName"
            placeholder="Vertical Name"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.verticalName ? true : false}
            error={errors.verticalName ? errors.verticalName.message : ""}
          />
          <HStack mt={aw(15)}>
            <LeadsWatchTextArea
              refresh={refresh}
              screen={"create"}
              style={{ width: aw(330), heigth: ah(120) }}
              control={control}
              name="description"
              placeholder="Description"
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.description ? true : false}
              error={errors.description ? errors.description.message : ""}
            />
          </HStack>
          <HStack mt={ah(45)} space={ah(20)}>
            <LeadsWatchButton
              label="cancel"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              borderColor="#D1D5DB"
              fontSize={aw(14)}
              onPress={() => {
                setIsModalOpen(false);
                setRefresh(!refresh);
                reset({
                  select: "10",
                  verticalName: "",
                  description: "",
                });
              }}
            />
            <LeadsWatchButton
              onPress={handleSubmit(handleCreateVertical)}
              isLoading={saving}
              label="Add Vertical"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              fontSize={aw(14)}
              color="#FFFFFF"
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this vertical
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDeleteVertical(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default Verticals;
