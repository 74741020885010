import * as React from "react";
import { aw, ah } from "../../utils";
const LeadsWatchSendIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="Sendto_icon" transform="translate(-26 -36)">
      <rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <path
        id="Path_10312"
        data-name="Path 10312"
        d="M11.742-13.781a.715.715,0,0,0-.527.211.774.774,0,0,0-.246.563.739.739,0,0,0,.246.527L14.7-9H7.488a6.535,6.535,0,0,0-2.6.527A6.285,6.285,0,0,0,2.742-7.031,6.772,6.772,0,0,0,1.3-4.887,6.424,6.424,0,0,0,.738-2.25v.738a.666.666,0,0,0,.246.527.666.666,0,0,0,.527.246.739.739,0,0,0,.527-.246.715.715,0,0,0,.211-.527V-2.25a5.126,5.126,0,0,1,.422-2.039A5.32,5.32,0,0,1,3.8-5.977,5.431,5.431,0,0,1,5.449-7.1a5.529,5.529,0,0,1,2.039-.387H14.7l-3.48,3.48a.666.666,0,0,0-.246.527.666.666,0,0,0,.246.527.666.666,0,0,0,.527.246.666.666,0,0,0,.527-.246l4.781-4.781a.715.715,0,0,0,.211-.527.715.715,0,0,0-.211-.527L12.27-13.57A.715.715,0,0,0,11.742-13.781Z"
        transform="translate(29 55)"
        fill="#212121"
      />
    </g>
  </svg>
);
export default LeadsWatchSendIcon;
