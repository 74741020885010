import React, { useState,  useEffect } from "react";
import {
  VStack,
  HStack,
  Text,  
  Pressable,
  Divider,
  
} from "native-base";
import { Animated, Easing } from "react-native";

import LeadsWatchMuteOffIcon from "./svg/LeadsWatchCallForwardIcon";
import LeadsWatchCallRecieveIcon from "./svg/LeadsWatchCallRecieveIcon";
import LeadsWatchCallCancelIcon from "./svg/LeadsWatchCallCancelIcon";
import LeadsWatchProfileIcon from "./svg/LeadsWatchProfileIcon";
import { ah, aw } from "../utils";
import LeadsWatchCallForwardIcon from "./svg/LeadsWatchCallForwardIcon";
import LeadsWatchCallPauseIcon from "./svg/LeadsWatchCallPauseIcon";
import LeadsWatchRecordIcon from "./svg/LeadsWatchRecordIcon";
import LeadsWatchAddCallIcon from "./svg/LeadsWatchAddCallIcon";
import LeadsWatchAddPersonIcon from "./svg/LeadsWatchAddPersonIcon";
import LeadsWatchKeyPadIcon from "./svg/LeadsWatchKeyPadIcon";
import LeadsWatchMoreIcon from "./svg/LeadsWatchMoreIcon";
import LeadsWatchButton from "./LeadsWatchButton";


const LeadsWatchAnswerModal = ({
  setIsOpen,
  isOpen,
  handleAnswer,
  callInfo,
  handleHangup,
}) => {
  const [isAnswerd, setIsAnswerd] = useState(false);
  const [spinValue] = useState(new Animated.Value(0));
  // const leftSpin = () => {
  //   Animated.timing(spinValue, {
  //     toValue: 1,
  //     duration: 100,
  //     easing: Easing.linear,
  //     useNativeDriver: true,
  //   }).start(() => {
  //     leftSpin();
  //   });
  // };

  function leftSpin() {
    spinValue.setValue(0);
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 300,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      leftSpin();
    });
  }
  useEffect(() => {
    leftSpin();
  }, []);
  const spin = spinValue.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: ["0deg", "20deg", "0deg"],
  });
  return (
    isOpen &&
    (!isAnswerd ? (
      <>
        <VStack
          borderRadius={aw(5)}
          width={aw(250)}
          height={ah(350)}
          alignItems={"center"}
          justifyContent={"center"}
          size={aw(50)}
          position="absolute"
          bottom={ah(20)}
          right={aw(30)}
          background={"white"}
          borderColor="#D7D7D7"
          borderWidth={aw(1)}
          style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
        >
          {/* <Avatar height={aw(70)} width={aw(70)} />
           */}
          <HStack
            style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
            borderRadius={"full"}
            // width={aw(80)}
            // height={ah(90)}
            // alignItems={"center"}
            // height={ah(70)}
            width={aw(75)}
            height={ah(70)}
            alignItems={"center"}

            // backgroundColor={"amber.500"}
          >
            <LeadsWatchProfileIcon />
          </HStack>
          <Text
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(18)}
          ></Text>
          <Text
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(14)}
            mt={ah(10)}
          >
            {callInfo && callInfo.src}
            {/* 9912519412 */}
          </Text>
          {/* <Text
          color={"#212121"}
          fontFamily={"RobotoMedium"}
          fontSize={aw(10)}
        >
          Lydia Phillips
        </Text> */}
          <Divider my={ah(20)} bg="#E6E7E9" height={ah(1)} />
          <>
            <VStack justifyContent={"center"} alignItems={"center"}>
              <HStack
                alignItems={"center"}
                justifyContent={"center"}
                space={aw(20)}
              >
                <Animated.View style={{ transform: [{ rotate: spin }] }}>
                  <Pressable
                    onPress={handleAnswer}
                    my={ah(20)}
                    size={ah(60)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{
                      background: "green",
                    }}
                  >
                    <LeadsWatchCallRecieveIcon color="#FFFFFF" />
                  </Pressable>
                </Animated.View>
                <Animated.View style={{ transform: [{ rotate: spin }] }}>
                  <Pressable
                    onPress={handleHangup}
                    my={ah(20)}
                    size={ah(60)}
                    borderColor="#EFF2F5"
                    borderWidth={aw(1)}
                    borderRadius={"full"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    style={{
                      background: "red",
                    }}
                    transform={[{ rotate: "150deg" }]}
                  >
                    <LeadsWatchCallCancelIcon color="#FFFFFF" />
                  </Pressable>
                </Animated.View>
              </HStack>
            </VStack>
          </>
        </VStack>
      </>
    ) : (
      <>
        <VStack
          borderRadius={aw(5)}
          // width={aw(250)}
          // height={ah(350)}
          alignItems={"center"}
          justifyContent={"center"}
          size={aw(50)}
          position="absolute"
          bottom={ah(20)}
          right={aw(30)}
          background={"white"}
          borderColor="#D7D7D7"
          borderWidth={aw(1)}
          pt={ah(10)}
          px={aw(10)}
          style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
        >
          <HStack
            style={{ shadowColor: "#00000033", shadowRadius: aw(12) }}
            borderRadius={"full"}
            // width={aw(80)}
            // height={ah(90)}
            // alignItems={"center"}
            // height={ah(70)}
            // width={aw(75)}
            // height={ah(70)}
            alignItems={"center"}

            // backgroundColor={"amber.500"}
          >
            <LeadsWatchProfileIcon />
          </HStack>
          <Text
            pt={ah(10)}
            color={"#212121"}
            fontFamily={"RobotoMedium"}
            fontSize={aw(18)}
          ></Text>
          <Text color={"#212121"} fontFamily={"RobotoMedium"} fontSize={aw(12)}>
            (862) 633-0498
          </Text>
          {/* <Text
        color={"#212121"}
        fontFamily={"RobotoMedium"}
        fontSize={aw(10)}
      >
        Lydia Phillips
      </Text> */}
          <Divider my={ah(20)} bg="#E6E7E9" height={ah(1)} />
          <VStack space={ah(20)}>
            <HStack space={aw(30)}>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchMuteOffIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchCallPauseIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchCallForwardIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchRecordIcon />
              </Pressable>
            </HStack>
            <HStack space={aw(30)}>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchAddCallIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchAddPersonIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchKeyPadIcon />
              </Pressable>
              <Pressable
                size={aw(40)}
                borderColor="#EFF2F5"
                borderWidth={aw(1)}
                borderRadius={"full"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LeadsWatchMoreIcon />
              </Pressable>
            </HStack>
          </VStack>
          <HStack my={ah(50)}>
            <LeadsWatchButton
              label="01:00- End Session"
              style={{
                width: aw(170),
                height: ah(40),
                borderRadius: "6px",
              }}
              color="#FFFFFF"
              fontSize={aw(14)}
              fontFamily="RobotoRegular"
              bg={"#D64547"}
              fontWeight="400"
            />
          </HStack>
        </VStack>
      </>
    ))
  );
};

export default LeadsWatchAnswerModal;
