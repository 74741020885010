import React, { useState, useEffect, useRef } from "react";
import { Input, FormControl } from "native-base";
import { Controller } from "react-hook-form";
import { Animated, Easing } from "react-native";
// import LeadsWatchSearchIcon from "../svg/LeadsWatchSearchIcon";

import { aw, ah } from "../utils";

const LeadsWatchSearch = React.forwardRef((props, ref) => {
  const [focus, setFocus] = useState(false);
  const animatedWidth = useRef(new Animated.Value(500)).current;
  const placeholderIndexRef = useRef(null);
  const placeholders = props.placeholder
    ? props.placeholder
    : ["Search by ID", "Search by Name", "Search by Email"];
  useEffect(() => {
    if (focus) {
      Animated.timing(animatedWidth, {
        toValue: 500,
        duration: 350,
        useNativeDriver: true,
        easing: Easing.bezier(0.2, 0.5, 0.8, 1),
      }).start();
    } else {
      Animated.timing(animatedWidth, {
        toValue: 500,
        duration: 350,
        useNativeDriver: true,
        easing: Easing.bezier(0.2, 0.5, 0.8, 1),
      }).start();
    }
  }, [focus]);
  useEffect(() => {
    // Set interval to change the placeholder every 2000 milliseconds (2 seconds)
    const interval = setInterval(() => {
      // placeholderIndexRef.current =
      //   (placeholderIndexRef.current + 1) % placeholders.length;
      const index =
        placeholderIndexRef.current.placeholder &&
        placeholders.indexOf(placeholderIndexRef.current.placeholder) <
          placeholders.length - 1
          ? placeholders.indexOf(placeholderIndexRef.current.placeholder) + 1
          : 0;

      placeholderIndexRef.current.placeholder = placeholders[index];
    }, 2000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures that the effect runs only once on mount
  return (
    // <Animated.View
    //   style={{
    //     shadowColor: "#D7D7D7",
    //     shadowRadius: ah(5),
    //     width: animatedWidth,
    //     borderRadius: aw(5),
    //     // height: props.height,
    //   }}
    // >
    <FormControl
      width={aw(300)}
      style={{
        shadowColor: "#D7D7D7",
        shadowRadius: ah(5),
        borderRadius: aw(5),
      }}
    >
      <Controller
        control={props.control}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            // ref={placeholderIndexRef}
            ref={placeholderIndexRef}
            width={aw(300)}
            onKeyPress={props.onKeyPress}
            color="black"
            // leftElement={<LeadsWatchSearchIcon />}
            onBlur={() => {
              setFocus(!focus);
              onBlur();
            }}
            onFocus={() => {
              setFocus(true);
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            placeholder={props.placeholder?.[0]}
            borderWidth={aw(1)}
            _focus={{
              backgroundColor: "white",
              borderColor: "#0000FF",
              borderWidth: aw(1),
            }}
            _hover={{
              borderColor: "#D1D5DB",
            }}
            focusOutlineColor={"#0000FF"}
            borderRadius={aw(5)}
            borderColor={"#D7D7D7"}
            style={{
              fontSize: aw(14),
              fontFamily: "RobotoRegular",
              backgroundColor: "white",
              zIndex: 1,
            }}
            value={value || ""}
            onChangeText={(value) => {
              onChange(value);
              if (props.onChangeText) {
                props.onChangeText();
              }
            }}
            h={props.height}
            w={animatedWidth}
            InputRightElement={props.inputRightElement}
          />
        )}
      />
    </FormControl>
    // </Animated.View>
  );
});

export default LeadsWatchSearch;
