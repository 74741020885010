import { React, useEffect, useState } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Hidden,
  useBreakpointValue,
  Flex,
  Box,
  Menu,
  ScrollView,
} from "native-base";
import { toast } from "react-toastify";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { aw, ah } from "../../utils";
import moment from "moment/moment";

import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";

import {
  getVerticals,
  getCampaigns,
  getBuyers,
  getPublishers,
  download,
} from "../../store/actions/reports";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";

const Campaigns = ({ isOpen }) => {
  const [downloading, setDownloading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const allVerticals = useSelector((state) => state.reports.verticals);
  const allBuyers = useSelector((state) => state.reports.buyers);
  const allPublishers = useSelector((state) => state.reports.publishers);
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [filters1, setFilters1] = useState({});

  const allCampaigns = useSelector((state) => state.reports.campaigns);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(
    page && page.reportCampaigns ? parseInt(page.reportCampaigns) : 1
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.campaignsReports
      ? parseInt(pagecount.campaignsReports)
      : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const {
    control,
    // handleSubmit,
    // formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.campaignsReports
          ? pagecount.campaignsReports.toString()
          : "10",
    },
  });

  const handleChangeSelect = (value) => {
    setSelectCount(value);
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, campaignsReports: value })
    );
    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, reportCampaigns: 1 })
    );
  };
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);

      setFilters({ ...filters, start_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const getData = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getCampaigns(currentPage, selectCount, undefined, filters1)
      );
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [currentPage, selectCount, undefined, filters1]);

  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, reportCampaigns: 1 })
      );
    }
  }, [filters.start_date, filters.end_date]);
  const fetchAllBuyers = async () => {
    try {
      await dispatch(getBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllPublishers = async () => {
    try {
      await dispatch(getPublishers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllVerticals = async () => {
    try {
      await dispatch(getVerticals(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   fetchAllVerticals();
  //   fetchAllBuyers();
  //   fetchAllPublishers();
  // }, []);
  const loadOptions = async (inputValue, callback, list) => {
    try {
      const data =
        list === "vertical"
          ? await dispatch(getVerticals(1, 10, undefined, inputValue))
          : list === "buyers"
          ? await dispatch(getBuyers(1, 10, undefined, undefined, inputValue))
          : await dispatch(
              getPublishers(1, 10, undefined, undefined, inputValue)
            );

      callback(
        list === "vertical"
          ? data &&
              data.list.map((val) => ({
                value: val._id,
                label: val.name,
              }))
          : data &&
              data.list.map((val) => ({
                value: val._id,
                label: `${val.firstname} ${val.lastname}`,
              }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const defaultOptions = allVerticals
    ? allVerticals.list.map((val) => ({ value: val._id, label: val.name }))
    : [];

  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForPub = allPublishers
    ? allPublishers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const handleOptionChange = (key, selectedOption) => {
    console.log("Selected Option:", selectedOption);
    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  const handleFilters = () => {
    setFilters1(filters);

    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, reportCampaigns: 1 })
    );
    setOpenFilter(false);
  };
  const handleDownload = async (type) => {
    setDownloading(true);
    try {
      await dispatch(
        download(
          currentPage,
          selectCount,
          { filters: filters1 },
          "campaigns",
          type
        )
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setDownloading(false);
  };
  return (
    <HStack
      height={ah(698)}
      // width={[aw(350), aw(350), aw(1366)]}
      width={
        isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
      }
    >
      <VStack
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <Hidden only={["sm", "base"]}>
          <VStack height={ah(35)} mt={ah(10)} zIndex={1}>
            <HStack
              justifyContent={"space-between"}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>

              <HStack space={aw(12)} width={aw(670)}>
                <LeadsWatchMenu
                  width={aw(120)}
                  mr={aw(5)}
                  title={
                    // <Pressable>
                    <Flex
                      direction="row"
                      borderColor="#D1D5DB"
                      bg="#FAFBFD"
                      borderWidth={aw(1)}
                      width={aw(115)}
                      height={ah(32)}
                      borderRadius={aw(5)}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {downloading ? null : <LeadsWatchDownloadIcon />}
                      <Text
                        fontFamily="RobotoRegular"
                        color="black"
                        fontSize={aw(14)}
                      >
                        {downloading ? "Downloading..." : "Download"}
                      </Text>
                    </Flex>
                    // </Pressable>
                  }
                >
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => handleDownload("csv")}
                  >
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      CSV
                    </Text>
                  </Menu.Item>
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => handleDownload("xlsx")}
                  >
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color="#212121"
                    >
                      XLSX
                    </Text>
                  </Menu.Item>
                </LeadsWatchMenu>
                <HStack>
                  <LeadsWatchCalendar
                    startDate={startDate}
                    handleFromDate={handleFromDate}
                    handleEndDate={handleEndDate}
                    width={aw(141)}
                    height={ah(32)}
                    endDate={endDate}
                  />
                </HStack>
                <HStack space={aw(50)}>
                  <LeadsWatchSelect
                    control={control}
                    name={"status"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters1({ ...filters1, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  />
                </HStack>
                <LeadsWatchButton
                  bg="#FFFFFF"
                  onPress={() => setOpenFilter(!openFilter)}
                  leftIcon={<LeadsWatchFilterIcon />}
                  label={"Filter"}
                  style={{
                    width: aw(115),
                    height: ah(32),
                    borderColor: "#D1D5DB",
                  }}
                  fontWeight="400"
                />
                {openFilter && (
                  <Box
                    px={aw(10)}
                    position="absolute"
                    zIndex={1}
                    top={ah(35)}
                    right={aw(98)}
                    width={
                      profile && profile.role === "publisher"
                        ? aw(300)
                        : aw(570)
                    }
                    minH={
                      profile && profile.role === "publisher"
                        ? ah(100)
                        : ah(180)
                    }
                    py={ah(15)}
                    bgColor="#FFFFFF"
                    borderColor="#E1E1E1"
                    style={{
                      shadowColor: "#00000033",

                      shadowRadius: aw(20),
                    }}
                    borderWidth={aw(1)}
                    borderRadius={aw(6)}
                  >
                    <ScrollView maxH={ah(220)} px={aw(10)} py={ah(15)}>
                      <Flex direction="row" wrap="wrap" gap={ah(20)}>
                        <Flex gap={ah(3)} maxW={"96%"}>
                          <LeadsWatchAsyncSelect
                            isMulti={true}
                            screen={
                              getValues("vertical") &&
                              getValues("vertical").length > 0
                                ? "edit"
                                : "create"
                            }
                            minWidth={aw(250)}
                            type="async"
                            defaultOptions={defaultOptions}
                            loadOptions={(inputValues, callback) =>
                              loadOptions(inputValues, callback, "vertical")
                            }
                            handleChange={(selectedOption) =>
                              handleOptionChange("vertical_id", selectedOption)
                            }
                            onFocus={() => {
                              if (allVerticals) {
                                if (allVerticals.list.length < 10) {
                                  fetchAllVerticals();
                                }
                              } else {
                                fetchAllVerticals();
                              }
                            }}
                            onInputChange={(inputValues) => {
                              if (inputValues === "") {
                                fetchAllVerticals();
                                // return inputValue;
                              }
                            }}
                            placeholder="Verticals"
                            onChange={(selectedOption) =>
                              handleOptionChange("vertical_id", selectedOption)
                            }
                            control={control}
                            name="vertical"
                          />
                        </Flex>
                        {profile && profile.role !== "publisher" && (
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("buyer") &&
                                getValues("buyer").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptionsForBuyers}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "buyer")
                              }
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllBuyers();

                                  // return inputValue;
                                }
                              }}
                              onFocus={() => {
                                if (allBuyers) {
                                  if (allBuyers.list.length < 10) {
                                    fetchAllBuyers();
                                  }
                                } else {
                                  fetchAllBuyers();
                                }
                              }}
                              handleChange={(selectedOption) =>
                                handleOptionChange("buyer_id", selectedOption)
                              }
                              control={control}
                              placeholder="Buyers"
                              name="buyer"
                            />
                          </Flex>
                        )}
                        {profile && profile.role !== "publisher" && (
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("publisher") &&
                                getValues("publisher").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              type="async"
                              defaultOptions={defaultOptionsForPub}
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "publisher")
                              }
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  fetchAllPublishers();
                                  // return inputValue;
                                }
                              }}
                              placeholder="Publishers"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "publisher_id",
                                  selectedOption
                                )
                              }
                              onFocus={() => {
                                if (allPublishers) {
                                  if (allPublishers.list.length < 10) {
                                    fetchAllPublishers();
                                  }
                                } else {
                                  fetchAllPublishers();
                                }
                              }}
                              control={control}
                              name="publisher"
                            />
                          </Flex>
                        )}
                      </Flex>
                      <Flex
                        width={
                          profile && profile.role === "publisher"
                            ? aw(250)
                            : aw(520)
                        }
                        justifyContent={"flex-end"}
                        direction="row"
                        gap={aw(10)}
                        zIndex={-1}
                        mt={ah(10)}
                      >
                        <LeadsWatchButton
                          label="Cancel"
                          onPress={() => {
                            setOpenFilter(false);
                            reset({
                              vertical: "",
                              publisher: "",
                              buyer: "",
                            });
                            if (Object.keys(filters).length > 0) {
                              setSelectedValues({});
                              setFilters({});
                            }
                            if (Object.keys(filters1).length > 0) {
                              setFilters1({});
                            }
                          }}
                          style={{ height: ah(32) }}
                        />
                        <LeadsWatchButton
                          onPress={handleFilters}
                          label="Apply"
                          style={{
                            height: ah(32),
                          }}
                          bg="#2230F8"
                          color="#FFFFFF"
                          borderColor="#05BBC9"
                        />
                      </Flex>
                    </ScrollView>
                  </Box>
                )}
              </HStack>
            </HStack>
          </VStack>
        </Hidden>
        <VStack mt={3}>
          <VStack>
            <LeadsWatchTable
              isOpen={isOpen}
              screen={"reports"}
              subScreen={"campaigns"}
              heading={[
                "_id",
                "name",
                "start_date",
                "end_date",
                "vertical_name",
                "status",
              ]}
              isLoading={isLoading}
              data={allCampaigns && allCampaigns.list && allCampaigns.list}
            />
          </VStack>
          <VStack
            bottom={aw(5)}
            position="fixed"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width="100%"
              totalCount={
                allCampaigns &&
                allCampaigns.total_count &&
                allCampaigns.total_count
              }
              currentPage={currentPage}
              pageSize={selectCount}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage);
                localStorage.setItem(
                  "page",
                  JSON.stringify({ ...page, reportCampaigns: currentPage })
                );
              }}
            />
          </VStack>
        </VStack>
      </VStack>
    </HStack>
  );
};
export default Campaigns;
