import * as events from "../Events";
const initialState = {
  allCampaigns: null,
  verticalBuyers: null,
  newCampaign: null,
  singleCampaign: null,
  editedCampaign: null,
  deletedCampaign: null,
};
export const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_CAMPAIGNS:
      return {
        ...state,
        allCampaigns: action.data,
      };
    case events.GET_BUYERS_BY_VERTICAL:
      return {
        ...state,
        verticalBuyers: action.data,
      };
    case events.CREATE_CAMPAIGN:
      return {
        ...state,
        newCampaign: action.data,
      };
    case events.GET_SINGLE_CAMPAIGN:
      return {
        ...state,
        singleCampaign: action.data,
      };
    case events.EDIT_CAMPAIGN:
      return {
        ...state,
        editedCampaign: action.data,
      };
    case events.DELETE_CAMPAIGN:
      return {
        ...state,
        deletedCampaign: action.data,
      };
    default:
      return state;
  }
};
