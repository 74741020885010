import {
  Box,
  Flex,
  HStack,
  Pressable,
  Text,
  VStack,
  Tooltip,
  Hidden,
} from "native-base";
import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { aw, ah } from "../utils";
import LeadsWatchActiveIcon from "./svg/LeadsWatchActiveIcon";
import LeadsWatchCampaignsIcon from "./svg/LeadsWatchCampaignsIcon";
import LeadsWatchPublishersIcon from "./svg/LeadsWatchPublishersIcon";
import LeadsWatchBuyersIcon from "./svg/LeadsWatchBuyersIcon";
import LeadsWatchVerticalsIcon from "./svg/LeadsWatchVerticalsIcon";
import LeadsWatchOperationsIcon from "./svg/LeadsWatchOperationsIcon";
import LeadsWatchSettingsIcon from "./svg/LeadsWatchSettingsIcon";
// import LeadsWatchSupportIcon from "./svg/LeadsWatchSupportIcon";
// import LeadsWatchLightIcon from "./svg/LeadsWatchLightIcon";
// import LeadsWatchDarkIcon from "./svg/LeadsWatchDarkIcon";
import LeadsWatchReportsIcon from "./svg/LeadsWatchReportsIcon";
import LeadsWatchCallsIcon from "./svg/LeadsWatchCallsIcon";
import LeadsWatchDashboardIcon from "./svg/LeadsWatchDashboardIcon";

const LeadsWatchSideBarTrial = ({ isOpen }) => {
  const profile = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();
  const location = useLocation();
  const permissionAllowed = (name) => {
    return profile.permission_ids &&
      profile.permission_ids[name] &&
      profile.permission_ids[name].includes("view")
      ? false
      : true;
  };
  return (
    <Hidden only={["sm", "base", "md"]}>
      <HStack zIndex={1}>
        {isOpen ? (
          <Box
            width={[aw(0), aw(0), aw(168)]}
            style={[styles.defaultopen, styles.styleopen]}
            borderRightWidth={ah(1)}
            borderRightColor={"#E6E7E9"}
            position="absolute"
          >
            <VStack
              justifyContent={
                profile && profile.role === "publisher"
                  ? "flex-start"
                  : "space-evenly"
              }
              space={profile && profile.role === "publisher" && ah(20)}
              mt={profile && profile.role === "publisher" && ah(10)}
              alignItems={"center"}
              height={ah(450)}
            >
              <Pressable
                onPress={() => navigate("/")}
                isDisabled={
                  profile && profile.role === "user"
                    ? permissionAllowed("stats")
                    : false
                }
              >
                {({ isFocused, isHovered }) => {
                  return (
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role === "user"
                          ? !permissionAllowed("stats")
                          : true
                      }
                    >
                      <Flex alignItems={"center"}>
                        <HStack
                          pl={aw(10)}
                          alignItems={"center"}
                          style={
                            isHovered || location.pathname === "/"
                              ? styles.styleStack
                              : styles.styleStackOff
                          }
                          space={aw(10)}
                        >
                          <HStack>
                            <LeadsWatchDashboardIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("stats")
                                  : false
                              }
                              hover={
                                isHovered || location.pathname === "/"
                                  ? "true"
                                  : "false"
                              }
                            />
                          </HStack>
                          <HStack>
                            <Text
                              fontFamily={"RobotoMedium"}
                              color="#121233"
                              style={
                                profile && profile.role === "user"
                                  ? !permissionAllowed("stats")
                                    ? isHovered || location.pathname === "/"
                                      ? styles.defaultText
                                      : styles.defaultTextoff
                                    : styles.disabled
                                  : isHovered || location.pathname === "/"
                                  ? styles.defaultText
                                  : styles.defaultTextoff
                              }
                              // style={
                              //   isHovered || location.pathname === "/"
                              //     ? styles.defaultText
                              //     : styles.defaultTextoff
                              // }
                            >
                              Dashboard
                            </Text>
                          </HStack>
                        </HStack>
                      </Flex>
                    </Tooltip>
                  );
                }}
              </Pressable>
              <Pressable
                onPress={() => navigate("/leads/lead")}
                isDisabled={
                  profile && profile.role === "user"
                    ? permissionAllowed("lead")
                    : false
                }
              >
                {({ isFocused, isHovered }) => {
                  return (
                    <Tooltip
                      label={"No Access"}
                      isDisabled={
                        profile && profile.role === "user"
                          ? !permissionAllowed("lead")
                          : true
                      }
                    >
                      <Flex alignItems={"center"}>
                        <HStack
                          alignItems={"center"}
                          pl={aw(10)}
                          style={
                            isHovered ||
                            (location.pathname.includes("leads") &&
                              !location.pathname.includes("reports")) ||
                            location.pathname.includes("leads/campaign")
                              ? styles.styleStack
                              : styles.styleStackOff
                          }
                          space={aw(10)}
                        >
                          <HStack>
                            <LeadsWatchActiveIcon
                              hover={
                                isHovered ||
                                (location.pathname.includes("leads") &&
                                  !location.pathname.includes("reports")) ||
                                location.pathname.includes("leads/campaign")
                                  ? "true"
                                  : "false"
                              }
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("lead")
                                  : false
                              }
                            />
                          </HStack>
                          <HStack>
                            <Text
                              isDisabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("lead")
                                  : false
                              }
                              fontFamily={"RobotoMedium"}
                              color="#121233"
                              style={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["lead"].includes(
                                      "view"
                                    )
                                    ? isHovered ||
                                      (location.pathname.includes("leads") &&
                                        !location.pathname.includes(
                                          "reports"
                                        )) ||
                                      location.pathname.includes(
                                        "leads/campaign"
                                      )
                                      ? styles.defaultText
                                      : styles.defaultTextoff
                                    : styles.disabled
                                  : isHovered ||
                                    (location.pathname.includes("leads") &&
                                      !location.pathname.includes("reports")) ||
                                    location.pathname.includes("leads/campaign")
                                  ? styles.defaultText
                                  : styles.defaultTextoff
                              }
                            >
                              Leads
                            </Text>
                          </HStack>
                        </HStack>
                      </Flex>
                    </Tooltip>
                  );
                }}
              </Pressable>
              <Pressable
                onPress={() => navigate("/campaigns/lead")}
                isDisabled={
                  profile && profile.role === "user"
                    ? permissionAllowed("campaign")
                    : false
                }
              >
                {({ isFocused, isHovered }) => {
                  return (
                    <Tooltip
                      label={"No Access"}
                      placement={"bottom left"}
                      openDelay={200}
                      isDisabled={
                        profile && profile.role === "user"
                          ? !permissionAllowed("campaign")
                          : true
                      }
                    >
                      <Flex alignItems={"center"}>
                        <HStack
                          pl={aw(10)}
                          alignItems={"center"}
                          style={
                            isHovered ||
                            location.pathname === "/campaigns" ||
                            (location.pathname.includes("campaigns") &&
                              !location.pathname.includes("reports"))
                              ? styles.styleStack
                              : styles.styleStackOff
                          }
                          space={aw(10)}
                        >
                          <HStack>
                            <LeadsWatchCampaignsIcon
                              hover={
                                isHovered ||
                                location.pathname === "/campaigns/lead" ||
                                (location.pathname.includes("campaigns") &&
                                  !location.pathname.includes("reports"))
                                  ? "true"
                                  : "false"
                              }
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("campaign")
                                  : false
                              }
                            />
                          </HStack>
                          <HStack>
                            <Text
                              color="#121233"
                              fontFamily={"RobotoMedium"}
                              style={
                                profile && profile.role === "user"
                                  ? !permissionAllowed("campaign")
                                    ? isHovered ||
                                      location.pathname === "/campaigns" ||
                                      (location.pathname.includes(
                                        "campaigns"
                                      ) &&
                                        !location.pathname.includes("reports"))
                                      ? styles.defaultText
                                      : styles.defaultTextoff
                                    : styles.disabled
                                  : isHovered ||
                                    location.pathname === "/campaigns" ||
                                    (location.pathname.includes("campaigns") &&
                                      !location.pathname.includes("reports"))
                                  ? styles.defaultText
                                  : styles.defaultTextoff
                              }
                            >
                              Campaigns
                            </Text>
                          </HStack>
                        </HStack>
                      </Flex>
                    </Tooltip>
                  );
                }}
              </Pressable>
              {profile && profile.role !== "publisher" && (
                <Pressable
                  onPress={() => navigate("/publishers")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("publisher")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("publisher")
                            : true
                        }
                      >
                        <Flex alignItems={"center"}>
                          <HStack
                            pl={aw(10)}
                            alignItems={"center"}
                            style={
                              isHovered || location.pathname === "/publishers"
                                ? styles.styleStack
                                : styles.styleStackOff
                            }
                            space={aw(10)}
                          >
                            <HStack>
                              <LeadsWatchPublishersIcon
                                disabled={
                                  profile && profile.role === "user"
                                    ? permissionAllowed("publisher")
                                    : false
                                }
                                hover={
                                  isHovered ||
                                  location.pathname === "/publishers"
                                    ? "true"
                                    : "false"
                                }
                              />
                            </HStack>
                            <HStack>
                              <Text
                                color="#121233"
                                fontFamily={"RobotoMedium"}
                                style={
                                  profile && profile.role === "user"
                                    ? !permissionAllowed("publisher")
                                      ? isHovered ||
                                        location.pathname === "/publishers"
                                        ? styles.defaultText
                                        : styles.defaultTextoff
                                      : styles.disabled
                                    : isHovered ||
                                      location.pathname === "/publishers"
                                    ? styles.defaultText
                                    : styles.defaultTextoff
                                }
                              >
                                Publishers
                              </Text>
                            </HStack>
                          </HStack>
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              )}
              {profile && profile.role !== "publisher" && (
                <Pressable
                  onPress={() => navigate("/buyers")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("buyer")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("publisher")
                            : true
                        }
                      >
                        <Flex alignItems={"center"}>
                          <HStack
                            pl={aw(10)}
                            alignItems={"center"}
                            style={
                              isHovered ||
                              location.pathname === "/buyers" ||
                              (location.pathname.includes("buyers") &&
                                !location.pathname.includes("reports"))
                                ? styles.styleStack
                                : styles.styleStackOff
                            }
                            space={aw(10)}
                          >
                            <HStack>
                              <LeadsWatchBuyersIcon
                                disabled={
                                  profile && profile.role === "user"
                                    ? permissionAllowed("buyer")
                                    : false
                                }
                                hover={
                                  isHovered ||
                                  location.pathname === "/buyers" ||
                                  (location.pathname.includes("buyers") &&
                                    !location.pathname.includes("reports"))
                                    ? "true"
                                    : "false"
                                }
                              />
                            </HStack>
                            <HStack>
                              <Text
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? permissionAllowed("buyer")
                                    : true
                                }
                                color="#121233"
                                fontFamily={"RobotoMedium"}
                                style={
                                  profile && profile.role === "user"
                                    ? !permissionAllowed("buyer")
                                      ? isHovered ||
                                        location.pathname === "/buyers" ||
                                        location.pathname === "/buyers" ||
                                        location.pathname.includes("buyers")
                                        ? styles.defaultText
                                        : styles.defaultTextoff
                                      : styles.disabled
                                    : isHovered ||
                                      location.pathname === "/buyers" ||
                                      location.pathname === "/buyers" ||
                                      location.pathname.includes("buyers")
                                    ? styles.defaultText
                                    : styles.defaultTextoff
                                }
                              >
                                Buyers
                              </Text>
                            </HStack>
                          </HStack>
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              )}
              {profile && profile.role !== "publisher" && (
                <Pressable
                  onPress={() => navigate("/verticals")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("vertical")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("vertical")
                            : true
                        }
                      >
                        <Flex alignItems={"center"}>
                          <HStack
                            pl={aw(10)}
                            alignItems={"center"}
                            style={
                              isHovered ||
                              location.pathname === "/verticals" ||
                              (location.pathname.includes("verticals") &&
                                !location.pathname.includes("reports"))
                                ? styles.styleStack
                                : styles.styleStackOff
                            }
                            space={aw(10)}
                          >
                            <HStack>
                              <LeadsWatchVerticalsIcon
                                disabled={
                                  profile && profile.role === "user"
                                    ? permissionAllowed("vertical")
                                    : false
                                }
                                hover={
                                  isHovered ||
                                  location.pathname === "/verticals" ||
                                  (location.pathname.includes("verticals") &&
                                    !location.pathname.includes("reports"))
                                    ? "true"
                                    : "false"
                                }
                              />
                            </HStack>
                            <HStack>
                              <Text
                                color="#121233"
                                fontFamily={"RobotoMedium"}
                                style={
                                  profile && profile.role === "user"
                                    ? !permissionAllowed("vertical")
                                      ? isHovered ||
                                        location.pathname === "/verticals" ||
                                        (location.pathname.includes(
                                          "verticals"
                                        ) &&
                                          !location.pathname.includes(
                                            "reports"
                                          ))
                                        ? styles.defaultText
                                        : styles.defaultTextoff
                                      : styles.disabled
                                    : isHovered ||
                                      location.pathname === "/verticals" ||
                                      (location.pathname.includes(
                                        "verticals"
                                      ) &&
                                        !location.pathname.includes("reports"))
                                    ? styles.defaultText
                                    : styles.defaultTextoff
                                }
                              >
                                Verticals
                              </Text>
                            </HStack>
                          </HStack>
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              )}
              {profile && profile.role !== "publisher" && (
                <Pressable
                  onPress={() => navigate("/operations")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("lead")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("lead")
                            : true
                        }
                      >
                        <Flex alignItems={"center"}>
                          <HStack
                            pl={aw(10)}
                            alignItems={"center"}
                            style={
                              isHovered || location.pathname === "/operations"
                                ? styles.styleStack
                                : styles.styleStackOff
                            }
                            space={aw(10)}
                          >
                            <HStack>
                              <LeadsWatchOperationsIcon
                                disabled={
                                  profile && profile.role === "user"
                                    ? permissionAllowed("lead")
                                    : false
                                }
                                hover={
                                  isHovered ||
                                  location.pathname === "/operations"
                                    ? "true"
                                    : "false"
                                }
                              />
                            </HStack>
                            <HStack>
                              <Text
                                color="#121233"
                                fontFamily={"RobotoMedium"}
                                style={
                                  profile && profile.role === "user"
                                    ? !permissionAllowed("lead")
                                      ? isHovered ||
                                        location.pathname === "/operations"
                                        ? styles.defaultText
                                        : styles.defaultTextoff
                                      : styles.disabled
                                    : isHovered ||
                                      location.pathname === "/operations"
                                    ? styles.defaultText
                                    : styles.defaultTextoff
                                }
                              >
                                Operations
                              </Text>
                            </HStack>
                          </HStack>
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              )}
              {profile && profile.role !== "publisher" && (
                <Pressable
                  onPress={() => navigate("/calls/numbers/manageNumbers")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["call"].includes("calls") ||
                        profile.permission_ids["number"].includes("buy") ||
                        profile.permission_ids["number"].includes("delete")
                        ? false
                        : true
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids["call"].includes(
                                "calls"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "buy"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "delete"
                              )
                              ? true
                              : false
                            : true
                        }
                      >
                        <Flex alignItems={"center"}>
                          <HStack
                            pl={aw(10)}
                            alignItems={"center"}
                            style={
                              isHovered ||
                              location.pathname === "/calls/incomingCalls" ||
                              location.pathname.includes("incomingCalls") ||
                              location.pathname.includes("incomingCalls")
                                ? styles.styleStack
                                : styles.styleStackOff
                            }
                            space={aw(10)}
                          >
                            <HStack>
                              <LeadsWatchCallsIcon
                                disabled={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["call"].includes(
                                        "calls"
                                      ) ||
                                      profile.permission_ids["number"].includes(
                                        "buy"
                                      ) ||
                                      profile.permission_ids["number"].includes(
                                        "delete"
                                      )
                                      ? false
                                      : true
                                    : false
                                }
                                hover={
                                  isHovered ||
                                  location.pathname === "/calls" ||
                                  location.pathname.includes("calls") ||
                                  location.pathname.includes("incomingCalls")
                                    ? "true"
                                    : "false"
                                }
                              />
                            </HStack>
                            <HStack>
                              <Text
                                fontFamily={"RobotoMedium"}
                                color="#121233"
                                // style={

                                //   isHovered ||
                                //   location.pathname === "/calls" ||
                                //   location.pathname.includes("calls") ||
                                //   location.pathname.includes("makeCalls")
                                //     ? styles.defaultText
                                //     : styles.defaultTextoff
                                // }
                                style={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["call"].includes(
                                        "calls"
                                      ) ||
                                      profile.permission_ids["number"].includes(
                                        "buy"
                                      ) ||
                                      profile.permission_ids["number"].includes(
                                        "delete"
                                      )
                                      ? isHovered ||
                                        location.pathname === "/calls" ||
                                        location.pathname.includes("calls") ||
                                        location.pathname.includes(
                                          "incomingCalls"
                                        )
                                        ? styles.defaultText
                                        : styles.defaultTextoff
                                      : styles.disabled
                                    : isHovered ||
                                      location.pathname === "/calls" ||
                                      location.pathname.includes("calls") ||
                                      location.pathname.includes(
                                        "incomingCalls"
                                      )
                                    ? styles.defaultText
                                    : styles.defaultTextoff
                                }
                              >
                                Calls
                              </Text>
                            </HStack>
                          </HStack>
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              )}

              <Pressable
                onPress={() => navigate("/reports/leads")}
                isDisabled={
                  profile && profile.role === "user"
                    ? profile.permission_ids["lead"].includes("view") ||
                      profile.permission_ids["buyer"].includes("view") ||
                      profile.permission_ids["publisher"].includes("view") ||
                      profile.permission_ids["campaign"].includes("view") ||
                      profile.permission_ids["vertical"].includes("view")
                      ? false
                      : true
                    : false
                }
              >
                {({ isFocused, isHovered }) => {
                  return (
                    <Tooltip
                      label={"No Access"}
                      placement={"bottom left"}
                      openDelay={200}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids["lead"].includes("view") ||
                            profile.permission_ids["buyer"].includes("view") ||
                            profile.permission_ids["publisher"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["campaign"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["vertical"].includes("view")
                            ? true
                            : false
                          : true
                      }
                    >
                      <Flex alignItems={"center"}>
                        <HStack
                          pl={aw(10)}
                          alignItems={"center"}
                          style={
                            isHovered || location.pathname.includes("reports")
                              ? styles.styleStack
                              : styles.styleStackOff
                          }
                          space={aw(10)}
                        >
                          <HStack>
                            <LeadsWatchReportsIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["lead"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids["buyer"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids[
                                      "publisher"
                                    ].includes("view") ||
                                    profile.permission_ids["campaign"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids["vertical"].includes(
                                      "view"
                                    )
                                    ? false
                                    : true
                                  : false
                              }
                              hover={
                                isHovered ||
                                location.pathname.includes("reports")
                                  ? "true"
                                  : "false"
                              }
                            />
                          </HStack>
                          <HStack>
                            <Text
                              fontFamily={"RobotoMedium"}
                              color="#121233"
                              style={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["lead"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids["buyer"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids[
                                      "publisher"
                                    ].includes("view") ||
                                    profile.permission_ids["campaign"].includes(
                                      "view"
                                    ) ||
                                    profile.permission_ids["vertical"].includes(
                                      "view"
                                    )
                                    ? isHovered ||
                                      location.pathname === "/reports" ||
                                      location.pathname.includes("reports")
                                      ? styles.defaultText
                                      : styles.defaultTextoff
                                    : styles.disabled
                                  : isHovered ||
                                    location.pathname === "/reports" ||
                                    location.pathname.includes("reports")
                                  ? styles.defaultText
                                  : styles.defaultTextoff
                              }
                            >
                              Reports
                            </Text>
                          </HStack>
                        </HStack>
                      </Flex>
                    </Tooltip>
                  );
                }}
              </Pressable>
            </VStack>

            <VStack
              top={aw(150)}
              justifyContent={"space-evenly"}
              alignItems={"center"}
              height={ah(50)}
            >
              <Pressable onPress={() => navigate("/settings/profile")}>
                {({ isFocused, isHovered }) => {
                  return (
                    <Flex alignItems={"center"}>
                      <HStack
                        pl={aw(10)}
                        alignItems={"center"}
                        style={
                          isHovered ||
                          location.pathname === "/settings" ||
                          location.pathname.includes("settings")
                            ? styles.styleStack
                            : styles.styleStackOff
                        }
                        space={aw(10)}
                      >
                        <HStack>
                          <LeadsWatchSettingsIcon
                            hover={
                              isHovered ||
                              location.pathname === "/settings" ||
                              location.pathname.includes("settings")
                                ? "true"
                                : "false"
                            }
                          />
                        </HStack>
                        <HStack>
                          <Text
                            fontFamily={"RobotoMedium"}
                            color="#121233"
                            style={
                              isHovered ||
                              location.pathname === "/settings" ||
                              location.pathname.includes("settings")
                                ? styles.defaultText
                                : styles.defaultTextoff
                            }
                          >
                            Settings
                          </Text>
                        </HStack>
                      </HStack>
                    </Flex>
                  );
                }}
              </Pressable>
              {/* <Pressable>
                {({ isFocused, isHovered }) => {
                  return (
                    <Flex>
                      {isHovered ? (
                        <HStack style={styles.styleStack} alignItems={"center"}>
                          <HStack left={aw(12)}>
                            <LeadsWatchSupportIcon
                              style={{ fill: "#2230F8" }}
                            />
                          </HStack>
                          <HStack left={aw(14)}>
                            <Text
                              color="#121233"
                              fontFamily={"RobotoMedium"}
                              style={[styles.defaultText, styles.text]}
                            >
                              Support
                            </Text>
                          </HStack>
                        </HStack>
                      ) : (
                        <HStack
                          alignItems={"center"}
                          style={styles.styleStackOff}
                        >
                          <HStack left={aw(12)}>
                            <LeadsWatchSupportIcon />
                          </HStack>
                          <HStack left={aw(14)}>
                            <Text
                              color="#121233"
                              fontFamily={"RobotoMedium"}
                              style={[styles.defaultTextoff, styles.text]}
                            >
                              Support
                            </Text>
                          </HStack>
                        </HStack>
                      )}
                    </Flex>
                  );
                }}
              </Pressable> */}
              {/* <Pressable>
                {({ isFocused, isHovered }) => {
                  return (
                    <Flex>
                      {isHovered ? (
                        <HStack
                          alignItems={"center"}
                          style={{
                            width: aw(96),
                            height: ah(48),
                            background: "#F9FBFC 0% 0% no-repeat padding-box",
                            borderRadius: aw(6),
                          }}
                        >
                          <HStack
                            alignItems={"center"}
                            justifyContent={"center"}
                            style={{
                              width: aw(40),
                              height: ah(40),
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                            }}
                          >
                            <LeadsWatchLightIcon />
                          </HStack>
                          <HStack
                            left={aw(16)}
                            alignItems={"center"}
                            justifyContent={"center"}
                            style={{
                              width: aw(40),
                              height: ah(40),
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                            }}
                          >
                            <LeadsWatchDarkIcon />
                          </HStack>
                        </HStack>
                      ) : (
                        <HStack width={aw(168)} left={aw(14)}>
                          <HStack
                            alignItems={"center"}
                            style={{
                              width: aw(96),
                              height: ah(48),
                              background: "#F9FBFC 0% 0% no-repeat padding-box",
                              borderRadius: aw(6),
                            }}
                          >
                            <HStack
                              alignItems={"center"}
                              justifyContent={"center"}
                              style={{
                                width: aw(40),
                                height: ah(40),
                                // background: "#FFFFFF 0% 0% no-repeat padding-box",
                              }}
                            >
                              <LeadsWatchLightIcon />
                            </HStack>
                            <HStack
                              left={aw(16)}
                              alignItems={"center"}
                              justifyContent={"center"}
                              style={{
                                width: aw(40),
                                height: ah(40),
                                // background: "#FFFFFF 0% 0% no-repeat padding-box",
                              }}
                            >
                              <LeadsWatchDarkIcon />
                            </HStack> 
                          </HStack>
                        </HStack>
                      )}
                    </Flex>
                  );
                }}
              </Pressable> */}
            </VStack>
          </Box>
        ) : (
          <Box
            width={[aw(0), aw(0), aw(68)]}
            style={[styles.default, styles.style]}
            position={"absolute"}
          >
            <VStack
              space={profile && profile.role === "publisher" && ah(20)}
              mt={profile && profile.role === "publisher" && ah(10)}
              justifyContent={
                profile && profile.role === "publisher"
                  ? "flex-start"
                  : "space-evenly"
              }
              alignItems={"center"}
              height={ah(450)}
            >
              <Tooltip
                label="Dashboard"
                openDelay={300}
                placement={"left"}
                mt={ah(30)}
              >
                <Pressable
                  onPress={() => {
                    navigate("/");
                  }}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("stats")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("stats")
                            : true
                        }
                      >
                        <Flex>
                          <LeadsWatchDashboardIcon
                            disabled={
                              profile && profile.role === "user"
                                ? permissionAllowed("stats")
                                : false
                            }
                            hover={
                              isHovered || location.pathname === "/"
                                ? "true"
                                : "false"
                            }
                          />
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              </Tooltip>
              <Tooltip
                label="Leads"
                openDelay={300}
                placement={"left"}
                mt={ah(30)}
              >
                <Pressable
                  onPress={() => {
                    navigate("/leads/lead");
                    localStorage.setItem("screen", "leads");
                  }}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("lead")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("lead")
                            : true
                        }
                      >
                        <Flex>
                          <LeadsWatchActiveIcon
                            disabled={
                              profile && profile.role === "user"
                                ? permissionAllowed("lead")
                                : false
                            }
                            hover={
                              isHovered ||
                              (location.pathname.includes("leads") &&
                                !location.pathname.includes("reports")) ||
                              location.pathname.includes("leads/campaign")
                                ? "true"
                                : "false"
                            }
                          />
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              </Tooltip>
              <Tooltip
                label="Campaigns"
                openDelay={300}
                placement={"left"}
                mt={ah(30)}
                // bg="#2230F8"
              >
                <Pressable
                  onPress={() => navigate("/campaigns/lead")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? permissionAllowed("campaign")
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? !permissionAllowed("campaign")
                            : true
                        }
                      >
                        <Flex>
                          <LeadsWatchCampaignsIcon
                            disabled={
                              profile && profile.role === "user"
                                ? profile.permission_ids["campaign"].includes(
                                    "view"
                                  )
                                  ? false
                                  : true
                                : false
                            }
                            hover={
                              isHovered ||
                              location.pathname === "/campaigns" ||
                              (location.pathname.includes("campaigns") &&
                                !location.pathname.includes("reports"))
                                ? "true"
                                : "false"
                            }
                          />
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              </Tooltip>
              {profile && profile.role !== "publisher" && (
                <Tooltip
                  label="Publishers"
                  openDelay={300}
                  placement={"left"}
                  mt={ah(30)}
                >
                  <Pressable
                    onPress={() => navigate("/publishers")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? permissionAllowed("publisher")
                        : false
                    }
                  >
                    {({ isFocused, isHovered }) => {
                      return (
                        <Tooltip
                          label={"No Access"}
                          placement={"bottom left"}
                          openDelay={200}
                          isDisabled={
                            profile && profile.role === "user"
                              ? !permissionAllowed("publisher")
                              : true
                          }
                        >
                          <Flex>
                            <LeadsWatchPublishersIcon
                              hover={
                                isHovered || location.pathname === "/publishers"
                                  ? "true"
                                  : "false"
                              }
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("publisher")
                                  : false
                              }
                            />
                          </Flex>
                        </Tooltip>
                      );
                    }}
                  </Pressable>
                </Tooltip>
              )}
              {profile && profile.role !== "publisher" && (
                <Tooltip
                  label="Buyers"
                  openDelay={500}
                  placement={"left"}
                  mt={ah(30)}
                >
                  <Pressable
                    onPress={() => navigate("/buyers")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? permissionAllowed("buyer")
                        : false
                    }
                  >
                    {({ isFocused, isHovered }) => {
                      return (
                        <Tooltip
                          label={"No Access"}
                          placement={"bottom left"}
                          openDelay={200}
                          isDisabled={
                            profile && profile.role === "user"
                              ? !permissionAllowed("buyer")
                              : true
                          }
                        >
                          <Flex>
                            <LeadsWatchBuyersIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("buyer")
                                  : false
                              }
                              hover={
                                isHovered ||
                                location.pathname === "/buyers" ||
                                (location.pathname.includes("buyers") &&
                                  !location.pathname.includes("reports"))
                                  ? "true"
                                  : "false"
                              }
                            />
                          </Flex>
                        </Tooltip>
                      );
                    }}
                  </Pressable>
                </Tooltip>
              )}
              {profile && profile.role !== "publisher" && (
                <Tooltip
                  label="Verticals"
                  openDelay={300}
                  placement={"left"}
                  mt={ah(30)}
                >
                  <Pressable
                    onPress={() => navigate("/verticals")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? permissionAllowed("vertical")
                        : false
                    }
                  >
                    {({ isFocused, isHovered }) => {
                      return (
                        <Tooltip
                          label={"No Access"}
                          placement={"bottom left"}
                          openDelay={200}
                          isDisabled={
                            profile && profile.role === "user"
                              ? !permissionAllowed("vertical")
                              : true
                          }
                        >
                          <Flex>
                            <LeadsWatchVerticalsIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("vertical")
                                  : false
                              }
                              hover={
                                isHovered ||
                                location.pathname === "/verticals" ||
                                (location.pathname.includes("verticals") &&
                                  !location.pathname.includes("reports"))
                                  ? "true"
                                  : "false"
                              }
                            />
                          </Flex>
                        </Tooltip>
                      );
                    }}
                  </Pressable>
                </Tooltip>
              )}
              {profile && profile.role !== "publisher" && (
                <Tooltip
                  label="Operations"
                  openDelay={300}
                  placement={"left"}
                  mt={ah(30)}
                >
                  <Pressable
                    onPress={() => navigate("/operations")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? permissionAllowed("lead")
                        : false
                    }
                  >
                    {({ isFocused, isHovered }) => {
                      return (
                        <Tooltip
                          label={"No Access"}
                          isDisabled={
                            profile && profile.role === "user"
                              ? !permissionAllowed("lead")
                              : true
                          }
                        >
                          <Flex>
                            <LeadsWatchOperationsIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? permissionAllowed("lead")
                                  : false
                              }
                              hover={
                                isHovered || location.pathname === "/operations"
                                  ? "true"
                                  : "false"
                              }
                            />
                          </Flex>
                        </Tooltip>
                      );
                    }}
                  </Pressable>
                </Tooltip>
              )}
              {profile && profile.role !== "publisher" && (
                <Tooltip
                  label="Calls"
                  openDelay={300}
                  placement={"left"}
                  mt={ah(30)}
                >
                  <Pressable
                    onPress={() => navigate("/calls/numbers/manageNumbers")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["call"].includes("calls") ||
                          profile.permission_ids["number"].includes("buy") ||
                          profile.permission_ids["number"].includes("delete")
                          ? false
                          : true
                        : false
                    }
                  >
                    {({ isFocused, isHovered }) => {
                      return (
                        <Tooltip
                          label={"No Access"}
                          placement={"bottom left"}
                          openDelay={200}
                          isDisabled={
                            profile && profile.role === "user"
                              ? profile.permission_ids["call"].includes(
                                  "calls"
                                ) ||
                                profile.permission_ids["number"].includes(
                                  "buy"
                                ) ||
                                profile.permission_ids["number"].includes(
                                  "delete"
                                )
                                ? true
                                : false
                              : true
                          }
                        >
                          <Flex>
                            <LeadsWatchCallsIcon
                              disabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["call"].includes(
                                      "calls"
                                    )
                                    ? false
                                    : true
                                  : false
                              }
                              hover={
                                isHovered ||
                                location.pathname === "/calls" ||
                                location.pathname.includes("calls") ||
                                location.pathname.includes("incomigCalls")
                                  ? "true"
                                  : "false"
                              }
                            />
                          </Flex>
                        </Tooltip>
                      );
                    }}
                  </Pressable>
                </Tooltip>
              )}
              <Tooltip
                label="Reports"
                openDelay={500}
                placement={"left"}
                mt={ah(30)}
              >
                <Pressable
                  onPress={() => navigate("/reports/leads")}
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["lead"].includes("view") ||
                        profile.permission_ids["buyer"].includes("view") ||
                        profile.permission_ids["publisher"].includes("view") ||
                        profile.permission_ids["campaign"].includes("view") ||
                        profile.permission_ids["vertical"].includes("view")
                        ? false
                        : true
                      : false
                  }
                >
                  {({ isFocused, isHovered }) => {
                    return (
                      <Tooltip
                        label={"No Access"}
                        placement={"bottom left"}
                        openDelay={200}
                        isDisabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids["lead"].includes("view") ||
                              profile.permission_ids["buyer"].includes(
                                "view"
                              ) ||
                              profile.permission_ids["publisher"].includes(
                                "view"
                              ) ||
                              profile.permission_ids["campaign"].includes(
                                "view"
                              ) ||
                              profile.permission_ids["vertical"].includes(
                                "view"
                              )
                              ? true
                              : false
                            : true
                        }
                      >
                        <Flex>
                          <LeadsWatchReportsIcon
                            disabled={
                              profile && profile.role === "user"
                                ? profile.permission_ids["lead"].includes(
                                    "view"
                                  ) ||
                                  profile.permission_ids["buyer"].includes(
                                    "view"
                                  ) ||
                                  profile.permission_ids["publisher"].includes(
                                    "view"
                                  ) ||
                                  profile.permission_ids["campaign"].includes(
                                    "view"
                                  ) ||
                                  profile.permission_ids["vertical"].includes(
                                    "view"
                                  )
                                  ? false
                                  : true
                                : false
                            }
                            hover={
                              isHovered ||
                              location.pathname === "/reports" ||
                              location.pathname.includes("/reports")
                                ? "true"
                                : "false"
                            }
                          />
                          {/* <Text color="black">Reports</Text> */}
                        </Flex>
                      </Tooltip>
                    );
                  }}
                </Pressable>
              </Tooltip>
            </VStack>

            <VStack
              top={aw(150)}
              alignItems={"center"}
              height={ah(50)}
              justifyContent={"space-evenly"}
            >
              <Tooltip
                label="Settings"
                openDelay={500}
                placement={"left"}
                mt={ah(30)}
              >
                <Pressable onPress={() => navigate("/settings/profile")}>
                  {({ isFocused, isHovered }) => {
                    return (
                      <Flex>
                        <LeadsWatchSettingsIcon
                          hover={
                            isHovered ||
                            location.pathname === "/settings" ||
                            location.pathname.includes("/settings")
                              ? "true"
                              : "false"
                          }
                        />
                      </Flex>
                    );
                  }}
                </Pressable>
              </Tooltip>
              {/* <Pressable>
                {({ isFocused, isHovered }) => {
                  return (
                    <Flex>
                      {isHovered ? (
                        <LeadsWatchSupportIcon style={{ fill: "#2230F8" }} />
                      ) : (
                        <LeadsWatchSupportIcon />
                      )}
                    </Flex>
                  );
                }}
              </Pressable> */}
              {/* <Pressable>
                {({ isFocused, isHovered }) => {
                  return (
                    <Flex>
                      {isHovered ? (
                        <LeadsWatchLightIcon style={{ fill: "#2230F8" }} />
                      ) : (
                        <LeadsWatchLightIcon />
                      )}
                    </Flex>
                  );
                }}
              </Pressable> */}
            </VStack>
          </Box>
        )}
      </HStack>
    </Hidden>
  );
};

const styles = {
  default: {
    height: ah(698),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    shadowColor: "#0000001A",
    shadowOffset: { width: "0px", height: "3px" },
    shadowRadius: "15px",
  },

  defaultopen: {
    height: ah(698),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    shadowColor: "#0000001A",
    shadowOffset: { width: "0px", height: "3px" },
    shadowRadius: "15px",
  },
  defaultText: {
    fontSize: aw(14),
    color: "#2230F8",
  },
  defaultTextoff: {
    fontSize: aw(14),
  },
  disabled: {
    color: "#C1C1C1",
    fontSize: aw(14),
  },
  styleStack: {
    width: aw(148),
    height: ah(38),
    borderRadius: aw(10),
    background: " #F3F3FF 0% 0% no-repeat padding-box",
  },
  styleStackOff: {
    width: aw(148),
    height: ah(38),
    borderRadius: aw(10),
    background: " #FFFFFF 0% 0% no-repeat padding-box",
  },
};

export default LeadsWatchSideBarTrial;
