import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchLeftDirection = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Group_945" data-name="Group 945" transform="translate(-895 -434)">
      <Rect
        id="Rectangle_812"
        data-name="Rectangle 812"
        width={aw(24)}
        height={ah(24)}
        transform="translate(895 434)"
        fill="none"
      />
      <Path
        id="Path_10309"
        data-name="Path 10309"
        d="M-.98-8,3.15-3.88,1.97-2.7-3.33-8l5.3-5.3,1.18,1.17Z"
        transform="translate(907 454)"
        fill={props.color || "#fff"}
      />
    </G>
  </Svg>
);
export default LeadsWatchLeftDirection;
