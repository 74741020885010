import React, { useState } from "react";
import {
  Flex,
  useBreakpointValue,
  Hidden,
  VStack,
  Box,
  Text,
  HStack,
} from "native-base";
import { useForm } from "react-hook-form";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

//utils
import { aw1, ah1 } from "../../utils";
//svg
import LeadsWatchLeftDirection from "../../components/svg/LeadsWatchDirection";
import LeadsWatchRightDirection from "../../components/svg/LeadsWatchRightDirection";
import LeadsWatchEmailIcon from "../../components/svg/LeadsWatchEmailIcon";
//components
import LeadsWatchInput from "../../components/LeadsWatchInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";
//redux
import { sendOtp, verifyOtp } from "../../store/actions/auth";

const Login = () => {
  // const textRef = useRef(0);
  const isVerified = useSelector((state) => state.auth.isVerified);

  const width = useBreakpointValue({
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
  });
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showotp, setShowOtp] = useState(false);

  // const formatTime = (timeInSeconds) => {
  //   return `${String(timeInSeconds).padStart(2, "0")}`;
  // };
  const handleOtpChange = (value, index) => {
    if (value.match(/^\d*$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < otp.length - 1 && value) {
        // Move focus to the next input box
        focusOnNextInput(index + 1);
      }
    }
  };

  // const focusOnNextInput = (index) => {

  //     if (index < otp.length) {
  //       // Focus on the next input
  //       document.getElementById(`otp-input-${index}`).focus();
  //     }

  // };
  const focusOnNextInput = (index) => {
    const nextInputId = `otp-input-${index}`;
    const nextInput = document.getElementById(nextInputId);

    if (nextInput) {
      // Focus on the next input if it exists
      nextInput.focus();
    } else {
      // Handle the case where the next input does not exist
      console.log(`Input with ID ${nextInputId} not found.`);
      // toast.error("Please Enter Valid OTP");
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    if (!showotp) {
      try {
        await dispatch(sendOtp(data));
        setShowOtp(true);
        toast.success("Otp Sent Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        const data = await dispatch(verifyOtp({ otp: otp.join("") }));
        toast.success("Otp Verified Successfully");
        console.log(isVerified);
        localStorage.setItem("isVerified", JSON.stringify(data));
        localStorage.setItem("email", getValues("email"));
        localStorage.setItem("userId", "null");
        localStorage.setItem("firstName", null);
        localStorage.setItem("lastName", null);
        localStorage.setItem("phone", null);
        localStorage.setItem("company", null);
        localStorage.setItem("time_zone", null);
        localStorage.setItem("verify_email", null);
        localStorage.setItem("image", undefined);
        localStorage.setItem("logo", undefined);
        localStorage.setItem("voice_id", null);
        window.location.reload(false);
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setLoading(false);
  };
  //for sec
  // const handleBackspace = (e, index) => {
  //   if (e.keyCode === 8 && otp[index] === "") {
  //     if (index > 0) {
  //       // Move focus to the previous input box
  //       document.getElementById(`otp-input-${index - 1}`).focus();
  //     }
  //   }
  // };
  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" || e.keyCode === 8) {
      if (otp[index] === "") {
        // Move focus to the previous input box on Backspace or Backspace key code (8)
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    } else if (e.key === "Delete" || e.keyCode === 46) {
      setOtp((prevOtp) => {
        prevOtp[index] = "";
        return [...prevOtp];
      });

      // Move focus to the next input box on Delete or Delete key code (46)
      if (index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        nextInput.focus();

        // Place the cursor in front of the value
        nextInput.selectionStart = 0;
        nextInput.selectionEnd = 0;
      }
    } else if (e.key === "ArrowLeft" || e.keyCode === 37) {
      // Move focus to the previous input box on left arrow key or left arrow key code (37)
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    } else if (e.key === "ArrowRight" || e.keyCode === 39) {
      // Move focus to the next input box on right arrow key or right arrow key code (39)
      if (index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  // useEffect(() => {
  //   if (textRef.current) {
  //     //   if (textRef.current.textContent) {
  //     let initialTime = 0;
  //     const interval = setInterval(() => {
  //       initialTime += 1;
  //       if (initialTime <= 30) {
  //         const formattedTime = formatTime(initialTime);
  //         textRef.current.textContent = formattedTime;
  //       }
  //     }, 1000);

  //     return () => {
  //       clearInterval(interval);
  //     };
  //     //   } else {
  //     //     textRef.current.textContent = 0;
  //     //   }
  //   }
  // }, []);

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData.split("");

    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      let currentIndex = index;

      pastedValues.forEach((value) => {
        if (currentIndex < newOtp.length && value.match(/^\d*$/)) {
          newOtp[currentIndex] = value;
          currentIndex++;
        }
      });
      if (currentIndex <= newOtp.length) {
        focusOnNextInput(currentIndex - 1);
      }
      return newOtp;
    });

    // Move focus to the next input box
    focusOnNextInput(index + pastedValues.length);
  };
  return (
    <Flex direction="row" height="100vh" bgColor="#FAFBFD" width="100%">
      <Flex
        width={["100%", "100%", "50%", "50%", "50%"]}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex
          width={["100%", "50%", "50%", "50%", "50%"]}
          gap={ah1(17)}
          alignItems={"center"}
        >
          <Text color="#0000FF" fontFamily={"RobotoMedium"} fontSize={ah1(22)}>
            LeadsWatch
          </Text>
          <Flex alignItems={"center"}>
            <Text fontFamily={"RobotoMedium"} fontSize={ah1(30)}>
              Welcome back
            </Text>
            <Text fontFamily={"RobotoMedium"} fontSize={ah1(14)}>
              Enter Your Email to Create or Sign in to your Account
            </Text>
          </Flex>
          <LeadsWatchInput
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSubmit(onSubmit)();
              }
            }}
            leftIcon={
              <HStack
                pl={aw1(5)}
                bgColor="#FFFFFF"
                height={ah1(50)}
                alignItems={"center"}
              >
                <LeadsWatchEmailIcon color={errors.email ? "red" : "#919191"} />
              </HStack>
            }
            placeholder={"Enter Email"}
            control={control}
            name="email"
            label="Email"
            height={ah1(50)}
            borderRadius={10}
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />
          {showotp && (
            <Flex>
              <Flex gap={ah1(10)} width="100%">
                <Flex direction="row" gap={ah1(18)}>
                  {otp.map((value, index) => (
                    <input
                      onPaste={(e) => handlePaste(e, index)}
                      className="otp-input"
                      onKeyDown={(e) => handleBackspace(e, index)}
                      style={{
                        textAlign: "center",
                        width: 40,
                        height: 30,
                        borderRadius: 5,
                        border: "1px solid #D1D5DB",
                        //  marginLeft:index!==0? aw1(13):0
                      }}
                      key={index}
                      id={`otp-input-${index}`}
                      value={value}
                      onChange={(e) => handleOtpChange(e.target.value, index)}
                      maxLength={1}
                      type="number"
                    />
                  ))}
                </Flex>
                <Flex
                  direction="row"
                  justifyContent={"space-between"}
                  width="100%"
                >
                  <Flex direction="row" alignItems={"baseline"}>
                    <Text fontSize={ah1(14)} fontFamily="RobotoMedium">
                      Did not get Otp{" "}
                    </Text>
                    <Text
                      underline
                      fontSize={ah1(14)}
                      fontFamily={"RobotoRegular"}
                      onPress={async () => {
                        try {
                          await dispatch(
                            sendOtp({ email: getValues("email") })
                          );
                          toast.success("Otp Sent Successfully");
                        } catch (err) {
                          toast.error(err.message.toLowerCase());
                        }
                      }}
                    >
                      Resend It
                    </Text>
                  </Flex>

                  {/* <Text
                  ref={textRef}
                  color={"#212121"}
                  fontFamily={"RobotoMedium"}
                  fontSize={ah1(14)}
                ></Text> */}
                </Flex>
              </Flex>
            </Flex>
          )}
          <LeadsWatchButton
            fontSize={ah1(14)}
            isLoading={loading}
            label={showotp ? "Verify Now" : "Get Started"}
            style={{ width: "100%", borderRadius: 10 }}
            bg="#0000FF"
            color="#FFFFFF"
            onPress={handleSubmit(onSubmit)}
          />
          {/* <Text fontFamily={"RobotoMedium"} fontSize={ah1(15)}>  By Creating an account, you agree to out Terms ande Conditions and agree to Privacy Policy</Text> */}
        </Flex>
      </Flex>
      <Hidden only={["sm", "base"]}>
        <Flex width="50%" p={10}>
          <Box
            borderRadius={20}
            style={{
              width: "100%", // Set full width
              height: "100vh",
              flex: 1,
              backgroundImage: "url(assets/registerBg.png)",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              width={"70%"}
              height={aw1(180)}
              borderColor="#00EEFF"
              borderWidth="2px"
              borderRadius="20px"
              // alignItems={"center"}
            >
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={125}
                isIntrinsicHeight={true}
                totalSlides={4}
              >
                <Slider>
                  <Slide index={0}>
                    <VStack
                      height={ah1(180)}
                      justifyContent="center"
                      alignItems="center"
                      space={aw1(12)}
                      mb={ah1(46)}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(20),
                          textAlign: "center",
                          width: "70%",
                        }}
                      >
                        {" "}
                        The Ultimate Lead Management App For Every Business.
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(14),
                          textAlign: "center",
                          width: "70%",
                        }}
                      >
                        LeadsWatch is a powerful & robust lead management app
                        that every marketing team deserves.
                      </Text>
                    </VStack>
                  </Slide>
                  <Slide index={1}>
                    <VStack
                      height={aw1(180)}
                      justifyContent="center"
                      alignItems="center"
                      space={aw1(12)}
                      mb={ah1(46)}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(20),
                          textAlign: "center",
                          width: "70%",
                        }}
                      >
                        {" "}
                        Capture, Monitor, and Track High-Quality Leads
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(14),
                          textAlign: "center",
                          width: aw1(320),
                        }}
                      >
                        LeadsWatch is a master tracker that helps you capture
                        and track every lead that enters your marketing funnel.
                      </Text>
                    </VStack>
                  </Slide>
                  <Slide index={2}>
                    <VStack
                      height={aw1(180)}
                      justifyContent="center"
                      alignItems="center"
                      space={aw1(12)}
                      mb={ah1(46)}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(20),
                          textAlign: "center",
                          width: "70%",
                        }}
                      >
                        {" "}
                        Work Seamlessly With Your Team and Close Deals.
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(14),
                          textAlign: "center",
                          width: aw1(320),
                        }}
                      >
                        LeadsWatch offers a seamless way to communicate and work
                        alongside your team to be more efficient and transcend
                        your revenue goals.
                      </Text>
                    </VStack>
                  </Slide>
                  <Slide index={3}>
                    <VStack
                      height={aw1(180)}
                      justifyContent="center"
                      alignItems="center"
                      space={aw1(12)}
                      mb={ah1(46)}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(20),
                          textAlign: "center",
                          width: "70%",
                        }}
                      >
                        {" "}
                        Set Up Automated Buyer Campaigns & Over-Deliver
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "RobotoMedium",
                          fontSize: ah1(14),
                          textAlign: "center",
                          width: aw1(320),
                        }}
                      >
                        LeadsWatch makes it super easy for you to create and run
                        personalized buyer campaigns that help you fulfill the
                        requirement of every buyer.
                      </Text>
                    </VStack>
                  </Slide>
                </Slider>
                <HStack
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonBack className="btn-arrow">
                    <LeadsWatchLeftDirection />
                  </ButtonBack>
                  <DotGroup />
                  <ButtonNext className="btn-arrow">
                    <LeadsWatchRightDirection />
                  </ButtonNext>
                </HStack>
              </CarouselProvider>
            </Box>
          </Box>
        </Flex>
      </Hidden>
    </Flex>
  );
};

export default Login;
