import * as events from "../Events";
const initialState = {
  allPipelines: null,
  unassignedLeads: null,
  pipelineLeads: null,
};
export const pipelinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_PIPELINES:
      return {
        ...state,
        allPipelines: action.data,
      };
    case events.UNASSIGNED_LEADS:
      return {
        ...state,
        unassignedLeads: action.data,
      };
    case events.PIPELINE_LEADS:
      return {
        ...state,
        pipelineLeads: action.data,
      };
    case events.ADD_NOTES:
      return {
        ...state,
        pipelineLeads: {
          ...state.pipelineLeads,
          list:
            state.pipelineLeads &&
            state.pipelineLeads.list.map((lead) => {
              if (lead._id === action.data._id) {
                return action.data;
              } else {
                return lead;
              }
            }),
        },
      };
    default:
      return state;
  }
};
