import React, { useState } from "react";
import { Text, VStack, HStack, Checkbox } from "native-base";
import { toast } from "react-toastify";
import { useStripe } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { aw, ah } from "../../utils";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import { stripePromise } from "../../utils";
import { API_URL } from "../../store/Events";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";

const AddSubscription = (props) => {
  const profile = useSelector((state) => state.auth.profile);
  const [checked, setChecked] = useState(false);
  const { control, handleSubmit, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("auth");
  const stripe = useStripe(stripePromise);
  const allCards = useSelector((state) => state.subscriptions.allCards);
  const createSubscription = async (data) => {
    setLoading(true);
    let defaultCard;
    // const defaultCard =
    //   allCards && allCards.filter((val) => val.default === true);
    for (let c of allCards && allCards) {
      if (c.default === true) {
        defaultCard = c.id;
      }
    }

    if (
      (profile && profile.subscribed === null) ||
      props.id.name === "Free" ||
      (profile && profile.subscribed && profile.subscribed.plan_type === "Free")
    ) {
      const finalData = {
        pm: defaultCard,
        price_id: props.id.id,
        referralcode: data.referralcode,
      };
      try {
        const response = await fetch(`${API_URL}/subscription/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(finalData),
        });
        if (!response.ok) {
          const resError = await response.json();

          if (resError && resError.error) {
            throw new Error(resError.error);
          }
        }
        const data = await response.json();
        console.log(data);
        if (data.data.clientSecret) {
          const confirm = await stripe.confirmCardPayment(
            data.data.clientSecret
          );
          if (confirm.error) {
            console.error(confirm.error);
          }
        }

        // dispatch(getProfile());

        toast.success("Subscribed Successfully, if not reflected refresh it");
        props.setIsOpenSubscriptionModal(false);
        window.location.reload(false);
      } catch (err) {
        setLoading(false);
        toast.error(err.message.toLowerCase());
      }
      setLoading(false);
    } else {
      const finalData = {
        pm: defaultCard,
        price_id: props.id.id,
        sub_id: profile.subscribed.subid,
        referralcode: data.referralcode,
      };
      try {
        const response = await fetch(`${API_URL}/subscription/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(finalData),
        });
        if (!response.ok) {
          const resError = await response.json();

          if (resError && resError.error) {
            throw new Error(resError.error);
          }
        }
        const data = await response.json();

        if (data.data.clientSecret) {
          const confirm = await stripe.confirmCardPayment(
            data.data.clientSecret
          );
          if (confirm.error) {
            console.error(confirm.error);
          }
        }

        props.setIsOpenSubscriptionModal(false);
        window.location.reload(false);
        // await dispatch(getProfile());

        toast.success("Subscribed Successfully, if not reflected refresh it");
      } catch (err) {
        setLoading(false);
        toast.error(err.message.toLowerCase());
      }
    }
  };

  return (
    <LeadsWatchModal
      width={aw(420)}
      height={ah(400)}
      showModal={props.isOpenSubscriptionModal}
      onClose={() => {
        {
          props.setIsOpenSubscriptionModal(false);
          reset();
          setChecked(false);
        }
      }}
      heading={
        <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
          Confirm Subscription
        </Text>
      }
    >
      <VStack space={ah(15)}>
        <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
          Are you sure want to proceed with this subscription plan?
        </Text>
        {props.id && props.id.name !== "Free" && (
          <Checkbox
            bgColor="#FAFBFD"
            _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
            _icon={{ color: "#FAFBFD" }}
            _hover={{ color: "#FAFBFD" }}
            onChange={() => setChecked(!checked)}
            isChecked={checked}
          >
            <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
              Have Referral Code?
            </Text>
          </Checkbox>
        )}
        {checked && (
          <LeadsWatchFilterInput
            control={control}
            name="referralcode"
            placeholder="Enter Referral code"
          />
        )}
        <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
          <LeadsWatchButton
            onPress={() => {
              props.setIsOpenSubscriptionModal(false);
              reset();
              setChecked(false);
            }}
            label="cancel"
            style={{
              width: aw(100),
              height: ah(32),
              borderRadius: aw(50),
            }}
          />
          <LeadsWatchButton
            isLoading={loading}
            onPress={handleSubmit(createSubscription)}
            label="Proceed"
            style={{
              width: aw(100),
              height: ah(32),
              borderRadius: aw(50),
            }}
            bg={"#2230F8"}
            color={"#F9FAFB"}
          />
        </HStack>
      </VStack>
    </LeadsWatchModal>
  );
};

export default AddSubscription;
