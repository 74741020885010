import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchEyeOffIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="EyeOff_default_icon" transform="translate(-268 -407)">
      <Rect
        id="Rectangle_287"
        data-name="Rectangle 287"
        width={aw(24)}
        height={ah(24)}
        transform="translate(268 407)"
        fill="none"
      />
      <Path
        id="Path_3977"
        data-name="Path 3977"
        d="M13.41-1.728A8.09,8.09,0,0,1,11.3-.774,8.252,8.252,0,0,1,9-.45,8.157,8.157,0,0,1,6.129-.963,8.1,8.1,0,0,1,3.695-2.381,8.287,8.287,0,0,1,1.881-4.527a8.373,8.373,0,0,1-1-2.673,8.17,8.17,0,0,1,.887-2.48,8.094,8.094,0,0,1,1.625-2.066L1.044-14.094l1.062-1.062L16.956-.306,15.894.756ZM4.455-10.692A6.852,6.852,0,0,0,3.168-9.1a6.562,6.562,0,0,0-.747,1.9A6.611,6.611,0,0,0,3.789-4.41,6.678,6.678,0,0,0,6.268-2.529a6.666,6.666,0,0,0,3.06.572,6.687,6.687,0,0,0,2.993-.86L10.8-4.347a3.277,3.277,0,0,1-1.426.5A3.42,3.42,0,0,1,7.889-4.01a3.293,3.293,0,0,1-1.278-.8,3.293,3.293,0,0,1-.8-1.278,3.42,3.42,0,0,1-.167-1.485A3.342,3.342,0,0,1,6.138-9ZM9.684-5.454l-2.43-2.43a1.8,1.8,0,0,0-.09,1.066,1.855,1.855,0,0,0,.513.941,1.855,1.855,0,0,0,.94.513A1.8,1.8,0,0,0,9.684-5.454Zm5.922,1.7-1.071-1.08A6.6,6.6,0,0,0,15.579-7.2a6.668,6.668,0,0,0-1.125-2.475,6.667,6.667,0,0,0-2.012-1.832,6.691,6.691,0,0,0-2.569-.887,6.66,6.66,0,0,0-2.709.2L5.985-13.383A8.2,8.2,0,0,1,9-13.95a8.157,8.157,0,0,1,2.871.513,8.1,8.1,0,0,1,2.435,1.417,8.287,8.287,0,0,1,1.814,2.147,8.373,8.373,0,0,1,1,2.673,8.488,8.488,0,0,1-.562,1.809A8.41,8.41,0,0,1,15.606-3.753ZM8.793-10.566a3.331,3.331,0,0,1,1.4.211,3.332,3.332,0,0,1,1.193.769,3.332,3.332,0,0,1,.769,1.193,3.331,3.331,0,0,1,.212,1.4Z"
        transform="translate(271 426)"
        fill="#919191"
      />
    </G>
  </Svg>
);
export default LeadsWatchEyeOffIcon;
