import {
  Text,
  VStack,
  HStack,
  Box,
  FlatList,
  ScrollView,
  Spinner,
  Link,
} from "native-base";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment/moment";

import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";

import { getAllInvoices } from "../../store/actions/subscription";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";

const Invoice = ({ isOpen }) => {
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [currentPage, setCurrentPage] = useState(
    page && page.invoice ? parseInt(page.invoice) : 1
  );
  const invoices = useSelector((state) => state.subscriptions.allInvoices);

  const [invoiceLoading, setInvoicesLoading] = useState(true);

  const dispatch = useDispatch();

  const getInvoicesData = async () => {
    setInvoicesLoading(true);
    try {
      await dispatch(getAllInvoices("sub", currentPage));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setInvoicesLoading(false);
  };
  useEffect(() => {
    getInvoicesData();
  }, [currentPage]);

  return (
    <Box mt={ah(30)} pb={ah(30)}>
      <LeadsWatchCard
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        height={ah(500)}
      >
        <VStack
          pb={aw(20)}
          pt={aw(10)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          px={aw(15)}
        >
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoMedium"}
            color={"black"}
            fontWeight={400}
          >
            Invoice History
          </Text>
          <HStack
            alignItems={"center"}
            style={{
              height: ah(60),
              background: "#F9FAFB 0% 0% no-repeat padding-box",
              border: "1px solid #D7D7D7",
              borderTopLeftRadius: aw(10),
              borderTopRightRadius: aw(10),
            }}
          >
            {["No", "Date", "Billing", "Plan", "Amount", "Download"].map(
              (val, index) => {
                return (
                  <HStack
                    key={index}
                    alignItems={"center"}
                    width={"16.7%"}
                    justifyContent="flex-start"
                  >
                    <Text
                      width={"100%"}
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      ml={aw(20)}
                      color="#121233"
                    >
                      {val}
                    </Text>
                  </HStack>
                );
              }
            )}
          </HStack>
          {!invoiceLoading ? (
            <ScrollView
              maxHeight={ah(300)}
              // showsVerticalScrollIndicator={false}
              style={{
                borderBottomRightRadius: aw(10),
                borderBottomLeftRadius: aw(10),
                borderbottomWidth: "1px solid #D7D7D7",
                borderTopWidth: "0px",
                borderColor: "#D7D7D7",
                borderWidth: "1px",
              }}
            >
              <FlatList
                // showsVerticalScrollIndicator={false}
                // height={ah(300)}
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(290)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={invoices && invoices.list && invoices.list}
                keyExtractor={(item) => item._id}
                renderItem={({ item, index }) => (
                  <HStack width="100%">
                    {[
                      "no",
                      "date",
                      "billing",
                      "plan_name",
                      "amount",
                      "download",
                    ].map((val) => (
                      <HStack
                        key={val}
                        height={ah(60)}
                        width={"16.7%"}
                        alignItems={"center"}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                          minHeight: ah(58),
                          borderTopWidth: index === 0 ? 0 : "1px",
                          borderLeftWidth: "0px",
                          borderRightWidth: "0px",
                          borderBottomWidth: "0px",
                          borderColor: "#D7D7D7",
                        }}
                      >
                        <Text
                          color="#121233"
                          width={"100%"}
                          ml={aw(20)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          {val === "download" || val === "billing" ? (
                            val === "download" ? (
                              <Link href={`${item["pdf"]}`}>
                                <Text underline color="#0000FF">
                                  {`invoice_${moment(item["date"]).format(
                                    "DDMMMMYY"
                                  )}`}
                                </Text>
                              </Link>
                            ) : (
                              <Text
                                color="#121233"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                Monthly
                              </Text>
                            )
                          ) : val === "no" || val === "date" ? (
                            val === "no" ? (
                              index + 1
                            ) : (
                              moment(item[val]).format("YYYY-MM-DD")
                            )
                          ) : (
                            item[val]
                          )}
                        </Text>
                      </HStack>
                    ))}
                  </HStack>
                )}
              />
            </ScrollView>
          ) : (
            <HStack
              justifyContent="center"
              alignItems={"center"}
              height={ah(300)}
              // width={aw(580)}
              style={{
                border: "1px solid #D7D7D7",
                borderTopWidth: 0,
                borderBottomLeftRadius: aw(10),
                borderBottomRightRadius: aw(10),
              }}
            >
              <Spinner size="lg" color="#2230F8" />
            </HStack>
          )}
        </VStack>
      </LeadsWatchCard>
      <VStack
        bottom={aw(5)}
        position="fixed"
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <LeadsWatchPagination
          totalCount={invoices && invoices.list ? invoices.total_count : 1}
          width="100%"
          // totalCount={100}
          currentPage={currentPage}
          pageSize={10}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
            localStorage.setItem(
              "page",
              JSON.stringify({ ...page, invoice: currentPage })
            );
          }}
        />
      </VStack>
    </Box>
  );
};

export default Invoice;
