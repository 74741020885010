import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchDashboardIcon(props) {
  return (
    <Svg
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"
        // stroke="#292D32"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />

      <Path
        d="M7.33 14.49l2.38-3.09c.34-.44.97-.52 1.41-.18l1.83 1.44c.44.34 1.07.26 1.41-.17l2.31-2.98"
        // stroke="#292D32"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </Svg>
  );
}

export default LeadsWatchDashboardIcon;
