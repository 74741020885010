import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchTickIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Selected-tick-icon" transform="translate(-196 -80)">
      <G
        id="Ellipse_185"
        data-name="Ellipse 185"
        transform="translate(198 82)"
        // fill="#0000FF"
        fill={props.color || "#0000FF"}
        stroke="#fff"
        strokeWidth={2}
      >
        <Circle cx={11} cy={11} r={11} stroke="none" />
        <Circle cx={11} cy={11} r={12} fill="none" />
      </G>
      <Path
        id="Path_10186"
        data-name="Path 10186"
        d="M5.831-3.752l5.362-5.362.826.826L5.831-2.1,2.121-5.81l.826-.826Z"
        transform="translate(202 99)"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default LeadsWatchTickIcon;
