import { React, useState } from "react";
import { Text, Flex } from "native-base";
import { toast } from "react-toastify";
import { aw, ah } from "../../utils";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
//redux
import { deleteAccount } from "../../store/actions/auth";

const Index = () => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const removeAccount = async () => {
    setLoadingDelete(true);
    try {
      await dispatch(deleteAccount());
      toast.success("Your Account is Deleted Successfully");
      localStorage.clear();
      navigate("/");
      window.location.reload(false);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoadingDelete(false);
  };
  return (
    <Flex
      width="100%"
      height={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={ah(20)}
      // bg={"#E0C6FD"}
    >
      <Flex bg="#2230F8" p={aw(40)} gap={ah(20)} borderRadius={aw(5)}>
        <Flex>
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#FFFFFF">
            Are you sure you want to delete your Account?
          </Text>
          <Text fontFamily="RobotoMedium" fontSize={aw(12)} color="#FFFFFF">
            If you delete your accout, all your data will be removed
          </Text>
        </Flex>
        <Flex direction="row" gap={ah(20)} justifyContent="center">
          <LeadsWatchButton
            label="Back"
            style={{ height: ah(32), width: aw(100) }}
            onPress={() => navigate(-1)}
          />
          <LeadsWatchButton
            label="Proceed"
            style={{ height: ah(32), width: aw(100) }}
            onPress={removeAccount}
            isLoading={loadingDelete}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Index;
