import { View, Text, VStack } from "native-base";
import React from "react";

import { aw, ah } from "../../../utils";

const RoutingPlans = () => {
  return (
    <VStack
      marginTop={ah(10)}
      width={aw(1248)}
      borderRadius={aw(20)}
      style={{ shadowColor: "#D7D7D7", shadowRadius: aw(3) }}
      height={aw(420)}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Text
        lineHeight={ah(34)}
        fontSize={aw(14)}
        fontFamily={"RobotoMedium"}
        color={"black"}
        fontWeight={400}
      >
        No Data Availble
      </Text>
    </VStack>
  );
};

export default RoutingPlans;
