import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchEditIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Edit_default_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Path_10234"
        data-name="Path 10234"
        d="M-6.029-6.434a2.219,2.219,0,0,0-.466.677,2.186,2.186,0,0,0-.185.8L-6.75-2a.507.507,0,0,0,.141.36.463.463,0,0,0,.352.149H-3.27a2.255,2.255,0,0,0,.853-.167,2.174,2.174,0,0,0,.729-.483L5.607-9.3a2.224,2.224,0,0,0,.7-1.652,2.224,2.224,0,0,0-.7-1.652l-.984-.967a2.283,2.283,0,0,0-1.679-.686,2.342,2.342,0,0,0-1.7.686ZM4.9-11.918a1.329,1.329,0,0,1,.4.976,1.272,1.272,0,0,1-.4.958l-.562.563-2.971-2.9.58-.562a1.34,1.34,0,0,1,.984-.4,1.4,1.4,0,0,1,1,.4Zm-10.23,6.17L.686-11.637l2.953,2.9L-2.391-2.83l-.088.07a1.191,1.191,0,0,1-.36.22,1.171,1.171,0,0,1-.431.079H-5.748L-5.7-4.922a1.312,1.312,0,0,1,.114-.457A1.258,1.258,0,0,1-5.326-5.748ZM6.75-1.986a.459.459,0,0,1-.123.325.459.459,0,0,1-.3.149l-5.221.018a.477.477,0,0,1-.352-.141.477.477,0,0,1-.141-.352A.468.468,0,0,1,.738-2.3a.445.445,0,0,1,.3-.158H6.258a.477.477,0,0,1,.352.141A.456.456,0,0,1,6.75-1.986Z"
        transform="translate(38 56)"
        fill={props.color || "#212121"}
      />
    </G>
  </Svg>
);
export default LeadsWatchEditIcon;
