import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchFilterIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Filters_default_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Path_10234"
        data-name="Path 10234"
        d="M-3.182-12.217a1.41,1.41,0,0,0-.439-1.046,1.453,1.453,0,0,0-1.055-.431,1.453,1.453,0,0,0-1.055.431,1.41,1.41,0,0,0-.439,1.046,1.41,1.41,0,0,0,.439,1.046,1.453,1.453,0,0,0,1.055.431,1.453,1.453,0,0,0,1.055-.431A1.41,1.41,0,0,0-3.182-12.217Zm1.318,0a2.679,2.679,0,0,1-.826,1.978,2.723,2.723,0,0,1-1.986.817,2.744,2.744,0,0,1-2-.817,2.679,2.679,0,0,1-.826-1.978,2.659,2.659,0,0,1,.826-1.96,2.744,2.744,0,0,1,2-.817,2.723,2.723,0,0,1,1.986.817A2.659,2.659,0,0,1-1.863-12.217Zm9.369,0a.625.625,0,0,0-.193-.457.645.645,0,0,0-.475-.193H1.406a.625.625,0,0,0-.457.193.625.625,0,0,0-.193.457.617.617,0,0,0,.193.466.64.64,0,0,0,.457.185H6.838a.661.661,0,0,0,.475-.185A.617.617,0,0,0,7.506-12.217ZM3.182-3.533a1.41,1.41,0,0,0,.439,1.046,1.453,1.453,0,0,0,1.055.431A1.453,1.453,0,0,0,5.73-2.487,1.41,1.41,0,0,0,6.17-3.533,1.41,1.41,0,0,0,5.73-4.579,1.453,1.453,0,0,0,4.676-5.01a1.453,1.453,0,0,0-1.055.431A1.41,1.41,0,0,0,3.182-3.533Zm-1.318,0a2.679,2.679,0,0,1,.826-1.978,2.723,2.723,0,0,1,1.986-.817,2.744,2.744,0,0,1,2,.817,2.679,2.679,0,0,1,.826,1.978,2.659,2.659,0,0,1-.826,1.96,2.744,2.744,0,0,1-2,.817,2.723,2.723,0,0,1-1.986-.817A2.659,2.659,0,0,1,1.863-3.533Zm-8.7-.65h5.432A.64.64,0,0,1-.949-4a.617.617,0,0,1,.193.466.625.625,0,0,1-.193.457.625.625,0,0,1-.457.193H-6.838a.645.645,0,0,1-.475-.193.625.625,0,0,1-.193-.457A.617.617,0,0,1-7.312-4,.661.661,0,0,1-6.838-4.184Z"
        transform="translate(38 56)"
        fill="#212121"
      />
    </G>
  </Svg>
);
export default LeadsWatchFilterIcon;
