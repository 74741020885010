import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchGroupIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <g data-name="Group 1095">
        <path
          fill="#212121"
          d="M23.22 18.76a5.9 5.9 0 00-2.05-2.91 5.6 5.6 0 00-1.35-.75 3.165 3.165 0 001.11-2.42 3.206 3.206 0 00-4.81-2.77 5.739 5.739 0 00-2.01-1.33 3.165 3.165 0 001.11-2.42 3.2 3.2 0 10-6.4 0 3.165 3.165 0 001.11 2.42 5.739 5.739 0 00-2.01 1.33 3.206 3.206 0 00-4.81 2.77 3.165 3.165 0 001.11 2.42 5.743 5.743 0 00-3.4 3.66 1.778 1.778 0 00.26 1.58 1.758 1.758 0 001.43.72h7.62a1.766 1.766 0 001.69-2.3 5.842 5.842 0 00-2.05-2.91 5.474 5.474 0 00-1.36-.75 3.062 3.062 0 00.51-.57h6.21a3.463 3.463 0 00.51.57 5.956 5.956 0 00-1.35.75 5.781 5.781 0 00-2.05 2.91 1.783 1.783 0 00.26 1.58 1.758 1.758 0 001.43.72h7.63a1.766 1.766 0 001.69-2.3zm-1.21.88a.6.6 0 01-.48.24H13.9a.6.6 0 01-.48-.24.622.622 0 01-.09-.53 4.585 4.585 0 018.76 0 .59.59 0 01-.09.53zm-6.32-6.96a2.03 2.03 0 112.03 2.03 2.031 2.031 0 01-2.03-2.03zM9.97 6.16A2.03 2.03 0 1112 8.19a2.031 2.031 0 01-2.03-2.03zm4.61 7.2H9.41a3.078 3.078 0 00.07-.68 3.223 3.223 0 00-.7-2 4.587 4.587 0 016.44 0 3.223 3.223 0 00-.7 2 3.078 3.078 0 00.07.68zm-8.3 1.35a2.03 2.03 0 112.03-2.03 2.031 2.031 0 01-2.03 2.03zm0 1.17a4.569 4.569 0 014.38 3.23.59.59 0 01-.09.53.6.6 0 01-.48.24H2.47a.6.6 0 01-.48-.24.622.622 0 01-.09-.53 4.569 4.569 0 014.38-3.23z"
          data-name="Path 10372"
        ></path>
        <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 891"></path>
      </g>
    </svg>
  );
}

export default LeadsWatchGroupIcon;
