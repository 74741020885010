import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchCopyCodeIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Copy_Code_icon" transform="translate(-282 -612)">
      <Rect
        id="Rectangle_391"
        data-name="Rectangle 391"
        width={aw(24)}
        height={ah(24)}
        transform="translate(282 612)"
        fill="none"
      />
      <Path
        id="Path_10314"
        data-name="Path 10314"
        d="M3.32-16.68V-15h-10V-3.32H-8.32V-15a1.621,1.621,0,0,1,.488-1.191A1.576,1.576,0,0,1-6.68-16.68Zm2.5,3.359a1.621,1.621,0,0,1,1.191.488A1.576,1.576,0,0,1,7.5-11.68V0a1.621,1.621,0,0,1-.488,1.191A1.621,1.621,0,0,1,5.82,1.68H-3.32a1.621,1.621,0,0,1-1.191-.488A1.621,1.621,0,0,1-5,0V-11.68a1.548,1.548,0,0,1,.137-.625,1.97,1.97,0,0,1,.352-.547,1.343,1.343,0,0,1,.527-.352,1.876,1.876,0,0,1,.664-.117ZM5.82,0V-11.68H-3.32V0Z"
        transform="translate(294 632)"
        fill="#212121"
      />
    </G>
  </Svg>
);
export default LeadsWatchCopyCodeIcon;
