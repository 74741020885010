import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchAddIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Create_plus_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={24}
        height={24}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Path_10127"
        data-name="Path 10127"
        d="M5.1-6.48H.88v4.26H-.9V-6.48H-5.1V-8.14H-.9v-4.28H.88v4.28H5.1Z"
        transform="translate(38 55)"
        fill={props.fill || "#FFFFFF"}
      />
    </G>
  </Svg>
);
export default LeadsWatchAddIcon;
