import * as events from "../Events";
const initialState = {
  allPublishers: null,
  newPublisher: null,
  singlePublisher: null,
  editPublisher: null,
  deletedPublisher: null,
  invitedPublishers: null,
};
export const publishersReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_PUBLISHERS:
      return {
        ...state,
        allPublishers: action.data,
      };
    case events.ADD_PUBLISHER:
      return {
        ...state,
        newPublisher: action.data,
      };
    case events.GET_SINGLE_PUBLISHER:
      return {
        ...state,
        singlePublisher: action.data,
      };
    case events.EDIT_PUBLISHER:
      return {
        ...state,
        editPublisher: action.data,
      };
    case events.DELETE_PUBLISHER:
      return {
        ...state,
        deletedPublisher: action.data,
      };
    case events.GET_ALL_INVITED_PUBLISHERS:
      return {
        ...state,
        invitedPublishers: action.data,
      };
    default:
      return state;
  }
};
