import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Roboto/Roboto-Medium.ttf";
import "./fonts/Roboto/Roboto-Regular.ttf";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/Index";
import { BrowserRouter as Router } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./utils";
// import { theme } from "./utils";
// import { ToastProvider } from "./components/ToastAlert";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
});
// const theme = extendTheme({
//   components: {
//     Select: {
//       baseStyle: ({ colorMode }) => {
//         return {
//           _focus: {
//             borderColor: "#003595",
//           },
//         };
//       },
//     },
//   },
// });
// const root = ReactDOM.createRoot(document.getElementById("root"));

ReactDOM.render(
  <React.StrictMode>
    <ToastContainer
      position="top-center"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      style={{
        fontFamily: "RobotoRegular",
      }}
    />
    <Provider store={store}>
      <NativeBaseProvider theme={theme}>
        <Router>
          {/* <Elements stripe={stripePromise}> */}
          <App />
          {/* </Elements> */}
        </Router>
      </NativeBaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
