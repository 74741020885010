import * as events from "../Events";

const initialState = {
  allCalls: null,
  activeCalls: null,
  availbleNum: null,
  newNumber: null,
  allNumbers: null,
  unallocatedNum: null,
  allBuyedNumbers: null,
  allPhoneNums: null,
  callLogs: null,
  priority: null,
};
export const callReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_CALLS:
      return {
        ...state,
        allCalls: action.data,
      };
    case events.GET_ACTIVE_CALLS:
      return {
        ...state,
        activeCalls: action.data,
      };
    case events.SEARCH_NUMBERS:
      return {
        ...state,
        availbleNum: action.data,
      };
    case events.BUY_NUMBER:
      return {
        ...state,
        newNumber: action.data,
      };
    case events.DELETE_NUMBERS:
      return {
        ...state,
      };
    // case events.GET_ALL_CALLS:
    //   return {
    //     ...state,
    //     allNumbers: action.data,
    //   };
    case events.REMOVE_SEARCHED_NUMBERS:
      return {
        ...state,
        availbleNum: null,
      };
    case events.GET_UNALLOCATED_NUM:
      return {
        ...state,
        unallocatedNum: action.data,
      };
    case events.GET_ALL_NUMBERS:
      return {
        ...state,
        allBuyedNumbers: action.data,
      };
    case events.GET_PHONE_NUM:
      return {
        ...state,
        allPhoneNums: action.data,
      };
    case events.CALL_LOGS:
      return {
        ...state,
        callLogs: action.data,
      };
    case events.FROM_NUMBER:
      return {
        ...state,
      };
    case events.PRIORITY:
      return {
        ...state,
        priority: action.data,
      };
    case events.SEND_RESPONSE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
