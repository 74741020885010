import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchAddInActiveIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-282 -612)">
        <Path
          data-name="Rectangle 391"
          transform="translate(282 612)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10348"
          d="M4-18.8h-8a5.414 5.414 0 00-2.127.408 4.573 4.573 0 00-1.622 1.106 4.982 4.982 0 00-1.042 1.708 5.967 5.967 0 00-.387 2.191v7.52a5.967 5.967 0 00.387 2.191 4.982 4.982 0 001.046 1.71A4.573 4.573 0 00-6.123-.859 5.414 5.414 0 00-4-.451h8a5.414 5.414 0 002.123-.408 4.573 4.573 0 001.622-1.107 4.982 4.982 0 001.042-1.708 5.967 5.967 0 00.387-2.191v-7.52a5.967 5.967 0 00-.387-2.191 4.982 4.982 0 00-1.042-1.708 4.573 4.573 0 00-1.622-1.106A5.414 5.414 0 004-18.8zm-8 1.29h8a3.692 3.692 0 012.836 1.128 4.216 4.216 0 011.053 3v7.52a4.216 4.216 0 01-1.053 3A3.692 3.692 0 014-1.74h-8a3.692 3.692 0 01-2.832-1.128 4.216 4.216 0 01-1.053-3v-7.52a4.216 4.216 0 011.053-3A3.692 3.692 0 01-4-17.51zm4 4.1a.615.615 0 01.419.161.584.584 0 01.2.4l.021.086v2.492h2.497a.6.6 0 01.44.183.626.626 0 01.183.462.615.615 0 01-.161.419.567.567 0 01-.376.2H.645v2.497a.618.618 0 01-.193.451.618.618 0 01-.452.194.615.615 0 01-.419-.161.584.584 0 01-.2-.4l-.026-.084V-9h-2.492a.6.6 0 01-.44-.183.6.6 0 01-.183-.44.6.6 0 01.161-.43.694.694 0 01.376-.215h2.578v-2.492a.618.618 0 01.193-.451.618.618 0 01.452-.195z"
          transform="translate(294 634)"
          fill="#c1c1c1"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchAddInActiveIcon;
