import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchInviteIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Invite_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Path_10307"
        data-name="Path 10307"
        d="M14.8-7.559l.387.527a.262.262,0,0,1,.088.229.4.4,0,0,1-.123.229q-.773.563-2.057,1.564T11.742-3.937q-.527.422-1.23.9A2.651,2.651,0,0,1,9-2.566a2.571,2.571,0,0,1-1.494-.475q-.686-.475-1.248-.9-.07-.07-1.318-1.037T2.953-6.5a.4.4,0,0,1-.123-.229.318.318,0,0,1,.053-.229l.387-.527a.281.281,0,0,1,.211-.123.351.351,0,0,1,.246.053q.492.422,1.248.984T6.785-5.168q.387.281,1.037.791A1.99,1.99,0,0,0,9-3.867a1.99,1.99,0,0,0,1.178-.51q.65-.51,1.037-.791l1.863-1.441q.773-.6,1.266-.984a.283.283,0,0,1,.246-.088A.281.281,0,0,1,14.8-7.559ZM16.7-.316V-9.633q-.738-.7-1.371-1.23T11.215-14.1q-.387-.281-1.037-.809A1.956,1.956,0,0,0,9-15.434a1.956,1.956,0,0,0-1.178.527q-.65.527-1.037.809Q3.3-11.391,2.672-10.863T1.3-9.633V-.316a.33.33,0,0,0,.088.211A.283.283,0,0,0,1.617,0H16.383a.309.309,0,0,0,.229-.088A.309.309,0,0,0,16.7-.316ZM18-9.633V-.316A1.558,1.558,0,0,1,17.525.826a1.558,1.558,0,0,1-1.143.475H1.617A1.558,1.558,0,0,1,.475.826,1.558,1.558,0,0,1,0-.316V-9.633a1.324,1.324,0,0,1,.105-.527,1.114,1.114,0,0,1,.316-.422,38.742,38.742,0,0,1,3.164-2.654Q5.2-14.449,6.258-15.328q.527-.422,1.23-.9A2.651,2.651,0,0,1,9-16.7a2.651,2.651,0,0,1,1.512.475q.7.475,1.23.9.984.809,2.654,2.074a41.237,41.237,0,0,1,3.182,2.672,1.114,1.114,0,0,1,.316.422A1.324,1.324,0,0,1,18-9.633Z"
        transform="translate(29 56)"
        fill="#212121"
      />
    </G>
  </Svg>
);
export default LeadsWatchInviteIcon;
