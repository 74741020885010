import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchInfoIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <Path data-name="Rectangle 37" fill="none" d="M0 0H24V24H0z" />
      <Path
        data-name="Path 10319"
        d="M0-1.667A8.159 8.159 0 01-4.2-2.8a8.436 8.436 0 01-3-3A8.159 8.159 0 01-8.333-10 8.159 8.159 0 01-7.2-14.2a8.436 8.436 0 013-3A8.159 8.159 0 010-18.333 8.159 8.159 0 014.2-17.2a8.436 8.436 0 013 3A8.159 8.159 0 018.333-10 8.159 8.159 0 017.2-5.8a8.436 8.436 0 01-3 3A8.159 8.159 0 010-1.667zm0-1.666a6.5 6.5 0 003.367-.917A6.622 6.622 0 005.75-6.633 6.5 6.5 0 006.667-10a6.5 6.5 0 00-.917-3.367 6.622 6.622 0 00-2.383-2.383A6.5 6.5 0 000-16.667a6.5 6.5 0 00-3.367.917 6.622 6.622 0 00-2.383 2.383A6.5 6.5 0 00-6.667-10a6.5 6.5 0 00.917 3.367A6.622 6.622 0 00-3.367-4.25 6.5 6.5 0 000-3.333zm-.833-10.834H.833v1.667H-.833zm0 3.333H.833v5H-.833z"
        transform="translate(12 22)"
        fill={props.color || "#212121"}
      />
    </Svg>
  );
}

export default LeadsWatchInfoIcon;
