import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchOperationsIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Operations_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(36)}
        height={ah(36)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <Path
        id="Path_10290"
        data-name="Path 10290"
        d="M-4.37-20.825a5.706,5.706,0,0,0-2.344.488,6.346,6.346,0,0,0-1.929,1.318,5.874,5.874,0,0,0-1.294,1.978,5.935,5.935,0,0,0-.488,2.393v7.422a5.935,5.935,0,0,0,.488,2.393A5.874,5.874,0,0,0-8.643-2.856,6.346,6.346,0,0,0-6.714-1.538,5.706,5.706,0,0,0-4.37-1.05H4.37a5.706,5.706,0,0,0,2.344-.488A6.346,6.346,0,0,0,8.643-2.856,5.874,5.874,0,0,0,9.937-4.834a5.935,5.935,0,0,0,.488-2.393v-7.422a5.935,5.935,0,0,0-.488-2.393,5.874,5.874,0,0,0-1.294-1.978,6.346,6.346,0,0,0-1.929-1.318,5.706,5.706,0,0,0-2.344-.488ZM8.911-14.648v.024H5.371a3.3,3.3,0,0,0-2.319,1.1A3.465,3.465,0,0,0,2.1-11.084,3.481,3.481,0,0,0,3.113-8.557,3.3,3.3,0,0,0,5.566-7.52H8.911v.293A4.514,4.514,0,0,1,7.581-3.943,4.336,4.336,0,0,1,4.37-2.588H-4.37a4.336,4.336,0,0,1-3.21-1.355A4.514,4.514,0,0,1-8.911-7.227v-7.422a4.514,4.514,0,0,1,1.331-3.284,4.336,4.336,0,0,1,3.21-1.355H4.37a4.336,4.336,0,0,1,3.21,1.355A4.514,4.514,0,0,1,8.911-14.648ZM5.566-13.086H8.911v4.028H5.566L5.42-9.082A1.842,1.842,0,0,1,4.138-9.7a1.972,1.972,0,0,1-.525-1.379A1.95,1.95,0,0,1,4.187-12.5,1.861,1.861,0,0,1,5.566-13.086Zm1.221,1.953a.777.777,0,0,1-.183.513.667.667,0,0,1-.476.244l-.1.024H5.713a.712.712,0,0,1-.537-.232.772.772,0,0,1-.22-.549.748.748,0,0,1,.2-.513.8.8,0,0,1,.464-.269H6.03a.712.712,0,0,1,.537.232A.772.772,0,0,1,6.787-11.133ZM1.123-15.381a.777.777,0,0,1-.183.513.762.762,0,0,1-.476.269H-5.078a.712.712,0,0,1-.537-.232.772.772,0,0,1-.22-.549.777.777,0,0,1,.183-.513.667.667,0,0,1,.476-.244H.366a.731.731,0,0,1,.537.22A.731.731,0,0,1,1.123-15.381Z"
        transform="translate(1053 46)"
        fill={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </G>
  </Svg>
);
export default LeadsWatchOperationsIcon;
