import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchPublishersIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Publishers_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(36)}
        height={ah(36)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <Path
        id="Path_10287"
        data-name="Path 10287"
        d="M10.107-18.652a1.9,1.9,0,0,0-.586-1.892,1.87,1.87,0,0,0-1.929-.476l-16.455,4.81a1.893,1.893,0,0,0-1.282,1.685,1.847,1.847,0,0,0,.94,1.9l6.738,4.15L1.611-1.758l.073.146A1.9,1.9,0,0,0,3.65-.806,1.821,1.821,0,0,0,5.225-2.222l4.858-16.284Zm-2-.879a.418.418,0,0,1,.378.159.426.426,0,0,1,.085.427L3.735-2.661l-.024.073a.458.458,0,0,1-.378.244.4.4,0,0,1-.4-.22L-.9-8.911l5.469-5.518.073-.1a.757.757,0,0,0,.159-.513.666.666,0,0,0-.232-.488l-.1-.073a.757.757,0,0,0-.513-.159.666.666,0,0,0-.488.232L-2-10.034l-6.372-3.931-.073-.049a.4.4,0,0,1-.134-.427.422.422,0,0,1,.305-.33Z"
        transform="translate(1053 46)"
        fill={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </G>
  </Svg>
);
export default LeadsWatchPublishersIcon;
