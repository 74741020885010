import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchActiveIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Leads_active_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(50)}
        height={ah(50)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <Path
        id="Path_10286"
        data-name="Path 10286"
        d="M-7.992-19.7H7.992a1.979,1.979,0,0,1,1.482.623,2.087,2.087,0,0,1,.6,1.5v1.547a2.181,2.181,0,0,1-.161.827,2.268,2.268,0,0,1-.44.7L2.643-7.584v5.221a.68.68,0,0,1-.086.344.808.808,0,0,1-.236.258l-.086.043L-2.234.387A.674.674,0,0,1-2.922.354a.658.658,0,0,1-.322-.591V-7.605l-6.273-6.939a1.863,1.863,0,0,1-.4-.58,2.012,2.012,0,0,1-.161-.687v-1.762a2.087,2.087,0,0,1,.6-1.5A1.979,1.979,0,0,1-7.992-19.7ZM7.992-18.3H-7.992a.645.645,0,0,0-.483.215.722.722,0,0,0-.2.516v1.59a.747.747,0,0,0,.054.269.637.637,0,0,0,.14.226L-2.02-8.357a1.069,1.069,0,0,1,.14.226.59.59,0,0,1,.054.247v6.531L1.246-2.793V-7.885A.744.744,0,0,1,1.278-8.1a.613.613,0,0,1,.1-.193l.064-.086L8.465-15.49a.7.7,0,0,0,.161-.247.8.8,0,0,0,.054-.29v-1.547a.722.722,0,0,0-.2-.516A.645.645,0,0,0,7.992-18.3Z"
        transform="translate(1053 45)"
        fill={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </G>
  </Svg>
);
export default LeadsWatchActiveIcon;
