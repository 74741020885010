import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchVerticalsIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Vertical_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(36)}
        height={ah(36)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <Path
        id="Path_10289"
        data-name="Path 10289"
        d="M-10-14.5l10-5,10,5L0-9.5ZM0-2.266l8.125-4.1L10-5.43,0-.391-10-5.43l1.875-.937ZM0-6.82l8.125-4.062L10-9.945l-10,5-10-5,1.875-.937ZM6.289-14.5,0-17.625-6.289-14.5,0-11.375Z"
        transform="translate(1053 44)"
        fill={
          props.disabled
            ? "#C1C1C1"
            : props.hover === "true"
            ? "#2230F8"
            : "#212121"
        }
      />
    </G>
  </Svg>
);
export default LeadsWatchVerticalsIcon;
