import * as React from "react";
import Svg, { G, Path, Rect } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchReportsIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G
      id="Group_1082"
      data-name="Group 1082"
      transform="translate(-8952 -4731)"
    >
      <G id="Layer_1" data-name="Layer 1" transform="translate(8956 4733)">
        <G id="document">
          <G id="Group_1081" data-name="Group 1081">
            <Path
              id="Path_10349"
              data-name="Path 10349"
              d="M2.487,1.129h8.3a1.559,1.559,0,0,1,.532.128,1.428,1.428,0,0,1,.459.3L15,4.782a1.34,1.34,0,0,1,.395.955V17.5a1.351,1.351,0,0,1-1.349,1.349H2.487A1.351,1.351,0,0,1,1.138,17.5V2.487A1.351,1.351,0,0,1,2.487,1.138m0-1.129A2.47,2.47,0,0,0,0,2.487V17.513A2.484,2.484,0,0,0,2.487,20H14.034a2.483,2.483,0,0,0,2.487-2.487V5.746A2.525,2.525,0,0,0,15.8,3.983L12.565.753a2.818,2.818,0,0,0-.8-.542A2.914,2.914,0,0,0,10.812,0H2.487Z"
              fill={
                props.disabled
                  ? "#C1C1C1"
                  : props.hover === "true"
                  ? "#2230F8"
                  : "#212121"
              }
            />
            <Path
              id="Path_10350"
              data-name="Path 10350"
              d="M14.7,6.466h-.408a4.489,4.489,0,0,1-1.316-.154,3.242,3.242,0,0,1-1.552-1.044,3.307,3.307,0,0,1-.771-1.752,14.2,14.2,0,0,1-.045-1.752V1.22h1.116v.554A15.885,15.885,0,0,0,11.771,3.4a2.169,2.169,0,0,0,.517,1.153,2.315,2.315,0,0,0,1.026.69,3.9,3.9,0,0,0,1.007.109H16.2V6.466H14.712Z"
              transform="translate(-0.795 -0.113)"
              fill={
                props.disabled
                  ? "#C1C1C1"
                  : props.hover === "true"
                  ? "#2230F8"
                  : "#212121"
              }
            />
            <Path
              id="Path_10351"
              data-name="Path 10351"
              d="M9.451,7.245H4.323a.563.563,0,0,1,0-1.125H9.451a.563.563,0,0,1,0,1.125Z"
              transform="translate(-0.285 -0.499)"
              fill={
                props.disabled
                  ? "#C1C1C1"
                  : props.hover === "true"
                  ? "#2230F8"
                  : "#212121"
              }
            />
            <Path
              id="Path_10352"
              data-name="Path 10352"
              d="M12.874,11.4H4.344a.563.563,0,1,1,0-1.125h8.54a.563.563,0,1,1,0,1.125Z"
              transform="translate(-0.348 -0.837)"
              fill={
                props.disabled
                  ? "#C1C1C1"
                  : props.hover === "true"
                  ? "#2230F8"
                  : "#212121"
              }
            />
            <Path
              id="Path_10353"
              data-name="Path 10353"
              d="M12.874,15.565H4.344a.563.563,0,1,1,0-1.125h8.54a.563.563,0,1,1,0,1.125Z"
              transform="translate(-0.348 -1.177)"
              fill={
                props.disabled
                  ? "#C1C1C1"
                  : props.hover === "true"
                  ? "#2230F8"
                  : "#212121"
              }
            />
          </G>
        </G>
      </G>
      <Rect
        id="Rectangle_879"
        data-name="Rectangle 879"
        width={24}
        height={24}
        transform="translate(8952 4731)"
        fill="none"
      />
    </G>
  </Svg>
);
export default LeadsWatchReportsIcon;
