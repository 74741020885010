import React, { useState, useRef, useEffect } from "react";
import {
  Text,
  HStack,
  VStack,
  Divider,
  Pressable,
  ScrollView,
  Menu,
  Checkbox,
  Spinner,
  Center,
  Tooltip,
  useBreakpointValue,
} from "native-base";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { aw, ah } from "../../utils";
import LeadsWatchMenu from "../../components/LeadsWatchMenu";
import LeadsWatchNextIcon from "../../components/svg/LeadsWatchNextIcon";
import LeadsWatchPrevIcon from "../../components/svg/LeadsWatchPrevIcon";
import LeadsWatchMoreIcon from "../../components/svg/LeadsWatchMoreIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
//redux
import {
  createPipeline,
  getAllPipelines,
  deletePipelines,
  movePipelines,
  unassignedLeads,
  addleadstopipelines,
  pipelineleads,
  moveleadsFrompipeline,
} from "../../store/actions/pipelines";
import LeadsWatchActionSheet from "../../components/LeadsWatchActionSheet";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchInfoIcon from "../../components/svg/LeadsWatchInfoIcon";

const Pipelines = ({ isOpen }) => {
  const pipelines = useSelector((state) => state.pipelines.allPipelines);
  const leadContacts = useSelector((state) => state.pipelines.unassignedLeads);
  const leads = useSelector((state) => state.pipelines.pipelineLeads);
  const [unassignedLoading, setunassignedLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectCount, setSelectCount] = useState(10);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [selectCount1, setSelectCount1] = useState(10);
  const [confirmModal1, setConfirmModal1] = useState(false);
  const [deleteLoading1, setDeleteLoading1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [index, setIndex] = useState("");
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openActionSheet, setOpenActionSheet] = useState(false);
  const [selected, setSelected] = useState([]);
  const [unSelected, setUnSelected] = useState([]);
  const [leadsLoading, setLeadsLoading] = useState(true);
  const [moveLeads, setMoveLeads] = useState([]);
  const [openmoveLeadsModal, setOpenmoveLeadsModal] = useState(false);
  const [moveLoading, setMoveLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      select: "10",
      selectcount: "10",
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    getValues: getValues1,
    reset: reset1,
  } = useForm();
  const scrollref = useRef(null);
  const dispatch = useDispatch();
  const [pressed, setPressed] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const scrollLeft = () => {
    scrollref.current.scrollTo({
      x: scrollref.current.contentOffset?.x - 100,
      animated: true,
    });
  };

  const scrollRight = () => {
    if (scrollref.current) {
      scrollref.current.scrollToEnd({ animated: true });
    }
  };
  const addPipeline = async (data) => {
    setLoading(true);
    try {
      await dispatch(createPipeline(data));
      const data1 = await dispatch(getAllPipelines());
      data1 && setPressed(data1.list[data1.list.length - 1]._id);
      scrollRight();
      toast.success("Pipeline Created Successfully");
      setOpenAddModal(false);
      setRefresh(!refresh);
      reset();
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };
  const fetchPipelines = async () => {
    try {
      await dispatch(getAllPipelines());
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    fetchPipelines();
  }, []);
  useEffect(() => {
    if (pipelines) {
      setPressed(`${pipelines.list[0]._id}`);
    }
  }, [pipelines]);
  const handleDelete = async () => {
    if (confirmModal) {
      setDeleteLoading(true);
      const index = pipelines.list.findIndex((val) => val._id === pressed);
      try {
        await dispatch(deletePipelines(pressed));
        const data = await dispatch(getAllPipelines());
        if (data) {
          if (index === pipelines?.total_count - 1) {
            setPressed(pipelines.list[index - 1]._id);
          } else {
            setPressed(pipelines?.list[index + 1]._id);
          }
        } else {
          setPressed(null);
        }

        toast.success("Pipeline Deleted Successfully");
        setConfirmModal(false);
        setUnSelected([]);
      } catch (err) {
        toast.error(err.message);
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
    }
  };
  const handleMove = async (move) => {
    const index = pipelines.list.findIndex((val) => val._id === pressed);
    console.log(index);
    const to =
      move === "left"
        ? pipelines.list[index - 1].order
        : pipelines.list[index + 1].order;
    const from = pipelines.list[index].order;
    try {
      await dispatch(movePipelines({ from, to }));
      await dispatch(getAllPipelines());
      toast.success("Pipeline Moved Successfully");
      setOpenMoveModal(false);
      setIndex("");
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };
  useEffect(() => {
    const fetchunassignedLeads = async () => {
      setunassignedLoading(true);
      try {
        await dispatch(unassignedLeads(currentPage, selectCount, pressed));
      } catch (err) {
        toast.error(err.message);
      }
      setunassignedLoading(false);
    };
    if (pressed) {
      fetchunassignedLeads();
    }
  }, [pressed, currentPage, selectCount]);
  const handleChangeSelect = (value) => {
    setSelectCount(value);
  };

  const handleChangeSelect1 = (value) => {
    setSelectCount1(value);
  };
  const handleClose = async () => {
    setOpenActionSheet(false);
    if (selected.length || unSelected.length) {
      try {
        await dispatch(addleadstopipelines(pressed, selected, unSelected));
        await dispatch(pipelineleads(pressed, currentPage1, selectCount1));
        await dispatch(unassignedLeads(currentPage, selectCount, pressed));
        setSelected([]);
        setUnSelected([]);
      } catch (err) {
        console.log(err);
      }
    }
  };
  useEffect(() => {
    const fetchPipelineLeads = async () => {
      setLeadsLoading(true);
      try {
        await dispatch(pipelineleads(pressed, currentPage1, selectCount1));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
      setLeadsLoading(false);
    };
    if (pressed) {
      fetchPipelineLeads();
    } else {
      setLeadsLoading(false);
    }
  }, [pressed, currentPage1, selectCount1]);
  const handleCheckboxChange = (val) => {
    if (
      selected.includes(val._id) ||
      (val.pipeline === pressed && !unSelected.includes(val._id))
    ) {
      if (val.pipeline === pressed) {
        setUnSelected([...unSelected, val._id]);
      }
      setSelected(selected.filter((v) => v !== val._id));
    } else {
      val.pipeline === pressed
        ? setUnSelected(unSelected.filter((v) => v !== val._id))
        : setSelected([...selected, val._id]);
    }
  };
  const onChangeHeaderCheckbox = () => {
    if (leads) {
      if (leads && leads.list.every((val) => checked.includes(val._id))) {
        const allIds = leads.list.map((val) => val._id);
        setChecked(checked.filter((val) => !allIds.includes(val)));
      } else {
        setChecked([...checked, ...leads.list.map((val) => val._id)]);
      }
    }
  };
  const handleChangeCheckBox = (id) => {
    if (checked.includes(id)) {
      setChecked((old) => old.filter((row) => row !== id));
    } else {
      setChecked((old) => [...old, id]);
    }
  };

  const handleDeleteLead = (id) => {
    if (id) {
      setUnSelected([id]);
      setConfirmModal1(true);
    } else {
      setUnSelected(checked);
      setConfirmModal1(true);
    }
  };
  const confirmDeleteLead = async () => {
    setDeleteLoading1(true);
    try {
      await dispatch(addleadstopipelines(pressed, undefined, unSelected));
      setConfirmModal1(false);
      toast.success("Lead Deleted Successfully");
      await dispatch(pipelineleads(pressed, currentPage1, selectCount1));
      await dispatch(unassignedLeads(currentPage, selectCount, pressed));
      setUnSelected([]);
      setChecked([]);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setDeleteLoading1(false);
  };
  const handleMoveLead = (id) => {
    if (id) {
      setOpenmoveLeadsModal(true);
      setMoveLeads([id]);
    } else {
      setOpenmoveLeadsModal(true);
      setMoveLeads(checked);
    }
  };
  const confirmMoveLead = async (data) => {
    setMoveLoading(true);
    try {
      await dispatch(
        moveleadsFrompipeline(pressed, {
          to_pipeline_id: data.pipeline.value,
          lead_ids: moveLeads,
        })
      );
      await dispatch(pipelineleads(pressed, currentPage1, selectCount1));
      toast.success("Lead Moved Successfully");
      reset1();
      setRefresh(!refresh);
      setOpenmoveLeadsModal(false);
      setMoveLeads([]);
      setChecked([]);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setMoveLoading(false);
  };

  const defaultOptionsForPipelines = pipelines
    ? pipelines.list.reduce((result, element) => {
        if (element._id !== pressed) {
          result.push({ label: element.name, value: element._id });
        }
        return result;
      }, [])
    : [];

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      >
        <VStack
        // width={[aw(350), aw(350), aw(1248)]}
        // left={[aw(0), aw(3), aw(98)]}
        >
          <VStack marginTop={ah(7)} width={"100%"}>
            <HStack
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <HStack lineHeight={ah(34)} space={aw(8)} alignItems={"baseline"}>
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Pipelines
                </Text>
              </HStack>
            </HStack>
            <HStack>
              <HStack
                // justifyContent={"space-between"}
                spcae={aw(30)}
                alignItems={"center"}
                // width={aw(700)}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Text
                  lineHeight={ah(34)}
                  fontSize={aw(14)}
                  fontFamily={"RobotoRegular"}
                  color={"#919191"}
                  fontWeight={400}
                >
                  All your Pipelines list will be shown here
                </Text>

                <HStack
                  space={aw(5)}
                  // bg={"amber.100"}
                  // alignItems={"center"}
                  // height={ah(30)}
                >
                  {pipelines && pipelines.list.length > 8 && (
                    <Pressable
                      onPress={scrollLeft}
                      alignItems={"center"}
                      // borderWidth={aw(1)}
                      borderRadius="full"
                      size={ah(24)}
                      background={"#D7D7D7"}
                    >
                      <LeadsWatchPrevIcon color="Gray 2" />
                    </Pressable>
                  )}

                  <ScrollView
                    // alignItems={"center"}
                    alignContent={"center"}
                    // bg="amber.900"
                    scrollEventThrottle={16}
                    ref={scrollref}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    width={aw(600)}
                    height={"100%"}
                    // bg="black"
                  >
                    <HStack
                      height={"100%"}
                      alignItems={"center"}
                      //   stifyContent={"center"}
                      //   alignItems={"center"}ju
                      space={aw(40)}
                      maxWidth={aw(2000)}
                      px={aw(10)}
                    >
                      {pipelines?.list.map((val) => (
                        <Pressable
                          borderColor={"#0000FF"}
                          height={ah(42)}
                          onPress={() => {
                            setPressed(val._id);
                            setCurrentPage(1);
                            setSelectCount(10);
                            setCurrentPage1(1);
                            setSelectCount1(10);
                          }}
                          key={val._id}
                          borderBottomWidth={pressed === val._id ? aw(3) : 0}
                        >
                          <Text
                            color={pressed === val._id ? "#0000FF" : "#919191"}
                            lineHeight={ah(34)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoRegular"}
                            fontWeight={400}
                          >
                            {val.name}
                          </Text>
                        </Pressable>
                      ))}
                    </HStack>
                  </ScrollView>
                  {pipelines && pipelines.list.length > 8 && (
                    <Pressable
                      onPress={scrollRight}
                      alignItems={"center"}
                      // borderWidth={aw(1)}
                      borderRadius="full"
                      size={ah(24)}
                      background={"#D7D7D7"}
                    >
                      {/* LeadsWatchRightArrow */}
                      <LeadsWatchNextIcon color="Gray 2" />
                    </Pressable>
                  )}
                </HStack>

                <LeadsWatchMenu
                  alignItems={"center"}
                  size={aw(25)}
                  borderWidth={aw(1)}
                  borderRadius={"full"}
                  borderColor="#D7D7D7"
                  title={<LeadsWatchMoreIcon />}
                  width={aw(150)}
                  mr={aw(10)}
                >
                  <Menu.Item onPress={() => setOpenAddModal(true)}>
                    Add
                  </Menu.Item>
                  <Menu.Item onPress={handleDelete}>Delete</Menu.Item>
                  <Menu.Item
                    onPress={() => {
                      const index1 = pipelines.list.findIndex(
                        (val) => val._id === pressed
                      );
                      if (index1 === 0) {
                        setIndex("disableLeft");
                        setOpenMoveModal(true);
                      } else if (index1 === pipelines.total_count - 1) {
                        setIndex("disableRight");
                        setOpenMoveModal(true);
                      } else {
                        setOpenMoveModal(true);
                      }
                    }}
                  >
                    Move
                  </Menu.Item>
                </LeadsWatchMenu>
              </HStack>
            </HStack>
            <Divider bg="#E6E7E9" height={ah(1)} mb={ah(10)} />

            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"selectcount"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect1(parseInt(value))
                    }
                    width={75}
                    height={ah(32)}
                  />
                </Center>
              </HStack>

              <HStack space={aw(10)}>
                <LeadsWatchButton
                  isDisabled={checked.length === 0}
                  label="delete"
                  style={{ height: ah(32), width: aw(80) }}
                  bg="#2230F8"
                  color="#FFFFFF"
                  borderColor="#05BBC9"
                  // leftIcon={<LeadsWatchDeleteIcon color={"#FFFFFF"} />}
                  onPress={() => handleDeleteLead()}
                />
                <LeadsWatchButton
                  isDisabled={checked.length === 0}
                  label="Move"
                  style={{ height: ah(32), width: aw(80) }}
                  bg="#2230F8"
                  color="#FFFFFF"
                  borderColor="#05BBC9"
                  onPress={() => handleMoveLead()}
                  // leftIcon={<LeadsWatchMoveIcon color={"#FFFFFF"} />}
                />
              </HStack>
            </HStack>
            <VStack mt={3}>
              <LeadsWatchTable
                isOpen={isOpen}
                selectedRows={checked}
                handleDelete={handleDeleteLead}
                onChangeHeaderCheckbox={onChangeHeaderCheckbox}
                handleChangeCheckBox={handleChangeCheckBox}
                handleMove={handleMoveLead}
                screen="pipelines"
                // sort={sort}
                // checkbox={true}
                heading={["Email", "Phone", "Add Notes", "Actions"]}
                data={leads ? leads.list : []}
                // filter={openFilter}
                isLoading={leadsLoading}
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={leads ? leads.total_count : 1}
                currentPage={currentPage1}
                pageSize={selectCount1}
                onPageChange={(currentPage) => {
                  setCurrentPage1(currentPage);
                }}
              />
            </VStack>
          </VStack>
          <Pressable
            position={"absolute"}
            bgColor={"#000FFF"}
            borderRadius={"full"}
            size={aw(40)}
            alignItems={"center"}
            justifyContent={"center"}
            bottom={ah(45)}
            right={aw(1)}
            onPress={() => setOpenActionSheet(true)}
          >
            {/* <Fab
            onPress={() => setOpenActionSheet(true)}
            bgColor={"#000FFF"}
            renderInPortal={false}
            shadow={2}
            size="sm"
            icon={
              <Icon
                color="white"
                as={<LeadsWatchAddIcon />}
                name="plus"
                size={6}
              />
            }
          /> */}
            <LeadsWatchAddIcon />
          </Pressable>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(350)}
        height={ah(300)}
        showModal={openAddModal}
        onClose={() => {
          setOpenAddModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Add Pipeline
          </Text>
        }
      >
        <VStack px={aw(5)} space={aw(20)}>
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("name") && getValues("name").lengtg > 0
                ? "edit"
                : "create"
            }
            placeholder="Enter Pipeline Name"
            control={control}
            name="name"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.name ? true : false}
            error={errors.name ? errors.name.message : ""}
          />
          <HStack justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              isLoading={loading}
              onPress={handleSubmit(addPipeline)}
              label="Add"
              style={{ height: ah(32), width: aw(50) }}
              bg="#0000FF"
              color="#fff"
            />
            <LeadsWatchButton
              style={{ height: ah(32) }}
              label="Cancel"
              onPress={() => {
                setOpenAddModal(false);
              }}
            />
          </HStack>

          <LeadsWatchActionSheet isOpen={openActionSheet} onClose={handleClose}>
            <VStack width="100%" alignItems={"center"} mb={ah(20)}>
              <Text fontSize={aw(15)} fontFamily={"RobotoMedium"}>
                Add Contacts
              </Text>
              <Text fontSize={aw(14)} fontFamily={"RobotoMedium"}>
                {pipelines &&
                  pressed &&
                  pipelines.list[
                    pipelines.list.findIndex((val) => val._id === pressed)
                  ].name}
              </Text>
            </VStack>
            <ScrollView maxHeight={ah(360)} width="100%">
              {!unassignedLoading ? (
                leadContacts ? (
                  leadContacts.list.map((val) => (
                    <VStack space={ah(10)} key={val._id} px={aw(5)}>
                      <Checkbox
                        isChecked={
                          (val.pipeline &&
                            val.pipeline === pressed &&
                            !unSelected.includes(val._id)) ||
                          selected.includes(val._id)
                            ? true
                            : false
                        }
                        onChange={() => handleCheckboxChange(val)}
                        key={val._id}
                        accessibilityLabel={val._id}
                        style={{ height: aw(16), width: aw(16) }}
                        bgColor="#FAFBFD"
                        color="#FAFBFD"
                        _checked={{
                          bgColor: "#0000FF",
                          borderColor: "#0000FF",
                        }}
                        _icon={{ color: "#FAFBFD" }}
                        _hover={{ color: "#FAFBFD" }}
                      >
                        <VStack>
                          <HStack>
                            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"}>
                              {" "}
                              Email :{" "}
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              color={"#919191"}
                            >
                              {val.Email}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"}>
                              {" "}
                              Phone :{" "}
                            </Text>
                            <Text
                              fontSize={aw(14)}
                              fontFamily={"RobotoMedium"}
                              color={"#919191"}
                            >
                              {val.Phone}
                            </Text>
                          </HStack>
                        </VStack>
                      </Checkbox>

                      <Divider height={aw(1)} mb={ah(10)} />
                    </VStack>
                  ))
                ) : (
                  <HStack
                    width="100%"
                    height={ah(300)}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text fontSize={aw(14)} fontFamily={"RobotoMedium"}>
                      No Leads
                    </Text>
                  </HStack>
                )
              ) : (
                <HStack
                  width="100%"
                  height={ah(300)}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </ScrollView>
            {leadContacts && leadContacts.total_count > selectCount && (
              <HStack
                // bg="black"
                // px={5}
                width="98%"
                height={ah(50)}
                bottom={aw(0)}
                position="fixed"
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{
                  shadowColor: "#0000001A",
                  shadowOffset: { width: "0px", height: "2px" },
                  shadowRadius: "10px",
                }}
              >
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  // value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={75}
                  height={ah(32)}
                />

                <LeadsWatchPagination
                  type="box"
                  totalCount={leadContacts?.total_count}
                  currentPage={currentPage}
                  pageSize={selectCount}
                  onPageChange={(currentPage) => {
                    setCurrentPage(currentPage);
                  }}
                />
              </HStack>
            )}
          </LeadsWatchActionSheet>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
          setUnSelected([]);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Pipeline?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => {
                setConfirmModal(false);
                setUnSelected([]);
              }}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete()}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(350)}
        height={ah(200)}
        showModal={openMoveModal}
        onClose={() => {
          setOpenMoveModal(false);
          setIndex("");
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Move Pipeline
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Move this Pipeline?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => {
                setOpenMoveModal(false);
                setIndex("");
              }}
              label="cancel"
              style={{
                width: aw(60),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              onPress={() => handleMove("left")}
              isDisabled={index === "disableLeft" ? true : false}
              label="Left"
              style={{
                width: aw(60),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
            <LeadsWatchButton
              onPress={() => handleMove("right")}
              isDisabled={index === "disableRight" ? true : false}
              label="Right"
              style={{
                width: aw(60),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal1}
        onClose={() => {
          setConfirmModal1(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this{" "}
            {checked.length ? "Leads" : "Lead"} from{" "}
            {pipelines &&
              pressed &&
              pipelines.list[
                pipelines.list.findIndex((val) => val._id === pressed)
              ].name}
            ?
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal1(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading1}
              onPress={confirmDeleteLead}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(400)}
        showModal={openmoveLeadsModal}
        onClose={() => {
          setOpenmoveLeadsModal(false);
          reset1();
          setRefresh(!refresh);
        }}
        heading={
          <>
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              Move {checked.length ? "Leads" : "Lead"}
            </Text>
            <Tooltip
              width={aw(170)}
              label="Move Lead based on the progress of status of Lead"
              placement={"bottom"}
              openDelay={200}
            >
              <HStack>
                <LeadsWatchInfoIcon color="#919191" />
              </HStack>
            </Tooltip>
          </>
        }
      >
        <VStack>
          <LeadsWatchAsyncSelect
            isMulti={false}
            refresh={refresh}
            screen={
              getValues1("pipeline") && getValues1("pipeline").value
                ? "edit"
                : "create"
            }
            minWidth={aw(250)}
            defaultOptions={defaultOptionsForPipelines}
            control={control1}
            placeholder="Select Pipeline"
            name="pipeline"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors1.pipeline ? true : false}
            error={errors1.pipeline ? errors1.pipeline.message : ""}
          />
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => {
                setOpenmoveLeadsModal(false);
                reset1();
                setRefresh(!refresh);
              }}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={moveLoading}
              onPress={handleSubmit1(confirmMoveLead)}
              label="Move"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default Pipelines;
