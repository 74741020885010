// import React from "react";
// import {
//   HStack,
//   Text,
//   Pressable,
//   Box,
//   VStack,
//   Button,
//   Input,
// } from "native-base";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import LeadsWatchCalendarIcon from "./svg/LeadsWatchCalendarIcon";
// import { aw, ah } from "../utils";

// const LeadsWatchCalendar = (props) => {
//   const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
//     <Pressable
//       ref={ref}
//       onPress={onClick}
//       style={{
//         borderWidth: aw(1),
//         borderColor: "#D1D5DB",
//         borderRadius: aw(5),
//       }}
//       placeholder="From"
//     >
//       <HStack
//         justifyContent={"flex-start"}
//         alignItems={"center"}
//         pl={2}
//         width={props.width || aw(141)}
//         height={props.height || ah(32)}
//         space={aw(5)}
//       >
//         <LeadsWatchCalendarIcon />
//         <Text color="#919191" fontSize={aw(12)}>
//           {/* {props.startDate !== ""
//                   ? props.startDate.toLocaleDateString()
//                   : "From"} */}
//           {value === "" ? "From" : value}
//         </Text>
//       </HStack>
//     </Pressable>
//   ));

//   const ExampleCustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
//     <Pressable
//       ref={ref}
//       onPress={onClick}
//       style={{
//         // border: "1px solid #D1D5DB"
//         borderWidth: aw(1),
//         borderColor: "#D1D5DB",
//       }}
//       rounded={props.rounded || aw(5)}
//       placeholder="From"
//     >
//       <HStack
//         justifyContent={"flex-start"}
//         alignItems={"center"}
//         pl={2}
//         width={props.width || aw(141)}
//         height={props.height || ah(32)}
//         space={aw(5)}
//       >
//         <LeadsWatchCalendarIcon />
//         <Text color="#919191" fontSize={aw(12)}>
//           {value === "" ? "To" : value}
//         </Text>
//       </HStack>
//     </Pressable>
//   ));
//   return (
//     <>
//       <DatePicker
//         selected={props.startDate}
//         onChange={(date) => props.handleFromDate(date)}
//         customInput={<ExampleCustomInput />}
//       />
//       <HStack
//         mx={aw(7)}
//         alignSelf={"center"}
//         width={aw(4)}
//         height={ah(2)}
//         style={{
//           background: "#212121 0% 0% no-repeat padding-box",
//         }}
//       ></HStack>

//       <DatePicker
//         startDate={props.startDate}
//         endDate={props.endDate}
//         selectsEnd
//         selected={props.endDate}
//         onChange={(date) => props.handleEndDate(date)}
//         customInput={<ExampleCustomInput1 />}
//         minDate={props.startDate}
//       />
//     </>
//   );
// };
// export default LeadsWatchCalendar;

import React from "react";
import { HStack, Text, Pressable } from "native-base";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LeadsWatchCalendarIcon from "./svg/LeadsWatchCalendarIcon";
import { aw, ah } from "../utils";

const LeadsWatchCalendar = (props) => {
  const date = new Date();

  const range = (start, end) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };
  const years = range(2020, date.getFullYear() + 10);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <Pressable
      ref={ref}
      onPress={onClick}
      style={{
        borderWidth: props.borderWidth || aw(1),
        borderColor: props.borderColor || "#D1D5DB",
        borderRadius: 4,
      }}
      placeholder="From"
    >
      <HStack
        justifyContent={"flex-start"}
        alignItems={"center"}
        pl={2}
        width={props.width || aw(141)}
        height={props.height || ah(32)}
        space={aw(5)}
      >
        <LeadsWatchCalendarIcon />

        <Text
          color={value === "" ? "#919191" : "#212121"}
          fontSize={aw(14)}
          fontFamily={"RobotoRegular"}
        >
          {/* {props.startDate !== ""
                      ? props.startDate.toLocaleDateString()
                      : "From"} */}
          {value === "" ? "From" : value}
        </Text>
      </HStack>
    </Pressable>
  ));

  const ExampleCustomInput1 = React.forwardRef(({ value, onClick }, ref) => (
    <Pressable
      ref={ref}
      onPress={onClick}
      style={{
        // border: "1px solid #D1D5DB"
        borderWidth: props.borderWidth || aw(1),
        borderColor: props.borderColor || "#D1D5DB",
        borderRadius: 4,
      }}
      rounded={props.rounded || aw(5)}
      placeholder="From"
    >
      <HStack
        justifyContent={"flex-start"}
        alignItems={"center"}
        pl={2}
        width={props.width || aw(141)}
        height={props.height || ah(32)}
        space={aw(5)}
      >
        <LeadsWatchCalendarIcon />
        <Text
          color={value === "" ? "#919191" : "#212121"}
          fontSize={aw(14)}
          fontFamily={"RobotoRegular"}
        >
          {value === "" ? "To" : value}
        </Text>
      </HStack>
    </Pressable>
  ));
  return (
    <>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        showIcon
        placeholderText="From"
        // showIcon={<LeadsWatchCalendarIcon />}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date.getFullYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        selected={props.startDate}
        onChange={(date) => props.handleFromDate(date)}
        // className="red-border"
        customInput={<ExampleCustomInput />}
      />
      <HStack
        mx={aw(7)}
        alignSelf={"center"}
        width={aw(4)}
        height={ah(2)}
        style={{
          background: "#212121 0% 0% no-repeat padding-box",
        }}
      ></HStack>

      <DatePicker
        dateFormat="yyyy/MM/dd"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              {"<"}
            </button>
            <select
              value={date.getFullYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              {">"}
            </button>
          </div>
        )}
        startDate={props.startDate}
        // maxDate={props.startDate&&new Date(props.startDate.setDate(new Date().getDate() + 30))}
        maxDate={
          props.maxDate &&
          props.startDate &&
          new Date(props.startDate).setDate(
            new Date(props.startDate).getDate() + 30
          )
        }
        endDate={props.endDate}
        selectsEnd
        selected={props.endDate}
        onChange={(date) => props.handleEndDate(date)}
        customInput={<ExampleCustomInput1 />}
        minDate={props.startDate}
      />
    </>
  );
};
export default LeadsWatchCalendar;
