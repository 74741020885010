import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchCrossIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(10)}
      height={ah(10.003)}
      viewBox="0 0 16 16.003"
      {...props}
    >
      <Path
        d="M15.845.16a.587.587 0 00-.8 0L8 7.2.96.16a.587.587 0 00-.8 0 .587.587 0 000 .8L7.2 8 .16 15.045a.587.587 0 000 .8.542.542 0 00.4.16.585.585 0 00.4-.16L8 8.8l7.042 7.042a.542.542 0 00.4.16.56.56 0 00.4-.96L8.8 8 15.845.96a.587.587 0 000-.8z"
        transform="translate(-.002 -.003)"
          fill={props.color || "#212121"}
      />
    </Svg>
  );
}

export default LeadsWatchCrossIcon;
