import { React, useEffect, useState, useRef } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Avatar,
  Stack,
  Pressable,
  Hidden,
  useBreakpointValue,
} from "native-base";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
// import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/material.css";
import { useNavigate } from "react-router-dom";
import { aw, ah } from "../../utils";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";

import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchInviteIcon from "../../components/svg/LeadsWatchInviteIcon";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchModal from "../../components/LeadsWatchModal";

import {
  getAllPublishers,
  addPublisher,
  getSinglePublisher,
  editPublisher,
  invitePublishers,
  deletePublisher,
} from "../../store/actions/publishers";

import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchProfileIcon from "../../components/svg/LeadsWatchProfileIcon";

// import * as ImagePicker from "expo-image-picker";

const Publishers = ({ isOpen }) => {
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));

  const profile = useSelector((state) => state.auth.profile);
  const [refresh, setRefresh] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState(null);
  const [clearSearch, setClearSearch] = useState(false);
  const hiddenFileInput = useRef(null);
  const [sort, setSort] = useState({});
  const [currentPage, setCurrentPage] = useState(
    page && page && page.publishers ? parseInt(page.publishers) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.publishers ? parseInt(pagecount.publishers) : 10
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [loadInvite, setLoadInvite] = useState(false);
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [edit, setEdit] = useState("");
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const allPublishers = useSelector((state) => state.publishers.allPublishers);
  const singlePublisher = useSelector(
    (state) => state.publishers.singlePublisher
  );
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,

    getValues,
  } = useForm({
    mode: "all",
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    getValues: getValues1,
    reset: reset1,
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.publishers
          ? pagecount.publishers.toString()
          : "10",
    },
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, publishers: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, publishers: 1 }));
  };
  const fetchPublishers = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllPublishers(currentPage, selectCount, sort, filters, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["publisher"] &&
        profile.permission_ids["publisher"].includes("view")
      ) {
        fetchPublishers();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchPublishers();
    }
  }, [currentPage, selectCount, sort, filters, search]);
  const handleInvite = async (data) => {
    setLoadInvite(true);
    try {
      await dispatch(invitePublishers(data.inviteEmail));

      setIsInviteModalOpen(false);

      toast.success("Invitation Sent Successfully");
      reset1();
      setRefresh(!refresh);
    } catch (err) {
      // setIsModalOpen(false);
      toast.error(err.message.toLowerCase());
    }
    setLoadInvite(false);
  };
  const createPublishers = async (data) => {
    let j = 0;
    const number = data.phone;
    let format = phoneNo && phoneNo.format.toString();
    for (let i = 0; i <= format.length - 1; i++) {
      if (format[i] === ".") {
        format = format.replace(".", number[j]);
        j++;
      }
    }
    let formData = new FormData();
    formData.append("firstname", data.firstname);
    formData.append("lastname", data.lastname);
    formData.append("company", data.company);
    formData.append("email", data.email);
    formData.append("phone", format);
    formData.append("image_file", image);
    formData.append("status", data.status);

    // dispatch(addPublisher(data));
    setIsSave(true);
    if (edit) {
      try {
        await dispatch(editPublisher(edit.id, formData));
        // setCurrentPage(1);
        // fetchPublishers();

        setIsModalOpen(false);

        toast.success("Publisher Edited Succesfully");
        await dispatch(
          getAllPublishers(currentPage, selectCount, sort, filters, search)
        );
      } catch (err) {
        setIsModalOpen(false);
        toast.error(err.message.toLowerCase());
      }
      setIsModalOpen(false);
      setEdit("");
      // alert("edit");
      setImage(null);
      setImage1(null);
    } else {
      try {
        await dispatch(addPublisher(formData));
        setCurrentPage(1);
        localStorage.setItem(
          "page",
          JSON.stringify({ ...page, publishers: 1 })
        );
        await dispatch(
          getAllPublishers(currentPage, selectCount, undefined, filters)
        );
        setIsModalOpen(false);
        setPhoneNo("in");
        setRefresh(!refresh);
        reset({
          firstname: "",
          lastname: "",
          company: "",
          email: "",
          phone: "91",
          status: "",
          // select: "",
        });
        // clearErrors();
        setImage1(null);
        setImage(null);

        toast.success("Publisher Added Succesfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setIsSave(false);
  };
  const handleView = async (id) => {
    try {
      await dispatch(getSinglePublisher(id));
    } catch (err) {
      alert(err.message);
    }
    setIsViewModalOpen(true);
  };
  const handleEdit = (item) => {
    setEdit({
      firstname: item.firstname,
      lastname: item.lastname,
      company: item.company,
      email: item.email,
      phone: item.phone && item.phone.replace(/[^0-9]/g, ""),
      id: item._id,
      status: item.status,
    });

    setImage1(item.image && item.image !== "" ? item.image : null);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (edit) {
      reset(edit);
    } else {
      setPhoneNo("in");
      reset({
        firstname: "",
        lastname: "",
        company: "",
        email: "",
        phone: "91",
        status: "",
        // select: "",
      });
      // clearErrors();
    }
  }, [edit]);

  const handleSort = (order) => {
    setSort(order);
  };

  const handleChange = (e) => {
    setImage(e.target.files[0]);
    setImage1(URL.createObjectURL(e.target.files[0]));
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deletePublisher(id));
        setDeleteId(null);
        setConfirmModal(false);
        fetchPublishers();
        toast.success("Publisher Deleted Succesfully");
      } catch (err) {
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, publishers: 1 }));
    }
  };

  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
          // width={[aw(350), aw(350), aw(1248)]}
          // left={[aw(0), aw(3), aw(98)]}
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                // width={aw(194)}
                justifyContent="space-between"
                space={aw(8)}
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Publishers
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {allPublishers && allPublishers.total_count
                    ? allPublishers.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(10)}>
                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Company"',
                      'Search by "Email"',
                      'Search by "Phone"',
                      'Search by "Status"',
                    ]}
                    height={ah(36)}
                    control={control2}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset2();
                            setSearch(getValues2("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, publishers: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit2(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit2(
                          handleSearch({ search: getValues2("search") })
                        );
                      }
                    }}
                  />

                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "publisher"
                        ? true
                        : profile && profile.role === "user"
                        ? profile.permission_ids["publisher"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setIsModalOpen(true);
                    }}
                    label="Add New"
                    style={{ width: aw(114), height: ah(36) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Publishers list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(10)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control1}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)}>
                  <LeadsWatchSelect
                    control={control}
                    name={"status1"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    handleChange={(value) =>
                      setFilters({ ...filters, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  />
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["invite"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    leftIcon={<LeadsWatchInviteIcon />}
                    onPress={() => setIsInviteModalOpen(true)}
                    label={"invite"}
                    style={{
                      width: aw(88),
                      height: ah(36),
                      borderColor: "#D1D5DB",
                      borderRadius: aw(6),
                    }}
                    fontWeight="400"
                  />
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack>
              <LeadsWatchTable
                isOpen={isOpen}
                sort={sort}
                screen="publisher"
                handleDelete={handleDelete}
                handleSort={handleSort}
                handleEdit={handleEdit}
                handleView={handleView}
                isLoading={isLoading}
                heading={[
                  "publisher_name",
                  "company",
                  "email",
                  "phone",
                  "Leads Accepted / total",
                  "status",
                  "Actions",
                ]}
                sortIcon={true}
                data={
                  allPublishers && allPublishers.list
                    ? allPublishers.list
                    : null
                }
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  allPublishers && allPublishers.total_count
                    ? allPublishers.total_count
                    : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, publishers: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>

      <LeadsWatchModal
        width={aw(370)}
        height={ah(680)}
        showModal={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEdit("");
          setImage1(null);
          setImage(null);
          // setPhoneNo("in");
          setRefresh(!refresh);
          reset({
            firstname: "",
            lastname: "",
            company: "",
            email: "",
            phone: "91",
            status: "",
            // select: "",
          });
          // clearErrors();
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            {edit ? "Edit Publisher" : "Add Publisher"}
          </Text>
        }
      >
        <VStack alignItems={"center"} space={ah(16)}>
          {image1 ? (
            <Avatar
              height={aw(72)}
              width={aw(72)}
              source={{
                uri: image1,
              }}
            />
          ) : (
            <LeadsWatchProfileIcon height={aw(72)} width={aw(72)} />
          )}
          <VStack alignItems={"center"}>
            <HStack space={1}>
              <VStack>
                <Text
                  // onPress={pickImage}
                  color="#212121"
                  fontFamily="RobotoMedium"
                  fontSize={aw(14)}
                  onPress={handleClick}
                >
                  Click to upload
                </Text>
                <input
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                  type="file"
                  onClick={(e) => (e.target.value = null)}
                  name="picture"
                  accept="image/png, image/jpg,image/jpeg"
                ></input>

                <Divider
                  mt={ah(9)}
                  bg="#E6E7E9"
                  height={ah(1)}
                  // width={[aw(350), aw(350), aw(1248)]}
                />
              </VStack>
              {/* <Text
                color="#212121"
                fontFamily="RobotoRegular"
                fontSize={aw(14)}
              >
                or drag and drop
              </Text> */}
            </HStack>

            <Text color="#919191" fontSize={aw(12)} fontFamily="RobotoRegular">
              Maximum file size 50 MB
            </Text>
          </VStack>
        </VStack>
        <Stack mt={aw(30)} space={aw(15)} alignItems="center">
          <HStack space={ah(20)}>
            <LeadsWatchFilterInput
              refresh={refresh}
              screen={
                getValues("firstname") && getValues("firstname").length > 0
                  ? "edit"
                  : "create"
              }
              hasValue={
                getValues("firstname") && getValues("firstname").length > 0
                  ? false
                  : true
              }
              // style={{ width: aw(155), heigth: ah(40) }}
              width={aw(155)}
              heigth={ah(40)}
              name="firstname"
              placeholder="First Name"
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.firstname ? true : false}
              error={errors.firstname ? errors.firstname.message : ""}
            />
            <LeadsWatchFilterInput
              refresh={refresh}
              screen={
                getValues("lastname") && getValues("lastname").length > 0
                  ? "edit"
                  : "create"
              }
              hasValue={
                getValues("lastname") && getValues("lastname").length > 0
                  ? false
                  : true
              }
              // style={{ width: aw(155), heigth: ah(40) }}
              width={aw(155)}
              heigth={ah(40)}
              name="lastname"
              placeholder="Last Name"
              control={control}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.lastname ? true : false}
              error={errors.lastname ? errors.lastname.message : ""}
            />
          </HStack>
          <HStack zIndex={1}>
            <Controller
              control={control}
              name={"phone"}
              style={{
                width: aw(330),
                heigth: ah(40),
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <PhoneInput
                  className={"phoneInput"}
                  country={"in"}
                  isValid={(inputNumber, country, countries) => {
                    setPhoneNo(country);
                    return !errors.phone;
                  }}
                  placeholder="Enter PhoneNumber"
                  value={value}
                  containerStyle={{
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
                  }}
                  inputStyle={{
                    borderWidth: errors.phone ? 2 : 1,
                    borderRadius: aw(5),
                    width: aw(330),
                    height: ah(10),
                    fontFamily: "RobotoRegular",
                    backgroundColor: "#fff",
                    // borderWidth: 0,
                    fontSize: aw(15),
                    borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                    // borderColor: !errors.phone && "#D1D5DB",
                  }}
                  onChange={(value, data) => {
                    onChange(value);
                    // onChange(value);
                  }}
                  onBlur={() => {
                    onBlur();
                  }}
                  dropdownStyle={{
                    height: ah(150),
                    width: aw(330),
                  }}
                />
              )}
              // rules={{
              //   required: {
              //     value: true,
              //     // validate: (value) =>
              //     //   phoneNo &&
              //     //   phoneNo.format.match(/\./g).length === value.length,
              //     message: "Enter a valid password",
              //     validate: (value) =>
              //       value.length === phoneNo.format.match(/\./g).length,
              //   },
              // }}
              rules={{
                required: {
                  value: true,
                },
                validate: (value) => {
                  return (
                    value && value.length === phoneNo.format.match(/\./g).length
                  );
                },
              }}
              invalid={errors.phone ? true : false}
              // invalid={true}
              error={errors.phone ? errors.phone.message : ""}
            />
          </HStack>
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("company") && getValues("company").length > 0
                ? "edit"
                : "create"
            }
            // hasValue={
            //   getValues("company") && getValues("company").length > 0
            //     ? false
            //     : true
            // }
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            heigth={ah(40)}
            name="company"
            placeholder="Company"
            control={control}
          />
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("email") && getValues("email").length > 0
                ? "edit"
                : "create"
            }
            hasValue={
              getValues("email") && getValues("email").length > 0 ? false : true
            }
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            heigth={ah(40)}
            name="email"
            placeholder="Email"
            control={control}
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors.email ? true : false}
            error={errors.email ? errors.email.message : ""}
          />
          {/* <LeadsWatchFilterInput
            hasValue={
              getValues("phone") && getValues("phone").length > 0 ? false : true
            }
            style={{ width: aw(330), heigth: ah(40) }}
            name="phone"
            placeholder="phone"
            control={control}
          /> */}
          {/* <InputMask mask="99-9999-9999" maskChar={null} /> */}

          {edit ? (
            <HStack>
              <LeadsWatchSelect
                control={control}
                name="status"
                width={aw(330)}
                placeholder="status"
                list={["Active", "InActive"]}
                // value={status}
                // handleChange={(value) => setStatus(value)}
                height={ah(40)}
              />
            </HStack>
          ) : null}
          <HStack space={ah(20)}>
            <LeadsWatchButton
              label="Cancel"
              style={{ width: aw(155), height: ah(40) }}
              borderColor="#D1D5DB"
              onPress={() => {
                setIsModalOpen(false);
                setEdit("");
                setPhoneNo("in");
                reset({
                  firstname: "",
                  lastname: "",
                  company: "",
                  email: "",
                  phone: "91",
                  status: "",
                  // select: "",
                });
                setRefresh(!refresh);
                // clearErrors();
                setImage(null);
                setImage1(null);
              }}
            />
            <LeadsWatchButton
              isLoading={isSave}
              onPress={handleSubmit(createPublishers)}
              label={edit ? "Edit Publisher" : "Add Publisher"}
              style={{ width: aw(155), height: ah(40) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
            />
          </HStack>
        </Stack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(680)}
        width={aw(750)}
        showModal={isViewModalOpen}
        onClose={() => setIsViewModalOpen(false)}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Publisher ID
            </Text>
            <Text
              color={"#212121"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              {singlePublisher && singlePublisher._id}
            </Text>
          </HStack>
        }
      >
        <Text
          fontFamily="RobotoMedium"
          color={"#000000"}
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Main details
        </Text>
        <HStack space={aw(90)}>
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singlePublisher && singlePublisher._id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                color="#919191"
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
              >
                Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(300)}
              >
                :{" "}
                {singlePublisher &&
                  `${singlePublisher.firstname} ${singlePublisher.lastname}`}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                color="#919191"
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
              >
                Phone No
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{singlePublisher && singlePublisher.phone}
              </Text>
            </HStack>
          </VStack>
          <VStack space={ah(15)}>
            <HStack alignItems="center" color="#919191">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
                width={aw(60)}
              >
                Company
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(150)}
              >
                : {singlePublisher && singlePublisher.company}
              </Text>
            </HStack>
            <HStack alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
                width={aw(60)}
              >
                Email
              </Text>
              <HStack alignItems="center">
                <Text
                  width={aw(150)}
                  fontSize={aw(14)}
                  fontFamily="RobotoMedium"
                  color="#212121"
                >
                  : {singlePublisher && singlePublisher.email}
                </Text>
              </HStack>
            </HStack>
            <HStack alignItems="center">
              <Text
                color="#919191"
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                width={aw(60)}
              >
                Status
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(150)}
              >
                : {singlePublisher && singlePublisher.status}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(370)}
        height={ah(555)}
        showModal={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
          setRefresh(!refresh);
          reset1();
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Invite Publisher
          </Text>
        }
      >
        <VStack space={ah(30)} alignItems={"center"}>
          <LeadsWatchFilterInput
            width={aw(330)}
            refresh={refresh}
            hasValue={
              getValues1("inviteEmail") && getValues1("inviteEmail").length > 0
                ? false
                : true
            }
            // style={{ heigth: ah(40) }}
            heigth={ah(40)}
            control={control1}
            name="inviteEmail"
            placeholder="Enter Publisher Email"
            rules={{
              required: {
                value: true,
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
            }}
            invalid={errors1.inviteEmail ? true : false}
            error={errors1.inviteEmail ? errors1.inviteEmail.message : ""}
          />
          <LeadsWatchButton
            isLoading={loadInvite}
            onPress={handleSubmit1(handleInvite)}
            label="Invite"
            style={{ height: ah(40), width: aw(100) }}
            bg="#2230F8"
            borderColor="#D1D5DB"
            color="#FFFFFF"
          />
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this publisher
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default Publishers;
