import React, { useState } from "react";
import {
  Menu,
  Box,
  Pressable,
  HStack,
  Checkbox,
  Text,
  ScrollView,
} from "native-base";
import { aw, ah } from "../utils";
import LeadsWatchDownArrow from "./svg/LeadsWatchDownArrow";
import LeadsWatchUpArrow from "./svg/LeadsWatchUpArrow";
const LeadsWatchCheckboxMenu = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <Box style={{ ...props.boxStyle }}>
      <Menu
        bgColor="white"
        mt={ah(2)}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        closeOnSelect={props.closeOnSelect || false}
        defaultIsOpen={props.defaultIsOpen || false}
        shouldOverlapWithTrigger={props.shouldOverlapWithTrigger || false}
        w={props.width || aw(190)}
        style={{ ...props.menuStyle }}
        trigger={(triggerProps) => {
          return (
            <Pressable
              {...triggerProps}
              style={{ border: "1px solid #D1D5DB" }}
              rounded="5"
              bgColor={"#FFFFFF"}
            >
              <HStack
                width={props.width || aw(190)}
                h={props.height || ah(40)}
                alignItems="center"
                justifyContent={"space-evenly"}
              >
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#919191"
                  bgColor="#FFFFFF"
                >
                  {props.title}
                </Text>
                {open ? <LeadsWatchUpArrow /> : <LeadsWatchDownArrow />}
              </HStack>
            </Pressable>
          );
        }}
      >
        {/* <Checkbox.Group
          colorScheme={"green" || props.color}
          defaultValue={props.groupValue}
          onChange={(value) => props.handleChage(value)}
        > */}
        <ScrollView height={props.sheight}>
          <Checkbox.Group
            accessibilityLabel={"checbox"}
            ml={1}
            colorScheme={"green"}
            defaultValue={props.value}
            onChange={(value) => props.handleChange(value)}
          >
            {props.arr.map((val, index) => (
              <Menu.Item bgColor="white" key={index}>
                <Checkbox
                  accessibilityLabel={index}
                  value={val}
                  bgColor="#FAFBFD"
                  _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
                  _icon={{ color: "#FAFBFD" }}
                  _hover={{ color: "#FAFBFD" }}
                  style={{ height: aw(16), width: aw(16) }}
                >
                  {(val === "Accepted" ||
                    val === "Pending" ||
                    val === "Rejected" ||
                    val === "Trash" ||
                    val === "Duplicate" ||
                    val === "Failure") && (
                    <Box
                      size={aw(10)}
                      borderRadius="full"
                      backgroundColor={
                        val === "Accepted"
                          ? "#1AB173"
                          : val === "Pending"
                          ? "#F9C034"
                          : val === "Rejected"
                          ? "#C81E1E"
                          : val === "Trash"
                          ? "#EB672D"
                          : val === "Duplicate"
                          ? "#3374B4"
                          : val === "Failure"
                          ? "#34476C"
                          : null
                      }
                    ></Box>
                  )}
                  <Text
                    fontSize={aw(13)}
                    color="#121233"
                    fontFamily={"RobotoRegular"}
                  >
                    {val}
                  </Text>
                </Checkbox>
              </Menu.Item>
            ))}
          </Checkbox.Group>
        </ScrollView>
        {/* </Checkbox.Group> */}
      </Menu>
    </Box>
  );
};

export default LeadsWatchCheckboxMenu;
