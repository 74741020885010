import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchMoreIcon = (props) => (
  <Svg
    id="More-icon"
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <Rect
      id="Rectangle_275"
      data-name="Rectangle 275"
      width={aw(24)}
      height={ah(24)}
      fill="none"
    />
    <Path
      id="Path_10219"
      data-name="Path 10219"
      d="M-8.156-9a1.964,1.964,0,0,0,.6,1.441,1.964,1.964,0,0,0,1.441.6,1.964,1.964,0,0,0,1.441-.6A1.964,1.964,0,0,0-4.078-9a1.964,1.964,0,0,0-.6-1.441,1.964,1.964,0,0,0-1.441-.6,1.964,1.964,0,0,0-1.441.6A1.964,1.964,0,0,0-8.156-9Zm6.117,0a1.964,1.964,0,0,0,.6,1.441A1.964,1.964,0,0,0,0-6.961a1.964,1.964,0,0,0,1.441-.6A1.964,1.964,0,0,0,2.039-9a1.964,1.964,0,0,0-.6-1.441A1.964,1.964,0,0,0,0-11.039a1.964,1.964,0,0,0-1.441.6A1.964,1.964,0,0,0-2.039-9ZM4.078-9a1.964,1.964,0,0,0,.6,1.441,1.964,1.964,0,0,0,1.441.6,1.964,1.964,0,0,0,1.441-.6A1.964,1.964,0,0,0,8.156-9a1.964,1.964,0,0,0-.6-1.441,1.964,1.964,0,0,0-1.441-.6,1.964,1.964,0,0,0-1.441.6A1.964,1.964,0,0,0,4.078-9Z"
      transform="translate(12 21)"
      fill={props.fill}
    />
  </Svg>
);
export default LeadsWatchMoreIcon;
