import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

import { aw, ah } from "../../utils";

function LeadsWatchRecordIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-282 -612)">
        <Path
          data-name="Rectangle 391"
          transform="translate(282 612)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10325"
          d="M0-17.5a8.7 8.7 0 013.555.742A8.5 8.5 0 016.484-14.8a8.592 8.592 0 011.953 2.891A8.972 8.972 0 019.18-8.32a8.7 8.7 0 01-.742 3.555 9.942 9.942 0 01-1.953 2.93A9.308 9.308 0 013.555.117 9.113 9.113 0 010 .82a9.113 9.113 0 01-3.555-.7 9.308 9.308 0 01-2.93-1.953 9.942 9.942 0 01-1.953-2.93A8.7 8.7 0 01-9.18-8.32a8.972 8.972 0 01.742-3.594A8.592 8.592 0 01-6.484-14.8a8.5 8.5 0 012.93-1.953A8.7 8.7 0 010-17.5zm0 1.68a7.544 7.544 0 00-2.93.586 7.525 7.525 0 00-2.383 1.6 7.815 7.815 0 00-1.6 2.383A7.544 7.544 0 00-7.5-8.32a7.261 7.261 0 00.586 2.89 7.415 7.415 0 001.6 2.422A7.879 7.879 0 00-2.93-1.445 7.138 7.138 0 000-.82a7.138 7.138 0 002.93-.625 7.879 7.879 0 002.383-1.563 8.346 8.346 0 001.6-2.422A7.261 7.261 0 007.5-8.32a7.544 7.544 0 00-.586-2.93 6.983 6.983 0 00-1.6-2.383 7.525 7.525 0 00-2.383-1.6A7.544 7.544 0 000-15.82zm0 4.14a3.2 3.2 0 012.344.977A3.242 3.242 0 013.32-8.32a3.2 3.2 0 01-.977 2.344A3.2 3.2 0 010-5a3.2 3.2 0 01-2.344-.977A3.2 3.2 0 01-3.32-8.32a3.242 3.242 0 01.976-2.38A3.2 3.2 0 010-11.68z"
          transform="translate(294 632)"
          fill="#212121"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchRecordIcon;
