import * as events from "../Events";
const initialState = {
  allVerticals: null,
  allVerticalFields: null,
  newVertical: null,
  editedVertical: null,
  singleVertical: null,
  newVerticalField: null,
  editedVerticalField: null,
  defaultVerticalFields: null,
};
export const verticalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_VERTICALS:
      return {
        ...state,
        allVerticals: action.data,
      };
    case events.ADD_VERTICAL:
      return {
        ...state,
        newVertical: action.data,
      };
    case events.EDIT_VERTICAL:
      return {
        ...state,
        editedVertical: action.data,
      };
    case events.DELETE_VERTICAL:
      return {
        ...state,
      };
    case events.GET_SINGLE_VERTICAL:
      return {
        ...state,
        singleVertical: action.data,
      };
    case events.ADD_VERTICAL_FIELD:
      return {
        ...state,
        newVerticalField: action.data,
      };
    case events.GET_ALL_VERTICAL_FIELDS:
      return {
        ...state,
        allVerticalFields: action.data,
      };
    case events.EDIT_VERTICAL_FIELD:
      return {
        ...state,
        editedVerticalField: action.data,
      };
    case events.CHOOSE_DEFAULT_FIELD:
      return {
        ...state,
        defaultVerticalFields: action.data,
      };
    case events.CREATE_VERTICAL_FIELD_DEFAULT:
      return {
        ...state,
      };
    case events.DELETE_VERTICAL_FIELD:
      return {
        ...state,
      };
    default:
      return state;
  }
};
