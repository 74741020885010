import * as React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchCallRecieveIcon = (props) => (
  <Svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <Defs>
      <ClipPath id="clip-path">
        <Rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width={aw(24)}
          height={ah(24)}
          fill="none"
        />
      </ClipPath>
    </Defs>
    <G id="Group_2" data-name="Group 2" clipPath="url(#clip-path)">
      <Path
        id="Path_2"
        data-name="Path 2"
        d="M7.21,10.92a13.051,13.051,0,0,0,5.88,5.87l1.96-1.96a.762.762,0,0,1,.92-.21,10.022,10.022,0,0,0,3.17.5.849.849,0,0,1,.6.25.826.826,0,0,1,.27.62v3.12a.928.928,0,0,1-.87.87,14.8,14.8,0,0,1-7.6-2.02A15.052,15.052,0,0,1,4.02,4.86a.832.832,0,0,1,.27-.6.832.832,0,0,1,.6-.27H8.01a.8.8,0,0,1,.62.27.849.849,0,0,1,.25.6,10.068,10.068,0,0,0,.5,3.17.861.861,0,0,1-.21.92L7.21,10.91Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default LeadsWatchCallRecieveIcon;
