import { VStack, Text, HStack, Center } from "native-base";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import { aw, ah } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import { getCallLogs } from "../../store/actions/calls";

const CallLogs = ({ isOpen }) => {
  const [loading, setLoading] = useState(true);
  const callLogs = useSelector((state) => state.calls.callLogs);

  const dispatch = useDispatch();

  const { control } = useForm({
    defaultValues: { select: "10" },
    mode: "all",
  });
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [selectCount, setSelectCount] = useState(10);
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, callLogs: 1 }));
  };
  const [currentPage, setCurrentPage] = useState(
    page && page.callLogs ? parseInt(page.callLogs) : 1
  );
  const fetchPhoneNums = async () => {
    setLoading(true);
    try {
      await dispatch(getCallLogs(currentPage, selectCount));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchPhoneNums();
  }, [selectCount, currentPage]);

  const requiredData =
    callLogs &&
    callLogs.list.map((val) => {
      return {
        duration: val.callduration,
        price: val.totalamount,
        to: val.to,
        from: val.from,
        direction: val.direction,
        recorded_audio: val.recordingurl,
        Status: val.callstatus,
      };
    });
  return (
    <>
      <HStack justifyContent={"space-between"}>
        <HStack height={ah(32)} alignItems="center">
          <Text fontSize={aw(14)} fontFamily="RobotoRegular" color="#212121">
            Show Entries :{" "}
          </Text>
          <Center>
            <LeadsWatchSelect
              control={control}
              name={"select"}
              list={["10", "15", "20", "25"]}
              // value={selectCount}
              handleChange={(value) => handleChangeSelect(parseInt(value))}
              width={75}
              // height={ah(32)}
            />
          </Center>
        </HStack>
      </HStack>
      <VStack top={5} zIndex={-1}>
        <LeadsWatchTable
          isOpen={isOpen}
          isLoading={loading}
          heading={[
            "from",
            "to",
            "direction",
            "price",
            "duration",
            "Status",
            "recorded_audio",
          ]}
          screen="calls"
          // sortIcon={true}
          data={requiredData}
        />
      </VStack>
      <VStack
        bottom={aw(5)}
        position="fixed"
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
      >
        <LeadsWatchPagination
          width="100%"
          totalCount={callLogs ? callLogs.total_count : 0}
          currentPage={currentPage}
          pageSize={selectCount}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
            localStorage.setItem(
              "page",
              JSON.stringify({ ...page, callLogs: currentPage })
            );
          }}
        />
      </VStack>
    </>
  );
};

export default CallLogs;
