import * as events from "../Events";
const initialState = {
  allBuyers: null,
  newBuyer: null,
  editedBuyer: null,
  buyerRoutes: null,
  newBuyerRoute: null,
  singleBuyerRoute: null,
  editedBuyerRoute: null,
  singleBuyer: null,
};
export const buyersReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_BUYERS:
      return {
        ...state,
        allBuyers: action.data,
      };
    case events.ADD_BUYER:
      return {
        ...state,
        newBuyer: action.data,
      };
    case events.EDIT_BUYER:
      return {
        ...state,
        editedBuyer: action.data,
      };
    case events.DELETE_BUYER:
      return {
        ...state,
      };
    case events.BUYER_DETAILS:
      return {
        ...state,
        buyerRoutes: action.data,
      };
    case events.CREATE_BUYER_ROUTE:
      return {
        ...state,
        newBuyerRoute: action.data,
      };
    case events.SINGLE_BUYER_ROUTE:
      return {
        ...state,
        singleBuyerRoute: action.data,
      };
    case events.EDIT_BUYER_ROUTE:
      return {
        ...state,
        editedBuyerRoute: action.data,
      };
    case events.GET_SINGLE_BUYER:
      return {
        ...state,
        singleBuyer: action.data,
      };
    case events.REMOVE_SINGLE_BUYER:
      return {
        ...state,
        singleBuyer: null,
      };
    case events.DELETE_BUYER_ROUTE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
