import { HStack, Text, Pressable, Divider, VStack, Menu } from "native-base";
import React from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import { aw, ah } from "../../../utils";
import LeadsWatchInput from "../../../components/LeadsWatchInput";
import LeadsWatchSearchIcon from "../../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchMenu from "../../../components/LeadsWatchMenu";
import LeadsWatchSelect from "../../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../../components/LeadsWatchButton";
import LeadsWatchSettingsIcon from "../../../components/svg/LeadsWatchSettingsIcon";

const Ivr = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });
  return (
    <>
      <HStack justifyContent={"space-between"}>
        <HStack space={aw(50)}>
          <Pressable
            borderColor={"#0000FF"}
            onPress={() => navigate(`/calls/ivr/routingPlans`)}
            borderBottomWidth={
              location.pathname.includes("routingPlans") && aw(3)
            }
            height={ah(42)}
          >
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={
                location.pathname.includes("routingPlans")
                  ? "#0000FF"
                  : "#919191"
              }
              fontWeight={400}
            >
              Routing Plans
            </Text>
          </Pressable>
          <Pressable
            borderBottomWidth={
              location.pathname.includes("createIvrTree") && aw(3)
            }
            borderColor={"#0000FF"}
            onPress={() => navigate(`/calls/ivr/createIvrTree`)}
            height={ah(42)}
          >
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={
                location.pathname.includes("createIvrTree")
                  ? "#0000FF"
                  : "#919191"
              }
              fontWeight={400}
            >
              Create IVR Tree
            </Text>
          </Pressable>
          <Pressable
            borderBottomWidth={
              location.pathname.includes("manageIvrTrees") && aw(3)
            }
            borderColor={"#0000FF"}
            onPress={() => navigate(`/calls/ivr/manageIvrTrees`)}
            height={ah(42)}
          >
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={
                location.pathname.includes("manageIvrTrees")
                  ? "#0000FF"
                  : "#919191"
              }
              fontWeight={400}
            >
              Manage IVR Trees
            </Text>
          </Pressable>
          <Pressable
            borderBottomWidth={
              location.pathname.includes("manageMedia") && aw(3)
            }
            borderColor={"#0000FF"}
            onPress={() => navigate(`/calls/ivr/manageMedia`)}
            height={ah(42)}
          >
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={
                location.pathname.includes("manageMedia")
                  ? "#0000FF"
                  : "#919191"
              }
              fontWeight={400}
            >
              Manage Media
            </Text>
          </Pressable>
        </HStack>
        <HStack space={aw(12)}>
          <LeadsWatchInput
            control={control}
            name="search"
            placeholder="search"
            leftIcon={<LeadsWatchSearchIcon />}
            style={{ width: aw(150), height: ah(32) }}
            height={ah(32)}
          />

          <LeadsWatchSelect
            control={control}
            name="sort"
            width={aw(150)}
            placeholder="sort"
            list={["asc", "des"]}
            // value={status}
            // handleChange={(value) => setStatus(value)}
            height={ah(32)}
          />
        </HStack>
      </HStack>
      <Divider width={aw(1248)} bg="#E6E7E9" />
      <VStack mt={ah(10)}>
        <Outlet />
      </VStack>
    </>
  );
};

export default Ivr;
