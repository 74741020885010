import React, { useEffect, useState, useRef } from "react";
import {
  HStack,
  VStack,
  Flex,
  Text,
  FlatList,
  ScrollView,
  Spinner,
} from "native-base";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";

import { aw, ah } from "../../utils";

//components
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchCard from "../../components/LeadsWatchCard";
//redux
import {
  getTopCampaigns,
  getTopPublishers,
  getTopBuyers,
  getTotalLeads,
  getamountPerformance,
  getLeadPerformnce,
  getLeadsmeter,
  getCreditsmeter,
} from "../../store/actions/dashboard";
const Dashboard = ({ isOpen }) => {
  const ammountPerformance = useSelector(
    (state) => state.dashboard.amountPerformance
  );
  const leadPerformance = useSelector(
    (state) => state.dashboard.leadPerformance
  );

  const [refresh, setRefresh] = useState(false);
  const { control, getValues } = useForm({
    mode: "all",
    defaultValues: {
      cam_interval: "monthtodate",
      cam_criteria: "profit",
      pub_interval: "monthtodate",
      pub_criteria: "payout",
      buyers_interval: "monthtodate",
      buyers_criteria: "revenue",
      lead_interval: "monthtodate",
      amount_interval: "monthtodate",
      lead_analysis_interval: "monthtodate",
    },
  });
  const dispatch = useDispatch();
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [publisherLoading, setPublisherLoading] = useState(false);
  const [buyerLoading, setBuyerLoading] = useState(false);
  const [leadsLoading, setLeadsLoading] = useState(false);
  const [amountLoading, setAmountLoading] = useState(false);
  const [leadsmeterLoading, setLeadsMeterLoading] = useState(false);
  const [creditsmeterLoading, setCreditsMeterLoading] = useState(false);
  const topCampaigns = useSelector((state) => state.dashboard.topCampagns);
  const topPublishers = useSelector((state) => state.dashboard.topPublishers);
  const topBuyers = useSelector((state) => state.dashboard.topBuyers);
  const totalLeads = useSelector((state) => state.dashboard.totalLeads);
  const leadsmeter = useSelector((state) => state.dashboard.leadsMeter);
  const creditsmeter = useSelector((state) => state.dashboard.creditsmeter);
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
  );

  const labels =
    getValues("amount_interval") === "last7days"
      ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      : ammountPerformance
      ? ammountPerformance.list.map((val) => val.time_interval)
      : [];

  const data = {
    labels,
    datasets: [
      {
        label: "profit",
        data: [4, 10, 12],
        data: ammountPerformance
          ? ammountPerformance.list.map((val) => val.profit)
          : [],
        backgroundColor: "#962DFF",
      },
      {
        label: "payout",
        // data: [6, 20, 14],
        data: ammountPerformance
          ? ammountPerformance.list.map((val) => val.payout)
          : [],
        backgroundColor: "#C893FD",
      },
      {
        label: "revenue",
        // data: [2, 30, 17],
        data: ammountPerformance
          ? ammountPerformance.list.map((val) => val.revenue)
          : [],
        backgroundColor: "#E0C6FD",
      },
    ],
  };
  const maxConvertedLeads = leadPerformance
    ? Math.max(...leadPerformance.list.map((val) => val.all_leads), 0)
    : 0;
  const maxCampaigns =
    totalLeads && totalLeads.campaigns_overview
      ? totalLeads.campaigns_overview.campaigns
      : 0;
  const maxRevenue = ammountPerformance
    ? Math.max(...ammountPerformance.list.map((val) => val.revenue), 0)
    : 0;

  //for profit
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.ceil(maxRevenue) + 1,
        // suggestedMax: maxConvertedLeads + buffer,

        grid: {
          display: true,
          borderWidth: aw(1),
        },
      },
    },
    barPercentage: 0.5,
    elements: {
      bar: {
        borderWidth: 0,
        barWidth: 20,
      },
    },
  };
  //for leads
  const options1 = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      x: {
        type: "category",
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        suggestedMax: Math.ceil(maxConvertedLeads) + 2,
        // suggestedMax: maxConvertedLeads + buffer,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },

        grid: {
          display: true,
          borderWidth: 1,
        },
      },
    },
  };
  const textPlugins = {
    afterDraw: (chart) => {
      const ctx = chart.ctx;
      const canvasCenterX = chart.width / 2;
      const canvasCenterY = chart.height / 1.5;

      // Your text content
      const line1 =
        leadsmeter &&
        `Remaining : ${(
          100 -
          (leadsmeter.sent_leads / leadsmeter.plan_leads) * 100
        ).toFixed(2)} %`;
      const line2 = leadsmeter && `Total Leads:${leadsmeter.plan_leads}`;

      // Font settings
      ctx.font = "20px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";

      // Draw text at the center
      // ctx.fillText(line1, canvasCenterX, canvasCenterY - 10); // Adjust vertical position
      ctx.fillText(line1, canvasCenterX, canvasCenterY + 10); // Adjust vertical position
    },
  };
  // const dotPlugin = {
  //   id: "dotPlugin",
  //   afterDatasetDraw: (chart) => {
  //     const ctx = chart.ctx;
  //     // const dataset = chart.config.data.datasets[0];
  //     // const dataPoint = chart._metasets[0].data[0];
  //     const xCoordinate = chart.width / 10;
  //     const yCoordinate = chart.height / 1.3;
  //     console.log(chart.width, chart.height);
  //     // console.log(ctx, chart, xCoordinate, yCoordinate);
  //     ctx.beginPath();
  //     ctx.arc(xCoordinate + 300, yCoordinate, 10, 0, 2 * Math.PI);
  //     ctx.fillStyle = "red"; // You can change the color of the dot
  //     ctx.fill();
  //     ctx.closePath();
  //   },
  // };
  const labels1 =
    getValues("lead_analysis_interval") === "last7days"
      ? ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      : leadPerformance
      ? leadPerformance.list.map((val) => val.time_interval)
      : [];

  const data1 = {
    labels: labels1,
    datasets: [
      {
        label: "Leads",
        // data: [50, 10000],
        data: leadPerformance
          ? leadPerformance.list.map((val) => val.all_leads)
          : [],
        backgroundColor: "#962DFF",
        borderColor: "#962DFF",
        borderWidth: "1.5",
        cubicInterpolationMode: "monotone",
      },
      {
        label: "Converted Leads",
        // data: [6, 20, 14],
        data: leadPerformance
          ? leadPerformance.list.map((val) => val.converted_leads)
          : [],
        backgroundColor: "#C893FD",
        borderColor: "#C893FD",
        borderWidth: "1.5",
        cubicInterpolationMode: "monotone",
      },
    ],
  };

  const data2 = {
    labels: ["Leads Sent", "Total Leads"],
    datasets: [
      {
        label: "Leads",
        // data: leadsmeter ? [leadsmeter.plan_leads, leadsmeter.sent_leads] : [],
        data: leadsmeter
          ? [
              (leadsmeter.sent_leads / leadsmeter.plan_leads) * 100,
              100 - (leadsmeter.sent_leads / leadsmeter.plan_leads) * 100,
            ]
          : [],
        backgroundColor: ["#4A3AFF", "#E5EAFC"],
        borderWidth: 0,
        // needleValue: 10,
      },
    ],
  };

  const options3 = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: false,
        },
        suggestedMax: Math.ceil(maxCampaigns) + 2,
        // suggestedMax: maxConvertedLeads + buffer,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
      y: {
        type: "category",
        // beginAtZero: true,

        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },

    barPercentage: 0.4,
    borderRadius: 50,
  };
  const data3 = {
    labels: ["Total", "Lead", "IVR", "PPC"],
    datasets: [
      {
        label: "Campaigns",
        backgroundColor: ["#4A3AFF", "#962DFF", "#C893FD", "#93AAFD"],
        data:
          totalLeads && totalLeads.campaigns_overview
            ? [
                totalLeads.campaigns_overview.campaigns,
                totalLeads.campaigns_overview.lead_campaigns,
                totalLeads.campaigns_overview.ivr_campaigns,
                totalLeads.campaigns_overview.ppc_campaigns,
              ]
            : [],
      },
    ],
  };

  const options2 = {
    cutout: "80%",
    rotation: 270,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    ticks: {
      maxCampaigns,
    },
    // plugins: [squarePlugin],
  };
  useEffect(() => {
    const fetchTopCampaigns = async () => {
      setCampaignLoading(true);
      try {
        await dispatch(
          getTopCampaigns(getValues("cam_interval"), getValues("cam_criteria"))
        );
      } catch (err) {
        console.log(err);
      }
      setCampaignLoading(false);
    };
    fetchTopCampaigns();
  }, [getValues("cam_criteria"), getValues("cam_interval")]);

  useEffect(() => {
    const fetchTopPublishers = async () => {
      setPublisherLoading(true);
      try {
        await dispatch(
          getTopPublishers(getValues("pub_interval"), getValues("pub_criteria"))
        );
      } catch (err) {
        console.log(err);
      }
      setPublisherLoading(false);
    };
    fetchTopPublishers();
  }, [getValues("pub_criteria"), getValues("pub_interval")]);
  useEffect(() => {
    const fetchTopBuyers = async () => {
      setBuyerLoading(true);
      try {
        await dispatch(
          getTopBuyers(
            getValues("buyers_interval"),
            getValues("buyers_criteria")
          )
        );
      } catch (err) {
        console.log(err);
      }
      setBuyerLoading(false);
    };
    fetchTopBuyers(false);
  }, [getValues("buyers_criteria"), getValues("buyers_interval")]);
  useEffect(() => {
    const fetchLeadsCount = async () => {
      // setLeadsLoading(true);
      try {
        await dispatch(getTotalLeads(getValues("lead_interval")));
      } catch (err) {
        console.log(err);
      }
      // setLeadsLoading(false);
    };
    fetchLeadsCount(false);
  }, [getValues("lead_interval")]);

  useEffect(() => {
    const fetchAmount = async () => {
      setAmountLoading(true);
      try {
        await dispatch(getamountPerformance(getValues("amount_interval")));
      } catch (err) {
        console.log(err);
      }
      setAmountLoading(false);
    };
    fetchAmount();
  }, [getValues("amount_interval")]);

  useEffect(() => {
    const fetchLeads = async () => {
      setLeadsLoading(true);
      try {
        await dispatch(getLeadPerformnce(getValues("lead_analysis_interval")));
      } catch (err) {
        console.log(err);
      }
      setLeadsLoading(false);
    };
    fetchLeads();
  }, [getValues("lead_analysis_interval")]);
  useEffect(() => {
    const fetchLeads = async () => {
      setLeadsMeterLoading(true);
      try {
        await dispatch(getLeadsmeter());
      } catch (err) {
        console.log(err);
      }
      setLeadsMeterLoading(false);
    };
    fetchLeads();
  }, []);
  useEffect(() => {
    const fetchLeads = async () => {
      // setCreditsMeterLoading(true);
      try {
        await dispatch(getCreditsmeter());
      } catch (err) {
        console.log(err);
      }
      // setCreditsMeterLoading(false);
    };
    fetchLeads();
  }, []);

  return (
    <HStack
      height={ah(698)}
      width={
        isOpen ? [aw(350), aw(350), aw(1186)] : [aw(350), aw(350), aw(1267)]
      }
      left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
    >
      <ScrollView height={ah(698)} width="100%">
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <Flex width="99%" marginTop={ah(25)}>
            <LeadsWatchCard
              height={ah(570)}
              width="100%"
              px={5}
              pb={5}
              pt={5}
              alignItems="flex-start"
            >
              <Flex gap={ah(15)} width="100%">
                <HStack width="100%" justifyContent={"space-between"}>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                    color="#121233"
                  >
                    Overview
                  </Text>

                  <LeadsWatchSelect
                    handleChange={(value) => setRefresh(!refresh)}
                    type="array"
                    placeholder="Select Period"
                    control={control}
                    list={[
                      { value: "today", label: "Today" },
                      { value: "yesterday", label: "Yesterday" },
                      { value: "last7days", label: "Last 7 Days" },
                      { value: "lastmonth", label: "Last Month" },
                      { value: "monthtodate", label: "Month to Date" },
                    ]}
                    name="lead_interval"
                    variant="underlined"
                  />
                </HStack>
                <Flex
                  width="100%"
                  justifyContent={"space-between"}
                  direction="row"
                >
                  <Flex width="60%" gap={ah(15)}>
                    <Flex
                      direction="row"
                      // justifyContent={"space-evenly"}
                      // width="75%"
                      width="100%"
                      justifyContent={"space-between"}
                    >
                      <LeadsWatchCard
                        width="32%"
                        shadow={true}
                        px={5}
                        pb={10}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              Total Publishers
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.publishers_overview
                                  ? totalLeads.publishers_overview.publishers
                                  : 0
                                : 0}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"flex-end"}
                          alignItems={"flex-end"}
                          width="100%"
                          direction="row"
                        >
                          <Text
                            alignSelf={"center"}
                            style={{
                              fontFamily: "RobotoMedium",
                              fontSize: aw(16),
                            }}
                            color="#409261"
                          >
                            Active :{" "}
                          </Text>
                          <Text
                            alignSelf={"center"}
                            style={{
                              fontFamily: "RobotoMedium",
                              fontSize: aw(16),
                            }}
                            color="#409261"
                          >
                            {totalLeads
                              ? totalLeads.publishers_overview
                                ? totalLeads.publishers_overview
                                    .active_publishers
                                : 0
                              : 0}
                          </Text>
                        </Flex>
                      </LeadsWatchCard>
                      <LeadsWatchCard
                        width="32%"
                        shadow={true}
                        px={5}
                        pb={10}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              Total Buyers
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.buyers_overview
                                  ? totalLeads.buyers_overview.buyers
                                  : 0
                                : 0}
                            </Text>
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={"flex-end"}
                          alignItems={"flex-end"}
                          width="100%"
                          direction="row"
                        >
                          <Text
                            alignSelf={"center"}
                            style={{
                              fontFamily: "RobotoMedium",
                              fontSize: aw(16),
                            }}
                            color="#409261"
                          >
                            Active :{" "}
                          </Text>
                          <Text
                            alignSelf={"center"}
                            style={{
                              fontFamily: "RobotoMedium",
                              fontSize: aw(16),
                            }}
                            color="#409261"
                          >
                            {totalLeads
                              ? totalLeads.buyers_overview
                                ? totalLeads.buyers_overview.active_buyers
                                : 0
                              : 0}
                          </Text>
                        </Flex>
                      </LeadsWatchCard>
                      <LeadsWatchCard
                        shadow={true}
                        px={5}
                        pb={5}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                        width="32%"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              Total Leads
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.leads_overview
                                  ? totalLeads.leads_overview.total_leads
                                  : 0
                                : 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </LeadsWatchCard>
                    </Flex>
                    <Flex
                      direction="row"
                      justifyContent={"space-between"}
                      width="100%"
                    >
                      <LeadsWatchCard
                        shadow={true}
                        px={5}
                        pb={5}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                        width="32%"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              System Accepted Leads
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.leads_overview
                                  ? totalLeads.leads_overview.system_accepted
                                  : 0
                                : 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </LeadsWatchCard>
                      <LeadsWatchCard
                        shadow={true}
                        px={5}
                        pb={5}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                        width="32%"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              Buyer Accepted Leads
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.leads_overview
                                  ? totalLeads.leads_overview.buyer_accepted
                                  : 0
                                : 0}
                            </Text>
                          </Flex>
                        </Flex>
                      </LeadsWatchCard>
                      <LeadsWatchCard
                        shadow={true}
                        px={5}
                        pb={5}
                        pt={5}
                        //   borderWidth={aw(1)}
                        borderWidth={aw(1)}
                        borderColor={"#D7D7D7"}
                        height={ah(130)}
                        alignItems="flex-start"
                        width="32%"
                      >
                        <Flex gap={ah(10)}>
                          <HStack justifyContent={"space-between"}>
                            <Text
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(17),
                              }}
                              color="#121233"
                            >
                              Buyer Pending Leads
                            </Text>
                          </HStack>
                          <Flex width={"50%"}>
                            <Text
                              alignSelf={"center"}
                              style={{
                                fontFamily: "RobotoMedium",
                                fontSize: aw(16),
                              }}
                              color="#121233"
                            >
                              {totalLeads
                                ? totalLeads.leads_overview
                                  ? totalLeads.leads_overview.buyer_pending
                                  : 0
                                : 0}
                              {/* {totalLeads ? totalLeads.buyer_pending : 0} */}
                            </Text>
                          </Flex>
                        </Flex>
                      </LeadsWatchCard>
                    </Flex>
                  </Flex>

                  <Flex height={"90%"} width="35%">
                    <Text
                      // alignSelf={"center"}
                      style={{
                        fontFamily: "RobotoMedium",
                        fontSize: aw(16),
                      }}
                      color="#121233"
                    >
                      Campaigns
                    </Text>
                    <Bar options={options3} data={data3} />
                    {/* <Line options={options} data={data} />; */}
                  </Flex>
                </Flex>
              </Flex>
            </LeadsWatchCard>
          </Flex>

          <Flex
            width="99%"
            marginTop={ah(25)}
            direction="row"
            justifyContent={"space-between"}
          >
            <LeadsWatchCard
              height={ah(537)}
              width="64%"
              px={5}
              pb={5}
              pt={5}
              alignItems="flex-start"
            >
              <Flex gap={ah(15)} width="100%">
                <HStack width="100%" justifyContent={"space-between"}>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                    color="#121233"
                  >
                    Amount Analysis
                  </Text>

                  <LeadsWatchSelect
                    handleChange={(value) => setRefresh(!refresh)}
                    type="array"
                    placeholder="Select Period"
                    control={control}
                    list={[
                      { value: "today", label: "Today" },
                      { value: "yesterday", label: "Yesterday" },
                      { value: "last7days", label: "Last 7 Days" },
                      { value: "lastmonth", label: "Last Month" },
                      { value: "monthtodate", label: "Month to Date" },
                    ]}
                    name="amount_interval"
                    variant="underlined"
                  />
                </HStack>
                {!amountLoading ? (
                  <Flex height={ah(400)} gap={ah(10)}>
                    <Flex direction="row" justifyContent={"space-evenly"}>
                      <Text
                        style={{
                          fontFamily: "RobotoRegular",
                          fontSize: aw(17),
                        }}
                        color="#121233"
                      >
                        Revenue :{" "}
                        {ammountPerformance
                          ? ammountPerformance.total_revenue
                          : 0}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "RobotoRegular",
                          fontSize: aw(17),
                        }}
                        color="#121233"
                      >
                        Payout :{" "}
                        {ammountPerformance
                          ? ammountPerformance.total_payout
                          : 0}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "RobotoRegular",
                          fontSize: aw(17),
                        }}
                        color="#121233"
                      >
                        Profit :{" "}
                        {ammountPerformance
                          ? ammountPerformance.total_profit
                          : 0}
                      </Text>
                    </Flex>
                    <Bar options={options} data={data} />
                  </Flex>
                ) : (
                  <Flex
                    height={ah(400)}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Spinner size="lg" color="#2230F8" />
                  </Flex>
                )}
              </Flex>
            </LeadsWatchCard>
            <LeadsWatchCard
              height={ah(537)}
              width="34%"
              px={5}
              pb={5}
              pt={5}
              alignItems="flex-start"
            >
              <Flex gap={ah(10)} width="100%">
                <HStack width="100%" justifyContent={"space-between"}>
                  <Flex>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      Total Leads
                    </Text>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      {leadsmeter ? leadsmeter.plan_leads : 0}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      Sent Leads
                    </Text>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      {leadsmeter ? leadsmeter.sent_leads : 0}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      Remaining Leads
                    </Text>
                    <Text
                      style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                      color="#121233"
                    >
                      {leadsmeter
                        ? leadsmeter.plan_leads - leadsmeter.sent_leads
                        : 0}
                    </Text>
                  </Flex>
                </HStack>
                {!leadsmeterLoading ? (
                  <Flex height={ah(400)} alignContent={"center"}>
                    {/* <Bar options={options} data={data} /> */}
                    <Doughnut
                      data={data2}
                      options={options2}
                      plugins={[textPlugins]}
                    />
                  </Flex>
                ) : (
                  <Flex
                    height={ah(400)}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Spinner size="lg" color="#2230F8" />
                  </Flex>
                )}
              </Flex>
            </LeadsWatchCard>
          </Flex>
          <Flex
            width="99%"
            marginTop={ah(25)}
            direction="row"
            justifyContent={"space-between"}
          >
            <LeadsWatchCard
              height={ah(537)}
              width="64%"
              px={5}
              pb={5}
              pt={5}
              alignItems="flex-start"
            >
              <Flex gap={ah(10)} width="100%">
                <HStack width="100%" justifyContent={"space-between"}>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                    color="#121233"
                  >
                    Leads Analysis
                  </Text>

                  <LeadsWatchSelect
                    handleChange={(value) => setRefresh(!refresh)}
                    type="array"
                    placeholder="Select Period"
                    control={control}
                    list={[
                      { value: "today", label: "Today" },
                      { value: "yesterday", label: "Yesterday" },
                      { value: "last7days", label: "Last 7 Days" },
                      { value: "lastmonth", label: "Last Month" },
                      { value: "monthtodate", label: "Month to Date" },
                    ]}
                    name="lead_analysis_interval"
                    variant="underlined"
                  />
                </HStack>
                {!leadsLoading ? (
                  <Flex height={ah(400)}>
                    {/* <Bar options={options} data={data} /> */}
                    <Line options={options1} data={data1} />
                  </Flex>
                ) : (
                  <Flex
                    height={ah(400)}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Spinner size="lg" color="#2230F8" />
                  </Flex>
                )}
              </Flex>
            </LeadsWatchCard>
            <LeadsWatchCard
              height={ah(537)}
              width="34%"
              px={5}
              pb={5}
              pt={5}
              alignItems="flex-start"
            >
              <Flex gap={ah(10)} width="100%" height="100%">
                <HStack width="100%" justifyContent={"space-between"}>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                    color="#121233"
                  >
                    Credits Analysis
                  </Text>
                </HStack>
                <Flex
                  width="100%"
                  justifyContent={"center"}
                  alignItems={"center"}
                  height="75%"
                >
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(20) }}
                    color="#121233"
                  >
                    Credits
                  </Text>
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(40) }}
                    color="#121233"
                  >
                    {creditsmeter
                      ? creditsmeter.remaining_credits
                        ? creditsmeter.remaining_credits.toFixed(4)
                        : 0
                      : 0}
                  </Text>
                  {creditsmeter && creditsmeter.last_credited && (
                    <Flex>
                      <Text
                        style={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
                        color="#9291A5"
                      >
                        Last Filled : {creditsmeter.last_credited.credits}{" "}
                        credits
                      </Text>

                      <Text
                        style={{ fontFamily: "RobotoMedium", fontSize: aw(14) }}
                        color="#9291A5"
                        alignSelf={"flex-end"}
                      >
                        Filled Date :{" "}
                        {moment(creditsmeter.last_credited.created).format(
                          "YYYY-MM-DD"
                        )}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </LeadsWatchCard>
          </Flex>
          <Flex width="99%" marginTop={ah(25)} marginBottom={ah(30)}>
            <LeadsWatchCard
              height={ah(537)}
              width="100%"
              px={5}
              pb={5}
              pt={5}
              // pb={10}
              // pt={10}
              // alignItems={"flex-start"}
            >
              <Flex gap={ah(15)} width="100%">
                <HStack width="100%">
                  <Text
                    style={{ fontFamily: "RobotoMedium", fontSize: aw(17) }}
                    color="#121233"
                  >
                    Account Overview
                  </Text>
                </HStack>

                <Flex
                  direction="row"
                  width="100%"
                  justifyContent={"space-between"}
                >
                  <Flex width="30%" gap={ah(10)}>
                    <Flex
                      direction="row"
                      width="100%"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text
                        style={{
                          fontFamily: "RobotoMedium",
                          fontSize: aw(14),
                          color: "#121233",
                        }}
                      >
                        Top Campaigns
                      </Text>
                      {/* <Flex direction="row" justifyContent={"space-between"}> */}
                      <Flex width="36%">
                        <LeadsWatchSelect
                          // fontSize={aw(11)}
                          handleChange={(value) => setRefresh(!refresh)}
                          type="array"
                          placeholder="Select Period"
                          control={control}
                          list={[
                            { value: "today", label: "Today" },
                            { value: "yesterday", label: "Yesterday" },
                            { value: "last7days", label: "Last 7 Days" },
                            { value: "lastmonth", label: "Last month" },
                            { value: "monthtodate", label: "Month to Date" },
                          ]}
                          name="cam_interval"
                          variant="underlined"
                        />
                      </Flex>
                      <Flex width="26%">
                        <LeadsWatchSelect
                          handleChange={(value) => setRefresh(!refresh)}
                          control={control}
                          list={["Revenue", "Profit", "Leads", "Payout"]}
                          name="cam_criteria"
                          variant="underlined"
                          placeholder="Select Criteria"
                        />
                        {/* </Flex> */}
                      </Flex>
                    </Flex>
                    <LeadsWatchCard
                      shadow={true}
                      //   borderWidth={aw(1)}

                      borderColor={"#D7D7D7"}
                      height={ah(300)}
                      alignItems="flex-start"
                      width="100%"
                    >
                      <HStack
                        style={{
                          borderTopLeftRadius: aw(10),
                          borderTopRightRadius: aw(10),
                        }}
                        height={ah(60)}
                        bg={"#F9FAFB"}
                        width={"100%"}
                        borderWidth={aw(1)}
                        borderBottomWidth={0}
                        borderColor={"#D7D7D7"}
                        alignItems={"center"}
                        px={4}
                        justifyContent="space-between"
                      >
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "70%",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "30%",
                          }}
                        >
                          {getValues("cam_criteria").charAt(0).toUpperCase() +
                            getValues("cam_criteria").slice(1)}
                        </Text>
                      </HStack>
                      {!campaignLoading ? (
                        <FlatList
                          maxHeight={ah(210)}
                          // width={aw(587)}
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(200)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={topCampaigns ? topCampaigns : []}
                          keyExtractor={(item) => item._id}
                          renderItem={({ item, index }) => (
                            <HStack
                              bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                              width={"100%"}
                              minHeight={ah(50)}
                              style={{
                                borderBottomWidth:
                                  index === topCampaigns.length - 1
                                    ? aw(0)
                                    : aw(1),

                                borderColor: "#D7D7D7",
                              }}
                              justifyContent={"space-between"}
                              px={aw(10)}
                              alignItems={"center"}
                            >
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "70%",
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "30%",
                                }}
                              >
                                {item[getValues("cam_criteria")]}
                              </Text>
                            </HStack>
                          )}
                        />
                      ) : (
                        <HStack
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(200)}
                        >
                          <Spinner size="lg" color="#2230F8" />
                        </HStack>
                      )}
                    </LeadsWatchCard>
                  </Flex>
                  <Flex width="30%" gap={ah(10)}>
                    <Flex
                      direction="row"
                      width="100%"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text
                        style={{
                          fontFamily: "RobotoMedium",
                          fontSize: aw(14),
                          color: "#121233",
                        }}
                      >
                        Top Publishers
                      </Text>
                      {/* <Flex direction="row" justifyContent={"space-between"}> */}
                      <Flex width="36%">
                        <LeadsWatchSelect
                          handleChange={(value) => setRefresh(!refresh)}
                          type="array"
                          placeholder="Select Period"
                          control={control}
                          list={[
                            { value: "today", label: "Today" },
                            { value: "yesterday", label: "Yesterday" },
                            { value: "last7days", label: "Last 7 Days" },
                            { value: "lastmonth", label: "Last Month" },
                            { value: "monthtodate", label: "Month to Date" },
                          ]}
                          name="pub_interval"
                          variant="underlined"
                        />
                      </Flex>
                      <Flex width="26%">
                        <LeadsWatchSelect
                          handleChange={(value) => setRefresh(!refresh)}
                          control={control}
                          list={["Revenue", "Profit", "Leads", "Payout"]}
                          name="pub_criteria"
                          variant="underlined"
                          placeholder="Select Criteria"
                        />
                        {/* </Flex> */}
                      </Flex>
                    </Flex>
                    <LeadsWatchCard
                      shadow={true}
                      //   borderWidth={aw(1)}

                      borderColor={"#D7D7D7"}
                      height={ah(300)}
                      alignItems="flex-start"
                      width="100%"
                    >
                      <HStack
                        style={{
                          borderTopLeftRadius: aw(10),
                          borderTopRightRadius: aw(10),
                        }}
                        height={ah(60)}
                        bg={"#F9FAFB"}
                        width={"100%"}
                        borderWidth={aw(1)}
                        borderBottomWidth={0}
                        borderColor={"#D7D7D7"}
                        alignItems={"center"}
                        px={4}
                        justifyContent="space-between"
                      >
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "70%",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "30%",
                          }}
                        >
                          {getValues("pub_criteria").charAt(0).toUpperCase() +
                            getValues("pub_criteria").slice(1)}
                        </Text>
                      </HStack>
                      {!publisherLoading ? (
                        <FlatList
                          maxHeight={ah(210)}
                          // width={aw(587)}
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(200)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={topPublishers ? topPublishers : []}
                          keyExtractor={(item) => item._id}
                          renderItem={({ item, index }) => (
                            <HStack
                              bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                              width={"100%"}
                              minHeight={ah(50)}
                              style={{
                                borderBottomWidth:
                                  index === topPublishers.length - 1
                                    ? aw(0)
                                    : aw(1),

                                borderColor: "#D7D7D7",
                              }}
                              justifyContent={"space-between"}
                              px={aw(10)}
                              alignItems={"center"}
                            >
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "70%",
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "30%",
                                }}
                              >
                                {item[getValues("pub_criteria")]}
                              </Text>
                            </HStack>
                          )}
                        />
                      ) : (
                        <HStack
                          width="100%"
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(200)}
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                        >
                          <Spinner size="lg" color="#2230F8" />
                        </HStack>
                      )}
                    </LeadsWatchCard>
                  </Flex>
                  <Flex width="30%" gap={ah(10)}>
                    <Flex
                      direction="row"
                      width="100%"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text
                        style={{
                          fontFamily: "RobotoMedium",
                          fontSize: aw(14),
                          color: "#121233",
                        }}
                      >
                        Top Buyers
                      </Text>
                      {/* <Flex direction="row" justifyContent={"space-between"}> */}
                      <Flex width="36%">
                        <LeadsWatchSelect
                          handleChange={(value) => setRefresh(!refresh)}
                          type="array"
                          placeholder="Select Period"
                          control={control}
                          list={[
                            { value: "today", label: "Today" },
                            { value: "yesterday", label: "Yesterday" },
                            { value: "last7days", label: "Last 7 Days" },
                            { value: "lastmonth", label: "Last Month" },
                            { value: "monthtodate", label: "Month to Date" },
                          ]}
                          name="buyers_interval"
                          variant="underlined"
                        />
                      </Flex>
                      <Flex width="26%">
                        <LeadsWatchSelect
                          handleChange={(value) => setRefresh(!refresh)}
                          control={control}
                          list={["Revenue", "Profit", "Leads", "Payout"]}
                          name="buyers_criteria"
                          variant="underlined"
                          placeholder="Select Criteria"
                        />
                        {/* </Flex> */}
                      </Flex>
                    </Flex>
                    <LeadsWatchCard
                      shadow={true}
                      //   borderWidth={aw(1)}

                      borderColor={"#D7D7D7"}
                      height={ah(300)}
                      alignItems="flex-start"
                      width="100%"
                    >
                      <HStack
                        style={{
                          borderTopLeftRadius: aw(10),
                          borderTopRightRadius: aw(10),
                        }}
                        height={ah(60)}
                        bg={"#F9FAFB"}
                        width={"100%"}
                        borderWidth={aw(1)}
                        borderBottomWidth={0}
                        borderColor={"#D7D7D7"}
                        alignItems={"center"}
                        px={4}
                        justifyContent="space-between"
                      >
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "70%",
                          }}
                        >
                          Name
                        </Text>
                        <Text
                          style={{
                            fontFamily: "RobotoMedium",
                            fontSize: aw(14),
                            color: "#121233",
                            width: "30%",
                          }}
                        >
                          {getValues("buyers_criteria")
                            .charAt(0)
                            .toUpperCase() +
                            getValues("buyers_criteria").slice(1)}
                        </Text>
                      </HStack>
                      {!buyerLoading ? (
                        <FlatList
                          maxHeight={ah(210)}
                          // width={aw(587)}
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          ListEmptyComponent={
                            <HStack
                              justifyContent="center"
                              alignItems={"center"}
                              height={ah(200)}
                            >
                              <Text
                                color="black"
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                No data found
                              </Text>
                            </HStack>
                          }
                          data={topBuyers ? topBuyers : []}
                          keyExtractor={(item) => item._id}
                          renderItem={({ item, index }) => (
                            <HStack
                              bg={index % 2 === 0 ? "#FFFFFF" : "#F9FAFB"}
                              width={"100%"}
                              minHeight={ah(50)}
                              style={{
                                borderBottomWidth:
                                  index === topBuyers.length - 1
                                    ? aw(0)
                                    : aw(1),

                                borderColor: "#D7D7D7",
                              }}
                              justifyContent={"space-between"}
                              px={aw(10)}
                              alignItems={"center"}
                            >
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "70%",
                                }}
                              >
                                {item.name}
                              </Text>
                              <Text
                                style={{
                                  fontFamily: "RobotoRegular",
                                  fontSize: aw(14),
                                  color: "#121233",
                                  width: "30%",
                                }}
                              >
                                {item[getValues("buyers_criteria")]}
                              </Text>
                            </HStack>
                          )}
                        />
                      ) : (
                        <HStack
                          width="100%"
                          borderBottomRightRadius={aw(10)}
                          borderBottomLeftRadius={aw(10)}
                          borderColor="#D7D7D7"
                          borderWidth={aw(1)}
                          justifyContent="center"
                          alignItems={"center"}
                          height={ah(200)}
                        >
                          <Spinner size="lg" color="#2230F8" />
                        </HStack>
                      )}
                    </LeadsWatchCard>
                  </Flex>
                </Flex>
              </Flex>
            </LeadsWatchCard>
          </Flex>
        </VStack>
      </ScrollView>
    </HStack>
  );
};
export default Dashboard;
