import {
  HStack,
  Avatar,
  Text,
  Pressable,
  Modal,
  VStack,
  Divider,
  Menu,
  useBreakpointValue,
  Hidden,
} from "native-base";
import { React, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { aw, ah } from "../utils";
// import LeadsWatchBellIcon from "./svg/LeadsWatchBellIcon";
// import LeadsWatchHelpIcon from "./svg/LeadsWatchHelpIcon";

import LeadsWatchNavIcon from "./svg/LeadsWatchNavIcon";
import { updateProfile, generateNewPassword } from "../store/actions/auth";
import LeadsWatchModal from "./LeadsWatchModal";
import LeadsWatchFilterInput from "./LeadsWatchFilterInput";
import LeadsWatchButton from "./LeadsWatchButton";
import LeadsWatchEyeOffIcon from "./svg/LeadsWatchEyeOffIcon";
import LeadsWatchEyeIcon from "./svg/LeadsWatchEyeIcon";
import LeadsWatchActiveIcon from "./svg/LeadsWatchActiveIcon";
import LeadsWatchCampaignsIcon from "./svg/LeadsWatchCampaignsIcon";
import LeadsWatchMenu from "./LeadsWatchMenu";
import LeadsWatchPublishersIcon from "./svg/LeadsWatchPublishersIcon";
import LeadsWatchBuyersIcon from "./svg/LeadsWatchBuyersIcon";
import LeadsWatchVerticalsIcon from "./svg/LeadsWatchVerticalsIcon";
import LeadsWatchOperationsIcon from "./svg/LeadsWatchOperationsIcon";
import LeadsWatchCallsIcon from "./svg/LeadsWatchCallsIcon";
import LeadsWatchReportsIcon from "./svg/LeadsWatchReportsIcon";
import LeadsWatchSettingsIcon from "./svg/LeadsWatchSettingsIcon";
import LeadsWatchDashboardIcon from "./svg/LeadsWatchDashboardIcon";

const LeadsWatchTopBar = ({ isOpen, setisOpen, image }) => {
  const profile = useSelector((state) => state.auth.profile);
  const [show, setShow] = useState(false);
  const [nshow, setNShow] = useState(false);
  const [cshow, setcShow] = useState(false);
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async (e) => {
    let formData = new FormData();
    formData.append("image_file", e.target.files[0]);
    try {
      await dispatch(updateProfile(formData));
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
    // setImage(null);
  };

  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });

  const handleEditProfile = () => {
    setOpenModal(false);
    setOpenEditModal(true);
  };
  const handleResetPassword = async (data) => {
    console.log(data);

    try {
      await dispatch(generateNewPassword(data));
      toast.success("Password Changed Successfully");
      setOpenEditModal(false);
      reset();
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
  };
  return (
    <>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        borderBottomColor={"#E6E7E9"}
        borderBottomWidth={ah(1)}
        height={ah(70)}
        width={[aw(375), aw(375), aw(1366)]}
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
        }}
        pr={aw(10)}
      >
        <Pressable
          isDisabled={
            profile && profile.subscribed
              ? profile.subscribed.plan_type === "Free" &&
                profile.subscribed.status !== "active"
                ? true
                : false
              : true
          }
          onPress={() => setisOpen(!isOpen)}
          // onHoverIn={() => setisOpen(true)}
          // onHoverOut={() => setisOpen(false)}
        >
          <Hidden only={["base", "sm", "md"]}>
            {isOpen ? (
              <HStack
                borderRightColor={"#E6E7E9"}
                borderRightWidth={ah(1)}
                style={{ width: aw(168), height: ah(70) }}
                zIndex={999}
              >
                <HStack
                  left={aw(20)}
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={aw(100)}
                >
                  {localStorage.getItem("logo") !== "undefined" ? (
                    <HStack pl={aw(10)}>
                      <Avatar
                        source={{
                          uri: localStorage.getItem("logo"),
                        }}
                        size={aw(50)}
                      ></Avatar>
                    </HStack>
                  ) : (
                    <Text
                      style={{
                        color: "#0000FF",
                        fontSize: aw(14),
                        fontWeight: 700,
                      }}
                    >
                      LeadsWatch
                    </Text>
                  )}
                </HStack>
              </HStack>
            ) : (
              <HStack alignItems={"center"} height={ah(70)} width={aw(68)}>
                {localStorage.getItem("logo") !== "undefined" ? (
                  <HStack ml={aw(10)}>
                    <Avatar
                      source={{
                        uri: localStorage.getItem("logo"),
                      }}
                      size={aw(50)}
                    />
                  </HStack>
                ) : (
                  <Text
                    marginLeft={aw(20)}
                    style={{
                      color: "#0000FF",
                      fontSize: aw(14),
                      fontWeight: 700,
                    }}
                  >
                    Leads Watch
                  </Text>
                )}
              </HStack>
            )}
          </Hidden>
          <Hidden only={["lg", "xl", "2xl"]}>
            {profile && profile.subscribed ? (
              profile.subscribed.plan_type === "Free" &&
              profile.subscribed.status !== "active" ? (
                <Text
                  marginLeft={aw(20)}
                  style={{
                    color: "#0000FF",
                    fontSize: aw(14),
                    fontWeight: 700,
                  }}
                >
                  Leads Watch
                </Text>
              ) : (
                <LeadsWatchMenu
                  title={<LeadsWatchNavIcon fill="#919191" />}
                  ml={0}
                  mr={aw(100)}
                >
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => navigate("/")}
                  >
                    <LeadsWatchDashboardIcon
                      disabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.stats &&
                            profile.permission_ids.stats.includes("view")
                            ? false
                            : true
                          : false
                      }
                    />
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      // color="#212121"
                      color={
                        profile && profile.role === "user"
                          ? profile.permission_ids.stats &&
                            profile.permission_ids.stats.includes("view")
                            ? "#212121"
                            : "#C1C1C1"
                          : "#212121"
                      }
                    >
                      Dashboard
                    </Text>
                  </Menu.Item>
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => navigate("/leads/lead")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids.lead.includes("view")
                          ? false
                          : true
                        : false
                    }
                  >
                    <LeadsWatchActiveIcon
                      disabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.lead.includes("view")
                            ? false
                            : true
                          : false
                      }
                    />
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      // color="#212121"
                      color={
                        profile && profile.role === "user"
                          ? profile.permission_ids.lead.includes("view")
                            ? "#212121"
                            : "#C1C1C1"
                          : "#212121"
                      }
                    >
                      Leads
                    </Text>
                  </Menu.Item>

                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => navigate("/campaigns/lead")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids.campaign.includes("view")
                          ? false
                          : true
                        : false
                    }
                  >
                    <LeadsWatchCampaignsIcon
                      disabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.campaign.includes("view")
                            ? false
                            : true
                          : false
                      }
                    />
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color={
                        profile && profile.role === "user"
                          ? profile.permission_ids.campaign.includes("view")
                            ? "#212121"
                            : "#C1C1C1"
                          : "#212121"
                      }
                    >
                      Campaigns
                    </Text>
                  </Menu.Item>
                  {profile && profile.role !== "publisher" && (
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => navigate("/publishers")}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.publisher.includes("view")
                            ? false
                            : true
                          : false
                      }
                    >
                      <LeadsWatchPublishersIcon
                        disabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids.publisher.includes("view")
                              ? false
                              : true
                            : false
                        }
                      />
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color={
                          profile && profile.role === "user"
                            ? profile.permission_ids.publisher.includes("view")
                              ? "#212121"
                              : "#C1C1C1"
                            : "#212121"
                        }
                      >
                        Publishers
                      </Text>
                    </Menu.Item>
                  )}
                  {profile && profile.role !== "publisher" && (
                    <Menu.Item
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.buyer.includes("view")
                            ? false
                            : true
                          : false
                      }
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => navigate("/buyers")}
                    >
                      <LeadsWatchBuyersIcon
                        disabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids["buyer"].includes("view")
                              ? false
                              : true
                            : false
                        }
                      />
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color={
                          profile && profile.role === "user"
                            ? profile.permission_ids.buyer.includes("view")
                              ? "#212121"
                              : "#C1C1C1"
                            : "#212121"
                        }
                      >
                        Buyers
                      </Text>
                    </Menu.Item>
                  )}
                  {profile && profile.role !== "publisher" && (
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => navigate("/verticals")}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.vertical.includes("view")
                            ? false
                            : true
                          : false
                      }
                    >
                      <LeadsWatchVerticalsIcon
                        disabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids["vertical"].includes(
                                "view"
                              )
                              ? false
                              : true
                            : false
                        }
                      />
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color={
                          profile && profile.role === "user"
                            ? profile.permission_ids.vertical.includes("view")
                              ? "#212121"
                              : "#C1C1C1"
                            : "#212121"
                        }
                      >
                        Verticals
                      </Text>
                    </Menu.Item>
                  )}
                  {profile && profile.role !== "publisher" && (
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => navigate("/operations")}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids.lead.includes("view")
                            ? false
                            : true
                          : false
                      }
                    >
                      <LeadsWatchOperationsIcon
                        disabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids.lead.includes("view")
                              ? false
                              : true
                            : false
                        }
                      />
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        color={
                          profile && profile.role === "user"
                            ? profile.permission_ids.lead.includes("view")
                              ? "#212121"
                              : "#C1C1C1"
                            : "#212121"
                        }
                      >
                        Operations
                      </Text>
                    </Menu.Item>
                  )}
                  {profile && profile.role !== "publisher" && (
                    <Menu.Item
                      bgColor="white"
                      _hover={{ bgColor: "#F5F7FB" }}
                      onPress={() => navigate("/calls/numbers/manageNumbers")}
                      isDisabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids["call"].includes("calls") ||
                            profile.permission_ids["number"].includes("buy") ||
                            profile.permission_ids["number"].includes("delete")
                            ? false
                            : true
                          : false
                      }
                    >
                      <LeadsWatchCallsIcon
                        disabled={
                          profile && profile.role === "user"
                            ? profile.permission_ids["call"].includes(
                                "calls"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "buy"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "delete"
                              )
                              ? false
                              : true
                            : false
                        }
                      />
                      <Text
                        fontSize={aw(14)}
                        fontFamily="RobotoRegular"
                        // color="#212121"
                        color={
                          profile && profile.role === "user"
                            ? profile.permission_ids["call"].includes(
                                "calls"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "buy"
                              ) ||
                              profile.permission_ids["number"].includes(
                                "delete"
                              )
                              ? "#212121"
                              : "#C1C1C1"
                            : "#212121"
                        }
                      >
                        Calls
                      </Text>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => navigate("/reports/leads")}
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["lead"].includes("view") ||
                          profile.permission_ids["buyer"].includes("view") ||
                          profile.permission_ids["publisher"].includes(
                            "view"
                          ) ||
                          profile.permission_ids["campaign"].includes("view") ||
                          profile.permission_ids["vertical"].includes("view")
                          ? false
                          : true
                        : false
                    }
                  >
                    <LeadsWatchReportsIcon
                      disabled={
                        profile && profile.role === "user"
                          ? profile.permission_ids["lead"].includes("view") ||
                            profile.permission_ids["buyer"].includes("view") ||
                            profile.permission_ids["publisher"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["campaign"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["vertical"].includes("view")
                            ? false
                            : true
                          : false
                      }
                    />
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color={
                        profile && profile.role === "user"
                          ? profile.permission_ids["lead"].includes("view") ||
                            profile.permission_ids["buyer"].includes("view") ||
                            profile.permission_ids["publisher"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["campaign"].includes(
                              "view"
                            ) ||
                            profile.permission_ids["vertical"].includes("view")
                            ? "#212121"
                            : "#C1C1C1"
                          : "#212121"
                      }
                    >
                      Reports
                    </Text>
                  </Menu.Item>
                  <Menu.Item
                    bgColor="white"
                    _hover={{ bgColor: "#F5F7FB" }}
                    onPress={() => navigate("/settings/profile")}
                  >
                    <LeadsWatchSettingsIcon />
                    <Text
                      fontSize={aw(14)}
                      fontFamily="RobotoRegular"
                      color={"#212121"}
                    >
                      Settings
                    </Text>
                  </Menu.Item>
                </LeadsWatchMenu>
              )
            ) : (
              <Text
                marginLeft={aw(20)}
                style={{
                  color: "#0000FF",
                  fontSize: aw(14),
                  fontWeight: 700,
                }}
              >
                Leads Watch
              </Text>
            )}
          </Hidden>
        </Pressable>

        <HStack
          mr={aw(5)}
          // width={aw(182)}
          alignItems={"center"}
          justifyContent={"space-evenly"}
        >
          {/* <Pressable>
            <LeadsWatchHelpIcon />
          </Pressable> */}
          {/* <Pressable>
            <LeadsWatchBellIcon />
          </Pressable> */}
          <Pressable>
            <HStack
              style={{
                // border: "3px solid #E6EBF2",
                borderRadius: aw(40),
                borderColor: "#E6EBF2",
                borderWidth: aw(3),
              }}
            >
              <LeadsWatchMenu
                width={aw(120)}
                mr={aw(5)}
                title={
                  <Avatar
                    size={aw(36)}
                    source={{
                      uri:
                        localStorage.getItem("image") !== "undefined"
                          ? localStorage.getItem("image")
                          : undefined,
                    }}
                  >
                    <Avatar.Badge
                      size={aw(10)}
                      bg="green.500"
                      borderColor={"#F9FAFB"}
                      // position={"topRight"}
                    />
                    {profile &&
                      profile.firstname &&
                      `${profile.firstname.charAt(0).toUpperCase()}${
                        profile.lastname &&
                        profile.lastname.charAt(0).toUpperCase()
                      }`}
                  </Avatar>
                }
              >
                {/* <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={() => setOpenModal(true)}
                >
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    profile
                  </Text>
                </Menu.Item> */}
                <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={handleLogout}
                >
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Logout
                  </Text>
                </Menu.Item>
              </LeadsWatchMenu>
            </HStack>
          </Pressable>
        </HStack>
      </HStack>
      <Modal
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
        }}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        // safeAreaTop={true}
      >
        <Modal.Content
          bg={"white"}
          maxWidth={aw(468)}
          maxHeight={ah(768)}
          height="full"
          style={{
            marginLeft: "auto",
            marginRight: 0,
            marginBottom: 0,
            marginTop: 0,
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            overflow: "visible",
            // zIndex: 1001,
          }}
        >
          {/* <HStack> */}{" "}
          {/* <Modal.CloseButton
            mt={0}
            width={aw(30)}
            height={ah(30)}
            color="white"
            style={{
              position: "relative",
              right: aw(45),
              top: ah(-15),
              backgroundColor: "#212121",
            }}
          /> */}
          <Modal.Header
            mt={0}
            style={{
              background: "#FFFFFF 0% 0% no-repeat padding-box",
              boxShadow: "0px 7px 10px #0000000D",
              borderRadius: "14px 14px 0px 0px",
              opacity: 1,
              borderColor: "#D7D7D7",
              borderWidth: 0,
            }}
          >
            <HStack>
              <Modal.CloseButton
                mt={0}
                width={aw(30)}
                height={ah(30)}
                color="white"
                style={{
                  position: "relative",
                  right: aw(60),
                  top: ah(-20),
                  backgroundColor: "#212121",
                }}
              />
              <Text
                // fontFamily={"RobotoMedium"}
                // fontSize={aw(20)}
                // color="#0D2238"
                style={{
                  fontFamily: "RobotoMedium",
                  fontSize: aw(20),
                  color: "#0D2238",
                }}
              >
                Profile
              </Text>
            </HStack>
          </Modal.Header>
          {/* </HStack> */}
          <Modal.Body>
            <VStack space={ah(28)}>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Name
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {profile && profile.firstname && profile.firstname}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Company
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {profile && profile.company && profile.company}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Email
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {profile && profile.email && profile.email}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Phone
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  {profile && profile.phone && profile.phone}
                </Text>
              </HStack>
              <HStack>
                <Text
                  width={aw(180)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Your Photo
                </Text>
                <HStack space={aw(23)}>
                  <Avatar
                    height={aw(50)}
                    width={aw(50)}
                    source={{
                      uri: localStorage.getItem("image"),
                    }}

                    // source={{
                    //   uri: image ? image.uri : null,
                    // }}
                  />
                  <VStack>
                    <Text
                      color="#212121"
                      fontFamily="RobotoMedium"
                      fontSize={aw(14)}
                      onPress={handleClick}
                    >
                      Click to upload
                    </Text>
                    <input
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                      type="file"
                      name="picture"
                      accept="image/png, image/jpg,image/jpeg"
                    ></input>
                    <Divider bg="#D7D7D7" thickness="2" width={aw(93)} />

                    <Text
                      color="#919191"
                      fontSize={aw(12)}
                      fontFamily="RobotoRegular"
                    >
                      Maximum file size 50 MB
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
            </VStack>
            <Divider my={ah(24)} bgColor="#EAEBEF" />
            <HStack justifyContent={"space-between"}>
              <Text
                fontFamily="RobotoRegular"
                fontSize={aw(14)}
                color="#494440"
              >
                password
              </Text>
              <VStack>
                <Text
                  onPress={handleEditProfile}
                  fontFamily="RobotoRegular"
                  fontSize={aw(14)}
                  color="#0000FF"
                >
                  Edit
                </Text>
                <Divider bgColor="#0000FF" />
              </VStack>
            </HStack>
            <Divider my={ah(24)} bgColor="#EAEBEF" />
            <Text fontFamily="RobotoRegular" fontSize={aw(14)} color="#494440">
              Configuration
            </Text>
            <HStack space={aw(89)}>
              <Text
                width={aw(120)}
                fontFamily={"RobotoRegular"}
                fontSize={aw(14)}
                color="#919191"
              >
                Custom Logo
              </Text>
              <HStack space={aw(28)}>
                <Avatar
                  height={aw(50)}
                  width={aw(50)}
                  // source={{
                  //   uri: image ? image.uri : null,
                  // }}
                />
                <VStack>
                  <Text
                    // onPress={pickImage}
                    color="#212121"
                    fontFamily="RobotoMedium"
                    fontSize={aw(14)}
                  >
                    Click to upload
                  </Text>
                  <Divider bg="#D7D7D7" thickness="2" width={aw(93)} />

                  <Text
                    color="#919191"
                    fontSize={aw(12)}
                    fontFamily="RobotoRegular"
                  >
                    Maximum file size 50 MB
                  </Text>
                </VStack>
              </HStack>
            </HStack>
            <VStack space={ah(28)}>
              <HStack space={aw(120)} mt={ah(42)}>
                <Text
                  width={aw(120)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Time Zone
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  GMT
                </Text>
              </HStack>
              <HStack space={aw(120)}>
                <Text
                  width={aw(120)}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  color="#919191"
                >
                  Email Notification
                </Text>
                <Text
                  color="#212121"
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                >
                  Active
                </Text>
              </HStack>
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <LeadsWatchModal
        width={aw(370)}
        height={ah(680)}
        showModal={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Reset password
          </Text>
        }
      >
        <VStack space={ah(20)}>
          <LeadsWatchFilterInput
            mt={ah(29)}
            style={{ width: aw(330), heigth: ah(40) }}
            name="password"
            placeholder="password"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            type={show ? "text" : "password"}
            rightIcon={
              !show ? (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setShow(!show)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
            invalid={errors.password ? true : false}
            error={errors.password ? errors.password.message : ""}
          />
          <LeadsWatchFilterInput
            mt={ah(29)}
            style={{ width: aw(330), heigth: ah(40) }}
            name="newpassword"
            placeholder="new password"
            type={nshow ? "text" : "password"}
            rightIcon={
              !nshow ? (
                <Pressable onPress={() => setNShow(!nshow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setNShow(!nshow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.newpassword ? true : false}
            error={errors.newpassword ? errors.newpassword.message : ""}
          />
          <LeadsWatchFilterInput
            mt={ah(29)}
            style={{ width: aw(330), heigth: ah(40) }}
            name="confirmpassword"
            placeholder="confirm password"
            control={control}
            type={cshow ? "text" : "password"}
            rightIcon={
              !cshow ? (
                <Pressable onPress={() => setcShow(!cshow)}>
                  <LeadsWatchEyeOffIcon />
                </Pressable>
              ) : (
                <Pressable onPress={() => setcShow(!cshow)}>
                  <LeadsWatchEyeIcon />
                </Pressable>
              )
            }
            rules={{
              required: true,
              message: "Enter a valid password",
              validate: (value) => value === getValues("newpassword"),
            }}
            invalid={errors.confirmpassword ? true : false}
            error={errors.confirmpassword ? errors.confirmpassword.message : ""}
          />
          <LeadsWatchButton
            label="reset password"
            style={{ width: aw(330), height: ah(36), borderRadius: aw(6) }}
            bg="#2230F8"
            borderColor="#D1D5DB"
            fontSize={aw(15)}
            color="#FFFFFF"
            onPress={handleSubmit(handleResetPassword)}
          />
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default LeadsWatchTopBar;
