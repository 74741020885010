import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchCalendarIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Calendar_icon" transform="translate(-26 -36)">
      <Rect
        id="Rectangle_37"
        data-name="Rectangle 37"
        width={aw(24)}
        height={ah(24)}
        transform="translate(26 36)"
        fill="none"
      />
      <Path
        id="Shape"
        d="M2.357,15A2.36,2.36,0,0,1,0,12.643V4.072A2.36,2.36,0,0,1,2.143,1.724V.643a.643.643,0,0,1,1.286,0V1.714H6.857V.643a.643.643,0,1,1,1.285,0V1.714h3.429V.643a.643.643,0,1,1,1.285,0V1.724A2.36,2.36,0,0,1,15,4.072v8.571A2.36,2.36,0,0,1,12.643,15ZM1.286,12.643a1.073,1.073,0,0,0,1.072,1.072H12.643a1.073,1.073,0,0,0,1.072-1.072V6.857H1.286ZM13.715,5.572v-1.5A1.073,1.073,0,0,0,12.643,3H2.357A1.073,1.073,0,0,0,1.286,4.072v1.5Z"
        transform="translate(30.5 40.5)"
        fill="#212121"
      />
    </G>
  </Svg>
);
export default LeadsWatchCalendarIcon;
