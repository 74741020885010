import { React, useState, useEffect } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
} from "native-base";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { aw, ah } from "../../utils";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";

import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import {
  getBuyerRoutes,
  getBuyerRouteById,
  getSingleBuyer,
  deleteBuyerRoute,
} from "../../store/actions/buyers";
import { addcallSchedule, addivrNum } from "../../store/actions/common";

const BuyerRoutes = ({ isOpen }) => {
  const location = useLocation();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [sort, setSort] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const singleBuyer = useSelector((state) => state.buyers.singleBuyer);
  const dispatch = useDispatch();
  const { bId, type } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(
    page && page.buyerRoute ? parseInt(page.buyerRoute) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.buyerRoutes ? parseInt(pagecount.buyerRoutes) : 10
  );
  const [clearSearch, setClearSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const buyerDetails = useSelector((state) => state.buyers.buyerRoutes);
  const { control } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.buyerRoutes
          ? pagecount.buyerRoutes.toString()
          : "10",
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues1("search"));

  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, buyerRoutes: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, buyerRoute: 1 }));
  };

  const fetchBuyerDetails = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getBuyerRoutes(
          currentPage,
          selectCount,
          bId,
          sort,
          { ...filters, type: type },
          search
        )
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  const fetchSingleBuyer = async () => {
    try {
      await dispatch(getSingleBuyer(bId));
    } catch (err) {
      console.log(err.message);
    }
  };
  useEffect(() => {
    fetchSingleBuyer();
  }, []);
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["buyer"] &&
        profile.permission_ids["buyer"].includes("view")
      ) {
        fetchBuyerDetails();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchBuyerDetails();
    }
  }, [currentPage, selectCount, sort, filters, search, type]);
  const handleSort = (order) => {
    setSort(order);
  };
  const handleEdit = async (item) => {
    try {
      await dispatch(getBuyerRouteById(bId, item._id));
      navigate(`/buyers/editBuyerRoute/${bId}/${item._id}/${type}`);
    } catch (err) {
      console.log(err);
    }
    // navigate(`/buyers/editBuyerRoute/${bId}/${item._id}`);
  };
  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteBuyerRoute(bId, id));
        setDeleteId(null);
        setConfirmModal(false);
        fetchBuyerDetails();
        toast.success("Buyer Route Deleted Successfully");
      } catch (err) {
        console.log(err.message);
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }

      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, buyerRoute: 1 }));
    }
  };
  useEffect(() => {
    if (type === "ivr" || type === "ppc") {
      dispatch(addcallSchedule([]));
      dispatch(addivrNum([]));
    }
  }, [type]);
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"flex-end"}
            >
              <HStack
                alignItems={"baseline"}
                //   width={aw(194)}
                space={aw(8)}
                justifyContent="space-between"
              >
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily={"RobotoMedium"}
                >
                  {singleBuyer
                    ? `${
                        singleBuyer["firstname"].charAt(0).toUpperCase() +
                        singleBuyer["firstname"].slice(1)
                      }'s Buyer Route`
                    : "Buyer Route"}
                </Text>
                <Text
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily={"RobotoMedium"}
                >
                  {buyerDetails && buyerDetails.total_count
                    ? buyerDetails.total_count
                    : 0}
                </Text>
              </HStack>
              <Hidden only={["sm", "base", "md"]}>
                <HStack space={aw(10)}>
                  <LeadsWatchSearch
                    placeholder={[
                      'Search by "Name"',
                      'Search by "Method"',
                      'Search by "Vertical"',
                      'Search by "Status"',
                    ]}
                    height={ah(36)}
                    control={control1}
                    name="search"
                    onFocus={() => setClearSearch(false)}
                    inputRightElement={
                      clearSearch ? (
                        <Pressable
                          w={aw(20)}
                          onPress={() => {
                            reset1();
                            setSearch(getValues1("search"));
                            setClearSearch(false);
                            setCurrentPage(1);
                            localStorage.setItem(
                              "page",
                              JSON.stringify({ ...page, buyerRoute: 1 })
                            );
                          }}
                        >
                          <LeadsWatchCrossIcon />
                        </Pressable>
                      ) : (
                        <Pressable
                          w={aw(30)}
                          onPress={handleSubmit1(handleSearch)}
                          // pr={aw(10)}
                        >
                          <LeadsWatchSearchIcon />
                        </Pressable>
                      )
                    }
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit1(
                          handleSearch({ search: getValues1("search") })
                        );
                      }
                    }}
                  />
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["buyer"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() =>
                      navigate(`/buyers/buyerRoutes/${bId}/create/${type}`)
                    }
                    label="create"
                    style={{ width: aw(101), height: ah(36) }}
                    bg="#2230F8"
                    borderColor="#2230F8"
                    color="#FFFFFF"
                    leftIcon={<LeadsWatchAddIcon />}
                  />
                </HStack>
              </Hidden>
            </HStack>
            <HStack space={aw(220)}>
              <Text
                lineHeight={ah(34)}
                fontSize={aw(14)}
                fontFamily={"RobotoRegular"}
                color={"#919191"}
                fontWeight={400}
              >
                All Your Buyers Routes list will be shown here
              </Text>
              <HStack space={aw(40)}>
                {["Lead", "IVR", "PPC"].map((val) => (
                  <Pressable
                    key={val}
                    borderBottomWidth={
                      location.pathname.includes(val.toLowerCase()) && aw(3)
                    }
                    borderColor={"#0000FF"}
                    onPress={() => {
                      navigate(
                        `/buyers/buyerRoutes/${bId}/${val.toLowerCase()}`
                      );
                      setCurrentPage(1);
                      localStorage.setItem(
                        "page",
                        JSON.stringify({ ...page, buyerRoute: 1 })
                      );
                    }}
                    height={ah(42)}
                  >
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      // color={type === val.toLowerCase() ? "#0000FF" : "#919191"}
                      color={
                        location.pathname.includes(val.toLowerCase())
                          ? "#0000FF"
                          : "#919191"
                      }
                      fontWeight={400}
                    >
                      {val}
                    </Text>
                  </Pressable>
                ))}
              </HStack>
            </HStack>
            <Divider
              // mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1.2)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} mt={ah(10)} zIndex={1}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base", "md"]}>
                <HStack space={aw(10)}>
                  <LeadsWatchSelect
                    control={control}
                    name={"status"}
                    placeholder="Status"
                    list={["Active", "InActive"]}
                    // value={filters["status"]}
                    handleChange={(value) => setFilters({ status: value })}
                    width={aw(100)}
                    // height={a(32)}
                  />
                  {/* <LeadsWatchMenu
                boxStyle={{
                  border: "1px solid #D1D5DB",
                  width: aw(150),
                  borderRadius: 5,
                  height: ah(36),
                }}
                width={aw(150)}
                // menuStyles={{ border: "1px solid #D1D5DB" }}
                title={
                  <HStack
                    width={aw(150)}
                    height={ah(36)}
                    // pl={aw(3)}
                    justifyContent="space-evenly"
                    alignItems={"center"}
                  >
                    <Text
                      fontSize={aw(14)}
                      color="#919191"
                      fontFamily="RobotoRegular"
                    >
                      Status
                    </Text>
                    <LeadsWatchDownArrow />
                  </HStack>
                }
              >
                <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={() => setFilters({ ...filters, status: "active" })}
                >
                  <Box
                    width={ah(10)}
                    height={ah(10)}
                    borderRadius="full"
                    backgroundColor={"#1AB173"}
                  ></Box>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    Active
                  </Text>
                </Menu.Item>
                <Menu.Item
                  bgColor="white"
                  _hover={{ bgColor: "#F5F7FB" }}
                  onPress={() => setFilters({ ...filters, status: "inactive" })}
                >
                  <Box
                    width={ah(10)}
                    height={ah(10)}
                    borderRadius="full"
                    backgroundColor={"#919191"}
                  ></Box>
                  <Text
                    fontSize={aw(14)}
                    fontFamily="RobotoRegular"
                    color="#212121"
                  >
                    inActive
                  </Text>
                </Menu.Item>
              </LeadsWatchMenu> */}
                </HStack>
              </Hidden>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <VStack zIndex={0}>
              <LeadsWatchTable
                isOpen={isOpen}
                handleDelete={handleDelete}
                sort={sort}
                screen={"buyer"}
                subScreen={"buyerRoute"}
                handleSort={handleSort}
                handleEdit={handleEdit}
                heading={
                  type === "lead"
                    ? ["name", "method", "vertical_name", "status", "Actions"]
                    : ["name", "group_name", "status", "Actions"]
                }
                sortIcon={true}
                data={
                  buyerDetails && buyerDetails.list ? buyerDetails.list : []
                }
                isLoading={isLoading}
              />
            </VStack>
            <VStack
              bottom={aw(5)}
              position="fixed"
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            >
              <LeadsWatchPagination
                width="100%"
                totalCount={
                  buyerDetails && buyerDetails.total_count
                    ? buyerDetails.total_count
                    : 1
                }
                currentPage={currentPage}
                pageSize={selectCount}
                onPageChange={(currentPage) => {
                  setCurrentPage(currentPage);
                  localStorage.setItem(
                    "page",
                    JSON.stringify({ ...page, buyerRoute: currentPage })
                  );
                }}
              />
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Buyer Route
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default BuyerRoutes;

{
  /* <VStack left={aw(30)} top={ah(8)}>
  <HStack lineHeight={ah(34)} space={aw(8)} alignItems={"flex-end"}> */
}
{
  /* <HStack justifyContent={"space-between"}> */
}
{
  /* <HStack>
      <Text fontSize={aw(26)} color={"#121233"}>
        Campaigns
      </Text>
      <Text fontSize={aw(18)} color={"#919191"}>
        {count}
      </Text>
    </HStack> */
}
{
  /* <LeadsWatchButton
              label="create"
              style={{ width: aw(101), height: ah(36) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              color="#FFFFFF"
              leftIcon={<LeadsWatchAddIcon />}
            /> */
}
{
  /* </HStack> */
}
{
  /* </HStack>

  <Text
    lineHeight={ah(34)}
    fontSize={aw(14)}
    fontFamily={"Roboto"}
    color={"#919191"}
    fontWeight={400}
  >
    Your all campaigns list will be shown here
  </Text>
  <Divider top={ah(10)} />
</VStack>; */
}
