import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchCrossFillIcon(props) {
  return (
    <Svg
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_53_23)">
        <Path
          d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
          fill="#F23B2E"
        />
        <Path
          d="M16.42 15.005a1 1 0 11-1.415 1.414L12 13.414 8.995 16.42a.997.997 0 01-1.414 0 1 1 0 010-1.414L10.586 12 7.581 8.995a1 1 0 111.414-1.414L12 10.586l3.005-3.005a1 1 0 111.414 1.414L13.414 12l3.005 3.005z"
          fill="#FAFAFA"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_53_23">
          <Path fill="#fff" d="M0 0H24V24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default LeadsWatchCrossFillIcon;
