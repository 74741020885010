import * as events from "../Events";
const initialState = {
  allPlans: null,
  allCards: null,
  allInvoices: null,
  planDetails: null,
  subscriptionDetails: null,
  subPlanDetails: null,
  autoReload: null,
};
export const subscriptionreducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_PLANS:
      return {
        ...state,
        allPlans: action.data,
      };
    case events.GET_ALL_CARDS:
      return {
        ...state,
        allCards: action.data,
      };
    case events.ADD_CARD:
      return {
        ...state,
        newCard: action.data,
      };

    case events.Default_CARD:
      return {
        ...state,
      };
    case events.DELETE_CARD:
      return {
        ...state,
      };
    case events.GET_INVOICES:
      return {
        ...state,
        allInvoices: action.data,
      };
    case events.GET_PLAN_DETAILS:
      return {
        ...state,
        planDetails: action.data,
      };
    case events.ADD_SUBSCRIPTION:
      return {
        ...state,
        subscriptionDetails: action.data,
      };
    case events.CANCEL_SUBSCRIPTION:
      return {
        ...state,
      };
    case events.AUTO_RELOAD_CREDITS:
      return {
        ...state,
        autoReload: action.data,
      };
      case events.REMOVE_INVOICES:
        return {
          ...state,
          allInvoices: null,
        }
    default:
      return state;
  }
};
