import React from "react";
import { Button, Actionsheet } from "native-base";
import { aw, ah } from "../utils";
const LeadsWatchActionSheet = (props) => {
  return (
    <>
      <Actionsheet
        isOpen={props.isOpen}
        onClose={props.onClose}
        // disableOverlay
        width={props.width || aw(600)}
        alignSelf={"center"}
      >
        <Actionsheet.Content
          minHeight={props.minHeight || ah(500)}
          width="100%"
          alignItems={props.alignItems || "flex-start"}
        >
          {props.children}
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};
export default LeadsWatchActionSheet;
