import {
  Text,
  VStack,
  HStack,
  ScrollView,
  Box,
  FlatList,
  Pressable,
  Spinner,
  Tooltip,
  Switch,
  Flex,
} from "native-base";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment/moment";

import LeadsWatchCard from "../../components/LeadsWatchCard";
import { aw, ah } from "../../utils";
import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import {
  getAllInvitedMembers,
  inviteTeamMembers,
  updateInvitedMember,
  getAllTeamMembers,
  deleteInviteMember,
} from "../../store/actions/team";
import { getallInvitedpub } from "../../store/actions/publishers";
import { toast } from "react-toastify";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";

const Team = ({ isOpen }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const [value, setValue] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "all",
  });
  const invitedpubs = useSelector(
    (state) => state.publishers.invitedPublishers
  );
  const invitedMembers = useSelector((state) => state.team.allInvites);
  const teamMembers = useSelector((state) => state.team.teamMembers);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);
  const [editId, setEditId] = useState("");
  const [pubLoading, setPubLoading] = useState(false);
  const [invitedModalOpen, setInvitedModalOpen] = useState(false);
  const [permissions, setPermissions] = useState({
    lead: [],
    vertical: [],
    campaign: [],
    publisher: [],
    buyer: [],
    invite: [],
    call: [],
    number: [],
    import: [],
    stats: [],
    role: "",
  });
  const dispatch = useDispatch();

  const fetchInvitedMembers = async () => {
    setIsLoading(true);
    try {
      await dispatch(getAllInvitedMembers());
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  const fetchinvitedPub = async () => {
    setPubLoading(true);
    try {
      await dispatch(getallInvitedpub());
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setPubLoading(false);
  };
  useEffect(() => {
    fetchInvitedMembers();
    fetchinvitedPub();
  }, []);
  const handleInvite = async (data) => {
    setSave(true);
    const finalData = editId
      ? { permission_ids: { ...permissions, role: data.permission } }
      : {
          email: data.inviteEmail,
          permission_ids: { ...permissions, role: data.permission },
          role: "user",
        };
    if (editId) {
      try {
        if (editId.from === "invited") {
          await dispatch(
            updateInvitedMember(editId.from, editId.id, finalData)
          );
          await dispatch(getAllInvitedMembers());

          toast.success("Invited Member Updated Successfully");
        } else {
          await dispatch(
            updateInvitedMember(editId.from, editId.id, finalData)
          );
          await dispatch(getAllTeamMembers());
          toast.success("Team Member Updated Successfully");
        }

        reset({ inviteEmail: "", permission: "" });
        setValue("");
        setEditId("");
        setInvitedModalOpen(false);
        setPermissions({
          lead: [],
          vertical: [],
          campaign: [],
          publisher: [],
          buyer: [],
          invite: [],
          call: [],
          number: [],
          import: [],
          stats: [],
          role: "",
        });
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(inviteTeamMembers(finalData));

        setInvitedModalOpen(false);
        setPermissions({
          lead: [],
          vertical: [],
          campaign: [],
          publisher: [],
          buyer: [],
          invite: [],
          call: [],
          number: [],
          import: [],
          stats: [],
          role: "",
        });
        reset({ inviteEmail: "", permission: "" });
        setValue("");
        await dispatch(getAllInvitedMembers());
        toast.success("Invited Member Added Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
    setSave(false);
  };
  const handleEdit = (from, id, email, pIds) => {
    setEditId({ id, from });
    reset({
      inviteEmail: email,
      permission: pIds.role,
    });
    setValue(pIds.role);
    setPermissions(pIds);
    setInvitedModalOpen(true);
  };
  const handleDelete = async (from, id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        if (from === "invited") {
          await dispatch(deleteInviteMember(from, id));

          toast.success("Invited Member Deleted Successfully");
          setConfirmModal(false);
          setDeleteId(null);
          await dispatch(getAllInvitedMembers());
        } else {
          await dispatch(deleteInviteMember(from, id));

          toast.success("Team Member Deleted Successfully");
          setConfirmModal(false);
          setDeleteId(null);
          await dispatch(getAllTeamMembers());
        }
      } catch (err) {
        setDeleteId(null);
        setConfirmModal(false);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId({ from, id });
    }
  };
  const handleChangePermission = (val) => {
    if (Object.keys(permissions).includes(val.toLocaleLowerCase())) {
      if (permissions[val.toLocaleLowerCase()].length === 0) {
        if (
          val === "Call" ||
          val === "Number" ||
          val === "Import" ||
          val === "Stats"
        ) {
          if (val === "Call") {
            setPermissions({
              ...permissions,
              call: ["calls"],
            });
          } else if (val === "Number") {
            setPermissions({
              ...permissions,
              number: ["buy", "delete"],
            });
          } else if (val === "Stats") {
            setPermissions({
              ...permissions,
              stats: ["view"],
            });
          } else {
            setPermissions({
              ...permissions,
              import: ["leads"],
            });
          }
        } else {
          if (value === "admin") {
            setPermissions({
              ...permissions,
              [val.toLocaleLowerCase()]: ["view", "create", "update", "delete"],
            });
          } else {
            setPermissions({
              ...permissions,
              [val.toLocaleLowerCase()]: ["view"],
            });
          }
        }
      } else {
        if (value === "admin") {
          if (val === "Vertical" || val === "Publisher" || val === "Buyer") {
            if (val === "Vertical" || val === "Buyer") {
              setPermissions({
                ...permissions,
                [val.toLocaleLowerCase()]: [],
                buyer: [],
                campaign: [],
              });
            } else {
              setPermissions({
                ...permissions,
                [val.toLocaleLowerCase()]: [],

                campaign: [],
              });
            }
          } else {
            setPermissions({
              ...permissions,
              [val.toLocaleLowerCase()]: [],
            });
          }
        } else {
          setPermissions({
            ...permissions,
            [val.toLocaleLowerCase()]: [],
          });
        }
      }
    } else {
      if (val === "Stats") {
        setPermissions({ ...permissions, stats: ["view"] });
      }
    }
  };

  return (
    <>
      <ScrollView maxHeight={ah(550)}>
        <Box marginTop={ah(30)}>
          <LeadsWatchCard
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1235)]
            }
            height={ah(257)}
            // alignItems={"flex-start"}
          >
            <Flex width="100%" px={aw(15)}>
              <HStack justifyContent={"space-between"} alignItems={"baseline"}>
                <Text
                  style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                  color="#121233"
                  mt={ah(15)}
                >
                  Invited Team Members
                </Text>
                <Tooltip
                  label={"No Access"}
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["invite"].includes("create")
                        ? true
                        : false
                      : true
                  }
                >
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["invite"].includes("create")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => setInvitedModalOpen(true)}
                    label={"Invite Team Member"}
                    style={{
                      width: aw(166),
                      height: ah(40),
                      borderRadius: "6px",
                    }}
                    color="#FFFFFF"
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    bg={"#2230F8"}
                    fontWeight="400"
                  />
                </Tooltip>
              </HStack>
              <HStack
                mt={ah(10)}
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                }}
              >
                {["Email", "Invitation Code", "User ID", "Actions"].map(
                  (val) => {
                    return (
                      <HStack
                        key={val}
                        alignItems={"center"}
                        width={aw(300)}
                        justifyContent="flex-start"
                      >
                        <Text
                          //   width={aw(300)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      </HStack>
                    );
                  }
                )}
              </HStack>
              {!isLoading ? (
                <ScrollView
                  mb={aw(20)}
                  maxHeight={ah(120)}
                  // showsVerticalScrollIndicator={false}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    // showsVerticalScrollIndicator={false}
                    // height={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(110)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={
                      invitedMembers &&
                      invitedMembers.list &&
                      invitedMembers.list
                    }
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) => (
                      <VStack key={index}>
                        <HStack
                          height={ah(60)}
                          alignItems={"center"}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                            minHeight: ah(58),
                            borderTopWidth: index === 0 ? 0 : "1px",
                            borderLeftWidth: "0px",
                            borderRightWidth: "0px",
                            borderBottomWidth: "0px",
                            borderColor: "#D7D7D7",
                          }}
                          width={aw(1200)}
                        >
                          <Text
                            color="#121233"
                            ml={aw(20)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            width={aw(300)}
                          >
                            {item.email}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item.invitation_code}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item._id}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            <Tooltip
                              label={"No Access"}
                              isDisabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["invite"].includes(
                                      "update"
                                    )
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Pressable
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["invite"].includes(
                                        "update"
                                      )
                                      ? false
                                      : true
                                    : false
                                }
                                onPress={() =>
                                  handleEdit(
                                    "invited",
                                    item._id,
                                    item.email,
                                    item.permission_ids
                                  )
                                }
                              >
                                <LeadsWatchEditIcon
                                  color={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "invite"
                                        ].includes("update")
                                        ? "#212121"
                                        : "#C1C1C1"
                                      : "#212121"
                                  }
                                />
                              </Pressable>
                            </Tooltip>
                            <Tooltip
                              label={"No Access"}
                              isDisabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["invite"].includes(
                                      "delete"
                                    )
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Pressable
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["invite"].includes(
                                        "delete"
                                      )
                                      ? false
                                      : true
                                    : false
                                }
                                onPress={() =>
                                  handleDelete("invited", item._id)
                                }
                              >
                                <LeadsWatchDeleteIcon
                                  color={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "invite"
                                        ].includes("delete")
                                        ? "#212121"
                                        : "#C1C1C1"
                                      : "#212121"
                                  }
                                />
                              </Pressable>
                            </Tooltip>
                          </Text>
                        </HStack>
                      </VStack>
                    )}
                  />
                </ScrollView>
              ) : (
                <HStack
                  mb={aw(20)}
                  justifyContent="center"
                  alignItems={"center"}
                  height={ah(120)}
                  style={{
                    border: "1px solid #D7D7D7",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </Flex>
          </LeadsWatchCard>
        </Box>
        <Box marginTop={ah(30)}>
          <LeadsWatchCard
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1235)]
            }
            height={ah(410)}
            // alignItems={"flex-start"}
          >
            <Flex width="100%" px={aw(15)}>
              <Text
                style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                color="#121233"
                mt={ah(15)}
              >
                Team Members
              </Text>
              <HStack
                mt={ah(10)}
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                }}
              >
                {["Email", "Invitation Code", "User ID", "Actions"].map(
                  (val) => {
                    return (
                      <HStack
                        key={val}
                        alignItems={"center"}
                        width={aw(300)}
                        justifyContent="flex-start"
                      >
                        <Text
                          //   width={aw(300)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      </HStack>
                    );
                  }
                )}
              </HStack>
              {!loading ? (
                <ScrollView
                  mb={aw(20)}
                  maxHeight={ah(280)}
                  // showsVerticalScrollIndicator={false}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    // showsVerticalScrollIndicator={false}
                    // height={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(270)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={teamMembers && teamMembers.list && teamMembers.list}
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) => (
                      <VStack key={index}>
                        <HStack
                          height={ah(60)}
                          alignItems={"center"}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                            minHeight: ah(58),
                            borderTopWidth: index === 0 ? 0 : "1px",
                            borderLeftWidth: "0px",
                            borderRightWidth: "0px",
                            borderBottomWidth: "0px",
                            borderColor: "#D7D7D7",
                          }}
                          width={aw(1200)}
                        >
                          <Text
                            color="#121233"
                            ml={aw(20)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            width={aw(300)}
                          >
                            {item.email}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item.invitation_code}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item._id}
                          </Text>
                          <Text
                            ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            <Tooltip
                              label={"No Access"}
                              isDisabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["invite"].includes(
                                      "update"
                                    )
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Pressable
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["invite"].includes(
                                        "update"
                                      )
                                      ? false
                                      : true
                                    : false
                                }
                                onPress={() =>
                                  handleEdit(
                                    "team",
                                    item._id,
                                    item.email,
                                    item.permission_ids
                                  )
                                }
                              >
                                <LeadsWatchEditIcon
                                  color={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "invite"
                                        ].includes("update")
                                        ? "#212121"
                                        : "#C1C1C1"
                                      : "#212121"
                                  }
                                />
                              </Pressable>
                            </Tooltip>
                            <Tooltip
                              label={"No Access"}
                              isDisabled={
                                profile && profile.role === "user"
                                  ? profile.permission_ids["invite"].includes(
                                      "delete"
                                    )
                                    ? true
                                    : false
                                  : true
                              }
                            >
                              <Pressable
                                isDisabled={
                                  profile && profile.role === "user"
                                    ? profile.permission_ids["invite"].includes(
                                        "delete"
                                      )
                                      ? false
                                      : true
                                    : false
                                }
                                onPress={() => handleDelete("team", item._id)}
                              >
                                <LeadsWatchDeleteIcon
                                  color={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "invite"
                                        ].includes("delete")
                                        ? "#212121"
                                        : "#C1C1C1"
                                      : "#212121"
                                  }
                                />
                              </Pressable>
                            </Tooltip>
                          </Text>
                        </HStack>
                      </VStack>
                    )}
                  />
                </ScrollView>
              ) : (
                <HStack
                  mb={aw(20)}
                  justifyContent="center"
                  alignItems={"center"}
                  height={ah(300)}
                  style={{
                    border: "1px solid #D7D7D7",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </Flex>
          </LeadsWatchCard>
        </Box>
        <Box my={ah(30)}>
          <LeadsWatchCard
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1235)]
            }
            height={ah(257)}
            // alignItems={"flex-start"}
          >
            <Flex width="100%" px={aw(15)}>
              <HStack justifyContent={"space-between"} alignItems={"baseline"}>
                <Text
                  style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                  color="#121233"
                  mt={ah(15)}
                >
                  Invited Publishers
                </Text>
              </HStack>
              <HStack
                mt={ah(10)}
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                }}
              >
                {["Email", "Invitation Code", "User ID", "Created"].map(
                  (val, index) => {
                    return (
                      <HStack
                        key={val}
                        alignItems={"center"}
                        width={aw(300)}
                        justifyContent="flex-start"
                      >
                        <Text
                          //   width={aw(300)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={index === 0 ? aw(20) : 0}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      </HStack>
                    );
                  }
                )}
              </HStack>
              {!pubLoading ? (
                <ScrollView
                  mb={aw(20)}
                  maxHeight={ah(120)}
                  // showsVerticalScrollIndicator={false}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    // showsVerticalScrollIndicator={false}
                    // height={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(110)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={invitedpubs ? invitedpubs : []}
                    keyExtractor={(item, index) => index}
                    renderItem={({ item, index }) => (
                      <VStack key={index}>
                        <HStack
                          height={ah(60)}
                          alignItems={"center"}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                            minHeight: ah(58),
                            borderTopWidth: index === 0 ? 0 : "1px",
                            borderLeftWidth: "0px",
                            borderRightWidth: "0px",
                            borderBottomWidth: "0px",
                            borderColor: "#D7D7D7",
                          }}
                          // width={aw(1200)}
                        >
                          <Text
                            color="#121233"
                            pl={aw(20)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                            width={aw(300)}
                          >
                            {item.email}
                          </Text>
                          <Text
                            // ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item.invitation_code}
                          </Text>
                          <Text
                            // ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item._id}
                          </Text>

                          <Text
                            // ml={aw(20)}
                            color="#121233"
                            width={aw(300)}
                            fontSize={aw(14)}
                            fontFamily={"RobotoMedium"}
                          >
                            {item.created
                              ? moment(item.created).format("DD-MM-YYYY")
                              : "_"}
                          </Text>
                        </HStack>
                      </VStack>
                    )}
                  />
                </ScrollView>
              ) : (
                <HStack
                  mb={aw(20)}
                  justifyContent="center"
                  alignItems={"center"}
                  height={ah(120)}
                  style={{
                    border: "1px solid #D7D7D7",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </Flex>
          </LeadsWatchCard>
        </Box>
        {/* </ScrollView> */}
        <LeadsWatchModal
          width={aw(570)}
          height={ah(480)}
          showModal={invitedModalOpen}
          onClose={() => {
            setInvitedModalOpen(false);

            setPermissions({
              lead: [],
              vertical: [],
              campaign: [],
              publisher: [],
              buyer: [],
              invite: [],
              call: [],
              number: [],
              stats: [],
              import: [],
            });
            reset({ inviteEmail: "", permission: "" });
            setValue("");
            setEditId("");
          }}
          heading={
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              {editId ? "update Permissions" : "Invite Team Member"}
            </Text>
          }
        >
          <VStack>
            <HStack
              space={ah(20)}
              py={ah(20)}
              justifyContent={"center"}
              px={aw(10)}
            >
              <LeadsWatchFilterInput
                isReadOnly={editId ? true : false}
                screen={
                  getValues("inviteEmail") &&
                  getValues("inviteEmail").length > 0
                    ? "edit"
                    : "create"
                }
                // style={{ heigth: ah(40), width: aw(400) }}
                width={aw(250)}
                height={ah(40)}
                control={control}
                type="email"
                name="inviteEmail"
                placeholder="Enter Email"
                rules={{
                  required: {
                    value: true,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                }}
                invalid={errors.inviteEmail ? true : false}
                error={errors.inviteEmail ? errors.inviteEmail.message : ""}
              />
              <LeadsWatchSelect
                handleChange={(value) => {
                  setValue(value);
                  setPermissions({
                    lead: [],
                    vertical: [],
                    campaign: [],
                    publisher: [],
                    buyer: [],
                    invite: [],
                    call: [],
                    number: [],
                    stats: [],
                    import: [],
                  });
                }}
                control={control}
                name="permission"
                width={aw(150)}
                height={ah(40)}
                placeholder="Select Role"
                list={["Admin", "User"]}
              />
              <LeadsWatchButton
                isLoading={save}
                label={editId ? "Update" : "Send Invite"}
                style={{ height: ah(40), width: aw(100) }}
                bg="#2230F8"
                borderColor="#D1D5DB"
                color="#FFFFFF"
                onPress={handleSubmit(handleInvite)}
              />
            </HStack>

            {getValues("inviteEmail") && !errors.inviteEmail && value ? (
              <>
                <VStack space={ah(18)} px={aw(10)}>
                  <Text
                    color="#212121"
                    fontFamily="RobotoMedium"
                    fontSize={aw(15)}
                  >
                    Permissions
                  </Text>

                  <VStack>
                    {[
                      "Lead",
                      "Campaign",
                      "Publisher",
                      "Buyer",
                      "Vertical",
                      "invite",
                      "Call",
                      "Number",
                      "Import",
                      "Stats",
                    ].map((val, index) => (
                      <VStack key={val} mt={index === 0 ? 0 : ah(10)}>
                        {getValues("permission") === "admin" ? (
                          <HStack
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Text
                              color="#212121"
                              fontFamily="RobotoMedium"
                              fontSize={aw(15)}
                            >
                              {val === "Stats" ? "Dashboard" : val}
                            </Text>
                            <Switch
                              disabled={
                                (getValues("permission") === "admin" &&
                                  val === "Campaign") ||
                                val === "Buyer"
                                  ? val === "Campaign"
                                    ? permissions["vertical"].length > 0 &&
                                      permissions["publisher"].length > 0 &&
                                      permissions["buyer"].length > 0
                                      ? false
                                      : true
                                    : permissions["vertical"].length > 0
                                    ? false
                                    : true
                                  : false
                              }
                              isChecked={
                                Object.keys(permissions).includes(
                                  val.toLowerCase()
                                ) && permissions[val.toLowerCase()].length > 0
                                  ? true
                                  : false
                              }
                              onToggle={() => handleChangePermission(val)}
                              _hover={{
                                offTrackColor: "#C1C1C1",
                                onTrackColor: "#0000FF",
                              }}
                              onTrackColor="#0000FF"
                              offTrackColor="#C1C1C1"
                            />
                          </HStack>
                        ) : (
                          val !== "Number" &&
                          val !== "Import" && (
                            <HStack
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Text
                                color="#212121"
                                fontFamily="RobotoMedium"
                                fontSize={aw(15)}
                              >
                                {val === "Stats" ? "Dashboard" : val}
                              </Text>
                              <Switch
                                isChecked={
                                  Object.keys(permissions).includes(
                                    val.toLocaleLowerCase()
                                  ) && permissions[val.toLowerCase()].length > 0
                                    ? true
                                    : false
                                }
                                _hover={{
                                  offTrackColor: "#C1C1C1",
                                  onTrackColor: "#0000FF",
                                }}
                                onTrackColor="#0000FF"
                                offTrackColor="#C1C1C1"
                                onToggle={() => handleChangePermission(val)}
                              />
                            </HStack>
                          )
                        )}
                        {getValues("permission") === "admin" ? (
                          val === "Lead" ||
                          val === "Stats" ||
                          val === "Call" ||
                          val === "Number" ||
                          val === "Import" ? (
                            val === "Call" ||
                            val === "Number" ||
                            val === "Lead" ||
                            val === "Stats" ? (
                              val === "Call" || val === "Number" ? (
                                val === "Call" ? (
                                  <Text
                                    color="#919191"
                                    fontFamily="RobotoRegular"
                                    fontSize={aw(14)}
                                  >
                                    Can recieve the Calls
                                  </Text>
                                ) : (
                                  <Text
                                    color="#919191"
                                    fontFamily="RobotoRegular"
                                    fontSize={aw(14)}
                                  >
                                    Can buy and delete Numbers
                                  </Text>
                                )
                              ) : (
                                <Text
                                  color="#919191"
                                  fontFamily="RobotoRegular"
                                  fontSize={aw(14)}
                                >
                                  Can view the{" "}
                                  {val === "Stats" ? "Dashboard" : `${val}`}
                                </Text>
                              )
                            ) : (
                              <Text
                                color="#919191"
                                fontFamily="RobotoRegular"
                                fontSize={aw(14)}
                              >
                                Can import the Leads
                              </Text>
                            )
                          ) : (
                            <Text
                              color="#919191"
                              fontFamily="RobotoRegular"
                              fontSize={aw(14)}
                            >
                              {`Can add,delete,edit,view the ${val}`}
                            </Text>
                          )
                        ) : (
                          val !== "Number" &&
                          val !== "Import" &&
                          (val === "Call" ? (
                            <Text
                              color="#919191"
                              fontFamily="RobotoRegular"
                              fontSize={aw(14)}
                            >
                              Can recieve the Calls
                            </Text>
                          ) : (
                            <Text
                              color="#919191"
                              fontFamily="RobotoRegular"
                              fontSize={aw(14)}
                            >
                              {`Can view the ${
                                val === "Stats" ? "Dashboard" : val
                              }`}
                            </Text>
                          ))
                        )}
                      </VStack>
                    ))}
                  </VStack>
                </VStack>
                {/* {editId && (
                <LeadsWatchButton
                  isLoading={save}
                  label="update"
                  style={{ height: ah(40) }}
                  bg="#2230F8"
                  borderColor="#D1D5DB"
                  color="#FFFFFF"
                  onPress={handleSubmit(handleInvite)}
                />
              )} */}
              </>
            ) : null}
          </VStack>
        </LeadsWatchModal>
      </ScrollView>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            {`Are you sure you want to delete this ${
              deleteId && deleteId.from
            } member`}
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId.from, deleteId.id)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};

export default Team;
