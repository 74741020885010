import * as events from "../Events";
const initialState = {
  users: null,
  user: null,
  profile: null,
  updatedProfile: null,
  allEmails: null,
  isVerified: null,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.REGISTER:
      return {
        ...state,
        users: action.data,
      };
    case events.SET_USER:
      return {
        ...state,
        user: action.data,
      };
    case events.LOGOUT:
      console.log("logout");
      return {
        ...state,
        user: null,
      };
    case events.FORGOT_PASSWORD:
      return {
        ...state,
      };
    case events.RESET_PASSWORD:
      return {
        ...state,
      };
    case events.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.data,
      };
    case events.GET_PROFILE:
      return {
        ...state,
        profile: action.data,
      };
    case events.REMOVE_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case events.EDIT_PROFILE:
      return {
        ...state,
      };
    case events.UPDATE_LOGO:
      return {
        ...state,
      };
    case events.EMAIL_SEND:
      return {
        ...state,
        allEmails: action.data,
      };
    case events.GET_OTP:
      return {
        ...state,
      };
    case events.VERIFY_OTP:
      return {
        ...state,
        isVerified: action.data,
      };
    case events.SELECT_USER:
      return {
        ...state,
      };
    case events.DELETE_ACCOUNT:
      return {
        ...state,
      };
    default:
      return state;
  }
};
