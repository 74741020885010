import { Text, VStack, HStack, Box } from "native-base";
import React from "react";
import { useForm } from "react-hook-form";

import LeadsWatchCard from "../../components/LeadsWatchCard";
import { aw, ah } from "../../utils";

import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchInput from "../../components/LeadsWatchInput";

const BuyerCards = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    value,
    getValues,
  } = useForm({
    // mode: "onBlur",
    mode: "all",
  });
  return (
    <>
      <Box marginTop={ah(30)}>
        <LeadsWatchCard
          width={aw(1235)}
          height={ah(188)}
          alignItems={"flex-start"}
        >
          <VStack pl={aw(20)} space={ah(15)}>
            <HStack
              justifyContent={"space-between"}
              alignItems={"baseline"}
              width={aw(1200)}
            >
              <Text
                style={{ fontFamily: "RobotoMedium", fontSize: aw(18) }}
                color="#121233"
                mt={ah(15)}
              >
                Payment Details
              </Text>
              <LeadsWatchButton
                label={"Submit"}
                style={{
                  width: aw(100),
                  height: ah(40),
                  borderRadius: "6px",
                }}
                color="#FFFFFF"
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                bg={"#2230F8"}
                fontWeight="400"
              />
            </HStack>
            <VStack mb={ah(20)} space={ah(20)}>
              <HStack space={aw(20)}>
                <LeadsWatchSelect
                  control={control}
                  name={"payment"}
                  placeholder={"Configure your payment settings"}
                  list={["active", "inactive"]}
                  width={aw(587)}
                  height={ah(40)}
                />
                <LeadsWatchInput
                  control={control}
                  name={"api_key"}
                  placeholder={"API Key"}
                  style={{ width: aw(587), height: ah(40) }}
                />
              </HStack>
              <HStack space={aw(20)}>
                <LeadsWatchSelect
                  control={control}
                  name={"payment"}
                  placeholder={"Configure your payment settings"}
                  list={["active", "inactive"]}
                  width={aw(587)}
                  height={ah(40)}
                />
                <LeadsWatchInput
                  control={control}
                  name={"api_key"}
                  placeholder={"API Key"}
                  style={{ width: aw(587), height: ah(40) }}
                />
              </HStack>
            </VStack>
          </VStack>
        </LeadsWatchCard>
      </Box>
    </>
  );
};

export default BuyerCards;
