import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchEditHeaderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
    >
      <g transform="translate(-26 -36)">
        <path
          fill="none"
          d="M0 0H24V24H0z"
          data-name="Rectangle 37"
          transform="translate(26 36)"
        ></path>
        <path
          fill="#5451de"
          d="M-2.848-9.193l5.5-5.5a2.242 2.242 0 011.648-.688 2.185 2.185 0 011.626.686l.9.9a2.185 2.185 0 01.686 1.626 2.185 2.185 0 01-.686 1.626L1.283-5.01a2.16 2.16 0 01-.694.466 2.125 2.125 0 01-.835.167h-2.76a.541.541 0 01-.4-.167.484.484 0 01-.149-.4l.07-2.795a1.977 1.977 0 01.167-.791 1.989 1.989 0 01.47-.663zm8.895-3.832l-.9-.9a1.161 1.161 0 00-.853-.352 1.161 1.161 0 00-.853.352l-.457.457 2.6 2.6.457-.457a1.161 1.161 0 00.352-.853 1.161 1.161 0 00-.346-.847zM4.8-10.09l-2.6-2.6-4.274 4.27a1.028 1.028 0 00-.22.325.993.993 0 00-.079.4l-.07 2.215h2.2a1.034 1.034 0 00.36-.062.978.978 0 00.305-.188l.088-.053zm-5.292-5.256a.526.526 0 01.387.158.526.526 0 01.158.387.538.538 0 01-.141.369.5.5 0 01-.334.176l-.07.018h-2.76a3.024 3.024 0 00-2.25.853 3.3 3.3 0 00-.9 2.294V-4.8a3.406 3.406 0 00.8 2.355 2.98 2.98 0 002.171.967h6.68a2.994 2.994 0 002.241-.853 3.32 3.32 0 00.888-2.294V-7.77a.512.512 0 01.167-.387.556.556 0 01.4-.158.559.559 0 01.369.132.48.48 0 01.176.343v3.04a5.317 5.317 0 01-.281 1.758 4.349 4.349 0 01-.826 1.38A3.712 3.712 0 015.1-.738a4.525 4.525 0 01-1.67.369h-6.68a4.462 4.462 0 01-1.707-.317 3.987 3.987 0 01-1.327-.87 3.948 3.948 0 01-.87-1.344 5.008 5.008 0 01-.352-1.723v-6.293a4.94 4.94 0 01.3-1.758 4.2 4.2 0 01.826-1.389 3.768 3.768 0 011.28-.914 4.525 4.525 0 011.67-.369h2.938z"
          data-name="Path 10270"
          transform="translate(38 56)"
        ></path>
      </g>
    </svg>
  );
}

export default LeadsWatchEditHeaderIcon;
