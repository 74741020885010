import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllCampaigns = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/campaigns?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sort: sort,
            filters: filters,
            search: search,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allCampaigns = resData.data;
        dispatch({ type: events.GET_ALL_CAMPAIGNS, data: allCampaigns });
        return allCampaigns ? allCampaigns : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getBuyersByVertical = (id, page, limit, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${
          events.API_URL
        }/buyers/vertical/${id}?page=${page}&limit=${limit}&search=${
          search ? search : ""
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const verticalBuyers = resData.data;
        dispatch({ type: events.GET_BUYERS_BY_VERTICAL, data: verticalBuyers });
        return verticalBuyers ? verticalBuyers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getBuyersByType = (type, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/type/${type}?page=${page}&limit=${limit}}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const verticalBuyers = resData.data;
        dispatch({ type: events.GET_BUYERS_BY_VERTICAL, data: verticalBuyers });
        return verticalBuyers ? verticalBuyers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const createCampaign = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/campaigns/new/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const newCampaign = resData.data;
        dispatch({ type: events.CREATE_CAMPAIGN, data: newCampaign });
        return newCampaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getSingleCampaign = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/campaigns/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const singleCampaign = resData.data;
        dispatch({ type: events.GET_SINGLE_CAMPAIGN, data: singleCampaign });
        return singleCampaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editCampaign = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/campaigns/new/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedCampaign = resData.data;
        dispatch({ type: events.EDIT_CAMPAIGN, data: editedCampaign });
        return editedCampaign ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteCampaign = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/campaigns/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedData = resData.data;
        dispatch({ type: events.DELETE_CAMPAIGN, data: deletedData });
        return deletedData ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
