import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchSingleProfileIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <g data-name="Group 1096">
        <path
          fill="#212121"
          d="M17.81 15.69a6.151 6.151 0 00-2.17-3.07 6.3 6.3 0 00-1.43-.8 3.331 3.331 0 001.17-2.56 3.38 3.38 0 10-5.59 2.56 5.753 5.753 0 00-1.43.8 6.125 6.125 0 00-2.17 3.07 1.828 1.828 0 00.28 1.66 1.857 1.857 0 001.5.76h8.05a1.867 1.867 0 001.78-2.42zm-1.27.93a.635.635 0 01-.51.25H7.98a.635.635 0 01-.51-.25.622.622 0 01-.09-.56 4.848 4.848 0 019.26 0 .617.617 0 01-.09.56zM9.86 9.27A2.14 2.14 0 1112 11.41a2.142 2.142 0 01-2.14-2.14z"
          data-name="Path 10373"
        ></path>
        <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 892"></path>
      </g>
    </svg>
  );
}

export default LeadsWatchSingleProfileIcon;
