import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchPrevIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-926 -530)">
        <Path
          data-name="Rectangle 414"
          transform="translate(926 530)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10226"
          d="M8.118-7.2l3.717 3.708-1.062 1.062L6-7.2l4.77-4.77 1.062 1.053z"
          transform="translate(929 549)"
          fill={props.disabled ? "#B4B4B4" : "#0d2238"}
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchPrevIcon;
