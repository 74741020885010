// // import React, { useState } from "react";
// // import { Input, Text, Box } from "native-base";

// // const LeadsWatchInputField = (props) => {
// //   const [show, setShow] = useState(true);
// //   return props.type === "box" ? (
// //     <Box mt={10}>
// //       <Input
// //         name={props.name}
// //         placeholder={show ? props.placeholder : ""}
// //         bgColor={props.bgColor || "#fffff"}
// //         width={props.width || "100%"}
// //         height={props.height}
// //         _focus={{
// //           ...props._focus,
// //           borderColor: "#00000050",
// //         }}
// //         value={props.value}
// //         InputLeftElement={props.InputLeftElement}
// //         InputRightElement={props.InputRightElement}
// //         onChangeText={props.onChangeText}
// //         autoFocus={props.autoFocus || false}
// //         variant={props.variant || "outline"}
// //         rounded={props.rounded}
// //         type={props.type || "text"}
// //         onFocus={() => {
// //           setShow(false);
// //         }}
// //         onBlur={() => {
// //           props.value !== "" ? setShow(false) : setShow(true);
// //           props.onBlur();
// //         }}
// //         style={{ ...styles.input, ...props.styles }}
// //         position="relative"
// //         ref={props.ref}
// //         error={props.error}
// //         autoCapitalize={props.autoCapitalize}
// //       />
// //       <Box
// //         position="absolute"
// //         top={-13}
// //         left={2}
// //         bgColor="white"
// //         px={show ? 0 : 1}
// //       >
// //         {show ? "" : <Text>{props.placeholder}</Text>}
// //       </Box>
// //     </Box>
// //   ) : (
// //     <Input
// //       placeholder={props.placeholder}
// //       bgColor={props.bgColor || "#fffff"}
// //       width={props.width || "100%"}
// //       height={props.height}
// //       value={props.value}
// //       onChangeText={props.onChangeText}
// //       _focus={{
// //         ...props._focus,
// //         borderColor: "#00000050",
// //       }}
// //       InputLeftElement={props.InputLeftElement}
// //       InputRightElement={props.InputRightElement}
// //       onFocus={props.onFocus}
// //       onBlur={props.onBlur}
// //       autoFocus={props.autoFocus || false}
// //       variant={props.variant || "outline"}
// //       rounded={props.rounded}
// //       ref={props.ref}
// //       error={props.error}
// //       autoCapitalize={props.autoCapitalize}
// //       type={props.type || "text"}
// //       style={{ ...styles.input, ...props.styles }}
// //     />
// //   );
// // };

// // export default LeadsWatchInputField;

// // const styles = {
// //   input: {
// //     fontSize: 20,
// //     fontFamily: "roboto",
// //   },
// // };

import React from "react";
import { Input, FormControl } from "native-base";
import { Controller } from "react-hook-form";
import { aw, ah } from "../utils";

const LeadsWatchInput = React.forwardRef((props, ref) => {
  return (
    <FormControl
      onFocus={props.onFocus}
      isRequired={props.isRequired}
      isInvalid={props.invalid}
      isReadOnly={props.isReadOnly || false}
      style={{
        // height: ah(48),
        ...props.style,
      }}
    >
      {props.label && (
        <FormControl.Label
          style={{
            fontSize: aw(13),
            fontFamily: "RobotoMedium",
            // color: props.invalid ? "red.500" : "rgba(102, 102, 102, 0.7)",
            color: "#212121",
            // height: ah(19),
            ...props.labelstyle,
          }}
        >
          {props.label}
        </FormControl.Label>
      )}

      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        // style={{
        //   zIndex: 1,
        // }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholderTextColor={"#919191"}
            isDisabled={props.isDisabled || false}
            color="black"
            tabIndex={props.tabIndex}
            isReadOnly={props.isReadOnly || false}
            ref={ref}
            size={props.size || "lg"}
            multiline={props.multiline || false}
            numberOfLines={props.numberOfLines || 1}
            minLength={props.minLength || 1}
            maxLength={props.maxLength || 255}
            isInvalid={props.invalid}
            autoComplete="off"
            autoFocus={props.autoFocus || false}
            type={props.type || "text"}
            onSubmitEditing={props.onSubmitEditing}
            onKeyPress={props.onKeyPress}
            borderRadius={props.borderRadius}
            borderColor={props.borderColor || "#D1D5DB"}
            onChangeText={(value) => {
              onChange(value);
              if (props.onlyNumbers) {
                // if (props.float) {
                onChange(
                  value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
                );
                if (props.onChangeText) {
                  props.onChangeText(value);
                }
                // } else {
                //   onChange(value.replace(/\D/g, ""));
                //   if (props.onChangeText) {
                //     props.onChangeText();
                //   }
                // }
              } else {
                onChange(props.toCaptlize ? value.toUpperCase() : value);
                if (props.onChangeText) {
                  props.onChangeText(value);
                }
              }
            }}
            _focus={{
              backgroundColor: "white",
            }}
            onBlur={() => {
              onBlur();
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            value={value || ""}
            variant={"outline"}
            invalidOutlineColor={"#FA4E2D"}
            focusOutlineColor={props.focusOutlineColor || "#0000FF"}
            _disabled={{
              borderColor: props.disabledBorderColor || "#9BA6B2",
              _hover: {
                borderColor: props.disabledBorderColor || "#9BA6B2",
              },
              color: "#919191",
            }}
            _invalid={{
              borderColor: "#FA4E2D",
              borderWidth: aw(1),
            }}
            _light={{
              borderWidth: aw(1),
            }}
            _dark={{
              borderWidth: aw(1),
            }}
            // _web={{
            //   borderWidth: aw(1),
            // }}
            style={{
              // fontSize: aw(15),
              fontFamily: "RobotoRegular",
              backgroundColor: "white",
              zIndex: 1,
              height: props.height || ah(36),
              ...props.textStyles,
            }}
            _hover={{
              borderColor: props.borderColor || "#D1D5DB",
            }}
            InputLeftElement={props.leftIcon || null}
            InputRightElement={props.rightIcon || null}
            placeholder={props.placeholder}
          />
        )}
      />
      <FormControl.ErrorMessage>{props.error}</FormControl.ErrorMessage>
      <FormControl.HelperText>{props.help}</FormControl.HelperText>
    </FormControl>
  );
});

export default LeadsWatchInput;
