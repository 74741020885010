import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw1, ah1 } from "../../utils";
const LeadsWatchEmailIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw1(24)}
    height={ah1(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Email_active_icon" transform="translate(-268 -407)">
      <Rect
        id="Rectangle_287"
        data-name="Rectangle 287"
        width={aw1(24)}
        height={ah1(24)}
        transform="translate(268 407)"
        fill="none"
      />
      <G id="Message" transform="translate(271.5 411.438)">
        <Path
          id="Stroke_1"
          data-name="Stroke 1"
          d="M9.977,0,6.215,3.059a1.945,1.945,0,0,1-2.421,0L0,0"
          transform="translate(3.487 4.954)"
          fill="none"
          stroke={props.color || "#919191"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        <Path
          id="Stroke_3"
          data-name="Stroke 3"
          d="M12.622,15.239c2.575.007,4.31-2.108,4.31-4.708V4.716A4.407,4.407,0,0,0,12.622,0H4.31A4.407,4.407,0,0,0,0,4.716V10.53c0,2.6,1.735,4.716,4.31,4.708Z"
          transform="translate(0 0)"
          fill="none"
          stroke={props.color || "#919191"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
      </G>
    </G>
  </Svg>
);
export default LeadsWatchEmailIcon;
