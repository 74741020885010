import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getLeads = (page, limit, filters) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/leads?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          filters: filters,
          
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allLeads = resData.data;
        dispatch({ type: events.LEADS_REPORT, data: allLeads });
        return allLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getVerticals = (page, limit, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/verticals?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          filters: filters,
          search: search,       
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allVerticals = resData.data;
        dispatch({ type: events.VERTICALS_REPORT, data: allVerticals });
        return allVerticals ? allVerticals : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getPublishers = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/publishers?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPublishers = resData.data;
        dispatch({ type: events.PUBLISHERS_REPORT, data: allPublishers });
        return allPublishers ? allPublishers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getCampaigns = (page, limit, sort, filters, search) => {
  console.log("filters", filters);
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/campaigns?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({          
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();
        const allCampaigns = resData.data;
        dispatch({ type: events.CAMPAIGNS_REPORT, data: allCampaigns });
        return allCampaigns ? allCampaigns : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getBuyers = (page, limit,  filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/buyers?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({        
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allBuyers = resData.data;
        dispatch({ type: events.BUYERS_REPORT, data: allBuyers });
        return allBuyers ? allBuyers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getBuyerRoutes = (page, limit, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/routes?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({        
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allBuyerRoutes = resData.data;
        dispatch({ type: events.BUYER_ROUTES_REPORT, data: allBuyerRoutes });
        return allBuyerRoutes ? allBuyerRoutes : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getVerticalFields = (page, limit, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/fields?page=${page}&limit=${limit}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({         
          filters: filters,
          search: search,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allVerticalFields = resData.data;
        dispatch({ type: events.VERTICAL_FIELDS, data: allVerticalFields });
        return allVerticalFields ? allVerticalFields : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const download = (page, limit, data, name, type) => {
  const url = `${events.API_URL}/reports/${name}?download_type=${type}
  `;
  return async (dispatch) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        // let resData = [];
        const resData = await response.blob();

        const exportedLeads = window.URL.createObjectURL(
          new Blob([resData], {
            type:
              type === "csv"
                ? "text/csv"
                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        let a = document.createElement("a");
        a.href = exportedLeads;
        a.download = `${name}.${type}`;
        a.click();

        dispatch({ type: events.HANDLE_DOWNLOAD });
        return exportedLeads ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
