import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllBuyers = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sort: sort,
            filters: filters,
            search: search,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allBuyers = resData.data;
        dispatch({ type: events.GET_ALL_BUYERS, data: allBuyers });
        return allBuyers ? allBuyers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getSingleBuyer = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/buyers/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const singlebuyer = resData.data;
        dispatch({ type: events.GET_SINGLE_BUYER, data: singlebuyer });
        return singlebuyer ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const removeSingleBuyerRoute = () => {
  return {
    type: events.REMOVE_SINGLE_BUYER,
  };
};
export const createBuyer = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/buyers/create`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newBuyer = resData.data;
        dispatch({ type: events.ADD_BUYER, data: newBuyer });
        return newBuyer ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const editBuyer = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/buyers/${id}`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedBuyer = resData.data;
        dispatch({ type: events.EDIT_BUYER, data: editedBuyer });
        return editedBuyer ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteBuyer = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/buyers/${id}`, {
        method: "DELETE",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedBuyer = resData.data;
        dispatch({ type: events.DELETE_BUYER, data: deletedBuyer });
        return deletedBuyer ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getBuyerRoutes = (page, limit, id, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/${id}/routes?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sort: sort,
            filters: filters,
            search: search,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const buyerRoutes = resData.data;
        dispatch({ type: events.BUYER_DETAILS, data: buyerRoutes });
        return buyerRoutes ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const createBuyerRoute = (data, id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/${id}/routes/new/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newBuyerRoute = resData.data;
        dispatch({ type: events.CREATE_BUYER_ROUTE, data: newBuyerRoute });
        return newBuyerRoute ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getBuyerRouteById = (id, bId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/${id}/routes/${bId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const buyerRoute = resData.data;
        dispatch({ type: events.SINGLE_BUYER_ROUTE, data: buyerRoute });
        return buyerRoute ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editBuyerRoute = (id, bId, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/${id}/routes/${bId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedRoute = resData.data;
        dispatch({ type: events.EDIT_BUYER_ROUTE, data: editedRoute });
        return editedRoute ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteBuyerRoute = (id, bId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/buyers/${id}/routes/${bId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedBuyer = resData.data;
        dispatch({ type: events.DELETE_BUYER_ROUTE, data: deletedBuyer });
        return deletedBuyer ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
