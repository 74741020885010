import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getAllPublishers = (page, limit, sort, filters, search) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/publishers?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            sort: sort,
            filters: filters,
            search: search,
          }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPublishers = resData.data;
        dispatch({ type: events.GET_ALL_PUBLISHERS, data: allPublishers });
        return allPublishers ? allPublishers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getallInvitedpub = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/invited-publishers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allPublishers = resData.data;
        dispatch({
          type: events.GET_ALL_INVITED_PUBLISHERS,
          data: allPublishers,
        });
        return allPublishers ? allPublishers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const addPublisher = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/publishers/create`, {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newPublisher = resData.data;
        dispatch({ type: events.ADD_PUBLISHER, data: newPublisher });
        return newPublisher ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getSinglePublisher = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/publishers/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const singlePub = resData.data;
        dispatch({ type: events.GET_SINGLE_PUBLISHER, data: singlePub });
        return singlePub ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editPublisher = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/publishers/${id}`, {
        method: "PATCH",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedPub = resData.data;
        dispatch({ type: events.EDIT_PUBLISHER, data: editedPub });
        return editedPub ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const invitePublishers = (email) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/invite`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ email: email, role: "publisher" }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const editedPub = resData.data;
        dispatch({ type: events.EDIT_PUBLISHER, data: editedPub });
        return editedPub ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deletePublisher = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/publishers/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const deletedPub = resData.data;
        dispatch({ type: events.DELETE_PUBLISHER, data: deletedPub });
        return deletedPub ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
