import * as React from "react";
import Svg, { G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-26 -36)">
        <Path
          data-name="Rectangle 37"
          transform="translate(26 36)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10294"
          d="M11.25-15.012a6.424 6.424 0 012.637.563 7.091 7.091 0 012.145 1.406 7.511 7.511 0 011.441 2.18A6.535 6.535 0 0118-8.262a6.894 6.894 0 01-.6 2.848 6.559 6.559 0 01-1.723 2.25 6.715 6.715 0 01-2.461 1.371l.387-.352a2.188 2.188 0 00.492-.773 2.452 2.452 0 00.141-.844v-.176A5.256 5.256 0 0015.891-5.8a5.164 5.164 0 00.6-2.461 5.126 5.126 0 00-.422-2.039 4.952 4.952 0 00-1.09-1.652 5.32 5.32 0 00-1.687-1.125 5.126 5.126 0 00-2.042-.423 4.97 4.97 0 00-1.863.352A5.08 5.08 0 007.8-12.2a5.168 5.168 0 00-1.16 1.406A5.4 5.4 0 006.047-9H4.5a2.877 2.877 0 00-2.109.879 2.877 2.877 0 00-.879 2.109 3.126 3.126 0 00.246 1.266 2.715 2.715 0 00.7.949 3 3 0 00.984.6 2.821 2.821 0 001.058.209h.879a3.036 3.036 0 00.527.844l.668.633H4.5a4.368 4.368 0 01-2.25-.6A4.38 4.38 0 01.6-3.727 4.558 4.558 0 010-6.012a4.492 4.492 0 01.6-2.25 4.65 4.65 0 011.65-1.652 4.368 4.368 0 012.25-.6h.387a6.566 6.566 0 011.406-2.32 6.282 6.282 0 012.215-1.582 6.492 6.492 0 012.742-.596z"
          transform="translate(29 56)"
          fill="#212121"
        />
        <Path
          data-name="Path 10320"
          d="M9.738-9a.715.715 0 01.527.211.739.739 0 01.246.527V-3.3l.949-.984a.715.715 0 01.527-.211.84.84 0 01.563.211.715.715 0 01.211.527.84.84 0 01-.211.563L10.3-.949a.84.84 0 01-.562.211.715.715 0 01-.527-.211L6.961-3.2a.84.84 0 01-.211-.562.8.8 0 01.211-.527.8.8 0 01.527-.211.84.84 0 01.563.211L9-3.3v-4.962a.8.8 0 01.211-.527A.8.8 0 019.738-9z"
          transform="rotate(180 24.256 23.131)"
          fill="#212121"
        />
      </G>
    </Svg>
  );
}

export default SvgComponent;
