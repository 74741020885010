import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

import { aw, ah } from "../../utils";

function LeadsWatchAddPersonIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-282 -612)">
        <Path
          data-name="Rectangle 391"
          transform="translate(282 612)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10346"
          d="M-1.955-18.8a4.651 4.651 0 00-1.891.387 5.117 5.117 0 00-1.547 1.043 4.825 4.825 0 00-1.053 1.558 4.887 4.887 0 00-.365 1.891 5.048 5.048 0 00.365 1.912 5.02 5.02 0 001.053 1.547 4.724 4.724 0 001.547 1.03 4.651 4.651 0 001.891.387 4.7 4.7 0 001.912-.387A4.724 4.724 0 001.5-10.463a5.02 5.02 0 001.057-1.547 5.048 5.048 0 00.365-1.912 4.887 4.887 0 00-.365-1.891A4.825 4.825 0 001.5-17.37a5.117 5.117 0 00-1.543-1.042 4.7 4.7 0 00-1.912-.388zm0 1.375A3.378 3.378 0 01.526-16.4a3.378 3.378 0 011.021 2.481A3.378 3.378 0 01.526-11.44a3.378 3.378 0 01-2.481 1.02 3.353 3.353 0 01-2.46-1.021 3.378 3.378 0 01-1.021-2.481A3.378 3.378 0 01-4.415-16.4a3.353 3.353 0 012.46-1.024zm0 10.055a24.6 24.6 0 00-3.072.17 8.489 8.489 0 00-2.256.569 3.291 3.291 0 00-1.4 1.042 2.5 2.5 0 00-.494 1.568 2.5 2.5 0 00.497 1.572 3.291 3.291 0 001.4 1.042 9.045 9.045 0 002.277.591 24.077 24.077 0 003.051.172h.6a22.043 22.043 0 002.813-.237 7.777 7.777 0 002.095-.591 3.1 3.1 0 001.278-1.02A2.5 2.5 0 005.285-4a2.485 2.485 0 00-.473-1.547A3.153 3.153 0 003.47-6.585a8.473 8.473 0 00-2.181-.591 24.933 24.933 0 00-2.943-.193zm0 1.375a14.4 14.4 0 014.383.5Q3.91-4.984 3.91-4T2.428-2.514a14.7 14.7 0 01-4.383.494 14.309 14.309 0 01-4.361-.5Q-7.8-3.029-7.8-4.018q0-.967 1.4-1.461a13.483 13.483 0 014.146-.516zM6.6-13.363a.647.647 0 01.462.172.74.74 0 01.226.43v1.225h1.2a.661.661 0 01.483.2.661.661 0 01.2.483.686.686 0 01-.171.476.625.625 0 01-.43.215H7.283V-9a.679.679 0 01-.193.483.651.651 0 01-.494.2.658.658 0 01-.451-.172.625.625 0 01-.215-.43L5.908-9v-1.16H4.727a.679.679 0 01-.483-.193.651.651 0 01-.2-.494.658.658 0 01.172-.451.625.625 0 01.43-.215l.086-.021h1.176v-1.139a.661.661 0 01.2-.483.661.661 0 01.492-.207z"
          transform="translate(294 634)"
          fill="#212121"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchAddPersonIcon;
