import { Box } from "native-base";
import React from "react";
import { ah } from "../utils";

const LeadsWatchCard = (props) => {
  return (
    // <Pressable>
    <Box
      ml={0.5}
      px={props.px}
      maxH={props.height}
      height={props.h}
      minH={props.minH}
      pb={props.pb}
      pt={props.pt}
      width={props.width || 141}
      style={{
        // borderRadius: props.borderRadius,
        borderWidth: props.borderWidth,
        borderColor: props.borderColor,
        alignItems: props.alignItems || "center",

        backgroundColor: props.backgroundColor || "#FFFFFF",
        shadowColor: props.shadow ? null : props.shadowColor || "#0000001A",
        shadowOffset: props.shadow
          ? null
          : props.shadowOffset || { width: "0px", height: "2px" },
        shadowRadius: props.shadow ? null : props.shadowRadius || "10px",
        borderRadius: props.borderRadius || "14px",
      }}
    >
      {props.children}
    </Box>
    // </Pressable>
  );
};

export default LeadsWatchCard;
