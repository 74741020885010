import {
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  FlatList,
  Switch,
  ScrollView,
  Pressable,
  Spinner,
  Flex,
} from "native-base";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  Elements,
  CardCvcElement,
  CardNumberElement,
  // CardElement,
  useElements,
  useStripe,
  // injectStripe,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import moment from "moment/moment";

import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchButton from "../../components/LeadsWatchButton";

import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchTickIcon from "../../components/svg/LeadsWatchTickIcon";
import { stripePromise } from "../../utils";
import { getProfile } from "../../store/actions/auth";
import { API_URL } from "../../store/Events";
import {
  deleteCard,
  getAllCards,
  getAllInvoices,
  defaultCard,
  addAutoReload,
} from "../../store/actions/subscription";
import LeadsWatchRemoveCardIcon from "../../components/svg/LeadsWatchRemoveCardIcon";
import AddCredits from "./AddCredits";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";

// import AddCard from "./AddCard";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
const StripeWrapper = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
const CardForm = ({
  setIsAddCardModalOpen,
  control,
  reset,
  handleSubmit,
  errors,
  getValues,
  refresh,
  setRefresh,

  isAddCardModalOpen,
}) => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("auth");
  const elements = useElements();
  const stripe = useStripe();
  const [save, setSave] = useState(false);
  const [phoneNo, setPhoneNo] = useState(null);
  const cardNumberElement = elements && elements.getElement(CardNumberElement);
  const cardExpiryElement = elements && elements.getElement(CardExpiryElement);
  const cardCvcElement = elements && elements.getElement(CardCvcElement);
  useEffect(() => {
    if (isAddCardModalOpen === false) {
      setPhoneNo("in");
      cardNumberElement && cardNumberElement.clear();
      cardExpiryElement && cardExpiryElement.clear();
      cardCvcElement && cardCvcElement.clear();
    }
  }, [isAddCardModalOpen]);
  const handleCreateCard = async (data) => {
    setSave(true);
    // Check if Stripe is loaded
    if (!stripe || !elements) {
      setSave(false);
      alert("Stripe is not loaded");
      return;
    }

    // Create a PaymentMethod with Card information

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        address: {
          city: data.city,
          country: data.country,
          line1: data.address1,
          line2: data.address2,
          postal_code: data.zipcode,
          state: data.state,
        },
      },
    });
    if (paymentMethod) {
      try {
        const response = await fetch(`${API_URL}/pm/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ id: paymentMethod.id }),
        });
        if (!response.ok) {
          const resError = await response.json();

          if (resError && resError.error) {
            throw new Error(resError.error);
          }
        }

        const data = await response.json();

        if (data.data.clientSecret) {
          const confirm = await stripe.confirmCardPayment(
            data.data.clientSecret
          );
          if (confirm.error) {
            console.error(confirm.error);
          }
        }
        //   alert("Payment Successful! Subscription active.");
        setPhoneNo("in");
        setSave(false);
        reset({
          address: "",
          address2: "",
          card_CVC: "",
          country: "",
          expiry: "",
          number: "",
          city: "",
          phone: "91",
          state: "",
          zipcode: "",
        });

        setRefresh(!refresh);
        // clearErrors();
        await dispatch(getAllCards());
        setIsAddCardModalOpen(false);
        cardNumberElement.clear();
        cardExpiryElement.clear();
        cardCvcElement.clear();
        toast.success("Payment Method Added Successfully");
      } catch (err) {
        setSave(false);
        toast.error(err.message.toLowerCase());
      }
    }
    if (error) {
      setSave(false);

      toast.error(error.message.toLowerCase());

      return;
    }
  };

  return (
    <VStack space={4}>
      <Text color="black" fontSize={aw(12)} fontFamily="RobotoRegular">
        Billing Details
      </Text>
      <LeadsWatchFilterInput
        refresh={refresh}
        screen={
          getValues("address") && getValues("address").length > 0
            ? "edit"
            : "create"
        }
        name="address"
        placeholder="Address Line 1"
        control={control}
        rules={{
          required: {
            value: true,
          },
        }}
        invalid={errors.address ? true : false}
        error={errors.address ? errors.address.message : ""}
      />
      <LeadsWatchFilterInput
        onChange={() => {}}
        refresh={refresh}
        screen={
          getValues("address2") && getValues("address2").length > 0
            ? "edit"
            : "create"
        }
        name="address2"
        placeholder="Address Line 2"
        control={control}
        // rules={{
        //   required: {
        //     value: true,
        //   },
        // }}
        // invalid={errors.address2 ? true : false}
        // error={errors.address2 ? errors.address2.message : ""}
      />
      <HStack zIndex={1}>
        <Controller
          control={control}
          name={"phone"}
          style={{
            width: aw(573),
            heigth: ah(40),
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <PhoneInput
              color="#212121"
              className={"phoneInput"}
              country={"in"}
              isValid={(inputNumber, country, countries) => {
                setPhoneNo(country);
                return !errors.phone;
              }}
              placeholder="Enter PhoneNumber"
              value={value}
              containerStyle={{
                borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",
              }}
              inputStyle={{
                borderWidth: errors.phone ? 2 : 1,
                borderRadius: aw(5),
                width: aw(573),
                height: ah(10),
                fontFamily: "RobotoRegular",
                backgroundColor: "#fff",
                // borderWidth: 0,
                fontSize: aw(15),
                borderColor: errors.phone ? "#FA4E2D" : "#D1D5DB",

                // borderColor: !errors.phone && "#D1D5DB",
              }}
              onChange={(value, data) => {
                onChange(value);
                // onChange(value);
              }}
              onBlur={() => {
                onBlur();
              }}
              dropdownStyle={{
                height: ah(150),
                width: aw(573),
              }}
            />
          )}
          // rules={{
          //   required: {
          //     value: true,
          //     // validate: (value) =>
          //     //   phoneNo &&
          //     //   phoneNo.format.match(/\./g).length === value.length,
          //     message: "Enter a valid password",
          //     validate: (value) =>
          //       value.length === phoneNo.format.match(/\./g).length,
          //   },
          // }}

          rules={{
            required: {
              value: true,
            },

            validate: (value) => {
              return (
                value && value.length === phoneNo.format.match(/\./g).length
              );
            },
          }}
          invalid={errors.phone ? true : false}
          // invalid={true}
          error={errors.phone ? errors.phone.message : ""}
        />
      </HStack>
      <HStack space={aw(16)}>
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("city") && getValues("city").length > 0
              ? "edit"
              : "create"
          }
          width={aw(280)}
          style={{ width: aw(280) }}
          name="city"
          placeholder="City"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          invalid={errors.city ? true : false}
          error={errors.city ? errors.city.message : ""}
        />
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          width={aw(275)}
          screen={
            getValues("state") && getValues("state").length > 0
              ? "edit"
              : "create"
          }
          style={{ width: aw(140) }}
          name="state"
          placeholder="State"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          invalid={errors.state ? true : false}
          error={errors.state ? errors.state.message : ""}
        />
      </HStack>
      <HStack space={aw(16)}>
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("zipcode") && getValues("zipcode").length > 0
              ? "edit"
              : "create"
          }
          maxLength={10}
          width={aw(280)}
          style={{ width: aw(280) }}
          name="zipcode"
          placeholder="Zipcode"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          invalid={errors.zipcode ? true : false}
          error={errors.zipcode ? errors.zipcode.message : ""}
        />
        <LeadsWatchFilterInput
          onChange={() => {}}
          refresh={refresh}
          screen={
            getValues("country") && getValues("country").length > 0
              ? "edit"
              : "create"
          }
          width={aw(275)}
          style={{ width: aw(275) }}
          name="country"
          placeholder="Country"
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
          maxLength={2}
          toCaptlize={true}
          invalid={errors.country ? true : false}
          error={errors.country ? errors.country.message : ""}
        />
      </HStack>

      {/* <CardElement /> */}

      <HStack space={aw(10)}>
        <VStack
          style={{
            width: "60%",
            borderColor: "rgba(0,0,0,0.15)",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,

            justifyContent: "center",
          }}
        >
          <CardNumberElement />
        </VStack>
        <Box
          style={{
            width: aw(105),
            borderColor: "#D1D5DB",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,
          }}
        >
          <CardExpiryElement />
        </Box>
        <Box
          style={{
            width: aw(105),
            borderColor: "#D1D5DB",
            borderWidth: aw(1),
            padding: aw(9),
            borderRadius: 4,
          }}
        >
          <CardCvcElement />
        </Box>
      </HStack>

      <HStack space={aw(5)} justifyContent={"flex-end"}>
        <LeadsWatchButton
          label="cancel"
          style={{ height: ah(40), width: aw(100) }}
          onPress={() => {
            //   setId("");
            setIsAddCardModalOpen(false);
            reset({
              address: "",
              address2: "",
              card_CVC: "",
              country: "",
              expiry: "",
              number: "",
              city: "",
              phone: "91",
              state: "",
              zipcode: "",
            });

            setPhoneNo("in");
            setRefresh(!refresh);
            cardNumberElement.clear();
            cardExpiryElement.clear();
            cardCvcElement.clear();
          }}
        />
        <LeadsWatchButton
          isLoading={save}
          onPress={handleSubmit(handleCreateCard)}
          // onPress={createSubscription}
          label="save"
          bg={"#0000FF"}
          color="white"
          style={{ height: ah(40), width: aw(100) }}
        />
      </HStack>
    </VStack>
  );
};

const Credits = ({ isOpen }) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const profile = useSelector((state) => state.auth.profile);
  const [toogle, setToogle] = useState(
    profile && profile.reload_credits ? true : false
  );
  const [refresh, setRefresh] = useState(false);
  const [save, setSave] = useState(false);
  const [paymentError, setPayMentError] = useState(false);

  const allCards = useSelector((state) => state.subscriptions.allCards);
  const invoices = useSelector((state) => state.subscriptions.allInvoices);
  const [openCreditsModal, setOpenCreditsModal] = useState(false);
  const [defaultCardId, setDefaultCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDefaultCardModal, setOpenDefaultCardModal] = useState(false);
  const [invoiceLoading, setInvoicesLoading] = useState(true);

  const dispatch = useDispatch();

  const [openAutoReloadModal, setOpenAutoReloadModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    control,
    getValues,
    handleSubmit,
    // formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    defaultValues: {
      reload_limit:
        profile && profile.credit_threshold
          ? profile.credit_threshold.toString()
          : "",
    },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    getValues: getValues1,

    formState: { errors: errors1 },

    reset: reset1,
  } = useForm({
    mode: "all",
  });
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);

  const getCardsData = async () => {
    try {
      await dispatch(getAllCards());
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };
  const getInvoicesData = async () => {
    setInvoicesLoading(true);
    try {
      await dispatch(getAllInvoices("credits", currentPage));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setInvoicesLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role !== "user") {
      getCardsData();
    }
  }, []);
  useEffect(() => {
    getInvoicesData();
  }, [currentPage]);
  const handleDeleteCard = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      const data = { id: id };
      try {
        await dispatch(deleteCard(data));
        getCardsData();
        setConfirmModal(false);

        toast.success("Card Deleted Successfully");
      } catch (err) {
        toast.error(err.message.toLowerCase());
        setConfirmModal(false);
      }

      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleAddDefaulrCard = () => {
    setLoading(true);
    try {
      dispatch(defaultCard({ id: defaultCardId }));
      getCardsData();
      setDefaultCardId(null);
      setOpenDefaultCardModal(false);
      getCardsData();
    } catch (err) {
      toast.error(err.message.toLowerCase());
      setDefaultCardId(null);
      setOpenDefaultCardModal(false);
    }
    setLoading(false);
  };
  const handleAutoReload = async (data) => {
    setSave(true);
    try {
      await dispatch(
        addAutoReload({
          reload_credits: toogle,
          credit_threshold: parseFloat(data.reload_limit),
        })
      );

      await dispatch(getProfile());
      toast.success("Now you will send alerts when you reach the threshold");

      setOpenAutoReloadModal(false);
      setToogle(profile && profile.reload_credits ? true : false);
      reset({
        reload_limit:
          profile && profile.credit_threshold
            ? profile.credit_threshold.toString()
            : "",
      });
      !toogle && setRefresh(!refresh);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setSave(false);
  };
  return (
    <ScrollView
      // showsVerticalScrollIndicator={false}
      height={ah(550)}
      //   paddingBottom={ah(300)}
    >
      <VStack height={ah(540)}>
        <Box mt={ah(20)}>
          <LeadsWatchCard
            // width={aw(1248)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            // width={[aw(350), aw(350), aw(1248)]}
            // left={isOpen1 ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
            height={ah(700)}
            alignItems="flex-start"
          >
            <Flex
              width={"100%"}
              px={aw(20)}
              my={aw(20)}
              justifyContent={"space-between"}
              direction="row"
            >
              <VStack width="63%">
                <HStack
                  justifyContent={"space-around"}
                  alignItems={"center"}
                  // width={aw(750)}
                  borderWidth={aw(1)}
                  borderColor={"#E6E7E9"}
                  borderRadius={aw(10)}
                  height={ah(200)}
                >
                  <VStack
                    height={aw(100)}
                    justifyContent="center"
                    space={ah(5)}
                  >
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(15)}
                      fontFamily={"RobotoRegular"}
                      color={"black"}
                      fontWeight={400}
                    >
                      Credits Remaining
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(30)}
                      fontFamily={"RobotoMedium"}
                      color={"black"}
                      fontWeight={400}
                      alignSelf={"center"}
                    >
                      {profile && profile.credits
                        ? parseFloat(profile.credits).toFixed(4)
                        : 0}
                    </Text>
                    {/* <Text
                      lineHeight={ah(34)}
                      fontSize={aw(15)}
                      fontFamily={"RobotoRegular"}
                      color={"black"}
                      fontWeight={400}
                    >
                      Spent a day ago
                    </Text> */}
                  </VStack>
                  <VStack
                    space={ah(20)}
                    height={aw(100)}
                    justifyContent="center"
                  >
                    <LeadsWatchButton
                      isDisabled={
                        profile && profile.role === "user" ? true : false
                      }
                      onPress={() => {
                        if (allCards.length) {
                          setOpenCreditsModal(true);
                        } else {
                          setPayMentError(true);
                          toast.error("Please Add Payment Method");
                        }
                      }}
                      label="Add Credits"
                      style={{ width: aw(120), height: ah(32) }}
                      bg={"#0000FF"}
                      color="#F9FAFB"
                    />
                    <LeadsWatchButton
                      isDisabled={
                        profile && profile.role === "user" ? true : false
                      }
                      onPress={() => {
                        setOpenAutoReloadModal(true);
                      }}
                      label="Set Auto Reload"
                      style={{ width: aw(120), height: ah(32) }}
                    />
                  </VStack>
                </HStack>

                {profile && profile.role !== "user" && (
                  <VStack>
                    <Text
                      mt={ah(10)}
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"black"}
                      fontWeight={400}
                    >
                      Payment Methods
                    </Text>

                    <HStack
                      space={aw(20)}
                      mb={ah(23)}
                      width={aw(750)}
                      display="flex"
                      flexWrap={"wrap"}
                      distance={2}
                    >
                      {/* <ScrollView horizontal> */}
                      {allCards &&
                        allCards.map((val, index) => (
                          <Pressable
                            key={index}
                            onPress={() => {
                              val.default !== true &&
                                setOpenDefaultCardModal(true);
                              setDefaultCardId(val.id);
                            }}
                            mb={aw(10)}
                            position="relative"
                            borderRadius={aw(10)}
                            p={aw(10)}
                            width={aw(200)}
                            height={ah(100)}
                            borderColor={
                              val.default === true ? "#0000FF" : "#C1C1C1"
                            }
                            borderWidth={"2px"}
                          >
                            <Text
                              lineHeight={ah(34)}
                              fontSize={aw(14)}
                              fontFamily={"RobotoRegular"}
                              color={"black"}
                              fontWeight={400}
                            >
                              Card {index + 1}
                            </Text>
                            <HStack space={aw(10)}>
                              <Box
                                bgColor="#0000001A"
                                height={ah(18)}
                                width={aw(30)}
                              ></Box>
                              <HStack
                                alignItems={"center"}
                                justifyContent={"center"}
                                height={ah(18)}
                              >
                                <Text
                                  lineHeight={ah(34)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoRegular"}
                                  color={"black"}
                                  fontWeight={400}
                                >
                                  **** **** ****
                                </Text>
                                <Text
                                  lineHeight={ah(34)}
                                  fontSize={aw(14)}
                                  fontFamily={"RobotoRegular"}
                                  color={"black"}
                                  fontWeight={400}
                                >
                                  {val.card && val.card.last4}
                                </Text>
                              </HStack>
                            </HStack>
                            <HStack
                              // mt={ah(8)}
                              justifyContent={"flex-end"}
                              // width={aw(200)}
                            >
                              {/* <Box
                          width={aw(18)}
                          height={ah(18)}
                          bgColor="#919191"
                          borderRadius={aw(100)}
                         
                        >
                          <LeadsWatchRemoveCardIcon />
                        </Box> */}
                              <Pressable
                                onPress={() => handleDeleteCard(val.id)}
                                // style={{
                                //   width: aw(2),
                                //   height: aw(2),
                                //
                                // }}
                                backgroundColor={"#919191"}
                                // bacg="#919191"
                                // leftIcon={}
                                // p={aw(10)}
                                // width={aw(18)}
                                // height={ah(1)}
                                size={ah(18)}
                                borderRadius={"full"}
                                // alignSelf={"center"}
                                // alignContent={"center"}
                                alignItems={"center"}
                              >
                                <LeadsWatchRemoveCardIcon />
                              </Pressable>
                            </HStack>
                            {val.default === true && (
                              <Box
                                position="absolute"
                                top={ah(-7)}
                                right={aw(-6)}
                                borderColor="#C1C1C1"
                              >
                                <LeadsWatchTickIcon />
                              </Box>
                            )}
                          </Pressable>
                        ))}

                      <HStack
                        width={aw(100)}
                        height={ah(100)}
                        alignItems={"center"}
                        justifyContent={"center"}
                        bgColor="#0000001A"
                        borderRadius={aw(10)}
                        borderWidth={
                          paymentError && allCards && allCards.length === 0
                            ? aw(1)
                            : 0
                        }
                        borderColor={
                          paymentError && allCards && allCards.length === 0
                            ? "#FA4E2D"
                            : "#C1C1C1"
                        }
                      >
                        {/* <LeadsWatchButton
                      onPress={() => setIsAddCardModalOpen(true)}
                      style={{
                        width: aw(32),
                        height: ah(32),
                        borderRadius: "100%",
                      }}
                     
                      leftIcon={<LeadsWatchAddIcon />}
                    /> */}
                        <Pressable
                          onPress={() => setIsAddCardModalOpen(true)}
                          size={ah(32)}
                          backgroundColor="#919191"
                          alignItems={"center"}
                          justifyContent={"center"}
                          borderRadius={"full"}
                        >
                          <LeadsWatchAddIcon />
                        </Pressable>
                      </HStack>
                      {/* </ScrollView> */}
                    </HStack>
                  </VStack>
                )}
              </VStack>
              <Box
                width={"35%"}
                height={ah(337)}
                borderWidth={aw(1)}
                borderColor={"#E6E7E9"}
                borderRadius={aw(10)}
              >
                <VStack px={aw(20)} py={aw(10)} space={ah(5)}>
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoMedium"}
                    color={"black"}
                    fontWeight={400}
                  >
                    Recent Transaction
                  </Text>
                  <Divider my={ah(8)} bg={"#E6E7E9"} />
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      You Added
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      _
                    </Text>
                  </HStack>
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      Date
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      {invoices && invoices.list
                        ? moment(invoices.list[0].created).format("YYYY-MM-DD")
                        : "_"}
                    </Text>
                  </HStack>
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      Time
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      {invoices && invoices.list
                        ? moment(invoices.list[0].created).format("HH:mm:ss")
                        : "_"}
                    </Text>
                  </HStack>
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      Amount
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      {invoices && invoices.list && invoices.list[0].pay
                        ? invoices.list[0].pay["amount"]
                        : "_"}
                    </Text>
                  </HStack>
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      Credits
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      {invoices && invoices.list
                        ? invoices.list[0].credits
                        : "_"}
                    </Text>
                  </HStack>
                  <HStack justifyContent={"space-between"}>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      Description
                    </Text>
                    <Text
                      lineHeight={ah(34)}
                      fontSize={aw(14)}
                      fontFamily={"RobotoRegular"}
                      color={"#C1C1C1"}
                      fontWeight={400}
                    >
                      {invoices && invoices.list ? invoices.list[0].desc : "_"}
                    </Text>
                  </HStack>
                </VStack>
              </Box>
            </Flex>
          </LeadsWatchCard>
        </Box>
        <Box mt={ah(30)} pb={ah(30)}>
          <LeadsWatchCard
            width={aw(1248)}
            height={ah(500)}
            alignItems={"flex-start"}
          >
            <VStack p={aw(20)}>
              <Text
                lineHeight={ah(34)}
                fontSize={aw(14)}
                fontFamily={"RobotoMedium"}
                color={"black"}
                fontWeight={400}
              >
                Credit History
              </Text>
              <HStack
                alignItems={"center"}
                style={{
                  height: ah(60),
                  background: "#F9FAFB 0% 0% no-repeat padding-box",
                  border: "1px solid #D7D7D7",
                  borderTopLeftRadius: aw(10),
                  borderTopRightRadius: aw(10),
                }}
              >
                {["No", "Date", "Amount", "Description", "credits"].map(
                  (val) => {
                    return (
                      <HStack
                        key={val}
                        alignItems={"center"}
                        width={aw(200)}
                        justifyContent="flex-start"
                      >
                        <Text
                          width={aw(200)}
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          ml={aw(20)}
                          color="#121233"
                        >
                          {val}
                        </Text>
                      </HStack>
                    );
                  }
                )}
              </HStack>
              {!invoiceLoading ? (
                <ScrollView
                  maxHeight={ah(240)}
                  showsVerticalScrollIndicator={false}
                  style={{
                    borderBottomRightRadius: aw(10),
                    borderBottomLeftRadius: aw(10),
                    borderbottomWidth: "1px solid #D7D7D7",
                    borderTopWidth: "0px",
                    borderColor: "#D7D7D7",
                    borderWidth: "1px",
                  }}
                >
                  <FlatList
                    // showsVerticalScrollIndicator={false}
                    // height={ah(300)}
                    ListEmptyComponent={
                      <HStack
                        justifyContent="center"
                        alignItems={"center"}
                        height={ah(240)}
                      >
                        <Text
                          color="black"
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                        >
                          No data found
                        </Text>
                      </HStack>
                    }
                    data={invoices && invoices.list && invoices.list}
                    keyExtractor={(item) => item._id}
                    renderItem={({ item, index }) => (
                      <HStack>
                        {["no", "date", "amount", "desc", "credits"].map(
                          (val) => (
                            <HStack
                              key={val}
                              height={ah(60)}
                              width={aw(200)}
                              alignItems={"center"}
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                                minHeight: ah(58),
                                borderTopWidth: index === 0 ? 0 : "1px",
                                borderLeftWidth: "0px",
                                borderRightWidth: "0px",
                                borderBottomWidth: "0px",
                                borderColor: "#D7D7D7",
                              }}
                            >
                              <Text
                                color="#121233"
                                width={aw(240)}
                                ml={aw(20)}
                                fontSize={aw(14)}
                                fontFamily={"RobotoMedium"}
                              >
                                {val === "no" ||
                                val === "date" ||
                                val === "amount"
                                  ? val === "no" || val === "date"
                                    ? val === "no"
                                      ? index + 1
                                      : moment(item["created"]).format(
                                          "YYYY-MM-DD"
                                        )
                                    : item.pay && item.pay.amount
                                  : item[val]}
                              </Text>
                            </HStack>
                          )
                        )}
                      </HStack>
                    )}
                  />
                </ScrollView>
              ) : (
                <HStack
                  justifyContent="center"
                  alignItems={"center"}
                  height={ah(240)}
                  // width={aw(580)}
                  style={{
                    border: "1px solid #D7D7D7",
                    borderTopWidth: 0,
                    borderBottomLeftRadius: aw(10),
                    borderBottomRightRadius: aw(10),
                  }}
                >
                  <Spinner size="lg" color="#2230F8" />
                </HStack>
              )}
            </VStack>
            <VStack pl={aw(20)}>
              <LeadsWatchPagination
                width={aw(1000)}
                totalCount={invoices && invoices.total_count}
                currentPage={currentPage}
                pageSize={5}
                onPageChange={(currentPage) => setCurrentPage(currentPage)}
              />
            </VStack>
          </LeadsWatchCard>
        </Box>

        <LeadsWatchModal
          width={aw(420)}
          height={ah(200)}
          showModal={openDefaultCardModal}
          onClose={() => {
            setOpenDefaultCardModal(false);
          }}
          heading={
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              Default Card
            </Text>
          }
        >
          <VStack>
            <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
              Are you sure you want to make this card as default
            </Text>
            <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
              <LeadsWatchButton
                onPress={() => setOpenDefaultCardModal(false)}
                label="cancel"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
              />
              <LeadsWatchButton
                isLoading={loading}
                onPress={handleAddDefaulrCard}
                label="Add"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
                bg={"#2230F8"}
                color={"#F9FAFB"}
              />
            </HStack>
          </VStack>
        </LeadsWatchModal>
      </VStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(300)}
        showModal={openAutoReloadModal}
        onClose={() => {
          setOpenAutoReloadModal(false);
          setToogle(profile && profile.reload_credits ? true : false);
          reset({
            reload_limit:
              profile && profile.credit_threshold
                ? profile.credit_threshold.toString()
                : "",
          });
          !toogle && setRefresh(!refresh);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Set Auto Reload Credit
          </Text>
        }
      >
        <VStack space={ah(20)}>
          <HStack justifyContent={"space-between"} alignItems={"center"}>
            <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoMedium">
              Auto Reload
            </Text>
            <Switch
              _hover={{
                offTrackColor: "#C1C1C1",
                onTrackColor: "#0000FF",
              }}
              onTrackColor="#0000FF"
              offTrackColor="#C1C1C1"
              isChecked={toogle}
              onToggle={() => setToogle(!toogle)}
            />
          </HStack>
          {/* <HStack space={aw(20)}> */}
          {/* <LeadsWatchFilterInput
              onlyNumers={true}
              control={control}
              name="realod_amount"
              placeholder="Enter Reload Amount"
            /> */}
          <LeadsWatchFilterInput
            refresh={!toogle ? refresh : null}
            screen={
              getValues("reload_limit") && getValues("reload_limit").length > 0
                ? "edit"
                : "create"
            }
            onlyNumers={true}
            control={control}
            name="reload_limit"
            placeholder="Enter Reload limit"
            // width={aw(180)}
          />
          {/* </HStack> */}
          <HStack space={aw(10)} justifyContent={"flex-end"}>
            <LeadsWatchButton
              onPress={() => {
                setOpenAutoReloadModal(false);
                setToogle(profile && profile.reload_credits ? true : false);
                reset({
                  reload_limit:
                    profile && profile.credit_threshold
                      ? profile.credit_threshold.toString()
                      : "",
                });

                !toogle && setRefresh(!refresh);
              }}
              label="Cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              onPress={handleSubmit(handleAutoReload)}
              isLoading={save}
              label="Save"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this Card
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDeleteCard(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(600)}
        height={ah(680)}
        showModal={isAddCardModalOpen}
        onClose={() => {
          setIsAddCardModalOpen(false);
          reset({
            address: "",
            address2: "",
            card_CVC: "",
            country: "",
            expiry: "",
            number: "",
            city: "",
            phone: "91",
            state: "",
            zipcode: "",
          });

          setRefresh(!refresh);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Add Card Details
          </Text>
        }
      >
        <StripeWrapper>
          <CardForm
            isAddCardModalOpen={isAddCardModalOpen}
            toast={toast}
            setIsAddCardModalOpen={setIsAddCardModalOpen}
            control={control1}
            handleSubmit={handleSubmit1}
            reset={reset1}
            errors={errors1}
            getValues={getValues1}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </StripeWrapper>
      </LeadsWatchModal>
      <StripeWrapper>
        <AddCredits
          setOpenCreditsModal={setOpenCreditsModal}
          openCreditsModal={openCreditsModal}
        />
      </StripeWrapper>
    </ScrollView>
  );
};

export default Credits;
