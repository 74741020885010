import * as events from "../Events";
const initialState = {
  allLeads: null,
  singleLead: null,
  exportedLeads: null,
  leadsSend: null,
  formFields: null,
  verticalCampaigns: null,
  campaignPub: null,
};
export const leadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.GET_ALL_LEADS:
      return {
        ...state,
        allLeads: action.data,
      };
    case events.GET_SINGLE_LEAD:
      return {
        ...state,
        singleLead: action.data,
      };
    case events.EXPORT_LEADS:
      return {
        ...state,
        // exportedLeads: null,
      };
    case events.SINGLE_EXPORT:
      return {
        ...state,
      };
    case events.SEND_BUYERS:
      return {
        ...state,
        leadsSend: action.data,
      };
    case events.GET_FORM_FIELDS:
      return {
        ...state,
        formFields: action.data,
      };
    case events.CREATE_LEADS:
      return {
        ...state,
      };
    case events.CREATE_MULTIPLE_LEADS:
      return {
        ...state,
      };
    case events.GET_CAMPAIGNS_BY_VERTICALS:
      return {
        ...state,
        verticalCampaigns: action.data,
      };
    case events.GET_PUB_BY_CAMPAIGNS:
      return {
        ...state,
        campaignPub: action.data,
      };

    default:
      return state;
  }
};
