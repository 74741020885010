// import * as React from "react";
// import Svg, { G, Rect, Path } from "react-native-svg";
// import { aw, ah } from "../../utils";
// const LeadsWatchUpArrow = (props) => (
//   <Svg
//     xmlns="http://www.w3.org/2000/svg"
//     width={aw(24)}
//     height={ah(24)}
//     // viewBox="6 0 16 15"
//     viewBox="6 1 15 1"
//     // viewBox="3 0 16 10"
//     // viewBox="5 8 17 17"
//     {...props}
//   >
//     <G id="Dropup_icon" transform="translate(-26 -36)">
//       <Rect
//         id="Rectangle_37"
//         data-name="Rectangle 37"
//         width={aw(24)}
//         height={ah(24)}
//         transform="translate(26 36)"
//         fill="none"
//       />
//       <Path
//         id="Path_10239"
//         data-name="Path 10239"
//         d="M0-6.318l3.708-3.717L4.77-8.973,0-4.2l-4.77-4.77,1.062-1.062Z"
//         transform="translate(38 40.762) rotate(180)"
//         fill="#212121"
//       />
//     </G>
//   </Svg>
// );
// export default LeadsWatchUpArrow;

import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchUpArrow(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.sort ? aw(20) : aw(24)}
      height={props.sort ? ah(20) : ah(24)}
      viewBox={props.sort ? "5 -1 17 17" : "0 0 24 24"}
      {...props}
    >
      <Path data-name="Rectangle 766" fill="none" d="M0 0H24V24H0z" />
      <Path
        data-name="Union 3"
        d="M3.541 2.36l-2.36 2.35L0 3.54 3.541 0l3.54 3.54L5.9 4.71z"
        transform="translate(8 10)"
        fill={props.color || "#212121"}
      />
    </Svg>
  );
}

export default LeadsWatchUpArrow;
