import * as events from "../Events";
const initialState = {
  allGroups: null,
  singleGroup: null,
  verticalGroups: null,
};
export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.CREATE_GROUP:
      return {
        ...state,
      };
    case events.GET_ALL_GROUPS:
      return {
        ...state,
        allGroups: action.data,
      };
    case events.GET_SINGLE_GROUP:
      return {
        ...state,
        singleGroup: action.data,
      };
    case events.GET_VERTICAL_GROUPS:
      return {
        ...state,
        verticalGroups: action.data,
      };
    case events.EDIT_GROUP:
      return {
        ...state,
      };
    case events.DELETE_GROUP:
      return {
        ...state,
      };
    default:
      return state;
  }
};
