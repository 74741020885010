import * as events from "../Events";
const token = localStorage.getItem("auth");

export const getTopCampaigns = (time, criteria) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/top5`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
          criteria: criteria,
          category: "campaigns",
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const topCampaigns = resData.data;
        dispatch({ type: events.GET_TOP_CAMPAIGNS, data: topCampaigns });
        return topCampaigns ? topCampaigns : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getTopPublishers = (time, criteria) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/top5`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
          criteria: criteria,
          category: "publishers",
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const topPublishers = resData.data;
        dispatch({ type: events.GET_TOP_PUBLISHERS, data: topPublishers });
        return topPublishers ? topPublishers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getTopBuyers = (time, criteria) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/top5`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
          criteria: criteria,
          category: "buyers",
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const topBuyers = resData.data;
        dispatch({ type: events.GET_TOP_BUYERS, data: topBuyers });
        return topBuyers ? topBuyers : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getTotalLeads = (time) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/overview`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const totalLeads = resData.data;
        dispatch({ type: events.GET_TOTAL_LEADS, data: totalLeads });
        return totalLeads ? totalLeads : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getamountPerformance = (time) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/performance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const totalLeads = resData.data;
        dispatch({ type: events.GET_AMOUNT_PERFORMANCE, data: totalLeads });
        return totalLeads ? totalLeads : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getLeadPerformnce = (time) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/performance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          time_interval: time,
        }),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const totalLeads = resData.data;
        dispatch({ type: events.GET_LEAD_PERFORMANCE, data: totalLeads });
        return totalLeads ? totalLeads : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getLeadsmeter = (time) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/leadsmeter`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const leads = resData.data;
        dispatch({ type: events.GET_LEADS_METER, data: leads });
        return leads ? leads : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getCreditsmeter = (time) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/reports/creditsmeter`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const credits = resData.data;
        dispatch({ type: events.CREDITS_ANALYSIS, data: credits });
        return credits ? credits : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
