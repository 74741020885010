import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

import { aw, ah } from "../../utils";

function LeadsWatchCallPauseIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-282 -612)">
        <Path
          data-name="Rectangle 391"
          transform="translate(282 612)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Union 10"
          d="M-5124.958-8474.488a15 15 0 01-3.223-1.934 30.959 30.959 0 01-3.4-2.954 31.471 31.471 0 01-2.954-3.385 14.965 14.965 0 01-1.936-3.221l-.085-.215a5.21 5.21 0 01-.441-2.021 2.771 2.771 0 01.526-1.633 6.657 6.657 0 011.58-1.644 3.1 3.1 0 011.989-.806h.021a1.785 1.785 0 01.676.215 2.98 2.98 0 01.612.452 14.3 14.3 0 011.419 1.707 5.644 5.644 0 01.966 1.622 1.64 1.64 0 010 .838 4.009 4.009 0 01-.429.9l-.087.194a1.692 1.692 0 00-.181.388.44.44 0 00.011.256 8.568 8.568 0 001.848 2.815 8.339 8.339 0 002.792 1.827.479.479 0 00.311.021 2.292 2.292 0 00.484-.237l.237-.13a2.974 2.974 0 01.795-.332 1.887 1.887 0 01.774.011 5.668 5.668 0 011.622.967 14.168 14.168 0 011.707 1.418 3.073 3.073 0 01.452.622 1.956 1.956 0 01.213.688 3.245 3.245 0 01-.848 2.04 5.876 5.876 0 01-1.556 1.483 2.963 2.963 0 01-1.784.58 4.884 4.884 0 01-2.111-.532zm-9.323-15.758a5.189 5.189 0 00-1.074 1.148l-.066.106a1.667 1.667 0 00-.237 1.043 4.4 4.4 0 00.409 1.43l.107.234a14.806 14.806 0 001.848 2.965 33.28 33.28 0 002.794 3.116 32.414 32.414 0 003.135 2.729 14.342 14.342 0 002.944 1.763l.194.085a3.459 3.459 0 001.364.312 1.9 1.9 0 001.063-.333l.042-.042a2.865 2.865 0 00.441-.356 7.109 7.109 0 00.548-.569 5.366 5.366 0 00.569-.784 1.01 1.01 0 00.181-.568.452.452 0 00-.064-.205 1.03 1.03 0 00-.194-.245 14.605 14.605 0 00-1.449-1.193 8.7 8.7 0 00-1.192-.827.727.727 0 00-.324.032 2.644 2.644 0 00-.492.247l-.237.13a3.045 3.045 0 01-.893.322 1.7 1.7 0 01-.891-.131 11.226 11.226 0 01-1.76-.9 9.375 9.375 0 01-1.494-1.215 8.768 8.768 0 01-1.213-1.514 9.909 9.909 0 01-.925-1.783 1.621 1.621 0 01-.1-.934 3.745 3.745 0 01.417-.979l.109-.194a1.594 1.594 0 00.181-.4.731.731 0 00.011-.29 8.609 8.609 0 00-.827-1.193 15.216 15.216 0 00-1.191-1.449 1.208 1.208 0 00-.247-.194.454.454 0 00-.2-.063h-.064a2.707 2.707 0 00-1.223.699zm13.846 3.648v-6.4h1.848v6.4zm-3.651 0v-6.4h1.848v6.4z"
          transform="translate(5421.836 9107.5)"
          fill="#212121"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchCallPauseIcon;
