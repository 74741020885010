import { loadStripe } from "@stripe/stripe-js";

export let DH = 768;
// export let DW = 1366;

export let DW =
  window.innerWidth <= 1536 || window.innerWidth >= 768
    ? 1366
    : window.innerWidth < 768
    ? window.innerWidth < 768 || window.innerWidth >= 450
      ? 500
      : 375
    : 375;

export const ah1 = (value) => {
  return `${(100 * value) / window.innerHeight}vh`;
};

export const aw1 = (value) => {
  return `${(100 * value) / window.innerWidth}vw`;
};
//  @media (max-width: 1400px) {
//   html {
//     font-size: 25px;
//   }
// }

// // Tablet screen

// @media (max-width: 768px) {
//   html {
//     font-size: 18px;
//   }
// }

// // Mobile screen

// @media (max-width: 450px) {
//   html {
//     font-size: 12px;
//   }
// }

export const ah = (value) => {
  return `${(100 * ch(value)) / window.innerHeight}vh`;
};

export const aw = (value) => {
  return `${(100 * cw(value)) / window.innerWidth}vw`;
};

const cw = (w) => {
  return (
    (window.innerWidth /
      (window.innerWidth >= 1536 || window.innerWidth >= 760 ? 1366 : 375)) *
    w
  );
};
const ch = (h) => {
  return (window.innerHeight / DH) * h;
};

// export const stripePromise = loadStripe(
//   "pk_test_51NLgheGF2K0riSA2vE8bQfGSpWxkJmrXboExnsBTI8e0lg6vAt9sQ1ueRcAp5rWCmlka058Wml7WOKjSrfgZcuQj00VEZKt1RG"
// );

// export const stripePromise = loadStripe(
//   "pk_test_GfLnJnzf7zGoQs4V22V3Kxao009u3iww3y"
// );
export const stripePromise = loadStripe(
  "pk_live_51NLgheGF2K0riSA2EgT8YiQHSIi7EicxZfrS9QToOODbDDfntboZ21c4sbvPojL1NwkGPQXzilsqxvOlbWWybEPw00oMEw7aLU"
);
// export const stripePromise = loadStripe(
//   "pk_test_51NLgheGF2K0riSA2vE8bQfGSpWxkJmrXboExnsBTI8e0lg6vAt9sQ1ueRcAp5rWCmlka058Wml7WOKjSrfgZcuQj00VEZKt1RG"
// );
