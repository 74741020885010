import { React, useState, useEffect } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  ScrollView,
  FlatList,
  Checkbox,
  Switch,
  Pressable,
  Spinner,
  Tooltip,
  useBreakpointValue,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { aw, ah } from "../../utils";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";

import LeadsWatchEditIcon from "../../components/svg/LeadsWatchEditIcon";
import LeadsWatchDeleteIcon from "../../components/svg/LeadsWatchDeleteIcon";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchFilterInput from "../../components/LeadsWatchFilterInput";
import LeadsWatchTextArea from "../../components/LeadsWatchTextArea";
import {
  createVerticalField,
  getAllVerticalFields,
  editVerticalField,
  chooseDefaultField,
  createVerticalFieldFromDefaultFields,
  deleteVerticalField,
} from "../../store/actions/verticals";
import LeadsWatchUpArrow from "../../components/svg/LeadsWatchUpArrow";
import LeadsWatchDownArrow from "../../components/svg/LeadsWatchDownArrow";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";

const ViewVertical = ({ isOpen }) => {
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const navigate = useNavigate();
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const [refresh, setRefresh] = useState(false);
  const [uListLoading, setuListLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [listData, setListData] = useState([]);
  const [openListModal, setOpenListModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sort, setSort] = useState({});
  const [listItem, setListItem] = useState(null);

  const profile = useSelector((state) => state.auth.profile);
  const allVerticalFields = useSelector(
    (state) => state.verticals.allVerticalFields
  );
  const defaultFields = useSelector(
    (state) => state.verticals.defaultVerticalFields
  );
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(
    page && page.verticalFields ? parseInt(page.verticalFields) : 1
  );
  const [selectCount, setSelectCount] = useState(10);
  const [isChooseFromModalOpen, setIsChooseFromModalOpen] = useState(false);
  const [isVerticalFieldEditModalOpen, setIsVerticalEditFieldModalOpen] =
    useState(false);
  const [isVerticalFieldAddModalOpen, setIsVerticalFieldAddModalOpen] =
    useState(false);
  const [toggleSwitch, setToggleSwitch] = useState([]);
  const [eToggleSwitch, setEToggleSwitch] = useState([]);

  const [loading, setLoading] = useState(false);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [edit, setEdit] = useState([]);
  const [checked, setChecked] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [clearSearch, setClearSearch] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm({
    mode: "all",
    defaultValues: { select: "10" },
  });
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    getValues: getValues1,
    reset: reset1,
  } = useForm({
    mode: "all",
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    getValues: getValues2,
    reset: reset2,
  } = useForm({
    mode: "all",
  });
  const {
    control: control3,
    handleSubmit: handleSubmit3,
    getValues: getValues3,
    reset: reset3,
    formState: { errors: errors3 },
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    setCurrentPage(1);
    localStorage.setItem(
      "page",
      JSON.stringify({ ...page, verticalFields: 1 })
    );
  };

  const handleEditVerticalField = (data) => {
    setIsVerticalEditFieldModalOpen(true);
    setDataToEdit(data);
    // setEVerticalType(data.datatype);
    setEToggleSwitch([
      ...eToggleSwitch,
      data.default === 1 && "default",
      data.mandatory === 1 && "required",
    ]);
  };
  const handleOpenVerticalField = () => {
    setIsVerticalFieldAddModalOpen(true);
  };
  const handleToogle = (data) => {
    toggleSwitch.includes(data)
      ? setToggleSwitch(toggleSwitch.filter((val) => val !== data))
      : setToggleSwitch([...toggleSwitch, data]);
  };
  const handleEToogle = (data) => {
    eToggleSwitch.includes(data)
      ? setEToggleSwitch(eToggleSwitch.filter((val) => val !== data))
      : setEToggleSwitch([...eToggleSwitch, data]);
  };
  const handleAddVerticalField = async (data) => {
    setLoading(true);
    try {
      await dispatch(
        createVerticalField(
          data.verticalFieldName,
          data.description,
          data.verticalType.value,
          toggleSwitch.includes("default") ? 1 : 0,
          toggleSwitch.includes("required") ? 1 : 0,
          id
        )
      );
      setCurrentPage(1);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, verticalFields: 1 })
      );
      setToggleSwitch([]);
      reset({ verticalFieldName: "", description: "", verticalType: "" });
      setIsVerticalFieldAddModalOpen(false);
      setRefresh(!refresh);
      dispatch(getAllVerticalFields(currentPage, selectCount, id));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setLoading(false);
  };

  const fetchAllVerticalFields = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllVerticalFields(currentPage, selectCount, id, sort, search)
      );
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["vertical"] &&
        profile.permission_ids["vertical"].includes("view")
      ) {
        fetchAllVerticalFields();
      } else {
        navigate("/settings/profile");
      }
    } else {
      fetchAllVerticalFields();
    }
  }, [currentPage, selectCount, sort, search]);

  useEffect(() => {
    reset1(edit);
  }, [edit]);
  useEffect(() => {
    if (dataToEdit) {
      console.log(dataToEdit.datatype);
      setEdit({
        verticalFieldName: dataToEdit.name,
        description: dataToEdit.desc,
        verticalType: {
          label: dataToEdit.datatype
            .toLowerCase()
            .replace(/^.|\s\S/g, (match) => match.toUpperCase()),
          value: dataToEdit.datatype,
        },
      });
    }
  }, [dataToEdit]);
  const handleEditRequest = async (data) => {
    setLoading(true);

    try {
      await dispatch(
        editVerticalField(
          data.verticalFieldName,
          data.description,
          data.verticalType.value,
          eToggleSwitch.includes("default") ? 1 : 0,
          eToggleSwitch.includes("required") ? 1 : 0,
          id,
          dataToEdit._id
        )
      );

      setIsVerticalEditFieldModalOpen(false);
      await dispatch(getAllVerticalFields(currentPage, selectCount, id));
      reset1();
      setEToggleSwitch([]);
      toast.success("Vertical Field Updated Successfully");
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
  };
  const fetchDefaultVerticalFields = async () => {
    setIsLoading1(true);
    try {
      await dispatch(chooseDefaultField());
      setIsChooseFromModalOpen(true);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading1(false);
  };

  const handleChooseDefaultField = () => {
    fetchDefaultVerticalFields();
  };
  const handleUpadteVertical = async () => {
    try {
      await dispatch(createVerticalFieldFromDefaultFields(checked, id));
      setCurrentPage(1);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, verticalFields: 1 })
      );

      await dispatch(getAllVerticalFields(currentPage, selectCount, id));
      // alert("vertical added");
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }

    setIsChooseFromModalOpen(false);

    setChecked([]);
  };
  const handleCheckbox = (data) => {
    if (checked.includes(data)) {
      setChecked((old) => old.filter((row) => row !== data));
    } else {
      setChecked((old) => [...old, data]);
    }
  };
  const handleSort = (order) => {
    setSort(order);
  };
  const onChangeHeaderCheckbox = () => {
    if (checked.length !== defaultFields.list.length) {
      defaultFields && setChecked(defaultFields.list.map((val) => val._id));
    } else {
      setChecked([]);
    }
  };

  const handleDeleteVerticalField = async (vfid) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteVerticalField(id, vfid));
        setConfirmModal(false);
        setDeleteId(null);
        fetchAllVerticalFields();
        toast.success("Vertical Field Deleted Successfully");
      } catch (err) {
        setConfirmModal(false);
        setDeleteId(null);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(vfid);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem(
        "page",
        JSON.stringify({ ...page, verticalFields: 1 })
      );
    }
  };
  const handleAddList = (data) => {
    setListData([{ id: Date.now(), label: data.field }, ...listData]);
    reset3({ field: "" });
  };
  const handleDeleteList = (id) => {
    setListData((old) => old.filter((row) => row.id !== id));
  };
  const handlelistData = (item) => {
    setOpenListModal(true);
    setListItem(item);

    let data = [];
    if (item && item.listdata) {
      for (let c in item.listdata) {
        data.push({ id: c, label: item.listdata[c] });
      }
      setListData(data);
    }
  };
  const handleupdateList = async () => {
    setuListLoading(true);
    try {
      await dispatch(
        editVerticalField(
          listItem.name,
          listItem.desc,
          listItem.datatype,
          listItem.mandatory,
          listItem.default,
          id,
          listItem._id,
          listData.map((val) => val.label)
        )
      );
      await dispatch(getAllVerticalFields(currentPage, selectCount, id));
      setOpenListModal(false);
      setListItem(null);
      setListData([]);
      setShowInput(false);
      toast.success("List Updated Succeesfully");
      setuListLoading(false);
    } catch (err) {
      setuListLoading(false);
      toast.error(err.message.toLowerCase());
    }
  };
  return (
    <>
      <HStack
        height={ah(698)}
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <VStack
            marginTop={ah(7)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <HStack
              // width={aw(1248)}
              lineHeight={ah(34)}
              justifyContent={"space-between"}
              space={aw(8)}
              alignItems={"center"}
            >
              <HStack alignItems={"baseline"} width={aw(250)} space={aw(8)}>
                {/* <HStack></HStack> */}
                <Text
                  fontSize={aw(26)}
                  color={"#121233"}
                  fontFamily="RobotoMedium"
                >
                  Vertical Fields
                </Text>
                <Text
                  top={ah(20)}
                  fontSize={aw(18)}
                  color={"#919191"}
                  fontFamily="RobotoMedium"
                >
                  {allVerticalFields && allVerticalFields.total_count
                    ? allVerticalFields.total_count
                    : 0}
                </Text>
              </HStack>
              <LeadsWatchSearch
                placeholder={['Search by "Name"', 'Search by "Data Type"']}
                control={control2}
                name="search"
                onFocus={() => setClearSearch(false)}
                inputRightElement={
                  clearSearch ? (
                    <Pressable
                      w={aw(20)}
                      onPress={() => {
                        reset2();
                        setSearch(getValues2("search"));
                        setClearSearch(false);
                        setCurrentPage(1);
                        localStorage.setItem(
                          "page",
                          JSON.stringify({ ...page, verticalFields: 1 })
                        );
                      }}
                    >
                      <LeadsWatchCrossIcon />
                    </Pressable>
                  ) : (
                    <Pressable
                      w={aw(30)}
                      onPress={handleSubmit2(handleSearch)}
                      // pr={aw(10)}
                    >
                      <LeadsWatchSearchIcon />
                    </Pressable>
                  )
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit2(
                      handleSearch({ search: getValues2("search") })
                    );
                  }
                }}
              />
            </HStack>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All Your Vertical Fields list will be shown here
            </Text>

            <Divider
              mt={ah(9)}
              bg="#E6E7E9"
              height={ah(1)}
              width={
                isOpen
                  ? [aw(350), aw(350), aw(1168)]
                  : [aw(350), aw(350), aw(1248)]
              }
            />
          </VStack>
          <VStack height={ah(35)} marginTop={ah(10)}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <HStack space={aw(5)}>
                <LeadsWatchButton
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["vertical"].includes("create")
                        ? false
                        : true
                      : false
                  }
                  onPress={() => handleChooseDefaultField()}
                  label="Choose from"
                  style={{
                    width: aw(120),
                    height: ah(40),
                    borderRadius: aw(6),
                  }}
                  bg="#2230F8"
                  borderColor="#D1D5DB"
                  fontSize={aw(15)}
                  color="#FFFFFF"
                />
                <LeadsWatchButton
                  isDisabled={
                    profile && profile.role === "user"
                      ? profile.permission_ids["vertical"].includes("create")
                        ? false
                        : true
                      : false
                  }
                  onPress={handleOpenVerticalField}
                  label="Add new"
                  style={{
                    width: aw(120),
                    height: ah(40),
                    borderRadius: aw(6),
                  }}
                  bg="#2230F8"
                  borderColor="#D1D5DB"
                  fontSize={aw(14)}
                  color="#FFFFFF"
                />
              </HStack>
            </HStack>
          </VStack>

          <VStack mt={ah(15)}>
            <HStack
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
                borderBottom: "1px solid #D7D7D7",
              }}
            >
              {["name", "datatype", "Required", "Actions"].map((val, index) => (
                <HStack
                  width={aw(200)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  {val !== "Actions" ? (
                    <HStack
                      width={
                        val.length < 10
                          ? val === "name" || val === "email" || val === "desc"
                            ? val === "desc"
                              ? aw(500)
                              : aw(350)
                            : aw(val.length + 100)
                          : val === "Description" || val === "publisher_name"
                          ? aw(700)
                          : aw(val.length + 150)
                      }
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      key={index}
                    >
                      <Text
                        fontSize={aw(14)}
                        color="#212121"
                        fontFamily={"RobotoMedium"}
                      >
                        {val === "_id"
                          ? "ID"
                          : val.charAt(0).toUpperCase() +
                            val.slice(1).replace("_", " ")}
                      </Text>
                      <VStack width={ah(12)} justifyContent={"center"}>
                        <Pressable onPress={() => handleSort({ [val]: 1 })}>
                          <LeadsWatchUpArrow
                            sort={"true"}
                            color={
                              sort
                                ? Object.keys(sort)[0] === val &&
                                  sort[val] === 1
                                  ? "#212121"
                                  : "#D7D7D7"
                                : "#D7D7D7"
                            }
                          />
                        </Pressable>
                        <Pressable onPress={() => handleSort({ [val]: -1 })}>
                          <LeadsWatchDownArrow
                            sort={"true"}
                            color={
                              sort
                                ? Object.keys(sort)[0] === val &&
                                  sort[val] === -1
                                  ? "#212121"
                                  : "#D7D7D7"
                                : "#D7D7D7"
                            }
                          />
                        </Pressable>
                      </VStack>
                    </HStack>
                  ) : (
                    <Text
                      fontSize={aw(14)}
                      width={aw(200)}
                      fontFamily={"RobotoMedium"}
                      color="black"
                    >
                      {val}
                    </Text>
                  )}
                </HStack>
              ))}
            </HStack>
            {!isLoading ? (
              <ScrollView
                maxHeight={ah(400)}
                // showsVerticalScrollIndicator={false}
                style={{
                  borderBottomRightRadius: aw(10),
                  borderBottomLeftRadius: aw(10),
                  borderbottomWidth: "1px solid #D7D7D7",
                  borderTopWidth: "0px",
                  borderColor: "#D7D7D7",
                  borderWidth: "1px",
                }}
              >
                <FlatList
                  ListEmptyComponent={
                    <HStack
                      justifyContent="center"
                      alignItems={"center"}
                      height={ah(390)}
                    >
                      <Text
                        color="black"
                        fontSize={aw(14)}
                        fontFamily={"RobotoMedium"}
                      >
                        No data found
                      </Text>
                    </HStack>
                  }
                  data={
                    allVerticalFields && allVerticalFields.list
                      ? allVerticalFields.list
                      : []
                  }
                  keyExtractor={(item) => item._id}
                  renderItem={({ item, index }) => (
                    <HStack
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {["name", "datatype", "mandatory", "Actions"].map(
                        (val) => (
                          <HStack
                            width={aw(200)}
                            justifyContent="flex-start"
                            alignItems={"center"}
                            key={val}
                            ml={aw(10)}
                          >
                            {val !== "Actions" && val !== "mandatory" ? (
                              <Text
                                fontSize={aw(14)}
                                width={aw(200)}
                                fontFamily={"RobotoRegular"}
                                color="#212121"
                              >
                                {item[val] === "list" ? (
                                  <LeadsWatchButton
                                    style={{ height: ah(32) }}
                                    label="list"
                                    onPress={() => {
                                      handlelistData(item);
                                    }}
                                  />
                                ) : val === "datatype" ? (
                                  item[val]
                                    .toLowerCase()
                                    .replace(/^.|\s\S/g, (match) =>
                                      match.toUpperCase()
                                    )
                                ) : (
                                  item[val]
                                )}
                              </Text>
                            ) : val === "Actions" ? (
                              <HStack width={aw(200)} space={aw(5)}>
                                <Tooltip
                                  label={"No Access"}
                                  isDisabled={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "vertical"
                                        ].includes("edit")
                                        ? true
                                        : false
                                      : true
                                  }
                                >
                                  <Pressable
                                    onPress={() =>
                                      handleEditVerticalField(item)
                                    }
                                    isDisabled={
                                      profile && profile.role === "user"
                                        ? profile.permission_ids[
                                            "vertical"
                                          ].includes("edit")
                                          ? false
                                          : true
                                        : false
                                    }
                                  >
                                    <LeadsWatchEditIcon
                                      color={
                                        profile && profile.role === "user"
                                          ? profile.permission_ids[
                                              "vertical"
                                            ].includes("edit")
                                            ? "#212121"
                                            : "#C1C1C1"
                                          : "#212121"
                                      }
                                    />
                                  </Pressable>
                                </Tooltip>
                                <Tooltip
                                  label={"No Access"}
                                  isDisabled={
                                    profile && profile.role === "user"
                                      ? profile.permission_ids[
                                          "vertical"
                                        ].includes("delete")
                                        ? true
                                        : false
                                      : true
                                  }
                                >
                                  <Pressable
                                    onPress={() =>
                                      handleDeleteVerticalField(item._id)
                                    }
                                    isDisabled={
                                      profile && profile.role === "user"
                                        ? profile.permission_ids[
                                            "vertical"
                                          ].includes("delete")
                                          ? false
                                          : true
                                        : false
                                    }
                                  >
                                    <LeadsWatchDeleteIcon
                                      color={
                                        profile && profile.role === "user"
                                          ? profile.permission_ids[
                                              "vertical"
                                            ].includes("delete")
                                            ? "#212121"
                                            : "#C1C1C1"
                                          : "#212121"
                                      }
                                    />
                                  </Pressable>
                                </Tooltip>
                              </HStack>
                            ) : (
                              <Text
                                fontSize={aw(14)}
                                width={aw(200)}
                                fontFamily={"RobotoRegular"}
                                color="#212121"
                              >
                                {item[val] === 0 ? "No" : "Yes"}
                              </Text>
                            )}
                          </HStack>
                        )
                      )}
                    </HStack>
                  )}
                />
              </ScrollView>
            ) : (
              <HStack
                justifyContent="center"
                alignItems={"center"}
                height={ah(400)}
                style={{
                  border: "1px solid #D7D7D7",
                  borderTopWidth: 0,
                  borderBottomLeftRadius: aw(10),
                  borderBottomRightRadius: aw(10),
                }}
              >
                <Spinner size="lg" color="#2230F8" />
              </HStack>
            )}
          </VStack>
          <VStack
            bottom={aw(5)}
            position="fixed"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width="100%"
              // totalCount={
              //   allVerticalFields[0] ? allVerticalFields[0].total_count : 1
              // }
              // currentPage={currentPage}
              // pageSize={selectCount}
              // onPageChange={(currentPage) => setCurrentPage(currentPage)}
              totalCount={
                allVerticalFields && allVerticalFields.total_count
                  ? allVerticalFields.total_count
                  : 1
              }
              currentPage={currentPage}
              pageSize={selectCount}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage);
                localStorage.setItem(
                  "page",
                  JSON.stringify({ ...page, verticalFields: currentPage })
                );
              }}
            />
          </VStack>
        </VStack>
      </HStack>
      <LeadsWatchModal
        height={ah(590)}
        width={aw(770)}
        showModal={isChooseFromModalOpen}
        onClose={() => setIsChooseFromModalOpen(false)}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            Default vertical field
          </Text>
        }
      >
        <VStack>
          <HStack
            justifyContent="space-evenly"
            alignItems={"center"}
            bgColor="#D7D7D7"
            style={{
              height: ah(60),
              background: "#F9FAFB 0% 0% no-repeat padding-box",
              borderLeft: "1px solid #D7D7D7",
              borderRight: "1px solid #D7D7D7",
              borderTop: "1px solid #D7D7D7",
              borderBottom: "1px solid #D7D7D7",
              borderTopLeftRadius: aw(10),
              borderTopRightRadius: aw(10),
            }}
          >
            <HStack width={aw(20)} justifyContent="center">
              <Checkbox
                // icon={<Icon as={<MaterialCommunityIcons name="bullseye" />} />}
                accessibilityLabel="headingcheck"
                style={{ height: aw(16), width: aw(16) }}
                bgColor="#FAFBFD"
                _checked={{ bgColor: "#0000FF", borderColor: "#0000FF" }}
                _icon={{ color: "#FAFBFD" }}
                _hover={{ color: "#FAFBFD" }}
                isChecked={
                  defaultFields && defaultFields.list.length === checked.length
                }
                onChange={onChangeHeaderCheckbox}
              />
            </HStack>
            {["Name", "Description", "Data Type", "Required"].map((val) => (
              <HStack
                width={aw(120)}
                justifyContent="flex-start"
                alignItems={"center"}
                key={val}
                ml={aw(10)}
              >
                <Text
                  fontSize={aw(14)}
                  width={aw(120)}
                  fontFamily={"RobotoMedium"}
                  color="#212121"
                >
                  {val}
                </Text>
              </HStack>
            ))}
          </HStack>
          {!isLoading1 ? (
            <ScrollView
              maxHeight={ah(290)}
              // showsVerticalScrollIndicator={false}
              style={{
                borderBottomRightRadius: aw(10),
                borderBottomLeftRadius: aw(10),
                borderbottomWidth: "1px solid #D7D7D7",
                borderTopWidth: "0px",
                borderColor: "#D7D7D7",
                borderWidth: "1px",
              }}
            >
              <FlatList
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(280)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={
                  defaultFields && defaultFields.list ? defaultFields.list : []
                }
                keyExtractor={(item) => item._id}
                renderItem={({ item, index }) => (
                  <>
                    <HStack
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      <HStack width={aw(20)} justifyContent="center">
                        <Checkbox
                          accessibilityLabel={`row-${item._id}`}
                          style={{ height: aw(16), width: aw(16) }}
                          bgColor="#FAFBFD"
                          _checked={{
                            bgColor: "#0000FF",
                            borderColor: "#0000FF",
                          }}
                          _icon={{ color: "#FAFBFD" }}
                          _hover={{ color: "#FAFBFD" }}
                          isChecked={checked.includes(item._id) === true}
                          onChange={() => handleCheckbox(item._id)}
                        />
                      </HStack>
                      {["name", "desc", "datatype", "mandatory"].map((val) => (
                        <HStack
                          width={aw(120)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          ml={aw(10)}
                        >
                          {val === "mandatory" ? (
                            <Text
                              fontSize={aw(14)}
                              width={aw(120)}
                              fontFamily={"RobotoRegular"}
                              color="#212121"
                            >
                              {item[val] === 1 ? "true" : "false"}
                            </Text>
                          ) : (
                            <Text
                              fontSize={aw(14)}
                              width={aw(120)}
                              fontFamily={"RobotoRegular"}
                              color="#212121"
                            >
                              {item[val]}
                            </Text>
                          )}
                        </HStack>
                      ))}
                    </HStack>
                  </>
                )}
              />
            </ScrollView>
          ) : (
            <HStack
              justifyContent="center"
              alignItems={"center"}
              height={ah(300)}
              style={{
                border: "1px solid #D7D7D7",
                borderTopWidth: 0,
                borderBottomLeftRadius: aw(10),
                borderBottomRightRadius: aw(10),
              }}
            >
              <Spinner size="lg" color="#2230F8" />
            </HStack>
          )}
          <HStack mt={ah(30)} space={aw(20)} justifyContent="flex-end">
            <LeadsWatchButton
              label="cancel"
              style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
              borderColor="#D1D5DB"
              fontSize={aw(15)}
              onPress={() => setIsChooseFromModalOpen(false)}
            />
            <LeadsWatchButton
              onPress={() => handleUpadteVertical()}
              label="Update"
              style={{ width: aw(120), height: ah(40), borderRadius: aw(6) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              fontSize={aw(15)}
              color="#FFFFFF"
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>

      <LeadsWatchModal
        height={ah(534)}
        width={aw(370)}
        showModal={isVerticalFieldEditModalOpen}
        onClose={() => {
          reset1();
          setEToggleSwitch([]);
          setIsVerticalEditFieldModalOpen(false);
        }}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            Edit Vertical Field
          </Text>
        }
      >
        <VStack alignItems="center">
          <LeadsWatchFilterInput
            screen={
              getValues1("verticalFieldName") &&
              getValues1("verticalFieldName").length > 0
                ? "edit"
                : "create"
            }
            hasValue={
              getValues1("verticalFieldName") &&
              getValues1("verticalFieldName").length > 0
                ? false
                : true
            }
            isReadOnly={
              getValues1("verticalFieldName") === "Email" || "Phone"
                ? true
                : false
            }
            mt={ah(29)}
            // style={{ width: aw(330), heigth: ah(40) }}
            width={aw(330)}
            height={ah(40)}
            name="verticalFieldName"
            placeholder="Vertical Field Name"
            control={control1}
          />
          <HStack mt={aw(15)}>
            <LeadsWatchTextArea
              screen={
                getValues1("description") &&
                getValues1("description").length > 0
                  ? "edit"
                  : "create"
              }
              width={aw(330)}
              control={control1}
              name="description"
              placeholder="Description"
              style={{ width: aw(330), heigth: ah(120) }}
            />
          </HStack>
          <HStack width={aw(330)} mt={aw(5)}>
            {/* <LeadsWatchSelect
              placeholder="Select Datatype"
              control={control1}
              name={"verticalType"}
              list={["string", "number", "boolean", "list"]}
              width={aw(330)}
              height={ah(40)}
              // eVerticalType
              // value={eVerticalType}
              // handleChange={(value) => setEVerticalType(value)}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.verticalType ? true : false}
              error={errors.verticalType ? errors.verticalType.message : ""}
            /> */}
            <LeadsWatchAsyncSelect
              height={ah(40)}
              screen={
                getValues1("verticalType") && getValues1("verticalType").value
                  ? "edit"
                  : "create"
              }
              minWidth={aw(330)}
              defaultOptions={[
                { label: "String", value: "string" },
                { label: "Number", value: "number" },
                { label: "Boolean", value: "boolean" },
                { label: "List", value: "list" },
              ]}
              control={control1}
              placeholder="Select Data Type"
              name="verticalType"
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.verticalType ? true : false}
              error={errors.verticalType ? errors.verticalType.message : ""}
            />
          </HStack>
          <HStack
            mt={ah(14)}
            justifyContent="space-between"
            width={aw(330)}
            height={ah(40)}
          >
            <Text fontFamily="RobotoRegular" fontSize={aw(14)} color="#494440">
              Required
            </Text>
            <Switch
              onToggle={() => handleEToogle("required")}
              offTrackColor="#C1C1C1"
              onTrackColor="#0000FF"
              _hover={{
                offTrackColor: "#C1C1C1",
                onTrackColor: "#0000FF",
              }}
              isChecked={
                eToggleSwitch.includes("required") === true ? true : false
              }
            />
          </HStack>
          <HStack
            mt={ah(14)}
            justifyContent="space-between"
            width={aw(330)}
            height={ah(40)}
          >
            <Text fontFamily="RobotoRegular" fontSize={aw(14)} color="#494440">
              Default
            </Text>
            <Switch
              offTrackColor="#C1C1C1"
              onTrackColor="#0000FF"
              onToggle={() => handleEToogle("default")}
              _hover={{
                offTrackColor: "#C1C1C1",
                onTrackColor: "#0000FF",
              }}
              isChecked={
                eToggleSwitch.includes("default") === true ? true : false
              }
            />
          </HStack>
          <HStack mt={ah(45)} space={ah(20)}>
            <LeadsWatchButton
              onPress={() => {
                reset1();
                setEToggleSwitch([]);
                setIsVerticalEditFieldModalOpen(false);
              }}
              label="cancel"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              borderColor="#D1D5DB"
              fontSize={aw(14)}
            />
            <LeadsWatchButton
              isLoading={loading}
              onPress={handleSubmit1(handleEditRequest)}
              label="Edit Vertical Field"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              fontSize={aw(14)}
              color="#FFFFFF"
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(534)}
        width={aw(370)}
        showModal={isVerticalFieldAddModalOpen}
        onClose={() => {
          setIsVerticalFieldAddModalOpen(false);
          setToggleSwitch([]);
          reset({ verticalFieldName: "", description: "", verticalType: "" });
          setRefresh(!refresh);
        }}
        heading={
          <Text color={"#000000"} fontFamily={"RobotoMedium"} fontSize={aw(18)}>
            Add Vertical Field
          </Text>
        }
      >
        <VStack alignItems="center">
          <LeadsWatchFilterInput
            refresh={refresh}
            screen={
              getValues("verticalFieldName") &&
              getValues("verticalFieldName").length > 0
                ? "edit"
                : "create"
            }
            mt={ah(29)}
            width={aw(330)}
            height={ah(40)}
            name="verticalFieldName"
            placeholder="Vertical Field Name"
            control={control}
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors.verticalFieldName ? true : false}
            error={
              errors.verticalFieldName ? errors.verticalFieldName.message : ""
            }
          />
          <HStack mt={aw(15)}>
            <LeadsWatchTextArea
              refresh={refresh}
              screen={
                getValues("description") && getValues("description").length > 0
                  ? "edit"
                  : "create"
              }
              // hasValue={
              //   getValues("description") && getValues("description").length > 0
              //     ? false
              //     : true
              // }
              width={aw(330)}
              control={control}
              name="description"
              placeholder="Description"
              style={{ width: aw(330), heigth: ah(120) }}
            />
          </HStack>
          <HStack width={aw(330)} mt={aw(5)}>
            {/* <LeadsWatchSelect
              placeholder="Select Datatype"
              control={control}
              name={"verticalType"}
              list={["string", "number", "boolean", "list"]}
              width={aw(330)}
              height={ah(40)}
              // value={verticalType}
              // handleChange={(value) => setVerticalType(value)}
              // handleChange={handleChangeVerticalType}
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.verticalType ? true : false}
              error={errors.verticalType ? errors.verticalType.message : ""}
            /> */}
            <LeadsWatchAsyncSelect
              height={ah(40)}
              screen={getValues("verticalType") > 0 ? "edit" : "create"}
              minWidth={aw(330)}
              defaultOptions={[
                { label: "String", value: "string" },
                { label: "Number", value: "number" },
                { label: "Boolean", value: "boolean" },
                { label: "List", value: "list" },
              ]}
              control={control}
              placeholder="Select Data Type"
              name="verticalType"
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors.verticalType ? true : false}
              error={errors.verticalType ? errors.verticalType.message : ""}
            />
          </HStack>
          <HStack
            mt={ah(14)}
            justifyContent="space-between"
            width={aw(330)}
            height={ah(40)}
            pl={aw(2)}
          >
            <Text fontFamily="RobotoRegular" fontSize={aw(14)} color="#494440">
              Required
            </Text>
            <Switch
              onToggle={() => handleToogle("required")}
              offTrackColor="#C1C1C1"
              onTrackColor="#0000FF"
              _hover={{
                offTrackColor: "#C1C1C1",
                onTrackColor: "#0000FF",
              }}
              isChecked={
                toggleSwitch.includes("required") === true ? true : false
              }
            />
          </HStack>
          <HStack
            mt={ah(14)}
            justifyContent="space-between"
            width={aw(330)}
            height={ah(40)}
            pl={aw(2)}
          >
            <Text fontFamily="RobotoRegular" fontSize={aw(14)} color="#494440">
              Default
            </Text>
            <Switch
              _hover={{
                offTrackColor: "#C1C1C1",
                onTrackColor: "#0000FF",
              }}
              offTrackColor="#C1C1C1"
              onTrackColor="#0000FF"
              onToggle={() => handleToogle("default")}
              isChecked={
                toggleSwitch.includes("default") === true ? true : false
              }
              // colorScheme={isChecked ? "#0000FF" : "#C1C1C1"}
            />
          </HStack>
          <HStack mt={ah(45)} space={ah(20)}>
            <LeadsWatchButton
              onPress={() => {
                setIsVerticalFieldAddModalOpen(false);
                setToggleSwitch([]);
                reset({
                  verticalFieldName: "",
                  description: "",
                  verticalType: "",
                });
                setRefresh(!refresh);
              }}
              label="cancel"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              borderColor="#D1D5DB"
              fontSize={aw(14)}
            />
            <LeadsWatchButton
              isLoading={loading}
              onPress={handleSubmit(handleAddVerticalField)}
              label="Add Vertical Field"
              style={{ width: aw(155), height: ah(40), borderRadius: aw(6) }}
              bg="#2230F8"
              borderColor="#D1D5DB"
              fontSize={aw(14)}
              color="#FFFFFF"
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this vertical Field
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDeleteVerticalField(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        width={aw(500)}
        height={ah(700)}
        showModal={openListModal}
        onClose={() => {
          setOpenListModal(false);
          setListItem(null);
          setListData([]);

          setShowInput(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            List
          </Text>
        }
      >
        <VStack space={ah(30)}>
          <VStack>
            <HStack
              justifyContent="space-evenly"
              alignItems={"center"}
              bgColor="#D7D7D7"
              style={{
                height: ah(60),
                background: "#F9FAFB 0% 0% no-repeat padding-box",
                borderLeft: "1px solid #D7D7D7",
                borderRight: "1px solid #D7D7D7",
                borderTop: "1px solid #D7D7D7",
                borderBottom: "1px solid #D7D7D7",
                borderTopLeftRadius: aw(10),
                borderTopRightRadius: aw(10),
              }}
            >
              {["Label", "Action"].map((val) => (
                <HStack
                  width={val === "Action" ? aw(150) : aw(140)}
                  justifyContent="flex-start"
                  alignItems={"center"}
                  key={val}
                  ml={aw(10)}
                >
                  <Text
                    fontSize={aw(14)}
                    width={aw(120)}
                    fontFamily={"RobotoMedium"}
                    color="#212121"
                  >
                    {val}
                  </Text>
                </HStack>
              ))}
            </HStack>
            <ScrollView
              maxHeight={ah(150)}
              // showsVerticalScrollIndicator={false}
              style={{
                borderBottomRightRadius: aw(10),
                borderBottomLeftRadius: aw(10),
                borderbottomWidth: "1px solid #D7D7D7",
                borderTopWidth: "0px",
                borderColor: "#D7D7D7",
                borderWidth: "1px",
              }}
            >
              <FlatList
                ListEmptyComponent={
                  <HStack
                    justifyContent="center"
                    alignItems={"center"}
                    height={ah(140)}
                  >
                    <Text
                      color="black"
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                    >
                      No data found
                    </Text>
                  </HStack>
                }
                data={listData}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => (
                  <>
                    <HStack
                      justifyContent="space-evenly"
                      alignItems={"center"}
                      style={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                        minHeight: ah(58),
                        borderTopWidth: index === 0 ? 0 : "1px",
                        borderLeftWidth: "0px",
                        borderRightWidth: "0px",
                        borderBottomWidth: "0px",
                        borderColor: "#D7D7D7",
                      }}
                    >
                      {["name", "Actions"].map((val) => (
                        <HStack
                          width={aw(140)}
                          justifyContent="flex-start"
                          alignItems={"center"}
                          key={val}
                          ml={aw(10)}
                        >
                          {val === "Actions" ? (
                            <Pressable
                              onPress={() => handleDeleteList(item.id)}
                            >
                              <LeadsWatchDeleteIcon />
                            </Pressable>
                          ) : (
                            <Text
                              fontSize={aw(14)}
                              width={aw(120)}
                              fontFamily={"RobotoRegular"}
                              color="#212121"
                            >
                              {item.label}
                            </Text>
                          )}
                        </HStack>
                      ))}
                    </HStack>
                  </>
                )}
              />
            </ScrollView>
          </VStack>
          {showInput && (
            <HStack space={aw(10)}>
              <LeadsWatchFilterInput
                screen={
                  getValues3("field") && getValues3("field").length > 0
                    ? "edit"
                    : "create"
                }
                control={control3}
                name="field"
                placeholder="Enter field"
                width={aw(360)}
                rules={{
                  required: {
                    value: true,
                  },
                }}
                invalid={errors3.field ? true : false}
                error={errors3.field ? errors3.field.message : ""}
              />
              <LeadsWatchButton
                onPress={handleSubmit3(handleAddList)}
                label="create"
                style={{
                  width: aw(100),
                  height: ah(40),
                  borderRadius: aw(50),
                }}
                bg={"#2230F8"}
                color={"#F9FAFB"}
              />
            </HStack>
          )}
          <HStack justifyContent={"center"} space={aw(10)}>
            <LeadsWatchButton
              isLoading={uListLoading}
              // onPress={() => setOpenListModal(false)}\
              onPress={handleupdateList}
              label="update"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
            <LeadsWatchButton
              onPress={() => setShowInput(true)}
              label="Add"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </>
  );
};
export default ViewVertical;
