import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchSearchIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Search_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(24)}
        height={ah(24)}
        transform="translate(1041 23)"
        fill="none"
      />
      <G
        id="Group_690"
        data-name="Group 690"
        transform="translate(441.9 -1191.7)"
      >
        <G
          id="Group_689"
          data-name="Group 689"
          transform="translate(601.1 1216.7)"
        >
          <G id="Group_688" data-name="Group 688">
            <G id="Group_686" data-name="Group 686">
              <Path
                id="Path_298"
                data-name="Path 298"
                d="M609.047,1232.593a7.947,7.947,0,1,1,7.946-7.947A8.017,8.017,0,0,1,609.047,1232.593Zm0-14.448a6.5,6.5,0,1,0,6.5,6.5A6.475,6.475,0,0,0,609.047,1218.145Z"
                transform="translate(-601.1 -1216.7)"
                fill="#212121"
              />
            </G>
            <G
              id="Group_687"
              data-name="Group 687"
              transform="translate(12.642 12.733)"
            >
              <Path
                id="Path_299"
                data-name="Path 299"
                d="M616.184,1230.8l6.321,6.321a.709.709,0,0,1,0,.993.763.763,0,0,1-.542.181c-.181,0-.452,0-.542-.181l-6.321-6.321Z"
                transform="translate(-615.1 -1230.8)"
                fill="#212121"
              />
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
);
export default LeadsWatchSearchIcon;
