import * as React from "react";
import Svg, { Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchApproveIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(10)}
      height={ah(10.003)}
      viewBox="0 0 16 11.293"
      {...props}
    >
      <Path
        d="M15.834.18a.607.607 0 00-.871 0L5.345 9.8 1.051 5.5a.623.623 0 00-.861 0 .607.607 0 000 .871l4.734 4.739a.6.6 0 00.44.18.621.621 0 00.44-.18l10.03-10.059a.623.623 0 000-.861z"
        transform="translate(-.006 .004)"
        // fill={props.color || "#212121"}
      />
    </Svg>
  );
}

export default LeadsWatchApproveIcon;
