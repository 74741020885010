import * as events from "../Events";
const initialState = {
  leads: null,
  verticals: null,
  publishers: null,
  campaigns: null,
  buyers: null,
  buyerRoutes: null,
  verticalFields: null,
};
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case events.LEADS_REPORT:
      return {
        ...state,
        leads: action.data,
      };
    case events.VERTICALS_REPORT:
      return {
        ...state,
        verticals: action.data,
      };
    case events.PUBLISHERS_REPORT:
      return {
        ...state,
        publishers: action.data,
      };
    case events.CAMPAIGNS_REPORT:
      return {
        ...state,
        campaigns: action.data,
      };
    case events.BUYERS_REPORT:
      return {
        ...state,
        buyers: action.data,
      };
    case events.BUYER_ROUTES_REPORT:
      return {
        ...state,
        buyerRoutes: action.data,
      };
    case events.HANDLE_DOWNLOAD:
      return {
        ...state,
      };
    case events.VERTICAL_FIELDS:
      return {
        ...state,
        verticalFields: action.data,
      };
    case events.SEND_RESPONSE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
