import * as React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchPlayIcon = (props) => (
  <Svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <Defs>
      <ClipPath id="clip-path">
        <Rect
          id="Rectangle_884"
          data-name="Rectangle 884"
          width={aw(24)}
          height={ah(24)}
          fill="none"
        />
      </ClipPath>
    </Defs>
    <G id="Group_1088" data-name="Group 1088" clipPath="url(#clip-path)">
      <G id="Group_1087" data-name="Group 1087">
        <Path
          id="Path_10364"
          data-name="Path 10364"
          d="M12,20a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM12,5a7,7,0,1,0,7,7A7.008,7.008,0,0,0,12,5Z"
          fill="#202020"
        />
        <Path
          id="Path_10365"
          data-name="Path 10365"
          d="M15.13,11.61,10.59,8.99a.454.454,0,0,0-.68.39v5.25a.454.454,0,0,0,.68.39l4.54-2.62a.45.45,0,0,0,0-.78Z"
          fill="#202020"
        />
      </G>
    </G>
  </Svg>
);
export default LeadsWatchPlayIcon;
