import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchRightArrow = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Next_arrow_active_icon" transform="translate(-926 -530)">
      <Rect
        id="Rectangle_414"
        data-name="Rectangle 414"
        width={aw(24)}
        height={ah(24)}
        transform="translate(926 530)"
        fill="none"
      />
      <Path
        id="Path_10293"
        data-name="Path 10293"
        d="M3.086-9.434a.667.667,0,0,0-.42.234A.673.673,0,0,0,2.5-8.75a.662.662,0,0,0,.2.488.662.662,0,0,0,.488.2H15.176L10.84-3.691l-.078.078a.8.8,0,0,0-.127.469.655.655,0,0,0,.205.449.651.651,0,0,0,.479.2.651.651,0,0,0,.479-.2l5.488-5.547a.147.147,0,0,1,.049-.059.147.147,0,0,0,.049-.059.67.67,0,0,0,.117-.449.66.66,0,0,0-.2-.43L11.8-14.8l-.078-.059A.618.618,0,0,0,11.27-15a.66.66,0,0,0-.43.2.718.718,0,0,0-.2.5.718.718,0,0,0,.2.5l4.336,4.375Z"
        transform="translate(928 551)"
        fill={props.disabled ? "#c1c1c1" : "#212121"}
      />
    </G>
  </Svg>
);
export default LeadsWatchRightArrow;
