import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchCampaignsIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Campaigns_icon" transform="translate(-1041 -23)">
      <Rect
        id="Rectangle_378"
        data-name="Rectangle 378"
        width={aw(50)}
        height={ah(50)}
        transform="translate(1041 23)"
        fill={"none"}
      />
      <G
        id="Group_907"
        data-name="Group 907"
        transform="translate(-177.35 -570.7)"
      >
        <G
          id="Group_345"
          data-name="Group 345"
          transform="translate(1220.3 594.491)"
        >
          <G id="Group_344" data-name="Group 344">
            <G id="Group_343" data-name="Group 343">
              <G id="Group_340" data-name="Group 340">
                <Path
                  id="Path_136"
                  data-name="Path 136"
                  d="M1235.532,610.806h0c-.166,0-.414,0-.5-.166l-12.334-3.56a3.254,3.254,0,0,1-2.4-3.146v-2.649a3.119,3.119,0,0,1,2.4-3.146l12.334-3.56a1.984,1.984,0,0,1,2.483,1.821v12.334a3.191,3.191,0,0,1-.5,1.49A2,2,0,0,1,1235.532,610.806Zm0-14.983h-.166l-12.334,3.56a2.108,2.108,0,0,0-1.49,1.821v2.649a1.67,1.67,0,0,0,1.49,1.821l12.334,3.56a.892.892,0,0,0,.662-.166c.166-.166.166-.248.166-.5V596.237C1236.194,596.071,1235.946,595.823,1235.532,595.823Z"
                  transform="translate(-1220.3 -594.491)"
                  // fill={props.fill}
                  fill={
                    props.disabled
                      ? "#C1C1C1"
                      : props.hover === "true"
                      ? "#2230F8"
                      : "#212121"
                  }
                />
              </G>
              <G
                id="Group_341"
                data-name="Group 341"
                transform="translate(16.225 4.022)"
              >
                <Path
                  id="Path_137"
                  data-name="Path 137"
                  d="M1239.9,599.474a.5.5,0,0,1,.662,0l2.649,1.324a.973.973,0,0,1,.414.5v3.974a.464.464,0,0,1-.414.5l-2.649,1.324c-.166,0-.166.166-.248.166a.535.535,0,0,1-.414-.166Zm.993,1.573v4.47l1.324-.662v-3.146Z"
                  transform="translate(-1239.9 -599.35)"
                  // fill={props.fill}
                  fill={
                    props.disabled
                      ? "#C1C1C1"
                      : props.hover === "true"
                      ? "#2230F8"
                      : "#212121"
                  }
                />
              </G>
              <G
                id="Group_342"
                data-name="Group 342"
                transform="translate(3.063 12.176)"
              >
                <Path
                  id="Path_138"
                  data-name="Path 138"
                  d="M1225.324,609.2l2.235,9.271c.166.414-.166.662-.5.828h-.166c-.248,0-.5-.248-.662-.5L1224,609.531Z"
                  transform="translate(-1224 -609.2)"
                  // fill={props.fill}
                  fill={
                    props.disabled
                      ? "#C1C1C1"
                      : props.hover === "true"
                      ? "#2230F8"
                      : "#212121"
                  }
                />
              </G>
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>
);
export default LeadsWatchCampaignsIcon;
