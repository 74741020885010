import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchProfileIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || aw(92)}
    height={props.height || aw(92)}
    viewBox="0 0 92 92"
    {...props}
  >
    <G
      id="Group_1080"
      data-name="Group 1080"
      transform="translate(-5918 -8910)"
    >
      <Circle
        id="Ellipse_292"
        data-name="Ellipse 292"
        cx={46}
        cy={46}
        r={46}
        transform="translate(5918 8910)"
        fill="#fff"
      />
      <Circle
        id="Ellipse_293"
        data-name="Ellipse 293"
        cx={46}
        cy={46}
        r={46}
        transform="translate(5918 8910)"
        fill="#dedede"
      />
      <Path
        id="Path_10340"
        data-name="Path 10340"
        d="M13.242-38.027a12.684,12.684,0,0,1-1.055,5.156,12.883,12.883,0,0,1-2.812,4.219A12.883,12.883,0,0,1,5.156-25.84,12.684,12.684,0,0,1,0-24.785,12.684,12.684,0,0,1-5.156-25.84a12.883,12.883,0,0,1-4.219-2.812,12.883,12.883,0,0,1-2.812-4.219,12.684,12.684,0,0,1-1.055-5.156,12.684,12.684,0,0,1,1.055-5.156,12.56,12.56,0,0,1,2.813-4.189,13.955,13.955,0,0,1,4.219-2.842A12.684,12.684,0,0,1,0-51.27a12.684,12.684,0,0,1,5.156,1.055,13.955,13.955,0,0,1,4.219,2.842,12.56,12.56,0,0,1,2.813,4.189A12.684,12.684,0,0,1,13.242-38.027ZM0-1.23a68.483,68.483,0,0,1-7.676-.41,27.266,27.266,0,0,1-6.387-1.318A10.584,10.584,0,0,1-18.4-5.625a5.75,5.75,0,0,1-1.582-4.16A5.826,5.826,0,0,1-18.4-14a11.5,11.5,0,0,1,4.365-2.607A26.794,26.794,0,0,1-7.676-17.93,68.483,68.483,0,0,1,0-18.34a68.483,68.483,0,0,1,7.676.41,27.266,27.266,0,0,1,6.387,1.318A10.584,10.584,0,0,1,18.4-13.945,5.826,5.826,0,0,1,19.98-9.727,5.75,5.75,0,0,1,18.4-5.566a11.5,11.5,0,0,1-4.365,2.607A26.794,26.794,0,0,1,7.676-1.641,68.483,68.483,0,0,1,0-1.23Z"
        transform="translate(5964 8982)"
        fill="#ececec"
      />
    </G>
  </Svg>
);
export default LeadsWatchProfileIcon;
