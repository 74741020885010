import {
  Text,
  VStack,
  HStack,
  Divider,
  Box,
  ScrollView,
  Spinner,
} from "native-base";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";

import { aw, ah } from "../../utils";
import LeadsWatchCard from "../../components/LeadsWatchCard";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import {
  getPlanDetails,
  getAllPlans,
  cancelSubscription,
} from "../../store/actions/subscription";

const Details = ({ isOpen }) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const profile = useSelector((state) => state.auth.profile);
  const details = useSelector((state) => state.subscriptions.planDetails);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const dispatch = useDispatch();
  const { id } = useParams();
  const getDetails = async () => {
    setIsLoading(true);
    try {
      await dispatch(getPlanDetails(id));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  //cancel subscription
  const handleCancelSubscription = async () => {
    setLoading(true);
    try {
      await dispatch(cancelSubscription());
      setOpenCancelModal(false);
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
    window.location.reload(false);
  };
  const getData = async () => {
    try {
      await dispatch(getAllPlans());
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };
  useEffect(() => {
    getDetails();
    getData();
  }, []);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      height={ah(698)}
      //   paddingBottom={ah(300)}
    >
      <VStack
        height={ah(698)}
        // left={aw(90)}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      >
        <VStack
          // width={aw(1248)}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            space={aw(8)}
            alignItems={"flex-end"}
          >
            <HStack
              alignItems={"baseline"}
              // width={aw(194)}
              justifyContent="space-between"
              space={aw(5)}
            >
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
              >
                Subscription Details
              </Text>
            </HStack>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            You're in LeadsWatch -{" "}
            {!isLoading &&
              details &&
              details.name
                .slice(11, details.name.length)
                .charAt(0)
                .toUpperCase() +
                details.name.slice(11, details.name.length).slice(1)}{" "}
            Plan
          </Text>
          <Divider top={ah(10)} bg="#E6E7E9" />
        </VStack>
        {!isLoading ? (
          <HStack space={aw(20)}>
            {profile &&
              details &&
              profile.subscribed &&
              profile.subscribed.planid === details.plan_id && (
                <Box mt={ah(20)}>
                  <LeadsWatchCard
                    height={ah(500)}
                    width={aw(505)}
                    alignItems={"flex-start"}
                  >
                    <VStack m={aw(20)}>
                      <HStack justifyContent={"space-between"}>
                        <Text
                          fontSize={aw(14)}
                          color={"#121233"}
                          fontFamily="RobotoMedium"
                        >
                          Subscription Details
                        </Text>
                        <LeadsWatchButton
                          onPress={() => setOpenCancelModal(true)}
                          label="cancel subscription"
                          style={{ height: ah(32) }}
                        />
                      </HStack>
                      <Divider top={ah(10)} bg="#E6E7E9" width={aw(470)} />
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Name
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {details && details.name}
                        </Text>
                      </HStack>
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Subscription type
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {details && details.type}
                        </Text>
                      </HStack>

                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Billing
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          Montly
                        </Text>
                      </HStack>
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Billing Date
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {profile &&
                            profile.subscribed &&
                            moment(profile.subscribed.created).format(
                              "DD-MM-YYYY"
                            )}
                        </Text>
                      </HStack>
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Status
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {profile.status}
                        </Text>
                      </HStack>
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Duration
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {profile &&
                            profile.subscribed &&
                            // moment
                            //   .unix(profile.subscribed.end)
                            //   .format("YYYY-MM-DD")-moment(Date.now())}
                            moment
                              .unix(profile.subscribed.end)
                              .diff(Date.now(), "days")}{" "}
                          Days
                        </Text>
                      </HStack>
                      <HStack mt={aw(20)}>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"black"}
                          fontWeight={400}
                          width={aw(240)}
                        >
                          Payment method
                        </Text>
                        <Text
                          fontSize={aw(14)}
                          fontFamily={"RobotoMedium"}
                          color={"#919191"}
                          fontWeight={400}
                        >
                          {profile &&
                            profile.subscribed &&
                            profile.subscribed.brand}
                        </Text>
                      </HStack>
                    </VStack>
                  </LeadsWatchCard>
                </Box>
              )}
            <Box mt={ah(20)}>
              <LeadsWatchCard
                height={ah(500)}
                width={aw(505)}
                alignItems={"flex-start"}
              >
                <VStack p={aw(20)}>
                  <Text
                    fontSize={aw(14)}
                    color={"#121233"}
                    fontFamily="RobotoMedium"
                  >
                    Plan Details
                  </Text>
                  <Divider top={ah(10)} bg="#E6E7E9" width={aw(470)} />
                  <HStack mt={aw(20)}>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"black"}
                      fontWeight={400}
                      width={aw(240)}
                    >
                      Users
                    </Text>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"#919191"}
                      fontWeight={400}
                    >
                      UnLimited
                    </Text>
                  </HStack>

                  <HStack mt={aw(20)}>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"black"}
                      fontWeight={400}
                      width={aw(240)}
                    >
                      No of Leads
                    </Text>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"#919191"}
                      fontWeight={400}
                    >
                      {details && details.no_of_leads}
                    </Text>
                  </HStack>
                  <HStack mt={aw(20)}>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"black"}
                      fontWeight={400}
                      width={aw(240)}
                    >
                      Price Per Lead
                    </Text>
                    <Text
                      fontSize={aw(14)}
                      fontFamily={"RobotoMedium"}
                      color={"#919191"}
                      fontWeight={400}
                    >
                      {details && details.price_per_lead}
                    </Text>
                  </HStack>
                </VStack>
              </LeadsWatchCard>
            </Box>
          </HStack>
        ) : (
          <HStack
            justifyContent="center"
            alignItems={"center"}
            height={"500"}
            width={aw(1248)}

            // style={{
            //   border: "1px solid #D7D7D7",
            //   borderTopWidth: 0,
            //   borderBottomLeftRadius: aw(10),
            //   borderBottomRightRadius: aw(10),
            // }}
          >
            <Spinner size="lg" color="#2230F8" />
          </HStack>
        )}
        <LeadsWatchModal
          width={aw(420)}
          height={ah(200)}
          showModal={openCancelModal}
          onClose={() => {
            setOpenCancelModal(false);
          }}
          heading={
            <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
              Cancel Subscription
            </Text>
          }
        >
          <VStack>
            <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
              Are you sure you want to cancel the subscription
            </Text>
            <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
              <LeadsWatchButton
                onPress={() => setOpenCancelModal(false)}
                label="cancel"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
              />
              <LeadsWatchButton
                isLoading={loading}
                onPress={handleCancelSubscription}
                label="confirm"
                style={{
                  width: aw(100),
                  height: ah(32),
                  borderRadius: aw(50),
                }}
                bg={"#2230F8"}
                color={"#F9FAFB"}
              />
            </HStack>
          </VStack>
        </LeadsWatchModal>
      </VStack>
    </ScrollView>
  );
};
export default Details;
