import React, { useRef, useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { aw, ah } from "../utils";
import { Animated } from "react-native";
import { Text } from "native-base";
const LeadsWatchAsyncSelect = (props) => {
  const animatedTop = useRef(
    props.screen === "edit" ? new Animated.Value(-9) : new Animated.Value(9)
  ).current;
  const [show, setShow] = useState(props.screen === "edit" ? false : true);
  useEffect(() => {
    setShow(props.screen === "edit" ? false : true);
  }, [props.screen]);
  useEffect(() => {
    if (!show) {
      Animated.timing(animatedTop, {
        toValue: -9,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(animatedTop, {
        toValue: 9,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  }, [show]);
  useEffect(() => {
    (props.refresh === true || props.refresh === false) && setShow(true);
  }, [props.refresh]);
  return (
    <>
      <Controller
        control={props.control}
        rules={props.rules}
        name={props.name}
        style={{
          zIndex: 1,
        }}
        render={({ field: { onChange, onBlur, value } }) =>
          props.type === "async" ? (
            <>
              {props.required ? null : (
                <Animated.View
                  style={{
                    top: animatedTop,
                    zIndex: !show ? 999 : 0,
                    paddingLeft: aw(5),
                    position: "absolute",
                  }}
                >
                  <Text
                    px={aw(3)}
                    style={{
                      fontSize: !show ? aw(12) : aw(14),
                      fontFamily: "RobotoRegular",
                      // color: "#494440",
                      backgroundColor: "white",
                      // width: !show ? aw(90) : props.width,
                      color: !show ? "#494440" : "#919191",
                      // color: "#919191",
                    }}
                  >
                    {props.placeholder}
                  </Text>
                </Animated.View>
              )}
              <AsyncSelect
                menuPosition="fixed"
                onFocus={() => {
                  setShow(false);
                  if (props.onFocus) {
                    props.onFocus(value);
                  }
                }}
                // onBlur={() => {
                //   value ? setShow(false) : setShow(true);
                //   onBlur();
                // }}
                onBlur={() => {
                  props.isMulti
                    ? value && value.length > 0
                      ? setShow(false)
                      : setShow(true)
                    : value
                    ? setShow(false)
                    : setShow(true);
                  onBlur();
                  if (props.onBlur) {
                    props.onBlur();
                  }
                }}
                isDisabled={props.isDisabled}
                isClearable={props.isClearable}
                menuPortalTarget={document.body}
                isMulti={props.isMulti}
                cacheOptions
                defaultOptions={props.defaultOptions}
                loadOptions={(inputValues, callback) =>
                  props.loadOptions(inputValues, callback)
                }
                onInputChange={(inputValues) => {
                  if (inputValues === "") {
                    props.onInputChange();
                  }
                }}
                placeholder={props.required ? props.placeholder : ""}
                // placeholder={show ? props.placeholder : ""}
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  if (props.handleChange) {
                    props.handleChange(selectedOption);
                  }
                }}
                value={value || ""}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: props.minHeight || ah(37),
                    maxHeight: props.maxHeight,
                    height: props.height || ah(40),
                    borderRadius: 4,
                    borderColor: state.isFocused
                      ? "#0000FF"
                      : props.invalid
                      ? "#FA4E2D"
                      : props.borderColor || "#D1D5DB",
                    borderWidth: props.invalid
                      ? !state.isFocused
                        ? 2
                        : 1
                      : props.borderWidth || 1,
                    minWidth: props.minWidth || aw(200),
                    marginLeft: props.ml,
                    ":hover": {
                      borderColor:
                        state.isFocused || props.invalid
                          ? props.invalid
                            ? "#FA4E2D"
                            : "#0000FF"
                          : "#D1D5DB",
                    },
                    backgroundColor: "transperent",
                    // opacity: 0.5,

                    // borderRadius: aw(10),
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: aw(14),
                    fontFamily: "RobotoRegular",
                    color: "#919191",
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                    fontSize: aw(14),
                    overflowY: "auto",
                  }),
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                    color: state.isSelected ? "#2230F8" : "#0D2238",
                    fontSize: aw(14),
                  }),
                }}
              />
            </>
          ) : (
            <>
              {props.required ? null : (
                <Animated.View
                  style={{
                    top: animatedTop,
                    zIndex: !show ? 999 : 0,
                    paddingLeft: aw(5),
                    position: "absolute",
                  }}
                >
                  <Text
                    px={aw(3)}
                    style={{
                      fontSize: !show ? aw(12) : aw(14),
                      fontFamily: "RobotoRegular",
                      // color: "#494440",
                      backgroundColor: "white",
                      // width: !show ? aw(90) : props.width,
                      color: !show ? "#494440" : "#919191",
                      // color: "#919191",
                    }}
                  >
                    {props.placeholder}
                  </Text>
                </Animated.View>
              )}
              <Select
                isDisabled={props.isDisabled}
                menuPosition="fixed"
                onFocus={() => {
                  setShow(false);
                  if (props.onFocus) {
                    props.onFocus();
                  }
                }}
                onBlur={() => {
                  props.isMulti
                    ? value && value.length > 0
                      ? setShow(false)
                      : setShow(true)
                    : value
                    ? setShow(false)
                    : setShow(true);
                  onBlur();
                  onBlur();
                  if (props.onBlur) {
                    props.onBlur();
                  }
                }}
                isClearable={props.isClearable}
                menuPortalTarget={document.body}
                isMulti={props.isMulti}
                cacheOptions
                options={props.defaultOptions}
                placeholder={props.required ? props.placeholder : ""}
                // placeholder={show ? props.placeholder : ""}
                onChange={(selectedOption) => {
                  onChange(selectedOption);
                  if (props.handleChange) {
                    props.handleChange(selectedOption);
                  }
                }}
                value={value || ""}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    // maxHeight:36,
                    borderRadius: 4,
                    minHeight: props.minHeight || ah(38),
                    height: props.height || ah(40),
                    maxHeight: props.maxHeight,
                    // height: props.height,
                    marginLeft: props.ml,
                    borderColor: state.isFocused
                      ? "#0000FF"
                      : props.invalid
                      ? "#FA4E2D"
                      : props.borderColor || "#D1D5DB",
                    borderWidth: props.invalid
                      ? !state.isFocused
                        ? 2
                        : 1
                      : props.borderWidth || 1,
                    minWidth: props.minWidth || aw(200),
                    ":hover": {
                      borderColor:
                        state.isFocused || props.invalid
                          ? props.invalid
                            ? "#FA4E2D"
                            : "#0000FF"
                          : "#D1D5DB",
                    },

                    backgroundColor: "transperent",
                    // opacity: 0.5,

                    // borderRadius: aw(10),
                  }),
                  placeholder: (baseStyles) => ({
                    ...baseStyles,
                    fontSize: aw(14),
                    fontFamily: "RobotoRegular",
                    color: "#919191",
                  }),
                  menuList: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                    fontSize: aw(14),
                  }),
                  multiValueLabel: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                  }),
                  input: (baseStyles) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "RobotoRegular",
                    color: state.isDisabled ? "#00000029" : "#0D2238",
                    fontSize: aw(14),
                  }),
                }}
              />
            </>
          )
        }
      />
    </>
  );
};
export default LeadsWatchAsyncSelect;
