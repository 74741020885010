import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
import { aw, ah } from "../../utils";

function LeadsWatchRemoveCardIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(18)}
      height={ah(18)}
      viewBox="0 0 18 18"
      {...props}
    >
      <G transform="translate(-198 -82)">
        <Circle
          data-name="Ellipse 185"
          cx={9}
          cy={9}
          r={9}
          transform="translate(198 82)"
          fill="#919191"
        />
        <Path
          data-name="Path 10204"
          d="M2.651-7.7v1.463h-7.6V-7.7z"
          transform="translate(208 98)"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default LeadsWatchRemoveCardIcon;
