import { View, Text } from "native-base";
import React from "react";

const ManageIvrTree = () => {
  return (
    <View>
      <Text color="black">ManageIvrTree</Text>
    </View>
  );
};

export default ManageIvrTree;
