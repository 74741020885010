import { React, useEffect, useState } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
} from "native-base";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { aw, ah } from "../../utils";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchAddIcon from "../../components/svg/LeadsWatchAddIcon";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";
import {
  getAllCampaigns,
  getSingleCampaign,
  deleteCampaign,
} from "../../store/actions/campaigns";

const Campaigns = ({ isOpen }) => {
  const { type } = useParams();
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const {
    control: control1,
    handleSubmit: handleSubmit1,
    // formState: { errors: errors1 },
    reset: reset1,
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const [sort, setSort] = useState({});
  const [search, setSearch] = useState(getValues1("search"));
  const profile = useSelector((state) => state.auth.profile);
  const allCampaigns = useSelector((state) => state.campaigns.allCampaigns);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    page && page.campaigns ? parseInt(page.campaigns) : 1
  );
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.campaigns ? parseInt(pagecount.campaigns) : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filters, setFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [clearSearch, setClearSearch] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    control,
    // handleSubmit,
    // formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.campaigns
          ? pagecount.campaigns.toString()
          : "10",
    },
  });

  const handleChangeSelect = (value) => {
    setSelectCount(value);
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, campaigns: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, campaigns: 1 }));
  };
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);
      setFilters({ ...filters, start_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllCampaigns(
          currentPage,
          selectCount,
          sort,
          { ...filters, type: type },
          search
        )
      );
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
    setIsLoading(false);
  };
  useEffect(() => {
    if (profile && profile.role === "user") {
      if (
        profile.permission_ids["campaign"] &&
        profile.permission_ids["campaign"].includes("view")
      ) {
        getData();
      } else {
        navigate("/settings/profile");
      }
    } else {
      getData();
    }
  }, [currentPage, selectCount, sort, filters, search, type]);

  const handleSort = (order) => {
    setSort(order);
  };
  const handleEdit = async (data) => {
    try {
      await dispatch(getSingleCampaign(data._id));
      navigate(`/campaigns/editCampaign/${data._id}/${type}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (id) => {
    if (confirmModal) {
      setDeleteLoading(true);
      try {
        await dispatch(deleteCampaign(id));
        setDeleteId(null);
        setConfirmModal(false);
        getData();
        toast.success("Campaign Deleted Successfully");
      } catch (err) {
        setConfirmModal(false);
        setDeleteId(null);
        console.log(err.message);
        toast.error(err.message.toLowerCase());
      }
      setDeleteLoading(false);
    } else {
      setConfirmModal(true);
      setDeleteId(id);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, campaigns: 1 }));
    }
  };

  return (
    <HStack
      height={ah(698)}
      width={
        isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
      }
      // width={[aw(350), aw(350), aw(1248)]}
      left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
      // left={[aw(0), aw(3), aw(98)]}
    >
      <VStack
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
      >
        <VStack
          marginTop={ah(7)}
          // width={[aw(350), aw(350), aw(1248)]}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            space={aw(8)}
            alignItems={"flex-end"}
            // width={aw(1248)}
          >
            <HStack
              alignItems={"baseline"}
              space={aw(8)}
              // justifyContent="space-between"
            >
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily={"RobotoMedium"}
              >
                Campaigns
              </Text>
              <Text
                fontSize={aw(18)}
                color={"#919191"}
                fontFamily={"RobotoMedium"}
              >
                {allCampaigns &&
                  allCampaigns.total_count &&
                  allCampaigns.total_count}
              </Text>
            </HStack>
            <Hidden only={["sm", "base", "md"]}>
              <HStack space={aw(10)}>
                <LeadsWatchSearch
                  placeholder={[
                    'Search by "Name"',
                    'Search by "Start Date"',
                    'Search by "End Date"',
                    'Search by "Vertical"',
                    'Search by "Status"',
                  ]}
                  height={ah(36)}
                  control={control1}
                  name="search"
                  onFocus={() => setClearSearch(false)}
                  inputRightElement={
                    clearSearch ? (
                      <Pressable
                        w={aw(20)}
                        onPress={() => {
                          reset1();
                          setSearch(getValues1("search"));
                          setClearSearch(false);
                          setCurrentPage(1);
                          localStorage.setItem(
                            "page",
                            JSON.stringify({ ...page, campaigns: 1 })
                          );
                        }}
                      >
                        <LeadsWatchCrossIcon />
                      </Pressable>
                    ) : (
                      <Pressable
                        w={aw(30)}
                        onPress={handleSubmit1(handleSearch)}
                        // pr={aw(10)}
                      >
                        <LeadsWatchSearchIcon />
                      </Pressable>
                    )
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit1(
                        handleSearch({ search: getValues1("search") })
                      );
                    }
                  }}
                />
                <LeadsWatchButton
                  isDisabled={
                    profile && profile.role === "publisher"
                      ? true
                      : profile && profile.role === "user"
                      ? profile.permission_ids["campaign"].includes("create")
                        ? false
                        : true
                      : false
                  }
                  onPress={() => navigate(`/campaigns/createCampaign/${type}`)}
                  label="create"
                  style={{ width: aw(101), height: ah(36) }}
                  bg="#2230F8"
                  borderColor="#2230F8"
                  color="#FFFFFF"
                  leftIcon={<LeadsWatchAddIcon />}
                />
              </HStack>
            </Hidden>
          </HStack>
          <HStack space={aw(220)}>
            <Text
              lineHeight={ah(34)}
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color={"#919191"}
              fontWeight={400}
            >
              All your Campaigns list will be shown here
            </Text>
            <HStack space={aw(40)}>
              {["Lead", "IVR", "PPC"].map((val) => (
                <Pressable
                  key={val}
                  borderBottomWidth={
                    location.pathname.includes(val.toLowerCase()) && aw(3)
                  }
                  borderColor={"#0000FF"}
                  onPress={() => {
                    navigate(`/campaigns/${val.toLowerCase()}`);
                    setCurrentPage(1);
                    localStorage.setItem(
                      "page",
                      JSON.stringify({ ...page, campaigns: 1 })
                    );
                  }}
                  height={ah(42)}
                >
                  <Text
                    lineHeight={ah(34)}
                    fontSize={aw(14)}
                    fontFamily={"RobotoRegular"}
                    // color={type === val.toLowerCase() ? "#0000FF" : "#919191"}
                    color={
                      location.pathname.includes(val.toLowerCase())
                        ? "#0000FF"
                        : "#919191"
                    }
                    fontWeight={400}
                  >
                    {val}
                  </Text>
                </Pressable>
              ))}
            </HStack>
          </HStack>
          <Divider
            // mt={ah(9)}
            bg="#E6E7E9"
            height={ah(1.2)}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
            // width={[aw(350), aw(350), aw(1248)]}
          />
        </VStack>

        <VStack height={ah(35)} mt={ah(10)} zIndex={1}>
          <HStack justifyContent={"space-between"}>
            <HStack height={ah(32)} alignItems="center">
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#212121"
              >
                Show Entries :{" "}
              </Text>
              <Center>
                <LeadsWatchSelect
                  control={control}
                  name={"select"}
                  list={["10", "15", "20", "25"]}
                  // value={selectCount}
                  handleChange={(value) => handleChangeSelect(parseInt(value))}
                  width={75}
                  height={ah(32)}
                />
              </Center>
            </HStack>
            <Hidden only={["sm", "base"]}>
              <HStack space={aw(12)}>
                <HStack>
                  <LeadsWatchCalendar
                    startDate={startDate}
                    handleFromDate={handleFromDate}
                    handleEndDate={handleEndDate}
                    width={aw(141)}
                    height={ah(32)}
                    endDate={endDate}
                  />
                </HStack>
                <HStack space={aw(50)}>
                  <LeadsWatchSelect
                    control={control}
                    name={"status"}
                    placeholder="Status"
                    list={["Active", "Inactive"]}
                    handleChange={(value) =>
                      setFilters({ ...filters, status: value })
                    }
                    width={aw(100)}
                    // height={ah(32)}
                  />
                </HStack>
              </HStack>
            </Hidden>
          </HStack>
        </VStack>

        <VStack>
          <VStack mt={ah(15)}>
            <LeadsWatchTable
              isOpen={isOpen}
              sort={sort}
              screen={"campaign"}
              handleDelete={handleDelete}
              type={type}
              heading={
                type === "lead"
                  ? [
                      "_id",
                      "name",
                      "start_date",
                      "end_date",
                      "vertical_name",
                      "status",
                      "View Leads",
                      "Actions",
                    ]
                  : [
                      "_id",
                      "name",
                      "start_date",
                      "end_date",
                      "status",
                      "View Leads",
                      "Actions",
                    ]
              }
              isLoading={isLoading}
              sortIcon={true}
              data={allCampaigns && allCampaigns.list && allCampaigns.list}
              handleSort={handleSort}
              handleEdit={handleEdit}
            />
          </VStack>
          <VStack
            bottom={aw(5)}
            position="fixed"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width="100%"
              totalCount={
                allCampaigns &&
                allCampaigns.total_count &&
                allCampaigns.total_count
              }
              currentPage={currentPage}
              pageSize={selectCount}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage);
                localStorage.setItem(
                  "page",
                  JSON.stringify({ ...page, campaigns: currentPage })
                );
              }}
            />
          </VStack>
        </VStack>
      </VStack>
      <LeadsWatchModal
        width={aw(420)}
        height={ah(200)}
        showModal={confirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
        heading={
          <Text fontFamily="RobotoMedium" fontSize={aw(18)} color="#212121">
            Confirm Delete
          </Text>
        }
      >
        <VStack>
          <Text color="#121233" fontSize={aw(14)} fontFamily="RobotoRegular">
            Are you sure you want to delete this campaign?
          </Text>
          <Text fontSize={aw(10)} fontFamily="RobotoRegular" color="#667085">
            Note: Number Attach to this campaign will be Removed.
          </Text>
          <HStack mt={ah(30)} justifyContent={"flex-end"} space={aw(10)}>
            <LeadsWatchButton
              onPress={() => setConfirmModal(false)}
              label="cancel"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
            />
            <LeadsWatchButton
              isLoading={deleteLoading}
              onPress={() => handleDelete(deleteId)}
              label="Delete"
              style={{
                width: aw(100),
                height: ah(32),
                borderRadius: aw(50),
              }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
    </HStack>
  );
};
export default Campaigns;
