import { React, useState, useEffect } from "react";
import {
  Text,
  HStack,
  Center,
  VStack,
  Divider,
  Pressable,
  Hidden,
  useBreakpointValue,
  Box,
  Flex,
  ScrollView,
} from "native-base";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import LeadsWatchAsyncSelect from "../../components/LeadsWatchAsyncSelect";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
// import { Select as Select1 } from "react-select";
import { getAllBuyers } from "../../store/actions/buyers";
import { getAllCampaigns } from "../../store/actions/campaigns";
import { getAllPublishers } from "../../store/actions/publishers";
import LeadsWatchSearchIcon from "../../components/svg/LeadsWatchSearchIcon";
import LeadsWatchSearch from "../../components/LeadsWatchSearch";
import { aw, ah } from "../../utils";
import LeadsWatchModal from "../../components/LeadsWatchModal";
import LeadsWatchTable from "../../components/LeadsWatchTable";
import LeadsWatchCheckboxMenu from "../../components/LeadsWatchCheckboxMenu";
import LeadsWatchPagination from "../../components/LeadsWatchPagination";
import LeadsWatchSelect from "../../components/LeadsWatchSelect";
import LeadsWatchButton from "../../components/LeadsWatchButton";
import LeadsWatchDownloadIcon from "../../components/svg/LeadsWatchDownloadIcon";
import LeadsWatchCalendar from "../../components/LeadsWatchCalendar";
import LeadsWatchFilterIcon from "../../components/svg/LeadsWatchFilterIcon";
import { useSelector, useDispatch } from "react-redux";

import {
  getAllLeads,
  getSingleLead,
  handleDownload,
  sendBuyers,
  createMultipleLeads,
  handleSingleDownload,
  getVerticalCampaigns,
  getCampaignPub,
} from "../../store/actions/leads";
import { getAllVerticals } from "../../store/actions/verticals";
import LeadsWatchSendIcon from "../../components/svg/LeadsWatchSendIcon";

import { getBuyersByVertical } from "../../store/actions/campaigns";
import LeadsWatchCrossIcon from "../../components/svg/LeadsWatchCrossIcon";

const Operations = ({
  setDisplayLeads,
  displayLeads,
  newLeads,
  setNewLeads,
  isOpen,
}) => {
  const width = useBreakpointValue({
    base: 0,
    sm: 375,
    md: 375,
    lg: 1366,
    xl: 1366,
  });
  const navigate = useNavigate();
  const [selectedValues, setSelectedValues] = useState({});
  const allBuyers = useSelector((state) => state.buyers.allBuyers);
  const allPublishers = useSelector((state) => state.publishers.allPublishers);
  const allCampaigns = useSelector((state) => state.campaigns.allCampaigns);
  const page =
    localStorage.getItem("page") && JSON.parse(localStorage.getItem("page"));
  const profile = useSelector((state) => state.auth.profile);
  const allLeads = useSelector((state) => state.leads.allLeads);
  const allVerticals = useSelector((state) => state.verticals.allVerticals);
  const allVerticalBuyers = useSelector(
    (state) => state.campaigns.verticalBuyers
  );
  const singleLead = useSelector((state) => state.leads.singleLead);
  const vCampaigns = useSelector((state) => state.leads.verticalCampaigns);
  const campaignPub = useSelector((state) => state.leads.campaignPub);
  const [currentPage, setCurrentPage] = useState(
    page && page.operations ? parseInt(page.operations) : 1
  );
  const pagecount =
    localStorage.getItem("pagecount") &&
    JSON.parse(localStorage.getItem("pagecount"));
  const [selectCount, setSelectCount] = useState(
    pagecount && pagecount.operations ? parseInt(pagecount.operations) : 10
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const dispatch = useDispatch();
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [filters1, setFilters1] = useState({});
  const [sort, setSort] = useState({});
  const [checked, setChecked] = useState([]);
  const [leadId, setLeadId] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [disSelecVertical, setDisSelectVertical] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [file, setFile] = useState(null);
  const [importLoading, setImportLoading] = useState(false);
  const [fields, setFields] = useState([
    "date",
    "email",
    "phone",
    // "time",
    "system_response",
    "publisher",
    "buyer",
    "buyer_response",
    "campaign",
  ]);
  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      select:
        pagecount && pagecount.operations
          ? pagecount.operations.toString()
          : "10",
    },
  });
  const {
    control: control1,
    reset: reset1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    getValues: getValues1,
  } = useForm({
    mode: "all",
  });
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    // formState: { errors: errors2 },
    reset: reset2,
    getValues: getValues2,
  } = useForm({
    mode: "all",
  });
  const {
    control: control3,
    reset: reset3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    getValues: getValues3,
  } = useForm({
    mode: "all",
  });
  const [search, setSearch] = useState(getValues2("search"));
  const handleChangeSelect = (value) => {
    setSelectCount(parseInt(value));
    localStorage.setItem(
      "pagecount",
      JSON.stringify({ ...pagecount, operations: value })
    );
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, operations: 1 }));
  };
  useEffect(() => {
    setStartDate(new Date(new Date().setDate(new Date().getDate() - 30)));
    setEndDate(new Date());
    setFilters({
      start_date: moment(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ).format("YYYY-MM-DD"),
      end_date: moment(new Date()).format("YYYY-MM-DD"),
    });
  }, []);
  const handleFromDate = (date) => {
    if (date) {
      setStartDate(date);
      let date1 = moment(date);
      let date2 = moment(filters.end_date);
      let difference = date2.diff(date1, "days");
      if (difference > 30 || difference < 0) {
        setEndDate(moment(date).add(30, "days").toDate());
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
          end_date: moment(date).add(30, "days").format("YYYY-MM-DD"),
        });
      } else {
        setFilters({
          ...filters,
          start_date: moment(date).format("YYYY-MM-DD"),
        });
      }
    }
  };
  const handleEndDate = (date) => {
    if (date) {
      setEndDate(date);
      setFilters({ ...filters, end_date: moment(date).format("YYYY-MM-DD") });
    }
  };
  const fetchVerticals = async () => {
    if (
      profile.role === "admin" ||
      (profile.role === "user" &&
        profile.permission_ids["vertical"].includes("view"))
    ) {
      try {
        await dispatch(getAllVerticals());
      } catch (err) {
        console.log(err.message);
      }
    }
  };
  // useEffect(() => {
  //   fetchVerticals();
  // }, []);

  const fetchAllLeads = async () => {
    setIsLoading(true);
    try {
      await dispatch(
        getAllLeads(
          currentPage,
          selectCount,
          sort,
          { ...filters1, type: "lead" },
          search
        )
      );
      // setDisplayLeads(allLeads && allLeads.list);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message.toLowerCase());
    }
    setNewLeads(0);
    setIsLoading(false);
  };
  const handleSort = (order) => {
    setSort(order);
  };
  useEffect(() => {
    if (filters1.start_date && filters1.end_date) {
      if (profile && profile.role === "user") {
        if (
          profile.permission_ids["publisher"] &&
          profile.permission_ids.lead.includes("view")
        ) {
          fetchAllLeads();
        } else {
          navigate("/settings/profile");
        }
      } else {
        fetchAllLeads();
      }
    }
  }, [currentPage, selectCount, sort, filters1, search]);

  const handleFilters = () => {
    setFilters1({ ...filters1, ...filters });
    setCurrentPage(1);
    localStorage.setItem("page", JSON.stringify({ ...page, operations: 1 }));
    setOpenFilter(false);
  };

  const onDownlaod = async () => {
    if (checked.length) {
      const data = {
        report_filters: filters1.report_filters,
        response_ids: checked,
        type: "lead",
      };
      try {
        await dispatch(handleDownload(data));
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    } else {
      try {
        await dispatch(
          handleDownload({
            filters: { ...filters1, type: "lead" },
            report_filters: filters1.report_filters,
          })
        );
      } catch (err) {
        toast.error(err.message.toLowerCase());
      }
    }
  };
  const handleChangeCheckBox = (id, rId) => {
    // setOpenFilter(false);
    if (checked.includes(rId)) {
      setChecked((old) => old.filter((row) => row !== rId));
      setLeadId((old) => old.filter((row) => row !== id));
    } else {
      setChecked((old) => [...old, rId]);
      setLeadId((old) => [...old, id]);
    }
  };

  const handleChangeVertical = async (value) => {
    // setFilters1({ ...filters1, vertical: value });
    if (
      profile.role === "admin" ||
      (profile.role === "user" &&
        profile.permission_ids["buyer"].includes("view"))
    ) {
      try {
        await dispatch(getBuyersByVertical(value, 1, 10));
        setFilters1({ ...filters1, vertical_id: value });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSendBuyers = async (data) => {
    setLoading(true);
    const arr = data.buyer_id.value.split(",");
    const finalData = {
      buyer_id: arr[0],
      route_id: arr[1],
      lead_ids: leadId,
    };
    try {
      await dispatch(sendBuyers(finalData));
      setLeadId([]);
      setChecked([]);

      toast.success("Leads Sent Successfully");
      setDisSelectVertical(false);
      reset1({ buyer_id: "", vertical_id: "" });
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setLoading(false);
    setOpenFilter(false);
  };
  const singleLeadDownload = async (id, rId) => {
    try {
      await dispatch(handleSingleDownload(id, rId));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
  };
  const onChangeHeaderCheckbox = () => {
    if (displayLeads.every((val) => checked.includes(val.response_id))) {
      const allResponseIds = displayLeads.map((val) => val.response_id);
      const allIds = displayLeads.map((val) => val._id);
      setChecked(checked.filter((val) => !allResponseIds.includes(val)));
      setLeadId(leadId.filter((val) => !allIds.includes(val)));
    } else {
      setChecked([...checked, ...displayLeads.map((val) => val.response_id)]);
      setLeadId([...leadId, ...displayLeads.map((val) => val._id)]);
    }
  };
  const handleSearch = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
      setCurrentPage(1);
      data.search !== "" && setClearSearch(true);
      localStorage.setItem("page", JSON.stringify({ ...page, operations: 1 }));
    }
  };

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };
  const handleChange = async (e) => {
    if (
      e.target.files[0].type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0].type === "text/csv"
    ) {
      setFile(e.target.files[0]);
    }
  };
  useEffect(() => {
    setDisplayLeads(
      allLeads
        ? allLeads.list.map((val) => {
            return {
              _id: val._id,
              response_id: val.response_id,
              buyer: val.buyer_name,
              publisher: val.publisher_name,
              campaign: val.campaign_name,
              created: val.created,
              buyer_response: val.buyer_response,
              system_response: val.system_response,
              cost: val.cost,
              price: val.price,
              profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,

              email: val.details
                ? val.details.find((item) => item.name === "Email")?.value
                : null,
              phone: val.details
                ? val.details.find((item) => item.name === "Phone")?.value
                : null,
            };
          })
        : []
    );
  }, [allLeads]);
  const handleGetCampaigns = async (value) => {
    try {
      await dispatch(getVerticalCampaigns(value));
    } catch (err) {
      console.log(err);
    }
  };
  const handleGetPub = async (value) => {
    const id = value.split("_")[0];
    try {
      await dispatch(getCampaignPub(id));
    } catch (err) {
      console.log(err);
    }
  };
  const importLeads = async (data) => {
    setImportLoading(true);
    try {
      const unique_id = data.campaign_id.label.split("(")[1];
      let formData = new FormData();
      formData.append("leads_file", file);
      formData.append("campaign_id", unique_id.slice(0, unique_id.length - 1));
      formData.append("access_key", data["pub_id"].value);
      await dispatch(createMultipleLeads(formData));
      toast.success("Leads Imported Successfully");
      setIsImportModalOpen(false);
      setImportLoading(false);
      reset3();
    } catch (err) {
      setImportLoading(false);

      toast.error(err.message.toLowerCase());
    }

    // setImportLoading(true);
  };
  const handleView = async (id, rId) => {
    try {
      await dispatch(getSingleLead(id, rId, "lead"));
    } catch (err) {
      toast.error(err.message.toLowerCase());
    }
    setIsViewModalOpen(true);
  };
  const fetchAllBuyers = async () => {
    try {
      await dispatch(getAllBuyers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllPublishers = async () => {
    try {
      await dispatch(getAllPublishers(1, 10));
    } catch (err) {
      console.log(err);
    }
  };
  const getData = async () => {
    try {
      await dispatch(getAllCampaigns(1, 10));
    } catch (err) {
      console.log(err.message);
    }
  };
  // useEffect(() => {
  //   getData();
  //   fetchAllBuyers();
  //   fetchAllPublishers();
  // }, []);
  const handleOptionChange = (key, selectedOption) => {
    const ids = selectedOption.map((val) => val.value);
    setSelectedValues({ ...selectedValues, [key]: selectedOption });
    setFilters({ ...filters, [key]: ids });
  };
  const loadOptions = async (inputValue, callback, list, defaultOptions) => {
    try {
      const data =
        list === "campaign"
          ? await dispatch(
              getAllCampaigns(1, 10, undefined, undefined, inputValue)
            )
          : list === "buyer"
          ? await dispatch(
              getAllBuyers(1, 10, undefined, undefined, inputValue)
            )
          : list === "verticals"
          ? await dispatch(getAllVerticals(1, 10, undefined, inputValue))
          : list === "verticalCampaign"
          ? getValues3("vertical_id")
            ? await dispatch(
                getVerticalCampaigns(
                  getValues3("vertical_id").value,
                  1,
                  10,
                  inputValue
                )
              )
            : null
          : list === "campaignPub"
          ? getValues3("campaign_id")
            ? await dispatch(
                getCampaignPub(
                  getValues3("campaign_id").value,
                  1,
                  10,
                  inputValue
                )
              )
            : null
          : list === "verticalBuyers"
          ? getValues("vertical_id")
            ? await dispatch(
                getBuyersByVertical(
                  getValues("vertical_id").value,
                  1,
                  10,
                  inputValue
                )
              )
            : null
          : await dispatch(
              getAllPublishers(1, 10, undefined, undefined, inputValue)
            );

      callback(
        list === "campaign" || list === "verticalCampaign"
          ? data &&
              data.list.map((val) => ({
                value: val.lead_id,
                label: `${val.name}(${val.unique_id})`,
              }))
          : list === "publisher" || list === "buyer" || list === "campaignPub"
          ? data &&
            data.list.map((val) => ({
              value: val._id,
              label: `${val.firstname} ${val.lastname}`,
            }))
          : list === "verticalBuyers"
          ? data &&
            data.list.map((val) => ({
              value: val.buyer_id + "," + val.route_id,
              label: val.buyer_name,
            }))
          : data &&
            data.list.map((val) => ({
              value: val._id,
              label: val.name,
            }))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const defaultOptionsForCam = allCampaigns
    ? allCampaigns.list.map((val) => ({
        value: val.unique_id,
        label: `${val.name}( ${val.unique_id})`,
      }))
    : [];
  const defaultOptionsForBuyers = allBuyers
    ? allBuyers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForPub = allPublishers
    ? allPublishers.list.map((val) => ({
        value: val._id,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForVertical = allVerticals
    ? allVerticals.list.map((val) => ({
        value: val._id,
        label: val.name,
      }))
    : [];
  const defaultOptionsForVerticalCam = vCampaigns
    ? vCampaigns.list.map((val) => ({
        value: val._id,
        label: `${val.name}(${val.unique_id})`,
      }))
    : [];
  const defaultOptionsForCamPub = campaignPub
    ? campaignPub.list.map((val) => ({
        value: val.access_key,
        label: `${val.firstname} ${val.lastname}`,
      }))
    : [];
  const defaultOptionsForVerticalBuyers = allVerticalBuyers
    ? allVerticalBuyers.list.map((val) => ({
        value: val.buyer_id + "," + val.route_id,
        label: val.buyer_name,
      }))
    : [];
  const defaultOptionsForRes = [
    {
      value: "Accepted",
      label: "Accepted",
    },
    {
      value: "Pending",
      label: "Pending",
    },
    {
      value: "Rejected",
      label: "Rejected",
    },
    {
      value: "Trash",
      label: "Trash",
    },
    {
      value: "Duplicate",
      label: "Duplicate",
    },
    {
      value: "Failure",
      label: "Failure",
    },
  ];
  const defaultOptionsForReportFil = [
    {
      value: "Leads_Watch_LeadId",
      label: "Leads Watch Lead Id",
    },
    {
      value: "Leads_Created_Date",
      label: "Leads Created Date",
    },
    {
      value: "Time",
      label: "Time",
    },
    {
      value: "Status_Change_Date",
      label: "Status Change Date",
    },
    {
      value: "Status_Change_Time",
      label: "Status Change Time",
    },
    {
      value: "System_Response",
      label: "System Response",
    },
    {
      value: "Publisher_ID",
      label: "Publisher ID",
    },
    {
      value: "Publisher_Name",
      label: "Publisher Name",
    },
    {
      value: "Buyer_ID",
      label: "Buyer ID",
    },
    {
      value: "Buyer_Name",
      label: "Buyer Name",
    },
    {
      value: "Campaign_ID",
      label: "Campaign ID",
    },
    {
      value: "Campaign_Name",
      label: "Campaign Name",
    },
    {
      value: "Buyer_Response",
      label: "Buyer Response",
    },

    {
      value: "Publisher_Payout",
      label: "Publisher Payout",
    },
    {
      value: "Buyer_Payout",
      label: "Buyer Payout",
    },
  ];
  const requiredData = allLeads
    ? allLeads.list.map((val) => {
        return {
          _id: val._id,
          buyer: val.buyer_name,
          publisher: val.publisher_name,
          campaign: val.campaign_name,
          response_id: val.response_id,
          system_response: val.system_response,
          buyer_response: val.buyer_response,
          created: val.created,
          cost: val.cost,
          price: val.price,
          profit: val.price - val.cost === 0 ? "0" : val.price - val.cost,

          email: val.details
            ? val.details.find((item) => item.name === "Email")?.value
            : null,
          phone: val.details
            ? val.details.find((item) => item.name === "Phone")?.value
            : null,
        };
      })
    : [];
  useEffect(() => {
    if (filters.start_date && filters.end_date) {
      setFilters1({
        ...filters1,
        start_date: filters.start_date,
        end_date: filters.end_date,
      });
      setCurrentPage(1);
      localStorage.setItem("page", JSON.stringify({ ...page, leads: 1 }));
    }
  }, [filters.start_date, filters.end_date]);
  return (
    <HStack height={ah(698)} width={[aw(350), aw(350), aw(1366)]}>
      <VStack
        width={
          isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
        }
        // width={[aw(350), aw(350), aw(1248)]}
        left={isOpen ? [aw(0), aw(3), aw(178)] : [aw(0), aw(3), aw(98)]}
        // left={[aw(0), aw(3), aw(98)]}
      >
        <VStack
          marginTop={ah(7)}
          // width={[aw(350), aw(350), aw(1248)]}
          width={
            isOpen ? [aw(350), aw(350), aw(1168)] : [aw(350), aw(350), aw(1248)]
          }
        >
          <HStack
            lineHeight={ah(34)}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <HStack lineHeight={ah(34)} space={aw(8)} alignItems={"baseline"}>
              <Text
                fontSize={aw(26)}
                color={"#121233"}
                fontFamily="RobotoMedium"
                alignItems="baseline"
              >
                Operations
              </Text>
              <Text
                fontSize={aw(18)}
                color={"#919191"}
                fontFamily="RobotoMedium"
              >
                {newLeads !== 0
                  ? allLeads &&
                    allLeads.total_count &&
                    allLeads.total_count + newLeads
                  : allLeads && allLeads.total_count && allLeads.total_count}
              </Text>
            </HStack>
            <Hidden only={["sm", "base"]}>
              <LeadsWatchSearch
                placeholder={[
                  'Search by "Date"',
                  'Search by "Buyer"',
                  'Search by "System Response"',
                  'Search by "Publisher"',
                  'Search by "Buyer Response"',
                  'Search by "Campaign"',
                ]}
                control={control2}
                name="search"
                onFocus={() => setClearSearch(false)}
                inputRightElement={
                  clearSearch ? (
                    <Pressable
                      w={aw(20)}
                      onPress={() => {
                        reset2();
                        setSearch(getValues2("search"));
                        setClearSearch(false);
                        setCurrentPage(1);
                        localStorage.setItem(
                          "page",
                          JSON.stringify({ ...page, operations: 1 })
                        );
                      }}
                    >
                      <LeadsWatchCrossIcon />
                    </Pressable>
                  ) : (
                    <Pressable
                      w={aw(30)}
                      onPress={handleSubmit2(handleSearch)}
                      // pr={aw(10)}
                    >
                      <LeadsWatchSearchIcon />
                    </Pressable>
                  )
                }
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit2(
                      handleSearch({ search: getValues2("search") })
                    );
                  }
                }}
              />
            </Hidden>
          </HStack>
          <Text
            lineHeight={ah(34)}
            fontSize={aw(14)}
            fontFamily={"RobotoRegular"}
            color={"#919191"}
            fontWeight={400}
          >
            All your Leads list will be shown here
          </Text>
          <Divider
            mt={ah(9)}
            bg="#E6E7E9"
            height={ah(1)}
            // width={[aw(350), aw(350), aw(1248)]}
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          />
        </VStack>

        {disSelecVertical ? (
          <HStack
            height={ah(35)}
            mt={ah(10)}
            space={ah(27)}
            zIndex={1}
            justifyContent={"space-between"}
          >
            <HStack>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={aw(70)}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
            </HStack>
            <Hidden only={["sm", "base"]}>
              <HStack justifyContent={"flex-end"} space={aw(10)} zIndex={-1}>
                {/* <FormControl
                  isInvalid={errors1.vertical_id}
                  width={aw(190)}
                  height={ah(40)}
                >
                  <Controller
                    control={control1}
                    name={"vertical_id"}
                    style={{
                      zIndex: 1,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select1
                        color="#212121"
                        placeholderTextColor={"#919191"}
                        fontFamily={"RobotoRegular"}
                        fontSize={aw(14)}
                        selectedValue={value}
                        _customDropdownIconProps={{
                          color: "muted.500",
                          mr: "3",
                          size: aw(10),
                          p: "1",
                        }}
                        _hover={{
                          borderColor: "#D1D5DB",
                        }}
                        w={aw(190)}
                        h={ah(40)}
                        // selectedValue={props.value}
                        baseStyle={{
                          fontFamily: "RobotoRegular",
                          fontSize: aw(14),
                        }}
                        borderColor={"#D1D5DB"}
                        rounded={5}
                        accessibilityLabel="Choose vertical"
                        _selectedItem={{
                          endIcon: <LeadsWatchDownArrow />,
                        }}
                        _focus={{
                          bgColor: "white",
                          borderColor: "#D1D5DB",
                          borderWidth: "1px",
                        }}
                        _invalid={{
                          borderColor: "red.300",
                          borderWidth: "1",
                        }}
                        placeholder={"Select Vertical"}
                        onValueChange={(value) => {
                          onChange(value);
                          handleChangeVertical(value);
                        }}
                        _isFocusVisible={false}
                        focusOutlineColor={"gray.500"}
                      >
                        {allVerticals &&
                          allVerticals.list &&
                          allVerticals.list.map((val) => (
                            <Select1.Item
                              key={val._id}
                              value={val._id}
                              label={val.name}
                              color="#212121"
                            />
                          ))}
                      </Select1>
                    )}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors.vertical_id ? true : false}
                    // invalid={true}
                    error={errors.vertical_id ? errors.vertical_id.message : ""}
                  />
                </FormControl> */}
                <Flex>
                  <LeadsWatchAsyncSelect
                    screen={
                      getValues1("vertical_id") &&
                      getValues1("vertical_id").value
                        ? "edit"
                        : "create"
                    }
                    minWidth={aw(250)}
                    type="async"
                    defaultOptions={defaultOptionsForVertical}
                    loadOptions={(inputValues, callback) =>
                      loadOptions(inputValues, callback, "verticals")
                    }
                    onFocus={() => {
                      if (allVerticals) {
                        if (allVerticals.list.length < 10) {
                          fetchVerticals();
                        }
                      } else {
                        fetchVerticals();
                      }
                    }}
                    onInputChange={(inputValues) => {
                      if (inputValues === "") {
                        fetchVerticals();
                        // return inputValue;
                      }
                    }}
                    placeholder="Select Vertical"
                    handleChange={(selectedOption) => {
                      handleChangeVertical(selectedOption.value);
                    }}
                    control={control1}
                    name="vertical_id"
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors1.vertical_id ? true : false}
                    // invalid={true}
                    error={
                      errors1.vertical_id ? errors1.vertical_id.message : ""
                    }
                  />
                </Flex>
                <Flex>
                  <LeadsWatchAsyncSelect
                    screen={
                      getValues1("buyer_id") && getValues1("buyer_id").value
                        ? "edit"
                        : "create"
                    }
                    minWidth={aw(250)}
                    type="async"
                    defaultOptions={defaultOptionsForVerticalBuyers}
                    loadOptions={(inputValues, callback) =>
                      loadOptions(inputValues, callback, "verticalBuyers")
                    }
                    onInputChange={(inputValues) => {
                      if (
                        (inputValues === "" || inputValues === undefined) &&
                        getValues1("vertical_id") &&
                        getValues1("vertical_id").value
                      ) {
                        try {
                          dispatch(
                            getBuyersByVertical(
                              getValues1("vertical_id").value,
                              1,
                              10
                            )
                          );
                        } catch (err) {
                          console.log(err);
                        }
                        // return inputValue;
                      }
                    }}
                    placeholder="Select Buyer"
                    control={control1}
                    name="buyer_id"
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors1.buyer_id ? true : false}
                    // invalid={true}
                    error={errors1.buyer_id ? errors1.buyer_id.message : ""}
                  />
                </Flex>
                {/* <FormControl
                  color="#212121"
                  placeholderTextColor={"#919191"}
                  fontFamily={"RobotoRegular"}
                  fontSize={aw(14)}
                  isInvalid={errors1.buyers}
                  width={aw(190)}
                  height={ah(40)}
                >
                  <Controller
                    control={control1}
                    name={"buyers"}
                    style={{
                      zIndex: 1,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select1
                        color="#212121"
                        placeholderTextColor={"#919191"}
                        fontFamily={"RobotoRegular"}
                        fontSize={aw(14)}
                        selectedValue={value}
                        _customDropdownIconProps={{
                          color: "muted.500",
                          mr: "3",
                          size: aw(10),
                          p: "1",
                        }}
                        _hover={{
                          borderColor: "#D1D5DB",
                        }}
                        w={aw(190)}
                        h={ah(40)}
                        // selectedValue={props.value}
                        baseStyle={{
                          fontFamily: "RobotoRegular",
                          fontSize: aw(14),
                        }}
                        borderColor={"#D1D5DB"}
                        rounded={5}
                        accessibilityLabel="Choose vertical"
                        _selectedItem={{
                          endIcon: <LeadsWatchDownArrow />,
                        }}
                        _focus={{
                          bgColor: "white",
                          borderColor: "#D1D5DB",
                          borderWidth: "1px",
                        }}
                        _invalid={{
                          borderColor: "red.300",
                          borderWidth: "1",
                        }}
                        placeholder={"Select Buyer"}
                        onValueChange={(value) => {
                          onChange(value);
                        }}
                        _isFocusVisible={false}
                        focusOutlineColor={"gray.500"}
                      >
                        {allVerticalBuyers &&
                          allVerticalBuyers.list &&
                          allVerticalBuyers.list.map((val) => (
                            <Select1.Item
                              key={val._id}
                              value={`${val.buyer_id},${val.route_id}`}
                              label={val.buyer_route_name}
                              color="#212121"
                            />
                          ))}
                      </Select1>
                    )}
                    rules={{
                      required: {
                        value: true,
                      },
                    }}
                    invalid={errors1.buyers ? true : false}
                    // invalid={true}
                    error={errors1.buyers ? errors1.buyers.message : ""}
                  />
                </FormControl> */}
                <LeadsWatchButton
                  isLoading={loading}
                  isDisabled={checked.length ? false : true}
                  onPress={handleSubmit1(handleSendBuyers)}
                  label="send to buyer"
                  style={{ width: aw(100), height: ah(40) }}
                  bg="#2230F8"
                  borderColor="#D1D5DB"
                  color="#FFFFFF"
                />
              </HStack>
            </Hidden>
          </HStack>
        ) : (
          <VStack height={ah(35)} mt={ah(10)} zIndex={1}>
            <HStack justifyContent={"space-between"}>
              <HStack height={ah(32)} alignItems="center">
                <Text
                  fontSize={aw(14)}
                  fontFamily="RobotoRegular"
                  color="#212121"
                >
                  Show Entries :{" "}
                </Text>
                <Center>
                  <LeadsWatchSelect
                    control={control}
                    name={"select"}
                    list={["10", "15", "20", "25"]}
                    // value={selectCount}
                    handleChange={(value) =>
                      handleChangeSelect(parseInt(value))
                    }
                    width={75}
                    // height={ah(32)}
                  />
                </Center>
              </HStack>
              <Hidden only={["sm", "base"]}>
                <HStack space={aw(12)} width={aw(1010)}>
                  <LeadsWatchButton
                    // onPress={() => setIsBuyerModalOpen(true)}
                    isDisabled={
                      profile.role !== "admin"
                        ? profile.role === "user" &&
                          profile.permission_ids["buyer"].includes("view") &&
                          profile.permission_ids["vertical"].includes("view")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => {
                      setDisSelectVertical(true);
                    }}
                    bg="#FFFFFF"
                    leftIcon={<LeadsWatchSendIcon />}
                    label={"send to Buyer"}
                    style={{
                      width: aw(140),
                      height: ah(32),
                      borderColor: "#D1D5DB",
                      borderRadius: aw(6),
                    }}
                    fontWeight="400"
                  />
                  <LeadsWatchButton
                    isDisabled={
                      profile && profile.role === "user"
                        ? profile.permission_ids["import"].includes("leads")
                          ? false
                          : true
                        : false
                    }
                    onPress={() => setIsImportModalOpen(true)}
                    // onPress={onDownlaod}
                    leftIcon={<LeadsWatchDownloadIcon />}
                    label={"Import"}
                    style={{
                      width: aw(115),
                      height: ah(32),
                      borderColor: "#D1D5DB",
                      borderRadius: aw(6),
                    }}
                    fontWeight="400"
                  />

                  <LeadsWatchButton
                    isDisabled={
                      checked.length === 0 &&
                      (!filters1.report_filters ||
                        (filters1.report_filters &&
                          filters1.report_filters.length === 0))
                    }
                    onPress={onDownlaod}
                    bg="#FFFFFF"
                    leftIcon={<LeadsWatchDownloadIcon />}
                    label={"Download"}
                    style={{
                      width: aw(115),
                      height: ah(32),
                      borderColor: "#D1D5DB",
                      borderRadius: aw(6),
                    }}
                    fontWeight="400"
                  />
                  <HStack style={{ zIndex: 1 }}>
                    <LeadsWatchCalendar
                      maxDate={true}
                      startDate={startDate}
                      handleFromDate={handleFromDate}
                      handleEndDate={handleEndDate}
                      width={aw(141)}
                      height={ah(30)}
                      endDate={endDate}
                    />
                  </HStack>
                  <HStack>
                    <LeadsWatchCheckboxMenu
                      sheight={ah(250)}
                      arr={[
                        "date",
                        "email",
                        "phone",
                        "buyer",
                        "system_response",
                        "publisher",
                        "buyer_response",
                        "campaign",
                        "cost",
                        "price",
                        "profit",
                        "_id",
                      ]}
                      title="Select Fields"
                      height={ah(32)}
                      width={aw(160)}
                      value={fields}
                      handleChange={(value) => setFields(value)}
                    />
                    <Flex
                      position="absolute"
                      right="-2"
                      top="-8"
                      borderColor="#C1C1C1"
                      bg="#2230F8"
                      borderRadius="50%"
                      size={aw(17)}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Text
                        color="#FFFFFF"
                        fontFamily="RobotoMedium"
                        fontSize={aw(11)}
                      >
                        {fields.length}
                      </Text>
                    </Flex>
                  </HStack>
                  <LeadsWatchButton
                    bg="#FFFFFF"
                    onPress={() => setOpenFilter(!openFilter)}
                    leftIcon={<LeadsWatchFilterIcon />}
                    label={"Filter"}
                    style={{
                      width: aw(115),
                      height: ah(32),
                      borderColor: "#D1D5DB",
                      borderRadius: aw(6),
                    }}
                    fontWeight="400"
                  />
                  {openFilter && (
                    <Box
                      px={aw(10)}
                      position="absolute"
                      zIndex={1}
                      top={ah(35)}
                      right={aw(98)}
                      width={aw(570)}
                      minH={ah(180)}
                      // maxH={ah(500)}
                      py={ah(5)}
                      // my={ah(10)}
                      bgColor="#FFFFFF"
                      borderColor="#E1E1E1"
                      style={{
                        shadowColor: "#00000033",
                        shadowRadius: aw(20),
                      }}
                      borderWidth={aw(1)}
                      borderRadius={aw(6)}
                    >
                      <ScrollView maxH={ah(400)} px={aw(10)} py={ah(15)}>
                        <Flex direction="row" wrap="wrap" gap={ah(20)}>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("campaign") &&
                                getValues("campaign").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              onFocus={() => {
                                if (allCampaigns) {
                                  if (allCampaigns.list.length < 10) {
                                    getData();
                                  }
                                } else {
                                  getData();
                                }
                              }}
                              minWidth={aw(250)}
                              type="async"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "campaign_id",
                                  selectedOption
                                )
                              }
                              loadOptions={(inputValues, callback) =>
                                loadOptions(inputValues, callback, "campaign")
                              }
                              onInputChange={(inputValues) => {
                                if (inputValues === "") {
                                  getData();
                                  // return inputValue;
                                }
                              }}
                              defaultOptions={defaultOptionsForCam}
                              control={control}
                              placeholder="Campaigns"
                              name="campaign"
                            />
                          </Flex>
                          {profile && profile.role !== "publisher" && (
                            <Flex gap={ah(3)} maxW={"96%"}>
                              <LeadsWatchAsyncSelect
                                isMulti={true}
                                screen={
                                  getValues("buyer") &&
                                  getValues("buyer").length > 0
                                    ? "edit"
                                    : "create"
                                }
                                minWidth={aw(250)}
                                type="async"
                                defaultOptions={defaultOptionsForBuyers}
                                loadOptions={(inputValues, callback) =>
                                  loadOptions(inputValues, callback, "buyer")
                                }
                                onFocus={() => {
                                  if (allBuyers) {
                                    if (allBuyers.list.length < 10) {
                                      fetchAllBuyers();
                                    }
                                  } else {
                                    fetchAllBuyers();
                                  }
                                }}
                                onInputChange={(inputValues) => {
                                  if (inputValues === "") {
                                    fetchAllBuyers();

                                    // return inputValue;
                                  }
                                }}
                                handleChange={(selectedOption) =>
                                  handleOptionChange("buyer_id", selectedOption)
                                }
                                control={control}
                                placeholder="Buyers"
                                name="buyer"
                              />
                            </Flex>
                          )}
                          {profile && profile.role !== "publisher" && (
                            <Flex gap={ah(3)} maxW={"96%"}>
                              <LeadsWatchAsyncSelect
                                isMulti={true}
                                screen={
                                  getValues("publisher") &&
                                  getValues("publisher").length > 0
                                    ? "edit"
                                    : "create"
                                }
                                minWidth={aw(250)}
                                type="async"
                                defaultOptions={defaultOptionsForPub}
                                loadOptions={(inputValues, callback) =>
                                  loadOptions(
                                    inputValues,
                                    callback,
                                    "publisher"
                                  )
                                }
                                onFocus={() => {
                                  if (allPublishers) {
                                    if (allPublishers.list.length < 10) {
                                      fetchAllPublishers();
                                    }
                                  } else {
                                    fetchAllPublishers();
                                  }
                                }}
                                onInputChange={(inputValues) => {
                                  if (inputValues === "") {
                                    fetchAllPublishers();
                                    // return inputValue;
                                  }
                                }}
                                placeholder="Publishers"
                                handleChange={(selectedOption) =>
                                  handleOptionChange(
                                    "publisher_id",
                                    selectedOption
                                  )
                                }
                                control={control}
                                name="publisher"
                              />
                            </Flex>
                          )}
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("system_respons") &&
                                getValues("system_response").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              defaultOptions={defaultOptionsForRes}
                              placeholder="System Response"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "system_response",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="system_response"
                            />
                          </Flex>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("buyer_response") &&
                                getValues("buyer_response").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              defaultOptions={defaultOptionsForRes}
                              placeholder="Buyer Response"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "buyer_response",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="buyer_response"
                            />
                          </Flex>
                          <Flex gap={ah(3)} maxW={"96%"}>
                            <LeadsWatchAsyncSelect
                              isMulti={true}
                              screen={
                                getValues("report_filters") &&
                                getValues("report_filters").length > 0
                                  ? "edit"
                                  : "create"
                              }
                              minWidth={aw(250)}
                              defaultOptions={defaultOptionsForReportFil}
                              placeholder="Report Filters"
                              handleChange={(selectedOption) =>
                                handleOptionChange(
                                  "report_filters",
                                  selectedOption
                                )
                              }
                              control={control}
                              name="report_filters"
                            />
                          </Flex>
                        </Flex>
                        <Flex
                          width={aw(520)}
                          justifyContent={"flex-end"}
                          direction="row"
                          gap={aw(10)}
                          zIndex={-1}
                          mt={ah(10)}
                        >
                          <LeadsWatchButton
                            label="Cancel"
                            onPress={() => {
                              setOpenFilter(false);
                              reset({
                                campaign: "",
                                publisher: "",
                                buyer: "",
                                buyer_response: "",
                                system_response: "",
                                report_filters: "",
                              });
                              if (Object.keys(filters).length > 2) {
                                setSelectedValues({});
                                setFilters({
                                  start_date: filters.start_date,
                                  end_date: filters.end_date,
                                });
                              }
                              if (Object.keys(filters1).length > 2) {
                                setFilters1({
                                  start_date: filters1.start_date,
                                  end_date: filters1.end_date,
                                });
                              }
                            }}
                            style={{ height: ah(32) }}
                          />
                          <LeadsWatchButton
                            onPress={handleFilters}
                            label="Apply"
                            style={{
                              height: ah(32),
                            }}
                            bg="#2230F8"
                            color="#FFFFFF"
                            borderColor="#05BBC9"
                          />
                        </Flex>
                      </ScrollView>
                    </Box>
                  )}
                </HStack>
              </Hidden>
            </HStack>
          </VStack>
        )}

        <VStack mt={ah(15)}>
          <VStack zIndex={0}>
            <LeadsWatchTable
              isOpen={isOpen}
              handleView={handleView}
              onChangeHeaderCheckbox={onChangeHeaderCheckbox}
              handleChangeCheckBox={handleChangeCheckBox}
              selectedRows={checked}
              screen="lead"
              sort={sort}
              heading={
                profile && profile.role === "publisher"
                  ? [
                      // "_id",
                      "date",
                      "email",
                      "phone",
                      // "time",
                      "system_response",
                      "publisher",
                      "buyer_response",
                      "campaign",
                      "Actions",
                    ]
                  : [...fields, "Actions"]
              }
              handleDownload={singleLeadDownload}
              handleSort={handleSort}
              sortIcon={true}
              data={
                currentPage === 1
                  ? displayLeads && displayLeads.slice(0, selectCount)
                  : requiredData
              }
              isLoading={isLoading}
            />
          </VStack>
          <VStack
            bottom={aw(5)}
            position="fixed"
            width={
              isOpen
                ? [aw(350), aw(350), aw(1168)]
                : [aw(350), aw(350), aw(1248)]
            }
          >
            <LeadsWatchPagination
              width="100%"
              totalCount={
                newLeads !== 0
                  ? allLeads &&
                    allLeads.total_count &&
                    allLeads.total_count + newLeads
                  : allLeads && allLeads.total_count && allLeads.total_count
                // displayLeads && displayLeads.length
              }
              currentPage={currentPage}
              pageSize={selectCount}
              onPageChange={(currentPage) => {
                setCurrentPage(currentPage);
                localStorage.setItem(
                  "page",
                  JSON.stringify({ ...page, operations: currentPage })
                );
              }}
            />
          </VStack>
        </VStack>
      </VStack>
      <LeadsWatchModal
        height={ah(680)}
        width={aw(400)}
        showModal={isImportModalOpen}
        onClose={() => {
          {
            setIsImportModalOpen(false);
            reset3();
          }
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Import Leads
            </Text>
            <Text
              color={"#212121"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            ></Text>
          </HStack>
        }
      >
        <VStack space={ah(20)}>
          <LeadsWatchAsyncSelect
            screen={
              getValues3("vertical_id") && getValues3("vertical_id").value
                ? "edit"
                : "create"
            }
            minWidth={aw(250)}
            type="async"
            defaultOptions={defaultOptionsForVertical}
            loadOptions={(inputValues, callback) =>
              loadOptions(inputValues, callback, "verticals")
            }
            onFocus={() => {
              if (allVerticals) {
                if (allVerticals.list.length < 10) {
                  fetchVerticals();
                }
              } else {
                fetchVerticals();
              }
            }}
            onInputChange={(inputValues) => {
              if (inputValues === "") {
                fetchVerticals();
                // return inputValue;
              }
            }}
            placeholder="Select Vertical"
            handleChange={(selectedOption) => {
              handleGetCampaigns(selectedOption.value);
              reset3({
                campaign_id: "",
                pub_id: "",
                vertical_id: selectedOption,
              });
            }}
            control={control3}
            name="vertical_id"
            rules={{
              required: {
                value: true,
              },
            }}
            invalid={errors3.vertical_id ? true : false}
            // invalid={true}
            error={errors3.vertical_id ? errors3.vertical_id.message : ""}
          />
          <Flex>
            <LeadsWatchAsyncSelect
              screen={
                getValues3("campaign_id") && getValues3("campaign_id").value
                  ? "edit"
                  : "create"
              }
              minWidth={aw(250)}
              type="async"
              defaultOptions={defaultOptionsForVerticalCam}
              loadOptions={(inputValues, callback) =>
                loadOptions(inputValues, callback, "verticalCampaign")
              }
              onInputChange={async (inputValues) => {
                if (
                  (inputValues === "" || inputValues === undefined) &&
                  getValues3("vertical_id").value
                ) {
                  try {
                    await dispatch(
                      getVerticalCampaigns(getValues3("vertical_id").value)
                    );
                  } catch (err) {
                    console.log(err);
                  }
                  // return inputValue;
                }
              }}
              placeholder="Select Campaign"
              handleChange={(selectedOption) => {
                handleGetPub(selectedOption.value);
                reset3({
                  campaign_id: selectedOption,
                  pub_id: "",
                  vertical_id: getValues3("vertical_id"),
                });
              }}
              control={control3}
              name="campaign_id"
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors3.campaign_id ? true : false}
              // invalid={true}
              error={errors3.campaign_id ? errors3.campaign_id.message : ""}
            />
          </Flex>

          <Flex>
            <LeadsWatchAsyncSelect
              screen={
                getValues3("pub_id") && getValues3("pub_id").value
                  ? "edit"
                  : "create"
              }
              minWidth={aw(250)}
              type="async"
              defaultOptions={defaultOptionsForCamPub}
              loadOptions={(inputValues, callback) =>
                loadOptions(inputValues, callback, "campaignPub")
              }
              onInputChange={async (inputValues) => {
                if (
                  (inputValues === "" || inputValues === undefined) &&
                  getValues3("campaign_id").value
                ) {
                  try {
                    await dispatch(
                      getCampaignPub(getValues3("campaign_id").value, 1, 10)
                    );
                  } catch (err) {
                    console.log(err);
                  }
                  // return inputValue;
                }
              }}
              placeholder="Select Publisher"
              control={control3}
              name="pub_id"
              rules={{
                required: {
                  value: true,
                },
              }}
              invalid={errors3.pub_id ? true : false}
              // invalid={true}
              error={errors3.pub_id ? errors3.pub_id.message : ""}
            />
          </Flex>
          <input
            onChange={handleChange}
            type="file"
            name="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,.csv"
          ></input>
          <HStack justifyContent={"flex-end"} space={aw(5)}>
            <LeadsWatchButton
              onPress={() => {
                setIsImportModalOpen(false);
                reset3();
              }}
              label="cancel"
              style={{ width: aw(100), height: ah(32) }}
              // bg={"#2230F8"}
              // color={"#F9FAFB"}
            />
            <LeadsWatchButton
              isLoading={importLoading}
              onPress={handleSubmit3(importLeads)}
              label="create"
              style={{ width: aw(100), height: ah(32) }}
              bg={"#2230F8"}
              color={"#F9FAFB"}
            />
          </HStack>
        </VStack>
      </LeadsWatchModal>
      <LeadsWatchModal
        height={ah(600)}
        width={aw(770)}
        showModal={isViewModalOpen}
        onClose={() => {
          setIsViewModalOpen(false);
        }}
        heading={
          <HStack space={ah(10)}>
            <Text
              color={"#000000"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            >
              Lead ID
            </Text>
            <Text
              color={"#212121"}
              fontFamily={"RobotoMedium"}
              fontSize={aw(18)}
            ></Text>
          </HStack>
        }
      >
        <Text
          fontFamily="RobotoMedium"
          color={"#000000"}
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Main Details
        </Text>
        <HStack space={aw(155)}>
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center" width={aw(300)}>
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                : {singleLead && singleLead.publisher_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center" color="#919191">
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                System Status
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead &&
                  singleLead.system_response &&
                  singleLead.system_response}
              </Text>
            </HStack>
            <HStack
              space={aw(14)}
              // alignItems="center"
              color="#919191"
              width={aw(300)}
            >
              <Text
                width={aw(91)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Response
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead &&
                  singleLead.response &&
                  JSON.stringify(singleLead.response)}
              </Text>
            </HStack>
          </VStack>
          <VStack space={ah(15)}>
            {singleLead &&
              singleLead.details &&
              singleLead.details.map((val) => (
                <HStack space={aw(14)} key={val["name"]} width={aw(280)}>
                  <Text
                    color="#919191"
                    fontSize={aw(14)}
                    width={aw(91)}
                    fontFamily="RobotoRegular"
                  >
                    {val["name"]}
                  </Text>
                  <Text
                    // width={aw(210)}
                    fontSize={aw(14)}
                    fontFamily="RobotoMedium"
                    color="#212121"
                  >
                    : {val["value"]}
                  </Text>
                </HStack>
              ))}
          </VStack>
        </HStack>
        <Divider
          mt={ah(9)}
          bg="#E6E7E9"
          height={ah(1)}
          // width={
          //   isOpen
          //     ? [aw(350), aw(350), aw(1168)]
          //     : [aw(350), aw(350), aw(1248)]
          // }
          width={[aw(350), aw(350), aw(1248)]}
        />
        <Text
          fontFamily="RobotoMedium"
          color="#212121"
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Other details
        </Text>
        <HStack space={aw(155)}>
          <VStack space={ah(15)}>
            <HStack
              space={aw(14)}
              alignItems="center"
              fontFamily="RobotoRegular"
            >
              <Text color="#919191" fontSize={aw(14)} width={aw(91)}>
                Campaign Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                :{" "}
                {singleLead && singleLead.campaign_id && singleLead.campaign_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead &&
                  singleLead.publisher_id &&
                  singleLead.publisher_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Vertical Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                :{" "}
                {singleLead && singleLead.vertical_id && singleLead.vertical_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="#212121">
                : {singleLead && singleLead.buyer_id && singleLead.buyer_id}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                fontSize={aw(14)}
                width={aw(91)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Response Id
              </Text>
              <Text fontSize={aw(14)} fontFamily="RobotoMedium" color="black">
                :{" "}
                {singleLead && singleLead.response_id && singleLead.response_id}
              </Text>
            </HStack>
          </VStack>
          <VStack space={ah(15)}>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Campaign Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.campaign &&
                  singleLead.campaign[0] &&
                  singleLead.campaign[0].campaign_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Publisher Name
              </Text>

              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.publisher &&
                  singleLead.publisher[0] &&
                  singleLead.publisher[0].publisher_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Vertical Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.vertical &&
                  singleLead.vertical[0] &&
                  singleLead.vertical[0].vertical_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer Name
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.buyer &&
                  singleLead.buyer[0] &&
                  singleLead.buyer[0].buyer_name}
              </Text>
            </HStack>
            <HStack space={aw(14)} alignItems="center">
              <Text
                width={aw(118)}
                fontSize={aw(14)}
                fontFamily="RobotoRegular"
                color="#919191"
              >
                Buyer response
              </Text>
              <Text
                fontSize={aw(14)}
                fontFamily="RobotoMedium"
                color="#212121"
                width={aw(118)}
              >
                :{" "}
                {singleLead &&
                  singleLead.buyer_response &&
                  singleLead.buyer_response}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <Divider
          my={ah(24)}
          bg="#D7D7D7"
          width={[aw(350), aw(350), aw(1248)]}
        />
        <Text
          fontFamily="RobotoMedium"
          color="#212121"
          mb={ah(18)}
          mt={ah(13)}
          fontSize={aw(14)}
        >
          Price details
        </Text>
        <HStack space={aw(213)}>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Cost
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              : {singleLead && singleLead.cost && singleLead.cost}
            </Text>
          </HStack>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Price
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              : {singleLead && singleLead.price && singleLead.price}
            </Text>
          </HStack>
          <HStack space={aw(14)}>
            <Text
              fontSize={aw(14)}
              fontFamily={"RobotoRegular"}
              color="#919191"
            >
              Profit
            </Text>
            <Text fontSize={aw(14)} fontFamily={"RobotoMedium"} color="#212121">
              :{" "}
              {singleLead &&
                singleLead.price &&
                singleLead.cost &&
                singleLead.price - singleLead.cost}
            </Text>
          </HStack>
        </HStack>
      </LeadsWatchModal>
    </HStack>
  );
};
export default Operations;
