import * as React from "react";
import Svg, { G, Rect, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchDeleteIcon = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <G id="Delete_icon" transform="translate(-1138 -482)">
      <Rect
        id="Rectangle_803"
        data-name="Rectangle 803"
        width={aw(24)}
        height={ah(24)}
        rx={4}
        transform="translate(1138 482)"
        fill="none"
      />
      <Path
        id="Path_10305"
        data-name="Path 10305"
        d="M-1.512-15.75H1.512a2.162,2.162,0,0,1,1.582.668A2.162,2.162,0,0,1,3.762-13.5v.738H6.75a.666.666,0,0,1,.527.246.715.715,0,0,1,.211.527.715.715,0,0,1-.211.527.715.715,0,0,1-.527.211H6.012v9A2.089,2.089,0,0,1,5.344-.668,2.162,2.162,0,0,1,3.762,0H-3.762A2.162,2.162,0,0,1-5.344-.668,2.089,2.089,0,0,1-6.012-2.25v-9H-6.75a.715.715,0,0,1-.527-.211.715.715,0,0,1-.211-.527.715.715,0,0,1,.211-.527.666.666,0,0,1,.527-.246h2.988V-13.5a2.162,2.162,0,0,1,.668-1.582A2.162,2.162,0,0,1-1.512-15.75ZM4.5-2.25v-9h-9v9a.715.715,0,0,0,.211.527.715.715,0,0,0,.527.211H3.762a.715.715,0,0,0,.527-.211A.715.715,0,0,0,4.5-2.25ZM1.512-14.238H-1.512a.715.715,0,0,0-.527.211.715.715,0,0,0-.211.527v.738h4.5V-13.5a.715.715,0,0,0-.211-.527A.8.8,0,0,0,1.512-14.238Z"
        transform="translate(1150 502)"
        fill={props.color || "#212121"}
      />
    </G>
  </Svg>
);
export default LeadsWatchDeleteIcon;
