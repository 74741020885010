import * as events from "../Events";
const token = localStorage.getItem("auth");

export const createGroup = (data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/groups/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const newGroup = resData.data;
        dispatch({ type: events.CREATE_GROUP, data: newGroup });
        return newGroup ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};

export const getAllGroups = (page, limit, data, search, sort) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/groups?page=${page}&limit=${limit}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ filters: data, search, sort }),
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const allGroups = resData.data;
        dispatch({ type: events.GET_ALL_GROUPS, data: allGroups });
        return allGroups ? allGroups : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getSinglegroup = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/groups/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const singleGroup = resData.data;
        dispatch({ type: events.GET_SINGLE_GROUP, data: singleGroup });
        return singleGroup ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const editGroup = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/groups/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const updatedGroup = resData.data;
        dispatch({ type: events.EDIT_GROUP, data: updatedGroup });
        return updatedGroup ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const deleteGroup = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${events.API_URL}/groups/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const delatedGroup = resData.data;
        dispatch({ type: events.DELETE_GROUP, data: delatedGroup });
        return delatedGroup ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getVerticalGroups = (id, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/groups/vertical/${id}?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const groups = resData.data;
        dispatch({ type: events.GET_VERTICAL_GROUPS, data: groups });
        return groups ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
export const getGroupByVertical = (type, page, limit) => {
  return async (dispatch) => {
    try {
      const response = await fetch(
        `${events.API_URL}/groups/type/${type}?page=${page}&limit=${limit}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const resError = await response.json();

        if (resError && resError.error) {
          throw new Error(resError.error);
        }
      } else {
        const resData = await response.json();

        const groups = resData.data;
        dispatch({ type: events.GET_VERTICAL_GROUPS, data: groups });
        return groups ? true : false;
      }
    } catch (err) {
      throw new Error(err.message);
    }
  };
};
