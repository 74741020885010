import * as React from "react";
import Svg, { Defs, ClipPath, Rect, G, Path } from "react-native-svg";
import { aw, ah } from "../../utils";
const LeadsWatchCallCancelIcon = (props) => (
  <Svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={aw(24)}
    height={ah(24)}
    viewBox="0 0 24 24"
    {...props}
  >
    <Defs>
      <ClipPath id="clip-path">
        <Rect
          id="Rectangle_1"
          data-name="Rectangle 1"
          width={aw(24)}
          height={ah(24)}
          fill="none"
        />
      </ClipPath>
    </Defs>
    <G id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
      <Path
        id="Path_1"
        data-name="Path 1"
        d="M16.15,9.38a13.126,13.126,0,0,0-8.31,0v2.77a.778.778,0,0,1-.5.8,9.777,9.777,0,0,0-2.59,1.89.864.864,0,0,1-.6.25.829.829,0,0,1-.63-.25L1.31,12.63a.921.921,0,0,1,0-1.24A15.063,15.063,0,0,1,15.9,7.44a14.905,14.905,0,0,1,6.81,3.95.921.921,0,0,1,0,1.24L20.5,14.84a.808.808,0,0,1-.63.25.828.828,0,0,1-.6-.25,10.175,10.175,0,0,0-2.59-1.89.861.861,0,0,1-.5-.8V9.38Z"
        fill="#fff"
      />
    </G>
  </Svg>
);
export default LeadsWatchCallCancelIcon;
