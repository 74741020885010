import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

import { aw, ah } from "../../utils";

export default function LeadsWatchShowMoreIcon(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      viewBox="0 0 24 24"
      {...props}
    >
      <G transform="translate(-268 -407)">
        <Path
          data-name="Rectangle 287"
          transform="translate(268 407)"
          fill="none"
          d="M0 0H24V24H0z"
        />
        <Path
          data-name="Path 10209"
          d="M9.882-7.2l-3.717-3.717 1.062-1.053L12-7.2 7.227-2.43 6.165-3.492z"
          transform="translate(271 426)"
          fill={props.color}
        />
      </G>
    </Svg>
  );
}
