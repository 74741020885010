import React from "react";
import { aw, ah } from "../../utils";

function LeadsWatchMoveIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={aw(24)}
      height={ah(24)}
      data-name="Layer 1"
      viewBox="0 0 24 24"
    >
      <defs>
        <clipPath id="clip-path">
          <path fill="none" d="M0 0H24V24H0z" data-name="Rectangle 893"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)" data-name="Group 1098">
        <g data-name="Group 1097">
          <path
            d="M20.07 5.71h-.91c-2.03 0-2.03-3.14-3.46-3.14H7.07A2.928 2.928 0 004.14 5.5v3.36h1.57V5.5a1.361 1.361 0 011.36-1.36h8.34c.1.15.22.38.31.55.53.97 1.41 2.6 3.45 2.6h.91a1.553 1.553 0 011.36 1.57v9.65a1.361 1.361 0 01-1.36 1.36H7.07a1.361 1.361 0 01-1.36-1.36v-1.79H4.14v1.79a2.928 2.928 0 002.93 2.93h13A2.928 2.928 0 0023 18.51V8.86a3.114 3.114 0 00-2.93-3.14z"
            data-name="Path 10374"
          ></path>
          <path
            d="M11.64 16.47l3.27-3.19v-.01a1.144 1.144 0 00.17-.33c.03-.08.05-.02.05-.1v-.08a.493.493 0 00-.06-.23.771.771 0 00-.14-.21.219.219 0 00-.03-.05l-3.27-3.26a.819.819 0 00-1.14.14.8.8 0 000 1.14l1.89 1.74H1.83a.783.783 0 00-.83.76.76.76 0 00.83.73h10.55l-1.89 1.95a.8.8 0 000 1.14c.16.16.36.09.57.09a.8.8 0 00.57-.24z"
            data-name="Path 10375"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default LeadsWatchMoveIcon;
